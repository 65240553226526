import { ConditionBaseProps } from '..'
import {
  StyledCondition,
  StyledConditionContent,
  StyledConditionLabel
} from '../index.styled'
import { SwitchLabel } from 'components/atoms/switch'
import { MouseEvent, useEffect, useState } from 'react'

export type ConditionSwitchProps = ConditionBaseProps<boolean> & {
  label?: string
  placeholder?: string
}

export const ConditionSwitchComponent = (props: ConditionSwitchProps) => {
  const {
    className,
    placeholder,
    defaultValue,
    value: valueProps,
    onChange: onChangeProps
  } = props
  const [value, setValue] = useState<boolean>(
    Boolean(defaultValue || valueProps)
  )

  const onChange = (val: boolean, _e: MouseEvent) => {
    const newValue = val
    setValue(newValue)
    onChangeProps && onChangeProps(newValue)
  }

  useEffect(() => {
    setValue(Boolean(valueProps))
  }, [valueProps])

  return (
    <SwitchLabel
      placeholder={placeholder}
      checked={value}
      onChange={onChange}
      containerClassname={className}
    />
  )
}
export const ConditionSwitch = (props: ConditionSwitchProps) => {
  const { label, className } = props

  return (
    <StyledCondition className={className}>
      {label && <StyledConditionLabel>{label}</StyledConditionLabel>}
      <StyledConditionContent>
        <ConditionSwitchComponent {...props} />
      </StyledConditionContent>
    </StyledCondition>
  )
}
