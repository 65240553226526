import { StyledDropdownMenu, StyledDropdownOverlay } from './index.styled'
import { Button, Dropdown, MenuProps } from 'antd'
import { Kebab } from 'icons'
import { ItemMenu } from 'interfaces'

export type TableDetailDropdownProps = {
  items: ItemMenu[]
  onClick: (key: string) => void
}
export const TableDetailDropdown = (props: TableDetailDropdownProps) => {
  const { items, onClick: onClickProps } = props

  const onClick: MenuProps['onClick'] = (item) => {
    onClickProps(item.key)
  }

  return (
    <Dropdown
      trigger={['click']}
      overlay={
        <StyledDropdownOverlay>
          <StyledDropdownMenu
            items={items}
            onClick={onClick}
            className="menuDropdownTable"
          />
        </StyledDropdownOverlay>
      }
      destroyPopupOnHide>
      {/* <StyledButton
        type="link"
        icon={<Icon component={Kebab} />}
        className="btnDetail"
      /> */}

      <Button type="link" icon={<Kebab />} />
    </Dropdown>
  )
}

export default TableDetailDropdown
