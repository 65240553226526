import {
  CampaignStepOverviewsValue,
  CampaignStepSegmentsValue,
  CampaignStepSitesValue,
  CampaignStepTemplatesValue
} from './CampaignStep'
import { FormInstance } from 'antd/lib/form'

export type CampaignFormRef = {
  overviewsForm: FormInstance | null
  sitesForm: FormInstance | null
  segmentsForm: FormInstance | null
  templatesForm: FormInstance | null
  onChangeStep: (step: number) => void
  modalOnClose: () => void
}
export type CampaignFormValue = CampaignStepOverviewsValue &
  CampaignStepSitesValue &
  CampaignStepSegmentsValue &
  CampaignStepTemplatesValue & {
    id?: string | number
    status?: string
  }
export type CampaignFormProps<T = CampaignFormValue> = {
  title?: string
  notFound?: boolean
  loading?: boolean
  disabled?: boolean
  disabledReady?: boolean
  template_type?: string
  defaultValue?: T
  value?: T
  onSave?: (value: T, isExit?: boolean) => void
  roles?: string[]
  permissions?: string[]
}

export const formatCampaignFormValue = (input: any): CampaignFormValue => {
  const formValue: CampaignFormValue = { ...input }

  if (input?.segments && input.segments?.length) {
    formValue.segments_ids = input.segments.map(({ id }: any) => id)
  }

  if (input?.sites && input.sites?.length) {
    formValue.site_ids = input.sites
  }

  if (input?.start_datetime) {
    formValue.start_time = input.start_datetime
  }

  if (input?.end_datetime) {
    formValue.end_time = input.end_datetime
  }

  if (input?.template_id === 0) {
    formValue.template_id = null
  }

  if (!input?.push_type || input?.push_type === '') {
    formValue.push_type = 'segment'
  }

  return formValue
}
