import {
  StyledSegmentPrediction,
  StyledSegmentPredictionItem,
  StyledSegmentPredictionSkeleton
} from './SegmentPrediction.styled'
import { SegmentPredictionHighchartsSlider } from './SegmentPredictionHighchartsSlider'
import Icon from '@ant-design/icons'
import { Button, ButtonProps, Divider } from 'antd'
import cn from 'classnames'
import { UseFormStoreReturn, useFormStore } from 'hooks/useFormValue'
import { CheckedCircle } from 'icons'
import { Close, Expand, UserOutline } from 'icons/V2'
import {
  CSSProperties,
  MouseEventHandler,
  ReactNode,
  Ref,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo
} from 'react'
import { formatNumberToCurrency } from 'utils'

const SEGMENT_PREDICTION_DECIMAL = 10 ** 10
const SEGMENT_PREDICTION_DELTA = 1 / SEGMENT_PREDICTION_DECIMAL
export const SEGMENT_PREDICTION_VALUES: Record<string, [number, number]> = {
  TOP_20: [0.8 + SEGMENT_PREDICTION_DELTA, 1],
  BOTTOM_80: [SEGMENT_PREDICTION_DELTA, 0.8],
  TOP_50: [0.5 + SEGMENT_PREDICTION_DELTA, 1],
  BOTTOM_50: [SEGMENT_PREDICTION_DELTA, 0.5]
}
const SEGMENT_PREDICTION_VALUES_CUSTOM: [number, number] = [
  0.3 + SEGMENT_PREDICTION_DELTA,
  0.8
]

export const getSegmentPredictionSliderValue = (value?: [number, number]) => {
  const newValue = SEGMENT_PREDICTION_VALUES.TOP_20.slice(0) as [number, number]
  if (typeof value?.[0] === 'number' && typeof value?.[1] === 'number') {
    newValue[0] = (value?.[0] - SEGMENT_PREDICTION_DELTA) * 100
    newValue[1] = value?.[1] * 100
  }

  return newValue
}
export type SegmentPredictionValue = {
  field: string
  type: string
  operator: string
  value: [number, number]
}
export type SegmentPredictionRef = UseFormStoreReturn<SegmentPredictionValue>
export type SegmentPredictionProps = {
  loading?: boolean
  data?: any
  disabled?: boolean
  defaultValue?: SegmentPredictionValue
  value?: SegmentPredictionValue
  onChange?: (value?: SegmentPredictionValue) => void
  onFinish?: (value?: SegmentPredictionValue) => void
  onClose?: ButtonProps['onClick']
  buttonFullScreenProps?: Pick<ButtonProps, 'icon'>
  onFullScreen?: ButtonProps['onClick']
}
const SegmentPredictionRenderFunc = (
  props: SegmentPredictionProps,
  ref?: Ref<SegmentPredictionRef>
) => {
  const {
    loading,
    data,
    disabled: disabledProps,
    defaultValue,
    value: valueProps,
    onChange: onChangeProps,
    onFinish: onFinishProps,
    onClose,
    onFullScreen,
    buttonFullScreenProps: { icon: IconFS, ...restButtonFullScreenProps } = {}
  } = props
  const iconFullScreen = IconFS || <Icon component={Expand} />
  const store = useFormStore<SegmentPredictionValue>({
    defaultValue,
    value: valueProps,
    onChange: onChangeProps,
    onFinish: onFinishProps
  })

  const activeKey = useMemo(() => {
    let activeKey = 'custom'
    Object.keys(SEGMENT_PREDICTION_VALUES).forEach((key) => {
      const value = SEGMENT_PREDICTION_VALUES[key]
      if (
        value[0] === store?.value?.value?.[0] &&
        value[1] === store.value?.value?.[1]
      ) {
        activeKey = key
      }
    })
    return activeKey
  }, [store.value?.value])

  const total = useMemo(() => {
    if (data && data?.data_insight) {
      const insight = data.data_insight
      return Object.keys(insight).reduce((total, k: string) => {
        const value = insight[k]
        return total + value
      }, 0)
    }
    return 0
  }, [data])

  const disabledSegmentPredictionItem = useMemo(() => {
    if (typeof loading !== 'undefined') {
      return !!loading
    }
    return !!disabledProps
  }, [disabledProps, loading])

  const textViewValue = useMemo(() => {
    if (!store.value?.value) {
      return ''
    }
    return `${(store.value?.value[0] - SEGMENT_PREDICTION_DELTA) * 100}% - ${
      store.value?.value[1] * 100
    }%`
  }, [store.value?.value])

  const onChange = useCallback(
    (value: [number, number]) => {
      return () => {
        store.onChange({ value })
      }
    },
    [store]
  )

  const onChangeSegmentPredictionSlider = useCallback(
    (value?: [number, number]) => {
      if (typeof value?.[0] === 'number' && typeof value?.[1] === 'number') {
        const newValue: [number, number] = [
          value[0] / 100 + SEGMENT_PREDICTION_DELTA,
          value[1] / 100
        ]
        onChange(newValue)()
      }
    },
    [onChange]
  )

  useImperativeHandle(ref, () => store, [store])

  return (
    <StyledSegmentPrediction className="segmentPrediction flex flex-col">
      <div className="segmentPredictionHead">
        <div className="flex w-full mb-[10px]">
          <h5 className="m-0 flex-1 min-w-0 text-[18px] text-gray800 font-bold">
            Explore Segment Prediction
          </h5>
          <div className="flex gap-4">
            {!!onFullScreen && (
              <Button
                {...restButtonFullScreenProps}
                type="link"
                icon={iconFullScreen}
                onClick={onFullScreen}
                className="!w-[24px] !h-[24px] !p-0 text-gray600"
              />
            )}
            {!!onClose && (
              <Button
                type="link"
                icon={<Icon component={Close} />}
                onClick={onClose}
                className="!w-[24px] !h-[24px] !p-0 text-gray600"
              />
            )}
          </div>
        </div>
        <p className="m-0 text-gray500 max-w-[867px]">
          This interaction chart helps you see how likely your target audience
          are to convert/open to your goal. Use radio buttons to quickly select
          top 20% or bottom 80% of users, or select a custom range by using the
          slider.
        </p>
        <Divider className="!my-5 border-neutral100" />
      </div>
      <div className="segmentPredictionBody flex-1 min-h-0 h-full overflow-auto">
        <div className="flex gap-4 items-start">
          <div className="predictionList w-1/3 max-w-[320px]">
            <SegmentPredictionItem
              loading={loading}
              disabled={disabledSegmentPredictionItem}
              active={activeKey === 'TOP_20'}
              title="Top 20%"
              users={total}
              onClick={onChange(SEGMENT_PREDICTION_VALUES.TOP_20)}
              description="Likelier to convert/open than average"
            />
            <SegmentPredictionItem
              loading={loading}
              disabled={disabledSegmentPredictionItem}
              active={activeKey === 'BOTTOM_80'}
              title="Bottom 80%"
              users={total}
              onClick={onChange(SEGMENT_PREDICTION_VALUES.BOTTOM_80)}
              description="Likelier to convert/open than average"
            />
            <SegmentPredictionItem
              loading={loading}
              disabled={disabledSegmentPredictionItem}
              active={activeKey === 'TOP_50'}
              title="Top 50%"
              users={total}
              onClick={onChange(SEGMENT_PREDICTION_VALUES.TOP_50)}
              description="Likelier to convert/open than average"
            />
            <SegmentPredictionItem
              loading={loading}
              disabled={disabledSegmentPredictionItem}
              active={activeKey === 'BOTTOM_50'}
              title="Bottom 50%"
              users={total}
              onClick={onChange(SEGMENT_PREDICTION_VALUES.BOTTOM_50)}
              description="Likelier to convert/open than average"
            />
            <SegmentPredictionItem
              loading={loading}
              disabled={disabledSegmentPredictionItem}
              active={activeKey === 'custom'}
              title="Custom"
              users={total}
              onClick={onChange(SEGMENT_PREDICTION_VALUES_CUSTOM)}
              description="Likelier to convert/open than average"
            />
          </div>
          <div className="flex-1 min-w-0 p-4 border border-solid border-gray100 rounded-lg text-gray600 font-normal">
            <SegmentPredictionHighchartsSlider
              loading={loading}
              data={{ ...data?.data_insight }}
              type={activeKey}
              disabled={disabledSegmentPredictionItem}
              defaultValue={getSegmentPredictionSliderValue(
                defaultValue?.value || store.value?.value
              )}
              value={getSegmentPredictionSliderValue(store.value?.value)}
              onChange={onChangeSegmentPredictionSlider}
            />
            <Divider className="!my-4 border-gray100" />
            <div className="mb-2 text-base text-gray800 font-semibold">
              <StyledSegmentPredictionSkeleton
                active
                loading={loading}
                className="title">
                {formatNumberToCurrency(total)} users selected
              </StyledSegmentPredictionSkeleton>
            </div>
            <div className="m-0">
              <StyledSegmentPredictionSkeleton
                active
                loading={loading}
                className="title">
                Viewing users in the {textViewValue}
              </StyledSegmentPredictionSkeleton>
            </div>
            <div className="m-0">
              <StyledSegmentPredictionSkeleton
                active
                loading={loading}
                title={{ width: '100%' }}
                className="title">
                Likelier to convert/open than average
              </StyledSegmentPredictionSkeleton>
            </div>
            <div className="my-5">
              <StyledSegmentPredictionSkeleton
                active
                loading={loading}
                title={false}
                paragraph={{ rows: 2 }}>
                Create an audience using this selection of users to target them
                with personalized messages in a journey
              </StyledSegmentPredictionSkeleton>
            </div>
            <Button
              type="primary"
              size="small"
              className="px-3 py-[5px]"
              disabled={loading}
              onClick={() => store.onFinish()}>
              Create Audience
            </Button>
          </div>
        </div>
      </div>
    </StyledSegmentPrediction>
  )
}

type SegmentPredictionItemProps = {
  loading?: boolean
  disabled?: boolean
  active?: boolean
  title?: ReactNode
  users?: number
  description?: ReactNode
  onClick?: MouseEventHandler<HTMLElement>
  className?: string
  style?: CSSProperties
}
const SegmentPredictionItem = (props: SegmentPredictionItemProps) => {
  const {
    loading,
    disabled,
    active,
    title,
    users,
    description,
    onClick,
    className,
    style
  } = props
  const userNumberString =
    typeof users === 'undefined' ? '--' : formatNumberToCurrency(users)
  return (
    <StyledSegmentPredictionItem
      onClick={onClick}
      className={cn(
        className,
        'predictionItem',
        'text-gray600 font-normal',
        'border border-solid border-gray100 rounded-lg p-4',
        'hover:bg-blue50 hover:border-transparent',
        '[&.active]:bg-blue50 [&.active]:border-transparent',
        '[&.disabled]:pointer-events-none',
        'transition-colors',
        'cursor-pointer',
        { active, disabled }
      )}
      style={style}>
      <div className="flex items-center mb-2">
        <label
          className={cn(
            'predictionItem-label',
            'flex-1 min-w-0 m-0',
            'text-base text-gray800 font-semibold'
          )}>
          <StyledSegmentPredictionSkeleton
            active
            loading={loading}
            className="title">
            {title}
          </StyledSegmentPredictionSkeleton>
        </label>
        {!!active && (
          <Icon component={CheckedCircle} className="text-primary text-2xl" />
        )}
      </div>
      <div className="flex items-center leading-[22px]">
        <Icon
          component={UserOutline}
          className="w-[18px] h-[18px] text-[18px] leading-none"
        />
        <StyledSegmentPredictionSkeleton
          active
          loading={loading}
          className="title"
          title={{ width: '100%' }}>
          <span>{active ? userNumberString : '--'} users</span>
        </StyledSegmentPredictionSkeleton>
      </div>
      <div className="m-0">
        <StyledSegmentPredictionSkeleton
          active
          loading={loading}
          className="title"
          title={{ width: '100%' }}>
          {description}
        </StyledSegmentPredictionSkeleton>
      </div>
    </StyledSegmentPredictionItem>
  )
}
export const SegmentPrediction = forwardRef(SegmentPredictionRenderFunc)
