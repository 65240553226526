import { useWorkflowBuilder } from '../../index.context'
import { WorkflowBuilderDrawerTemplate } from '../WorkflowBuilderDrawerTemplate'
import { WorkflowBuilderHandle } from '../WorkflowBuilderHandle'
import { WorkflowBuilderNodeTemplate } from '../WorkflowBuilderNodeTemplate'
import { WFNode, useNode } from '../index.helpers'
import {
  StyledWFButton,
  StyledWFTitle,
  StyledWFTitleContainer,
  WFDrawerButton,
  WFDrawerFooter,
  WFDrawerFooterGroup,
  WFForm,
  WFPreview,
  WFPreviewContainer,
  WFPreviewMode,
  WFPreviewModeButton,
  WFPreviewWrapper
} from '../index.styled'
import Icon from '@ant-design/icons'
import { Form, Input, Skeleton, notification } from 'antd'
import cn from 'classnames'
import { ListTemplate } from 'components/atoms/list/listTemplate'
import {
  CheckedCircle,
  ChevronLeft,
  Close,
  Edit,
  Iphone,
  ScreenEmpty,
  TrashBin
} from 'icons'
import { OptionType } from 'interfaces'
import { TemplateBuilderPreviewIframe } from 'pages/templateBuilderCreate/TemplateBuilderPreview'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Position } from 'reactflow'
import { TemplateServices } from 'services/template'
import styled from 'styled-components'

const StyledEmailInsight = styled.div`
  color: ${({ theme }) => theme?.colors?.gray400};
`

const configInsightsDelivery = {
  total_email_sends: { label: 'Total sends', unit: '' },
  total_emails_delivered: { label: 'Delivery', unit: '' }
}
const configInsightsEngagement = {
  total_email_opens: { label: 'Open', unit: '' },
  total_email_clicked: { label: 'Click', unit: '' }
}

const WFEmail: WFNode = (props) => {
  const {
    id,
    data,
    title: titleProps,
    bgColor,
    color,
    icon,
    modalWidth
  } = props
  const [form] = Form.useForm()
  const { site, disabled, nodes, setNodes, onNodesDelete, onNodesChangeData } =
    useWorkflowBuilder()
  const { open, onOpen, onClose, onUpdate, onCancel, onDelete } = useNode({
    id,
    form,
    nodes,
    setNodes,
    onNodesDelete,
    onNodesChangeData
  })
  const [isPreview, setIsPreview] = useState(Boolean(data?.template_id))
  const [previewLoading, setPreviewLoading] = useState(false)
  const [previewInfo, setPreviewInfo] = useState<any>({})
  const [previewId, setPreviewId] = useState<string | number | undefined>()
  const [previewMode, setPreviewMode] = useState<'mobile' | 'desktop'>(
    'desktop'
  )

  const [editTitle, setEditTitle] = useState(false)
  const [title, setTitle] = useState(data?.label || titleProps)

  const emailInsights = useMemo(() => {
    const insights: OptionType[] = []
    if (data?.campaignInsightDelivery) {
      Object.keys(configInsightsDelivery).forEach((k) => {
        const key = k as keyof typeof configInsightsDelivery
        const config = configInsightsDelivery[key]
        if (typeof data.campaignInsightDelivery?.[key] !== 'undefined') {
          insights.push({
            value: data.campaignInsightDelivery[key] + config.unit,
            label: config.label
          })
        }
      })
    }
    if (data?.campaignInsightEngagement) {
      Object.keys(configInsightsEngagement).forEach((k) => {
        const key = k as keyof typeof configInsightsEngagement
        const config = configInsightsEngagement[key]
        insights.push({
          value: data.campaignInsightEngagement[key] + config.unit,
          label: config.label
        })
      })
    }
    return insights
  }, [data?.campaignInsightDelivery, data?.campaignInsightEngagement])

  const onPreview = useCallback(async () => {
    try {
      const values = await form.validateFields()
      if (!values?.template_id) {
        notification.error({
          message: 'Please choose template before review!'
        })
        return
      }
      setIsPreview(true)
      setPreviewId(values.template_id)
    } catch (error) {}
  }, [form])

  const onPreviewMode = useCallback((preview: 'mobile' | 'desktop') => {
    return () => {
      setPreviewMode(preview)
    }
  }, [])

  const onBack = useCallback(() => {
    setIsPreview(false)
  }, [])

  const onSave = useCallback(() => {
    const nodeChanged = nodes.find((node) => node.id === id)
    if (!nodeChanged || !previewId) {
      return
    }
    nodeChanged.data = {
      ...nodeChanged.data,
      template_id: previewId,
      label: title
    }
    onUpdate(nodeChanged)
    onClose({ isReset: false })
  }, [id, nodes, onClose, onUpdate, previewId, title])

  const onDiscard = useCallback(() => {
    setPreviewId(data?.template_id)
    setIsPreview(Boolean(data?.template_id))
    onCancel()
  }, [data?.previewId])

  const descriptionComp = useMemo(() => {
    if (!data?.template_id) {
      return ''
    }
    return `Template ID : ${data.template_id}`
  }, [data?.template_id])

  const titleComp = useMemo(() => {
    if (editTitle) {
      return (
        <StyledWFTitleContainer>
          <Input
            size="small"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            maxLength={31}
            status={title?.length > 30 ? 'error' : undefined}
          />
          <StyledWFButton
            danger
            icon={<Icon component={Close} />}
            onClick={() => {
              setTitle(data?.label || titleProps)
              setEditTitle(false)
            }}
          />
          <StyledWFButton
            className="checked"
            icon={<Icon component={CheckedCircle} />}
            onClick={() => {
              const nodeChanged = nodes.find((node) => node.id === id)
              if (!nodeChanged) {
                return
              }

              nodeChanged.data = { ...nodeChanged.data, label: title }
              onUpdate(nodeChanged)
              onClose({ isReset: false })
              setEditTitle(false)
            }}
          />
        </StyledWFTitleContainer>
      )
    }
    return (
      <StyledWFTitleContainer>
        <StyledWFTitle>{title}</StyledWFTitle>
        <StyledWFButton
          icon={<Icon component={Edit} />}
          onClick={() => setEditTitle(true)}
        />
      </StyledWFTitleContainer>
    )
  }, [editTitle, title, onClose, onUpdate])

  const footerComp = useMemo(() => {
    if (isPreview) {
      return (
        <WFDrawerFooter>
          <WFDrawerButton
            icon={<Icon component={ChevronLeft} />}
            size="small"
            onClick={onBack}
            className="back">
            Back
          </WFDrawerButton>
          <WFDrawerFooterGroup>
            <WFDrawerButton
              onClick={onDelete(id)}
              icon={<Icon component={TrashBin} />}
              size="small"
            />
            <WFDrawerButton onClick={onDiscard} size="small">
              Discard
            </WFDrawerButton>
            <WFDrawerButton
              disabled={disabled}
              onClick={onSave}
              type="primary"
              size="small">
              Select Template
            </WFDrawerButton>
          </WFDrawerFooterGroup>
        </WFDrawerFooter>
      )
    }
    return (
      <WFDrawerFooter>
        <WFDrawerFooterGroup>
          <WFDrawerButton
            onClick={onDelete(id)}
            icon={<Icon component={TrashBin} />}
            size="small"
          />
          <WFDrawerButton onClick={onPreview} type="primary" size="small">
            Preview Template
          </WFDrawerButton>
        </WFDrawerFooterGroup>
      </WFDrawerFooter>
    )
  }, [isPreview, onDelete, id, onPreview, onBack, onCancel, onSave])

  useEffect(() => {
    if (!previewId || typeof previewId === 'boolean') {
      return
    }
    ;(async () => {
      try {
        setPreviewLoading(true)
        const previewRes = await TemplateServices.getById(previewId)
        const { data: previewData } = previewRes
        if (!previewData?.data) {
          throw new Error(previewRes?.data?.error)
        }

        setPreviewInfo(previewData.data)
      } catch (error: any) {
        console.log('** Error preview ', previewId, ' : ', error)
      } finally {
        setPreviewLoading(false)
      }
    })()
  }, [previewId])

  useEffect(() => {
    if (open) {
      setPreviewId(data?.template_id)
      setEditTitle(false)
    }
  }, [data?.template_id, open])

  return (
    <>
      <WorkflowBuilderNodeTemplate
        isShowDivider={Boolean(emailInsights.length)}
        bgColor={bgColor}
        color={color}
        icon={icon}
        title={title}
        description={descriptionComp}
        onClick={onOpen}
        sources={
          <WorkflowBuilderHandle
            type="source"
            id={`${id}-source`}
            position={Position.Bottom}
          />
        }
        targets={
          <WorkflowBuilderHandle
            type="target"
            id={`${id}-target`}
            position={Position.Top}
          />
        }>
        {Boolean(emailInsights.length) &&
          emailInsights.map((insight, index) => {
            return (
              <StyledEmailInsight key={index.toString()}>
                {insight.label}: {insight.value || '--'}
              </StyledEmailInsight>
            )
          })}
      </WorkflowBuilderNodeTemplate>
      <WorkflowBuilderDrawerTemplate
        open={open}
        width={modalWidth}
        title={titleComp}
        onClose={onClose}
        onUpdate={onPreview}
        footer={footerComp}
        className="disableBodyScroll"
        destroyOnClose>
        <WFForm form={form} initialValues={data}>
          <Form.Item name="template_id">
            <ListTemplate site={site} type="email" />
          </Form.Item>
        </WFForm>
        <WFPreview className={cn({ isPreview })}>
          <WFPreviewContainer>
            {previewLoading ? (
              <Skeleton active title paragraph={{ rows: 5 }} />
            ) : (
              <WFPreviewWrapper
                className={cn({
                  isDesktop: Boolean(previewMode === 'desktop')
                })}>
                <TemplateBuilderPreviewIframe
                  isMobile={Boolean(previewMode === 'mobile')}
                  htmlTemplate={previewInfo?.template?.body_html || ''}
                />
              </WFPreviewWrapper>
            )}
          </WFPreviewContainer>
          <WFPreviewMode>
            <WFPreviewModeButton
              type="link"
              onClick={onPreviewMode('desktop')}
              icon={<Icon component={ScreenEmpty} />}
              className={cn({
                isActive: Boolean(previewMode === 'desktop')
              })}
            />
            <WFPreviewModeButton
              type="link"
              onClick={onPreviewMode('mobile')}
              icon={<Icon component={Iphone} />}
              className={cn({
                isActive: Boolean(previewMode === 'mobile')
              })}
            />
          </WFPreviewMode>
        </WFPreview>
      </WorkflowBuilderDrawerTemplate>
    </>
  )
}

WFEmail.validate = (node) => {
  if (!node.data || !node.data?.template_id) {
    return false
  }
  return true
}
export { WFEmail }
