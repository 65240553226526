import {
  getWorkflowBuilderQueriesSegments,
  useWorkflowBuilder,
  useWorkflowBuilderQueries
} from '../../index.context'
import { WorkflowBuilderDrawerTemplate } from '../WorkflowBuilderDrawerTemplate'
import { WorkflowBuilderHandle } from '../WorkflowBuilderHandle'
import { WorkflowBuilderNodeTemplate } from '../WorkflowBuilderNodeTemplate'
import { WFNode, useNode } from '../index.helpers'
import { FormInstance, Skeleton, Tag } from 'antd'
import {
  FormWorkflowStartingPoint,
  FormWorkflowStartingPointValue
} from 'components/molecules/form/FormWorkflow'
import { SEGMENT_TYPE } from 'constants/segment'
import { EventItem } from 'interfaces/event'
import { Segment } from 'interfaces/segment'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Node, Position } from 'reactflow'
import { EventServices } from 'services/event'
import { segmentServices } from 'services/segment'
import styled from 'styled-components'
import { cssLimitLines } from 'styles'
import { formatNumberToCurrency } from 'utils'

const StyledTagNameInner = styled.div`
  ${cssLimitLines(1)}
`

const StyledTagName = styled.div`
  position: relative;
  flex: 1;
  min-width: 0;
  padding-right: 4px;
  margin-right: 4px;
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    height: 60%;
    width: 1px;
    border-right: 1px solid;
    transform: translate(-50%, -50%);
  }
`

const StyledTag = styled(Tag)`
  display: flex;
  max-width: 100%;
  margin: 0;
  margin-bottom: 4px;
  padding: 2px 8px;
  font-size: 12px;
  line-height: 1.5;
  border-color: transparent;
`

const MAPING_SEGMENT_TYPE_WITH_OLD = [
  'segment',
  SEGMENT_TYPE.ATTRIBUTE,
  SEGMENT_TYPE.EVENT,
  SEGMENT_TYPE.CSV
]

export async function fetchStartingPointInfo(
  id?: string | number,
  type?: string
) {
  const info = { name: '', number: '', data: null }
  if (!id || !type) {
    return info
  }
  if (MAPING_SEGMENT_TYPE_WITH_OLD.includes(type)) {
    try {
      const response: any = await segmentServices.getSegment(id)
      if (response._message || !response.data) {
        throw new Error(JSON.stringify(response))
      }
      const total =
        response.data?.count_data?.reduce(
          (sum: number, { totalCount }: any) => sum + totalCount,
          0
        ) ||
        response.data?.num_of_users ||
        0
      return {
        name: response.data?.name,
        number: formatNumberToCurrency(total),
        data: response.data
      }
    } catch (error) {
      console.log('** ERROR WFNodeStartingPoint.tsx 75 : ', error)
      return info
    }
  }
  if (type === 'event') {
    try {
      const response: any = await EventServices.getById(id)
      if (response._message || !response.data) {
        throw new Error(JSON.stringify(response))
      }
      return {
        name: response.data?.list_name,
        number: formatNumberToCurrency(response.data?.num_of_users || 0),
        data: response.data
      }
    } catch (error) {
      console.log('** ERROR WFNodeStartingPoint.tsx 90 : ', error)
      return info
    }
  }

  return info
}

const WFNodeStartingPoint: WFNode = (props) => {
  const { id, data, title, bgColor, color, icon, modalWidth } = props
  const form = useRef<FormInstance>(null)
  const [values, setValues] = useState<FormWorkflowStartingPointValue>()
  const {
    disabled,
    site,
    nodes,
    setNodes,
    onNodesDelete,
    edges,
    setEdges,
    onNodesChangeData
  } = useWorkflowBuilder()
  const { open, onOpen, onClose, onCancel } = useNode({
    id,
    form: form.current as FormInstance,
    nodes,
    setNodes,
    onNodesDelete,
    onNodesChangeData
  })
  const {
    segments,
    setSegments: setSegmentsFunc,
    segmentSelected,
    setSegmentSelected: setSegmentSelectedFunc
  } = useWorkflowBuilderQueries()
  const setSegments = useRef(setSegmentsFunc)
  const setSegmentSelected = useRef(setSegmentSelectedFunc)

  const [loading, setLoading] = useState(false)

  const descriptionComp = useMemo(() => {
    if (!segmentSelected?.id) {
      return null
    }

    const number =
      (segmentSelected as Segment)?.count_data?.reduce(
        (sum: number, { totalCount }: any) => sum + totalCount,
        0
      ) ||
      (segmentSelected as EventItem)?.num_of_users ||
      0
    return (
      <Skeleton
        title={false}
        loading={loading}
        paragraph={{ rows: 1, width: '100%' }}>
        <StyledTag color="green" style={{ width: '100%' }}>
          <StyledTagName
            title={
              (segmentSelected as EventItem)?.list_name || segmentSelected?.name
            }>
            <StyledTagNameInner>
              {(segmentSelected as EventItem)?.list_name ||
                segmentSelected?.name}
            </StyledTagNameInner>
          </StyledTagName>
          ID : {segmentSelected?.id}
        </StyledTag>
        {number} users
      </Skeleton>
    )
  }, [loading, segmentSelected])
  const initialValues = useMemo(
    () => ({
      ...data,
      id: data?.id || null,
      type:
        data?.type === 'segment'
          ? SEGMENT_TYPE.ATTRIBUTE
          : data?.type || SEGMENT_TYPE.ATTRIBUTE
    }),
    [data]
  )
  const type = useMemo(() => {
    // Mapping the old value type
    if (data?.type === 'segment') {
      return SEGMENT_TYPE.ATTRIBUTE
    }
    return values?.type || data.type || initialValues?.type
  }, [data.type, initialValues?.type, values?.type])
  const segmentOptions = useMemo(() => {
    return (
      segments?.[type]?.map((segment) => ({
        value: segment?.id,
        label: segment?.list_name || segment?.name
      })) || []
    )
  }, [segments, type])

  const fetchSegments = useCallback(
    ({ type, site }: { type?: string; site?: string | number }) => {
      if (!type || (segments[type] && segments[type]?.length)) {
        return
      }
      getWorkflowBuilderQueriesSegments({
        type,
        site_id: site
      }).then((res) => {
        setSegments.current?.((oldSegments) => ({
          ...oldSegments,
          [type]: res.data
        }))
      })
    },
    [segments]
  )

  const fetchStartingPoint = (
    id?: string | number,
    type?: string,
    resetNodes = false
  ) => {
    setLoading(true)
    fetchStartingPointInfo(id, type)
      .then((res) => {
        setSegmentSelected.current?.(res.data)
        if (resetNodes) {
          const nodesReset = nodes
            .filter((node) =>
              ['TRIGGER', 'WAIT_UNTIL'].some((key) => key === node.type)
            )
            .map((node) => {
              if (node.type === 'TRIGGER') {
                const newNode = { ...node }
                if (res.data?.segment_type === SEGMENT_TYPE.EVENT) {
                  newNode.data.events_conditions = [
                    {
                      event_id: res.data?.events_conditions?.[0]?.event?.id,
                      conditions: []
                    }
                  ]
                  newNode.data.segment_event_id = id
                  newNode.data.segment_events_conditions =
                    res.data?.events_conditions

                  return newNode
                }
                if (type === SEGMENT_TYPE.DYNAMIC_LIST) {
                  delete newNode.data?.segment_event_id
                  delete newNode.data?.segment_events_conditions
                  newNode.data.events_conditions = [
                    { event_id: id, conditions: [] }
                  ]
                  return newNode
                }
                return newNode
              }
              return { ...node }
            })
          onNodesChangeData?.(nodesReset)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleFinish = async (valuesFinish: any) => {
    if (valuesFinish) {
      const nodeChanged = nodes.find((node) => node.id === id) as Node
      if (!nodeChanged) {
        return
      }
      nodeChanged.data = {
        ...valuesFinish
      }

      let newEdges = [...edges]

      const nodesTrigger = nodes
        .filter((node) =>
          ['TRIGGER', 'WAIT_UNTIL'].some((key) => key === node.type)
        )
        .map((node) => {
          if (node.type === 'WAIT_UNTIL') {
            newEdges = newEdges.filter(({ source }) => source !== node.id)
          }
          return {
            ...node,
            data: {}
          }
        })

      onNodesChangeData?.([nodeChanged, ...nodesTrigger])
      setTimeout(() => {
        setEdges?.(newEdges)
      }, 0)
      fetchStartingPoint(valuesFinish?.id, valuesFinish?.type, true)
      onClose({ isReset: false })
    }
  }

  const handleValuesChange = useCallback(
    (valueChanged: any, valuesChanged: any) => {
      const newValuesChanged = { ...valuesChanged }
      if ('type' in valueChanged) {
        delete valueChanged.id
        fetchSegments({ type: valueChanged?.type })
      }
      form.current?.setFieldsValue(newValuesChanged)
      setValues(newValuesChanged)
    },
    [fetchSegments, form]
  )

  useEffect(() => {
    form.current?.setFieldsValue(initialValues)
  }, [initialValues])

  useEffect(() => {
    fetchSegments({ type, site })
  }, [])

  useEffect(() => {
    if (data?.id === segmentSelected?.id || !data?.id || !type) {
      return
    }
    fetchStartingPoint(data?.id, type)
  }, [data?.id, segmentSelected?.id, type])

  return (
    <>
      <WorkflowBuilderNodeTemplate
        color={color}
        bgColor={bgColor}
        icon={icon}
        title={title}
        // titleOutside="Title ID 1937402"
        description={descriptionComp}
        onClick={onOpen}
        sources={
          <WorkflowBuilderHandle
            id={`${id}-source`}
            type="source"
            position={Position.Bottom}
          />
        }
      />
      <WorkflowBuilderDrawerTemplate
        open={open}
        title={title}
        width={modalWidth}
        onClose={onClose}
        onCancel={onCancel}
        onUpdate={form.current?.submit}>
        <FormWorkflowStartingPoint
          ref={form}
          initialValues={initialValues}
          onValuesChange={handleValuesChange}
          onFinish={handleFinish}
          segmentOptions={segmentOptions}
          disabled={disabled}
        />
      </WorkflowBuilderDrawerTemplate>
    </>
  )
}

WFNodeStartingPoint.validate = (node) => {
  if (!node.data || !node.data?.id) {
    return false
  }
  return true
}

export { WFNodeStartingPoint }
