import { menuItemsSort } from './index.helpers'
import {
  StyledIconContainer,
  StyledSortDropdownMenu,
  StyledSortDropdownOverlay,
  StyledSortDropdownTitle
} from './index.styled'
import { Dropdown, MenuProps } from 'antd'
import cn from 'classnames'
import { SortKeys } from 'constants/segment'
import { CaretDown } from 'icons'
import { useEffect, useState } from 'react'

export type TableColumnSortProps = {
  disabled?: boolean
  value?: SortKeys
  onChange?: (value: SortKeys) => void
  className?: string
}
export const TableColumnSort = (props: TableColumnSortProps) => {
  const {
    disabled,
    value: valueProps,
    onChange: onChangeProps,
    className
  } = props
  const [value, setValue] = useState(valueProps)

  const onClick: MenuProps['onClick'] = (info) => {
    const val = info.key as SortKeys
    setValue(val)
    onChangeProps && onChangeProps(val)
  }

  useEffect(() => {
    setValue(valueProps)
  }, [valueProps])

  return (
    <Dropdown
      trigger={['click']}
      overlay={
        <StyledSortDropdownOverlay>
          <StyledSortDropdownTitle>SORT BY</StyledSortDropdownTitle>
          <StyledSortDropdownMenu
            activeKey={value}
            items={menuItemsSort}
            onClick={onClick}
            className="menuDropdownTable"
          />
        </StyledSortDropdownOverlay>
      }
      disabled={disabled}>
      <StyledIconContainer
        className={cn(className, { active: Boolean(value) })}>
        <CaretDown />
      </StyledIconContainer>
    </Dropdown>
  )
}

export default TableColumnSort
