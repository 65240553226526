import { StyleButton, StyledRight, StyledWrap } from './index.styled'
import Icon from '@ant-design/icons'
import { Button, Dropdown, DropdownProps } from 'antd'
import { InputSearch } from 'components/atoms/input'
import { menuRoutes } from 'components/molecules'
import { PanelTabItem, PanelTabs } from 'components/molecules/templateBuilder'
import { DISABLED_SMS } from 'constants/env'
import { PERMISSIONS } from 'constants/permission'
import { ROLES } from 'constants/role'
import { TEMPLATE_TYPE } from 'constants/template'
import { useCheckAuth } from 'hooks/useCheckAuth'
import { Filter, Grid, List, Plus } from 'icons'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
  listTabs: PanelTabItem[]
  viewType: string
  searchStr: string
  activeTab: string
  openFilter: boolean
  onChangeTab: (activeKey: string) => void
  onChangeSearch: (searchStr: string) => void
  onChangeViewType: (viewType: string) => void
  setOpenFilter?: (val: boolean) => void
}

export function PanelTableHeader({
  listTabs,
  viewType,
  searchStr,
  activeTab,
  openFilter,
  onChangeTab,
  onChangeSearch,
  onChangeViewType,
  setOpenFilter
}: Props) {
  const navigate = useNavigate()
  const isCreateEmail = !useCheckAuth({
    roles: [ROLES.admin, ROLES.campaign_manager],
    permissions: [PERMISSIONS.template_email_create]
  })
  const isCreateLandingPage = !useCheckAuth({
    roles: [ROLES.admin, ROLES.campaign_manager],
    permissions: [PERMISSIONS.template_landing_page_create]
  })
  const isCreateAppPush = !useCheckAuth({
    roles: [ROLES.admin, ROLES.campaign_manager],
    permissions: [PERMISSIONS.template_app_push_create]
  })
  const isCreateWebPush = !useCheckAuth({
    roles: [ROLES.admin, ROLES.campaign_manager],
    permissions: [PERMISSIONS.template_web_push_create]
  })
  const isSearch = useCheckAuth({
    permissions: [PERMISSIONS.template_search]
  })
  const isFilter = useCheckAuth({
    permissions: [PERMISSIONS.template_filter]
  })
  const dropdownMenuProps = useMemo<DropdownProps['menu']>(
    () => ({
      className: 'min-w-[180px] py-0 overflow-hidden',
      items: [
        {
          key: menuRoutes.template + '/new-email-template',
          label: 'Email Template',
          className: 'py-[9px] px-[16px] first:rounded-t-[6px]',
          disabled: isCreateEmail
        },
        {
          key: menuRoutes.template + '/new-landing-page',
          label: 'Landing Page Template',
          className: 'py-[9px] px-[16px]',
          disabled: isCreateLandingPage
        },
        {
          key: menuRoutes.template + '/new-app-push',
          label: 'App Push Template',
          className: 'py-[9px] px-[16px]',
          disabled: isCreateAppPush
        },
        {
          key: menuRoutes.template + '/new-web-push',
          label: 'Web Push Template',
          className: 'py-[9px] px-[16px]',
          disabled: isCreateWebPush
        },
        {
          key: menuRoutes.template + '/new-whats-app',
          label: 'WhatsApp Template',
          className: 'py-[9px] px-[16px] last:rounded-b-[6px]',
          disabled: isCreateWebPush
        },
        DISABLED_SMS
          ? null
          : {
              key: menuRoutes.template + `/new-${TEMPLATE_TYPE.TWILIO}`,
              label: 'Twilio Template',
              className: 'py-[9px] px-[16px] last:rounded-b-[6px]',
              disabled: isCreateWebPush
            }
      ].filter(Boolean),
      onClick({ key }) {
        navigate(key)
      }
    }),
    [
      isCreateEmail,
      isCreateLandingPage,
      isCreateAppPush,
      isCreateWebPush,
      navigate
    ]
  )

  return (
    <StyledWrap className="panel-table-header">
      <PanelTabs
        listTabs={listTabs}
        activeTab={activeTab}
        onChangeTab={onChangeTab}
      />
      <StyledRight>
        <InputSearch
          disabled={!isSearch}
          value={searchStr}
          onChange={(e) => onChangeSearch(e.target.value)}
          placeholder="Search template"
        />
        <Button
          disabled={!isFilter}
          type={openFilter ? 'primary' : 'ghost'}
          className={openFilter ? 'active-filter' : ''}
          icon={<Icon component={Filter} />}
          onClick={() => setOpenFilter && setOpenFilter(!openFilter)}>
          Filter
        </Button>
        <Dropdown placement="bottomRight" menu={dropdownMenuProps}>
          <StyleButton icon={<Plus />} type="primary" size="middle" disabled>
            New template
          </StyleButton>
        </Dropdown>
        <div>
          <Button
            onClick={() => {
              onChangeViewType(viewType === 'list' ? 'grid' : 'list')
            }}
            icon={<Icon component={viewType === 'list' ? Grid : List} />}
            size="middle"
            type={viewType === 'grid' ? 'primary' : 'default'}
          />
        </div>
      </StyledRight>
    </StyledWrap>
  )
}
