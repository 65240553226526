import { CachedSystem } from './cachedSystem'
import { CUSTOM_EVENT_PATHS } from 'constants/apiPaths'
import { sidisBaseAxios } from 'utils/api'

const PREFIX = 'CustomEvent'
export const CustomEventServices = {
  getList() {
    CachedSystem.clearCached(PREFIX + CUSTOM_EVENT_PATHS.custom_event)
    return sidisBaseAxios.get(CUSTOM_EVENT_PATHS.custom_event)
  }
}
