import { ModalEmpty } from '../modalEmpty'
import { fetchAttributeConditions } from './ModalDictionary.helpers'
import { StyledCell, StyledTable, StyledTabs } from './ModalDictionary.styled'
import Tooltip from 'antd/lib/tooltip'
import { InputSearch } from 'components/atoms/input'
import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { filterOption } from 'utils'

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: 25,
    render: (v: any, record: any) => {
      return (
        <Tooltip title={record.name}>
          <StyledCell>{record.name}</StyledCell>
        </Tooltip>
      )
    }
  },
  {
    title: 'Key',
    dataIndex: 'key',
    width: 20,
    render: (v: any, record: any) => {
      return (
        <Tooltip title={record.key}>
          <StyledCell>{record.key}</StyledCell>
        </Tooltip>
      )
    }
  },
  {
    title: 'Data Type',
    dataIndex: 'dataType',
    width: 15
  },
  {
    title: 'Description',
    dataIndex: 'description',
    width: 20,
    render: (v: any, record: any) => {
      return (
        <Tooltip title={record.description}>
          <StyledCell>{record.description}</StyledCell>
        </Tooltip>
      )
    }
  }
]

export type ModalDictionaryProps = {
  disabledTabs?: ('event' | 'attribute')[]
  open?: boolean
  onClose?: () => void
  attributeDataSource?: any[]
  onRowItemClick?: (record?: any, number?: number | undefined) => void
}
export const ModalDictionary = (props: ModalDictionaryProps) => {
  const { disabledTabs, open, onClose, onRowItemClick, attributeDataSource } =
    props
  const debounce = useRef<NodeJS.Timeout>()
  const [tab, setTab] = useState('attribute')
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [dataSource, setDataSource] = useState(attributeDataSource || [])

  const fetch = useCallback(
    async ({ search = '' }: { search?: string } = {}) => {
      if (tab === 'attribute' && attributeDataSource?.length) {
        setDataSource(
          attributeDataSource.filter(({ name, key }) =>
            filterOption(search, { label: name, value: key })
          )
        )
      }
      if (tab === 'attribute' && !attributeDataSource?.length) {
        setLoading(true)
        try {
          const data = await fetchAttributeConditions({ search })
          setDataSource(data.data)
        } catch (error) {
          console.log('** ERROR ModalDictionary.tsx 33 error : ', error)
        }
        setLoading(false)
      }
    },
    [open, search, attributeDataSource]
  )

  const onCancel = useCallback(() => onClose?.(), [onClose])

  const onChangeTab = (activeTab: string) => {
    setSearch('')
    setTab(activeTab)
  }

  const onSearch: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newSearch = e.target.value
    setSearch(newSearch)
    if (debounce.current) clearTimeout(debounce.current)
    debounce.current = setTimeout(() => {
      fetch({ search: newSearch })
    }, 300)
  }

  const onRow = useCallback(
    (record: any) => {
      return {
        onClick() {
          onRowItemClick?.(record)
        }
      }
    },
    [onRowItemClick]
  )

  useEffect(() => {
    setDataSource(attributeDataSource || [])
    if (!attributeDataSource?.length && open) {
      fetch()
    }
  }, [attributeDataSource])

  return (
    <ModalEmpty
      title="Data Dictionary"
      open={open}
      onCancel={onCancel}
      cancelText="Cancel"
      okText="Continue"
      onOk={onCancel}
      width={720}
      destroyOnClose>
      <InputSearch value={search} onChange={onSearch} />
      <StyledTabs
        activeKey={tab}
        onChange={onChangeTab}
        items={[
          {
            key: 'event',
            label: 'Event',
            disabled: disabledTabs?.includes('event')
          },
          {
            key: 'attribute',
            label: 'Attributes',
            disabled: disabledTabs?.includes('attribute'),
            children: (
              <StyledTable
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                scroll={{ y: 300 }}
                onRow={onRow}
              />
            )
          }
        ]}
      />
    </ModalEmpty>
  )
}
