import {
  Facebook,
  Global,
  Group, // Like,
  Mail,
  Phone,
  Psychographic,
  SMS,
  SettingsGear,
  Stethoscope,
  Subot,
  Trigger,
  Viber
} from 'icons'

export const categories = {
  behaviors: {
    icon: Trigger,
    label: 'Behavior'
  },
  demographics: {
    icon: Group,
    label: 'Demographic'
  },
  geographics: {
    icon: Global,
    label: 'Geographic'
  },
  psychographics: {
    icon: Psychographic,
    label: 'Psychographic'
  },
  health_conditions: {
    icon: Stethoscope,
    label: 'Health Condition'
  },
  // "interest": {
  // 	icon: Like,
  // 	label: 'Interests'
  // },
  conditions: {
    icon: SettingsGear,
    label: 'Conditions'
  }
}
export type CategoryKeys = keyof typeof categories

export const operatorString = {
  contain: 'Contains',
  not_contain: 'Does not contain',
  equal: 'Equal',
  not_equal: 'Not equal',
  in: 'In',
  not_in: 'Not in',
  regex: 'RegEx'
}
export type OperatorStringKeys = keyof typeof operatorString
export const operatorStringOptions = Object.keys(operatorString).map((k) => {
  const key = k as OperatorStringKeys
  return {
    value: key,
    label: operatorString[key]
  }
})

export const operatorInteger = {
  equal: 'Equal',
  not_equal: 'Not equal',
  greater: 'Greater',
  greater_equal: 'Greater or equal',
  less: 'Less',
  less_equal: 'Less or equal',
  in: 'In',
  not_in: 'Not in',
  set: 'Set',
  not_set: 'Not set',
  range: 'Range'
}
export type OperatorIntegerKeys = keyof typeof operatorInteger
export const operatorIntegerOptions = Object.keys(operatorInteger).map((k) => {
  const key = k as OperatorIntegerKeys
  return {
    value: key,
    label: operatorInteger[key]
  }
})

export const operatorDate = {
  equal: 'Equal',
  not_equal: 'Not equal',
  greater: 'Greater',
  greater_equal: 'Greater or equal',
  in: 'In',
  not_in: 'Not in',
  less: 'Less',
  less_equal: 'Less or equal',
  range: 'Range'
}
export type OperatorDateKeys = keyof typeof operatorDate
export const operatorDateOptions = Object.keys(operatorDate).map((k) => {
  const key = k as OperatorDateKeys
  return {
    value: key,
    label: operatorDate[key]
  }
})

export const sources = {
  email: {
    icon: Mail,
    label: 'Email'
  },
  facebook: {
    icon: Facebook,
    label: 'Facebook'
  },
  push: {
    icon: Phone,
    label: 'Push'
  },
  sms: {
    icon: SMS,
    label: 'SMS'
  },
  viber: {
    icon: Viber,
    label: 'Viber'
  },
  subot: {
    icon: Subot,
    label: 'Subot'
  }
}
export type SourcesKeys = keyof typeof sources

export const SortValueLabel = {
  DESC: 'DESC',
  ASC: 'ASC'
}

export type SortKeys = keyof typeof SortValueLabel

export const SortOptions = Object.keys(SortValueLabel).map((k) => {
  const key = k as SortKeys
  return {
    value: key,
    label: SortValueLabel[key]
  }
})

export const TypeValueLabel = {
  internal: 'Internal',
  client: 'Client'
}

export type TypeKeys = keyof typeof TypeValueLabel

export const TypeOptions = Object.keys(TypeValueLabel).map((k) => {
  const key = k as TypeKeys
  return {
    value: key,
    label: TypeValueLabel[key]
  }
})

export const StatusValueLabel = {
  active: 'Active',
  draft: 'Draft',
  finished: 'Finished',
  on_going: 'On going',
  schedule: 'Schedule'
}

export type StatusKeys = keyof typeof StatusValueLabel

export const StatusOptions = Object.keys(StatusValueLabel).map((k) => {
  const key = k as StatusKeys
  return {
    value: key,
    label: StatusValueLabel[key]
  }
})

export const initFormCsvList = {
  id: null,
  name: '',
  description: '',
  tag_ids: []
}

export const userReachableDefault = [
  {
    key: 'EmailCount',
    label: 'Email',
    value: 0
  },
  {
    key: 'PhoneCount',
    label: 'Phone',
    value: 0
  },
  {
    key: 'WhatsAppCount',
    label: 'WhatsApp',
    value: 0
  },
  // {
  //   key: 'FacebookCount',
  //   label: 'Facebook',
  //   value: 0
  // },
  {
    key: 'AppPushCount',
    label: 'App Push',
    value: 0
  },
  {
    key: 'WebPushCount',
    label: 'Web Push',
    value: 0
  }
]

export const orderVennData = ['left', 'right', 'mid']

/** New Revamp in Segment */
// #region
export const SEGMENT_TYPE = {
  MOBILE: 'mobile',
  ATTRIBUTE: 'condition',
  EVENT: 'segment-event-condition',
  EVENT_CUSTOM: 'custom-event-conditions',
  CSV: 'csv',
  DYNAMIC_LIST: 'event'
}
export const SEGMENT_RELATION = {
  AND: 'AND',
  OR: 'OR'
}
export const SEGMENT_CONDITION_TYPE = {
  text: 'text',
  number: 'number',
  date: 'date',
  bool: 'bool',
  array: 'array'
}
export const SEGMENT_CONDITION_OPERATOR = {
  EQUAL: '=',
  LESS: '<',
  GREATER: '>',
  LESS_OR_EQUAL: '<=',
  GREATER_OR_EQUAL: '>=',
  NOT_EQUAL: '!=',
  BETWEEN: 'between',
  IN: 'in',
  NOT_IN: 'not_in',
  CONTAINS: 'contains',
  DOES_NOT_CONTAINS: 'does_not_contains',
  IN_THE_LAST: 'in_the_last',
  EMPTY: 'empty',
  NOT_EMPTY: 'not_empty'
}

export type SegmentConditionOperatorKeys =
  keyof typeof SEGMENT_CONDITION_OPERATOR
export const SEGMENT_CONDITION_OPERATOR_LABEL: Record<
  SegmentConditionOperatorKeys,
  string
> = {
  LESS: 'Less than (<)',
  GREATER: 'Greater than (>)',
  EQUAL: 'Equals (=)',
  NOT_EQUAL: 'Does not equal (!=)',
  LESS_OR_EQUAL: 'Less than or equal to (<=)',
  GREATER_OR_EQUAL: 'Greater than or equal to (>=)',
  BETWEEN: 'Is between',
  IN: 'In',
  NOT_IN: 'Not in',
  CONTAINS: 'Contains',
  DOES_NOT_CONTAINS: 'Does not contain',
  IN_THE_LAST: 'In the last',
  EMPTY: 'Is Empty',
  NOT_EMPTY: 'Not Empty'
}
// #endregion
