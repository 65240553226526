import { ConditionBaseProps } from '..'
import {
  StyledCondition,
  StyledConditionContent,
  StyledConditionLabel,
  StyledConditionRadio,
  StyledConditionRadioGroup
} from '../index.styled'
import { RadioChangeEvent } from 'antd'
import { OptionType } from 'interfaces'
import { useEffect, useState } from 'react'

export type ConditionRadioProps = ConditionBaseProps<any> & {
  label?: string
  options?: OptionType[]
}
export const ConditionRadio = (props: ConditionRadioProps) => {
  const {
    label,
    options,
    className,
    defaultValue,
    value: valueProps,
    onChange: onChangeProps
  } = props
  const [value, setValue] = useState<string | number | boolean>(
    defaultValue || valueProps
  )

  const onChange = (e: RadioChangeEvent) => {
    const valueChanged = e.target.value
    setValue(valueChanged)
    onChangeProps && onChangeProps(valueChanged)
  }

  useEffect(() => {
    setValue(valueProps)
  }, [valueProps])

  return (
    <StyledCondition className={className}>
      {label && <StyledConditionLabel>{label}</StyledConditionLabel>}
      <StyledConditionContent>
        <StyledConditionRadioGroup value={value} onChange={onChange}>
          {options?.map((option, index) => {
            return (
              <StyledConditionRadio
                key={`${index}--${option.value}`}
                value={option.value}
                disabled={option?.disabled}>
                {option.label}
              </StyledConditionRadio>
            )
          })}
        </StyledConditionRadioGroup>
      </StyledConditionContent>
    </StyledCondition>
  )
}
