import DrawerCreateSegment from '../DrawerCreateEventSegment'
import {
  countSegmentByCampaignTemplateType,
  fetchStepSegment,
  fetchStepSelectSegments,
  selectSegmentsColumns,
  selectSegmentsTabs
} from './CampaignStepSelectSegments.helpers'
import {
  StyledSelectContainer,
  StyledStepSelectHeader,
  StyledStepSelectHeaderSearch,
  StyledStepSelectHeaderTabs,
  StyledStepSelectInputSearch,
  StyledStepSelectTabs
} from './CampaignStepSelectSegments.styled'
import {
  Button,
  Radio,
  TableColumnProps,
  TablePaginationConfig,
  TableProps,
  message,
  notification
} from 'antd'
import { TableScroll } from 'components/atoms/table'
import { SEGMENT_STATUS_KEYS } from 'components/molecules/segment'
import { sort } from 'constants/common'
import { SEGMENT_TYPE } from 'constants/segment'
import { TEMPLATE_TYPE } from 'constants/template'
import {
  Key,
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { segmentServices } from 'services/segment'
import { formatNumberToCurrency } from 'utils'

type CampaignStepSelectSegmentsParams = {
  site?: string | number
  type?: string
  search?: string
  pagination: TablePaginationConfig
}
const DetailComponent = (props: {
  record: any
  columns: TableColumnProps<any>[]
}) => {
  const { record, columns } = props
  return (
    <tr
      key={record.id}
      className="ant-table-row ant-table-row-level-0 ant-table-row-selected">
      <td className="ant-table-cell ant-table-selection-column">
        <Radio checked />
      </td>
      {columns.map((col, index) => {
        const key = col.dataIndex?.toString()
        const value = record?.[key as keyof typeof record] || ''
        const children = col?.render ? col.render(value, record, index) : value
        return (
          <td key={key} className="ant-table-cell">
            {children}
          </td>
        )
      })}
    </tr>
  )
}
export type CampaignStepSelectSegmentsProps = Pick<
  CampaignStepSelectSegmentsParams,
  'site'
> & {
  template_type?: string
  disabled?: boolean
  loading?: boolean
  defaultValue?: Key[]
  value?: Key[]
  onChange?: (value: Key[]) => void
}
const CampaignStepSelectSegmentsWithoutRef = (
  props: CampaignStepSelectSegmentsProps,
  ref?: Ref<HTMLDivElement>
) => {
  const {
    site,
    template_type,
    disabled: disabledProps,
    loading: loadingProps,
    defaultValue,
    value: valueProps,
    onChange
  } = props
  const debounceRef = useRef<NodeJS.Timeout | null>(null)
  const [value, setValue] = useState(defaultValue || valueProps || [])
  const [detail, setDetail] = useState<any>(null)
  const [loading, setLoading] = useState(!!loadingProps)
  const [dataSource, setDataSource] = useState([])
  const [params, setParams] = useState<CampaignStepSelectSegmentsParams>({
    site,
    type: 'condition',
    search: '',
    pagination: { position: [], total: 0, current: 1, pageSize: 20 }
  })
  const [isOpenCreateEventSegment, setIsOpenCreateEventSegment] =
    useState(false)

  const fetchDataSource = useCallback(
    async (input: CampaignStepSelectSegmentsParams) => {
      setLoading(true)
      const { data, meta } = await fetchStepSelectSegments({
        page: input.pagination.current || 1,
        per_page: input.pagination.pageSize || 20,
        search: input.search,
        type: input?.type,
        site_id: input?.type === 'csv' ? undefined : input.site,
        sort_by: '',
        sort_type: sort.descend
      })
      setDataSource(data)
      setParams((prs) => ({
        ...prs,
        ...input,
        pagination: {
          ...prs.pagination,
          total: meta.total_items,
          current: meta.current_page,
          pageSize: meta.per_page
        }
      }))
      setLoading(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.site, params.type]
  )

  const putSegmentStatus = (id?: number) => {
    if (typeof id !== 'number') {
      return
    }
    setLoading(true)
    segmentServices
      .updateSegment(id, {
        status: SEGMENT_STATUS_KEYS.PUBLIC
      })
      .then((res: any) => {
        if (res.data && !res._message) {
          notification.success({
            message: (
              <span>
                Status segment <strong>{res.data?.name}</strong> is updated
                successfully
              </span>
            )
          })
          fetchDataSource(params)
        }
      })
      .catch(() => {
        notification.error({ message: 'Segment cannot updated status.' })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const columns = useMemo(() => {
    const key = params.type ? params.type : 'segment'
    const newColumns =
      selectSegmentsColumns?.[key as keyof typeof selectSegmentsColumns] ||
      selectSegmentsColumns.condition
    return newColumns.map((column) => {
      if (column.dataIndex === 'num_of_users') {
        return {
          ...column,
          render(_: any, record: any) {
            return formatNumberToCurrency(
              countSegmentByCampaignTemplateType(template_type, record)
            )
          }
        }
      }
      if (column.dataIndex === 'id') {
        return {
          ...column,
          render(id: number, record: any) {
            if (
              record?.status !== SEGMENT_STATUS_KEYS.DRAFT ||
              [SEGMENT_TYPE.CSV, SEGMENT_TYPE.MOBILE].includes(
                record?.segment_type
              )
            ) {
              return null
            }
            return (
              <Button
                ghost
                size="small"
                type="primary"
                onClick={() => putSegmentStatus(id)}
                className="text-[12px] font-normal py-1 px-2 h-auto flex items-center">
                Publish segment
              </Button>
            )
          }
        }
      }
      return column
    })
  }, [params.type, template_type])
  const tabsItems = useMemo(() => {
    return selectSegmentsTabs.map((tab) => {
      let disabled =
        tab.disabled.some((key) => key === template_type) || disabledProps
      if (detail?.[0]?.segment_type && disabledProps) {
        disabled = Boolean(tab.key !== detail[0].segment_type)
      }
      return {
        key: tab.key,
        label: tab.label,
        disabled
      }
    })
  }, [detail, disabledProps, template_type])
  const detailComp = useMemo(() => {
    if (!detail || !detail?.length) {
      return null
    }

    return (
      <>
        {detail.map((record: any) => {
          if (dataSource?.some(({ id }) => id === record.id)) {
            const indexDetail = dataSource.findIndex(
              ({ id }) => id === record.id
            )
            if (indexDetail !== -1 && indexDetail !== 0) {
              const newDataSource = [...dataSource]
              newDataSource[indexDetail] = dataSource?.[0]
              newDataSource[0] = dataSource?.[indexDetail]
              setDataSource(newDataSource)
              document
                .getElementsByClassName('ant-table-body')?.[0]
                ?.scrollTo({ top: 0, behavior: 'smooth' })
            }
            return null
          }
          return (
            <DetailComponent
              key={record.id}
              columns={columns}
              record={record}
            />
          )
        })}
      </>
    )
  }, [columns, dataSource, detail])

  const handleSelect = (
    record: any
    // _selected: boolean,
    // _selectedRows: any[],
    // _nativeEvent: Event
  ) => {
    if (
      record &&
      countSegmentByCampaignTemplateType(template_type, record) <= 0
    ) {
      notification.error({
        message:
          'Please use another segment because this segment has gotten value 0'
      })
      return
    }

    if (record && record.id) {
      const hasValue = value.some((id) => id === record.id)
      const newValue = hasValue ? [] : [record.id]
      const newDetail = hasValue ? [] : [record]
      setParams((prs) => ({ ...prs, type: record?.segment_type }))
      setValue(newValue)
      setDetail(newDetail)
      onChange?.(newValue)
      const indexDetail = dataSource.findIndex(({ id }) => id === record.id)
      if (indexDetail !== -1 && indexDetail !== 0) {
        const newDataSource = [...dataSource]
        newDataSource[indexDetail] = dataSource?.[0]
        newDataSource[0] = dataSource?.[indexDetail]
        setDataSource(newDataSource)
        document
          .getElementsByClassName('ant-table-body')?.[0]
          ?.scrollTo({ top: 0, behavior: 'smooth' })
      }
      message.success(
        <>
          Selected segment : <strong>{record?.name}</strong>
        </>
      )
    }
  }

  const handleChangeTable: TableProps<any>['onChange'] = (pagi) => {
    fetchDataSource({
      ...params,
      pagination: {
        ...params.pagination,
        current: pagi.current,
        pageSize: pagi.pageSize
      }
    })
    document
      .getElementsByClassName('ant-table-body')?.[0]
      ?.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleChangeTabs = (typeVal?: string) => {
    const type = typeVal as CampaignStepSelectSegmentsParams['type']
    fetchDataSource({
      ...params,
      type,
      site,
      pagination: {
        ...params.pagination,
        current: 1
      }
    })
    document
      .getElementsByClassName('ant-table-body')?.[0]
      ?.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleSearch = (search: string) => {
    const newParams = {
      ...params,
      search,
      pagination: {
        ...params.pagination,
        current: 1
      }
    }
    setParams(newParams)
    if (debounceRef.current) {
      clearTimeout(debounceRef.current)
    }
    debounceRef.current = setTimeout(() => {
      fetchDataSource(newParams)
    }, 350)
  }

  useEffect(() => {
    setValue(valueProps || [])
    if (!valueProps) {
      setDetail(null)
    }
  }, [valueProps])

  useEffect(() => {
    setLoading(!!loadingProps)
  }, [loadingProps])

  useEffect(() => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current)
    }
    debounceRef.current = setTimeout(() => {
      fetchDataSource(params)
    }, 250)
  }, [fetchDataSource])

  useEffect(() => {
    if (!valueProps?.[0]) {
      return
    }
    ;(async () => {
      const fetchData = await fetchStepSegment(valueProps[0])
      setDetail(fetchData ? [fetchData] : [])
      if (fetchData && fetchData?.segment_type) {
        setParams((prs) => ({
          ...prs,
          type: fetchData?.segment_type
        }))
      }
    })()
  }, [])

  useEffect(() => {
    const enableTab = tabsItems.find(({ disabled }) => !disabled)
    if (enableTab) {
      setParams((prs) => ({
        ...prs,
        type: detail?.[0]?.segment_type || enableTab?.key
      }))
    }
  }, [tabsItems, detail?.[0]?.segment_type])

  useEffect(() => {
    return () => {
      if (debounceRef.current) clearTimeout(debounceRef.current)
    }
  }, [])

  const isShowBtnCreateEventSegment =
    template_type === TEMPLATE_TYPE.WEB_PUSH &&
    params.type === SEGMENT_TYPE.EVENT

  return (
    <StyledSelectContainer ref={ref}>
      {isShowBtnCreateEventSegment && (
        <DrawerCreateSegment
          open={isOpenCreateEventSegment}
          key={String(isOpenCreateEventSegment)}
          siteId={site}
          onClose={() => setIsOpenCreateEventSegment(false)}
          getContainer={() =>
            document.getElementsByClassName('container_2')?.[0]
          }
          onCreateSegment={(data) => {
            if (data?.id) {
              setDataSource((prev) => [data, ...prev] as never[])
              setIsOpenCreateEventSegment(false)
              setTimeout(() => {
                handleSelect(data)
              }, 300)
            }
          }}
        />
      )}
      <TableScroll
        bordered
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        pagination={params.pagination}
        onChange={handleChangeTable}
        components={{
          body: {
            row(props?: any) {
              if ((dataSource?.[0] as any)?.id === props['data-row-key']) {
                return (
                  <>
                    {detailComp}
                    <tr {...props} />
                  </>
                )
              }
              return <tr {...props} />
            }
          }
        }}
        rowKey={(record) => record.id}
        rowSelection={{
          type: 'radio',
          columnTitle: ' ',
          columnWidth: 10,
          checkStrictly: true,
          selectedRowKeys: value,
          getCheckboxProps(record) {
            return {
              disabled:
                record?.status !== SEGMENT_STATUS_KEYS.PUBLIC &&
                ![SEGMENT_TYPE.CSV, SEGMENT_TYPE.MOBILE].includes(
                  record?.segment_type
                )
            }
          },
          onSelect: handleSelect
        }}
        title={() => (
          <StyledStepSelectHeader>
            <StyledStepSelectHeaderTabs>
              <StyledStepSelectTabs
                activeKey={params.type}
                items={tabsItems}
                onChange={handleChangeTabs}
              />
            </StyledStepSelectHeaderTabs>
            <StyledStepSelectHeaderSearch className="flex">
              {isShowBtnCreateEventSegment && (
                <Button
                  size="small"
                  type="link"
                  onClick={() => setIsOpenCreateEventSegment(true)}>
                  Create New Event Segment
                </Button>
              )}
              <StyledStepSelectInputSearch
                allowClear
                value={params.search}
                onSearch={handleSearch}
                placeholder="Search segment"
              />
            </StyledStepSelectHeaderSearch>
          </StyledStepSelectHeader>
        )}
      />
    </StyledSelectContainer>
  )
}
export const CampaignStepSelectSegments = forwardRef(
  CampaignStepSelectSegmentsWithoutRef
)
