import { useWorkflowBuilder } from '../../index.context'
import { WorkflowBuilderDrawerTemplate } from '../WorkflowBuilderDrawerTemplate'
import { WorkflowBuilderHandle } from '../WorkflowBuilderHandle'
import { WorkflowBuilderNodeTemplate } from '../WorkflowBuilderNodeTemplate'
import { WFNode, useNode } from '../index.helpers'
import { FormInstance } from 'antd'
import {
  FormWorkflowTimeWindow,
  FormWorkflowTimeWindowValue,
  formatFormWorkflowTimeWindow
} from 'components/molecules/form/FormWorkflow'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { Position } from 'reactflow'

const WFTimeWindow: WFNode = (props) => {
  const { id, title, bgColor, color, icon, modalWidth, data } = props
  const form = useRef<FormInstance<any>>(null)
  const { timezone, nodes, setNodes, onNodesDelete, onNodesChangeData } =
    useWorkflowBuilder()
  const { open, onOpen, onClose, onCancel, onUpdate } = useNode({
    id,
    form: form.current as FormInstance,
    nodes,
    setNodes,
    onNodesDelete,
    onNodesChangeData
  })

  const descriptionComp = useMemo(() => {
    return (
      <span>
        Wait until <strong>{data?.type}</strong>
        <strong>
          {data?.day ? ` on ${data?.day?.join(',')} at ` : ' at '}
        </strong>
        <strong>
          {(data?.date_time || data?.time || data?.specific_time || [])
            .map((time: any) => {
              if (typeof time === 'string') {
                return time
              }
              const { from, to } = time
              return data?.type_day === 'single_day' ? from : `${from}-${to}`
            })
            .join(',')}
        </strong>
      </span>
    )
  }, [data])

  const onUpdateDrawer = useCallback(async () => {
    const node = nodes.find((node) => node.id === id)
    if (!node) {
      return
    }
    try {
      const values = await form.current?.validateFields()
      node.data = formatWFTimeWindowData(values)
      onUpdate(node)
      onClose({ isReset: false })
    } catch (error) {
      console.log('** RYAN WFTimeWindow.tsx 71 error : ', error)
    }
  }, [id, nodes, onClose, onUpdate])

  useEffect(() => {
    form.current?.setFieldsValue(data)
  }, [data])

  return (
    <>
      <WorkflowBuilderNodeTemplate
        bgColor={bgColor}
        color={color}
        icon={icon}
        title={title}
        description={descriptionComp}
        onClick={onOpen}
        sources={
          <WorkflowBuilderHandle
            type="source"
            id={`${id}-source`}
            position={Position.Bottom}
          />
        }
        targets={
          <WorkflowBuilderHandle
            type="target"
            id={`${id}-target`}
            position={Position.Top}
          />
        }
      />
      <WorkflowBuilderDrawerTemplate
        open={open}
        width={modalWidth}
        title={title}
        onClose={onClose}
        onCancel={onCancel}
        onUpdate={onUpdateDrawer}
        destroyOnClose>
        <FormWorkflowTimeWindow
          ref={form}
          initialValues={formatFormWorkflowTimeWindow({ ...data, timezone })}
        />
      </WorkflowBuilderDrawerTemplate>
    </>
  )
}

type WFTimeWindowData = FormWorkflowTimeWindowValue
function formatWFTimeWindowData(value: any): WFTimeWindowData {
  const data: WFTimeWindowData = { ...value }
  try {
  } catch (error) {}
  return data
}

WFTimeWindow.validate = (node) => {
  const { data = {} } = node
  switch (data?.type) {
    case 'any_day': {
      switch (data?.type_time) {
        case 'specific_time': {
          if (data?.specific_time?.length) {
            return true
          }
          return false
        }
        case 'any_time': {
          if (data?.time?.length) {
            return true
          }
          return false
        }
      }
      break
    }
    case 'specific_days': {
      if (data?.date_time?.length) {
        return true
      }
      break
    }
  }
  return false
}
export { WFTimeWindow }
