import cn from 'classnames'
import iconError from 'icons/AlertError.svg'
import iconSuccess from 'icons/AlertSuccess.svg'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  border-left: 4px solid transparent;
  gap: 12px;

  &::before {
    width: 18px;
    height: 18px;
  }

  &[data-alert-type='error'] {
    border-color: #ff5331;
    background-color: #fae9e8;
    &::before {
      content: url('${iconError}');
    }
  }

  &[data-alert-type='success'] {
    border-color: #00b16a;
    background-color: #e0f8ee;
    &::before {
      content: url('${iconSuccess}');
    }
  }
`

const StyledMessage = styled.p`
  margin: 0;
  color: #262626;
  font-size: 14px;
`

type AlertProps = {
  type: AlertType
  message: string
  className?: string
}

export const Alert = ({ type, message, className }: AlertProps) => {
  return (
    <StyledWrapper className={cn('ui-alert', className)} data-alert-type={type}>
      <StyledMessage>{message}</StyledMessage>
    </StyledWrapper>
  )
}

export { AlertComponent } from './AlertComponent'
