import { WorkflowBuilderHandle } from '../WorkflowBuilderHandle'
import { WorkflowBuilderNodeTemplate } from '../WorkflowBuilderNodeTemplate'
import { WFNode } from '../index.helpers'
import { Position } from 'reactflow'

const WFNodeExit: WFNode = (props) => {
  const { id, title, bgColor, color, icon } = props

  // const number = 30000

  return (
    <WorkflowBuilderNodeTemplate
      color={color}
      bgColor={bgColor}
      icon={icon}
      title={title}
      // description={`Total users: ${number}`}
      onClick={console.log}
      targets={
        <WorkflowBuilderHandle
          id={`${id}-target`}
          type="target"
          position={Position.Top}
        />
      }
    />
  )
}

WFNodeExit.validate = () => true
export { WFNodeExit }
