import Icon from '@ant-design/icons'
import { Button } from 'antd'
import { FitView, Fullscreen, Minus, Plus } from 'icons'
import { ControlProps, Controls, useReactFlow } from 'reactflow'
import styled, { css } from 'styled-components'

const DnDControls = styled(Controls)`
  display: flex;
  flex-direction: column;
  box-shadow: none;
  margin: 0;
  &.right {
    right: 24px;
  }
  &.bottom {
    bottom: 24px;
  }

  .react-flow__controls-button {
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    border: 0;
    background-color: #fff;
    font-size: 18px;
  }
`

const cssControlsButton = css`
  background-color: #fff;
  border-radius: 4px !important;
  box-shadow: 0px 0px 1px 0px #0000000a, 0px 0px 2px 0px #0000000f,
    0px 4px 8px 0px #0000000a;
  color: ${({ theme }) => theme?.colors?.gray600};
  margin-top: 7px;

  > * {
    font-size: 18px !important;
  }
`

const DnDControlsButtonFullscreen = styled(Button)`
  ${cssControlsButton}
`
const DnDControlsButtonFitView = styled(Button)`
  ${cssControlsButton}
`
const DnDControlsButtonZoom = styled.div`
  display: flex;
  flex-direction: column;
  ${cssControlsButton}
`
const DnDControlsButtonZoomIn = styled(Button)`
  color: ${({ theme }) => theme?.colors?.gray600};
`
const DnDControlsButtonZoomOut = styled(Button)`
  color: ${({ theme }) => theme?.colors?.gray600};
`

export function WorkflowBuilderControls(props: ControlProps) {
  const { fitViewOptions, onFitView, onZoomIn, onZoomOut } = props
  const reactFlowInstance = useReactFlow()

  return (
    <DnDControls
      {...props}
      showZoom={false}
      showFitView={false}
      showInteractive={false}
      position="bottom-right">
      <DnDControlsButtonFullscreen
        icon={<Icon component={Fullscreen} />}
        size="small"
        type="link"
      />
      <DnDControlsButtonFitView
        icon={<Icon component={FitView} />}
        size="small"
        type="link"
        onClick={() =>
          onFitView?.() || reactFlowInstance.fitView(fitViewOptions)
        }
      />
      <DnDControlsButtonZoom>
        <DnDControlsButtonZoomIn
          icon={<Icon component={Plus} />}
          size="small"
          type="link"
          onClick={() => onZoomIn?.() || reactFlowInstance.zoomIn()}
        />
        <DnDControlsButtonZoomOut
          icon={<Icon component={Minus} />}
          size="small"
          type="link"
          onClick={() => onZoomOut?.() || reactFlowInstance.zoomOut()}
        />
      </DnDControlsButtonZoom>
    </DnDControls>
  )
}
