import {
  MenuSidebarContext,
  getItemsMenuByRolesAndPermissions,
  menuBottomItems,
  menuRoutes,
  menuTopItems
} from './MenuSidebar.helpers'
import {
  StyledContainer,
  StyledExpandIcon,
  StyledNavigation,
  StyledNavigationMenu,
  StyledNavigationWrapper,
  StyledWrapper
} from './MenuSidebar.styled'
import { MenuSidebarProfile } from './MenuSidebarProfile'
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons'
import { MenuProps as AntMenuProps, Divider } from 'antd'
import cn from 'classnames'
import { useAuth } from 'context/Auth'
import { Burger, LogOut, Logo } from 'icons/V2'
import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

type MenuSidebarProps = {
  bgColor?: string
  stickyMenu?: boolean
  className?: string
}

const MenuExpandIcon = (props: { isOpen?: boolean }) => {
  const { collapse } = useContext(MenuSidebarContext)

  if (collapse) {
    return null
  }
  return (
    <StyledExpandIcon>
      {props.isOpen ? <CaretDownOutlined /> : <CaretRightOutlined />}
    </StyledExpandIcon>
  )
}
type MenuProps = {
  openKeys?: string[]
  active?: string[]
}
const MenuTop = ({ active, openKeys }: MenuProps) => {
  const navigate = useNavigate()
  const { userAuth } = useAuth()
  const items = useMemo(
    () => getItemsMenuByRolesAndPermissions(menuTopItems, userAuth?.auth || {}),
    [userAuth?.auth]
  )
  const handleClick: AntMenuProps['onClick'] = (menuInfo) => {
    if (menuInfo.key === menuRoutes.segment) {
      navigate(menuRoutes.segment_dashboard)
      return
    }
    navigate(menuInfo.key)
  }
  return (
    <StyledNavigationMenu
      multiple
      mode="inline"
      items={items?.map((item) => {
        return { ...item, onTitleClick: handleClick }
      })}
      onClick={handleClick}
      openKeys={openKeys}
      selectedKeys={active}
      rootClassName="menu--top"
      expandIcon={MenuExpandIcon}
    />
  )
}

type MenuBottomProps = MenuProps
const MenuBottom = ({ active, openKeys }: MenuBottomProps) => {
  const navigate = useNavigate()
  const { userAuth } = useAuth()
  const items = useMemo(
    () =>
      getItemsMenuByRolesAndPermissions(menuBottomItems, userAuth?.auth || {}),
    [userAuth?.auth]
  )
  const handleClick: AntMenuProps['onClick'] = (menuInfo) => {
    if (menuInfo.key === menuRoutes.setting) {
      const menuItem = items?.find((item) => item.key === menuInfo.key)
      if (menuItem && menuItem.children) {
        const firstItemActive = menuItem.children.find((item) => !item.disabled)

        if (firstItemActive) {
          navigate(firstItemActive.key)
          return
        }
      }

      navigate(menuRoutes.setting_advanced)
      return
    }
    navigate(menuInfo.key)
  }
  return (
    <StyledNavigationMenu
      multiple
      mode="inline"
      items={items?.map((item) => {
        return { ...item, onTitleClick: handleClick }
      })}
      onClick={handleClick}
      openKeys={openKeys}
      selectedKeys={active}
      rootClassName="menu--bottom"
      expandIcon={MenuExpandIcon}
    />
  )
}

const MenuSidebar: FC<MenuSidebarProps> = ({
  children,
  stickyMenu,
  className
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { userAuth, signout } = useAuth()
  const { collapse, setCollapse, openKeys, setOpenKeys } =
    useContext(MenuSidebarContext)

  const handleCollapse = () => {
    setCollapse(!collapse)
    setTimeout(() => {
      const newResizeEvent = new Event('resize')
      window.dispatchEvent(newResizeEvent)
    }, 250)
  }

  const active = useMemo(() => {
    const pathnameArr = location.pathname.split('/')
    /**
     * '/segment' -> ['/segment']
     * '/segment/1' -> ['/segment']
     * '/segment/1/xyz' -> ['/segment']
     */
    return [pathnameArr.slice(0, 2).join('/'), pathnameArr.join('/')]
  }, [location.pathname])

  const handleClickMenuSidebarProfileDropdown = (item: any) => {
    switch (item?.key) {
      case 'log_out': {
        signout?.()
        break
      }
      default: {
        navigate(item?.key || '')
        break
      }
    }
  }

  useEffect(() => {
    document.body.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }, [location.pathname])

  useEffect(() => {
    const pathnameArr = location.pathname.split('/')
    const openKs = [pathnameArr.slice(0, 2).join('/')]

    // Open parent menu when active its children menu
    setOpenKeys(pathnameArr.length >= 4 || collapse ? undefined : openKs)
    // Collapse sidebar when go to detail items
    setCollapse((prevCollapse) => {
      if (pathnameArr.length > 3) {
        return true
      }
      return prevCollapse
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, setOpenKeys, setCollapse])

  useEffect(() => {
    setOpenKeys((prevOpenKeys) => {
      if (collapse) {
        return undefined
      }
      return prevOpenKeys
    })
  }, [collapse, setOpenKeys])

  return (
    <StyledContainer hasSider className={cn(className, { stickyMenu })}>
      <StyledNavigation width={240} collapsedWidth={56} collapsed={collapse}>
        <StyledNavigationWrapper className="overflow-x-hidden">
          <div
            className={cn(
              'flex items-center h-[56px] py-[16px] border-b-[1px] border-b-solid border-gray-700',
              {
                'px-[24px]': !collapse,
                'px-[16px]': !!collapse
              }
            )}>
            <Burger
              onClick={handleCollapse}
              className="w-[24px] h-[24px] text-white cursor-pointer"
            />
            <Logo
              onClick={() => navigate(menuRoutes.segment_dashboard)}
              className={cn('h-[24px] cursor-pointer transition-all', {
                'ml-6': !collapse,
                'w-0 ml-0': !!collapse
              })}
            />
          </div>
          <Divider className="divider !m-0" />
          <div className="flex-1 min-h-0 scrollbar overflow-y-auto overflow-x-hidden">
            <MenuTop active={active} openKeys={openKeys} />
            <Divider className="divider !my-2" />
            <MenuBottom active={active} openKeys={openKeys} />
          </div>
          <Divider className="divider !m-0" />
          <div
            className={cn('menuSidebar-bottom py-3', {
              'px-4': !collapse,
              'px-3': !!collapse
            })}>
            <MenuSidebarProfile
              collapse={collapse}
              avatar={userAuth?.avatar}
              name={userAuth?.name}
              description={userAuth?.roleName}
              menu={{
                items: [
                  // {
                  //   key: menuRoutes.profile,
                  //   label: 'Profile',
                  //   icon: <Profile />,
                  //   type: undefined
                  // },
                  {
                    key: 'log_out',
                    label: 'Log out',
                    icon: <LogOut />,
                    type: undefined
                  }
                ],
                onClick: handleClickMenuSidebarProfileDropdown
              }}
            />
          </div>
        </StyledNavigationWrapper>
      </StyledNavigation>
      <StyledWrapper>{children}</StyledWrapper>
    </StyledContainer>
  )
}

export const MenuSidebarProvider: FC = ({ children }) => {
  const [collapse, setCollapse] = useState(false)
  const [openKeys, setOpenKeys] = useState<string[] | undefined>([])

  useEffect(() => {
    if (collapse) {
      document.body.classList.add('layout-sider-hidden')
    } else {
      document.body.classList.remove('layout-sider-hidden')
    }
  }, [collapse])

  return (
    <MenuSidebarContext.Provider
      value={{ collapse, setCollapse, openKeys, setOpenKeys }}>
      {children}
    </MenuSidebarContext.Provider>
  )
}

export { MenuSidebarContext, menuRoutes }
export default MenuSidebar
