import { Button, Divider, Space } from 'antd'
import styled from 'styled-components'

export const StyledSpace = styled(Space)`
  &.full_width {
    width: 100%;
  }
  .ant-space-item {
    display: flex;
    gap: 20px;
    flex: 1;
    .ant-form-item {
      flex: 1;

      .ant-input-number {
        width: 100%;
      }
    }
  }
`

export const StyledDivider = styled(Divider)`
  margin-top: 24px;
  margin-bottom: 24px;
`

export const StyledButton = styled(Button)`
  &.condition_btn {
    padding: 5px 12px;
    height: auto;
    .anticon {
      font-size: 18px;
      line-height: 1;
      vertical-align: top;
    }
  }

  &.remove_item_btn {
    width: 18px;
    height: 18px;
    padding: 0;
    margin-left: 12px;
    color: ${({ theme }) => theme?.colors?.neutral400};
    font-size: 18px;
    .anticon {
      font-size: inherit;
    }
    &:hover {
      color: inherit;
    }
  }
`

export const StyledLabel = styled.div`
  &.and_label {
    margin-bottom: 8px;
    color: ${({ theme }) => theme?.colors?.gray400};
    font-size: 12px;
    font-weight: 700;
  }
  &.item_label {
    color: ${({ theme }) => theme?.colors?.gray800};
    font-size: 12px;
    font-weight: 600;
  }
`

export const StyledItemHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  ${StyledLabel} {
    flex: 1;
  }
`

export const StyledItem = styled.div`
  margin-bottom: 16px;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme?.colors?.neutral100};
  border-radius: 4px;
`

export const StyleHandleItem = styled.div`
  text-align: center;
  .handle-title {
    color: ${({ theme }) => theme?.colors?.gray400};
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 5px;
  }
`
