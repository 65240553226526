import { TEMPLATE_TYPE } from 'constants/template'

export const CAMPAIGN_TEMPLATE = [
  TEMPLATE_TYPE.APP_PUSH,
  TEMPLATE_TYPE.EMAIL,
  TEMPLATE_TYPE.WEB_PUSH,
  TEMPLATE_TYPE.WHATSAPP,
  TEMPLATE_TYPE.VNPT,
  TEMPLATE_TYPE.TWILIO
]

export const TEMPLATE_MESSAGE = {
  [TEMPLATE_TYPE.EMAIL]: 'email',
  [TEMPLATE_TYPE.APP_PUSH]: 'app push',
  [TEMPLATE_TYPE.WEB_PUSH]: 'web push',
  [TEMPLATE_TYPE.LP_PAGE]: 'landing page',
  [TEMPLATE_TYPE.WHATSAPP]: 'WhatsApp',
  [TEMPLATE_TYPE.VNPT]: 'VNPT',
  [TEMPLATE_TYPE.TWILIO]: 'Twilio'
}

export * from './CampaignTemplateCreate'
export * from './CampaignTemplateEdit'
