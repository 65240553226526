import Icon from '@ant-design/icons'
import { Avatar, MenuProps, Tooltip } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { StatusWorkflow, statusWf } from 'components/atoms/status'
import { TableCellTitle } from 'components/atoms/table'
import { FORMAT_DATE_DAYJS, sort } from 'constants/common'
import { isProduction } from 'constants/env'
import { SITE_FLAGS } from 'constants/site'
import dayjs from 'dayjs'
import {
  Archive,
  BarChartGray,
  DuplicateFile,
  Edit,
  Move,
  Start,
  TrashBin,
  TurnOff2
} from 'icons'
import { FolderTwoTone } from 'icons/V2'
import { OptionTypeSite } from 'interfaces'
import { WorkflowStatus } from 'interfaces/workflow'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

export const WORKFLOW_TABLE_TABS_KEYS = {
  WORKFLOW: 'workflow',
  FOLDER: 'folder'
}

export const WORKFLOW_TABLE_TABS = [
  {
    key: WORKFLOW_TABLE_TABS_KEYS.WORKFLOW,
    label: 'Workflow'
  },
  {
    key: WORKFLOW_TABLE_TABS_KEYS.FOLDER,
    label: 'Folder',
    disabled: isProduction
  }
]

export const WORKFLOW_TABLE_COLUMNS_DATAINDEX = {
  NAME: 'name',
  CREATED_AT: 'created_at'
}

export const WORKFLOW_TABLE_COLUMNS_KEYS_SORT = [
  WORKFLOW_TABLE_COLUMNS_DATAINDEX.NAME,
  WORKFLOW_TABLE_COLUMNS_DATAINDEX.CREATED_AT
]

export const WORKFLOW_TABLE_COLUMNS_KEYS_SORT_ITEMS = {
  [WORKFLOW_TABLE_COLUMNS_DATAINDEX.CREATED_AT]: [
    {
      label: 'Oldest',
      key: sort.ascend
    },
    {
      label: 'Lastest',
      key: sort.descend
    }
  ]
}

export const WORKFLOW_TABLE_STATUS_OPTIONS = Object.keys(statusWf).map((k) => {
  const key = k as any
  return {
    // This is required for fucking grammar
    label: statusWf[key].label === 'Finish' ? 'Finished' : statusWf[key].label,
    value: statusWf[key].label
  }
})

export const WORKFLOW_TABLE_COLUMNS: Record<string, ColumnProps<any>[]> = {
  [WORKFLOW_TABLE_TABS_KEYS.FOLDER]: [
    {
      title: 'Folder',
      dataIndex: WORKFLOW_TABLE_COLUMNS_DATAINDEX.NAME,
      render: (name, record, index) => {
        return (
          <div className="flex">
            <Icon component={FolderTwoTone} className="mr-2" />
            <Link
              to={`/workflow-folder/${record?.id}`}
              className="text-gray800 whitespace-nowrap flex-1 min-w-0 text-ellipsis overflow-hidden">
              <Tooltip title={name}>{name}</Tooltip>
            </Link>
          </div>
        )
      }
    },
    {
      title: '',
      dataIndex: 'actions',
      width: 68
    }
  ],
  [WORKFLOW_TABLE_TABS_KEYS.WORKFLOW]: [
    {
      title: 'Workflow',
      dataIndex: WORKFLOW_TABLE_COLUMNS_DATAINDEX.NAME,
      width: 322,
      render: (name, record) => {
        return (
          <TableCellTitle
            to={`/workflow/${record?.id}`}
            title={name}
            titleLimitLines={1}
            subTitle={`ID: ${record?.id}`}
            subTitleLimitLines={1}
          />
        )
      }
    },
    {
      title: 'Send activities',
      dataIndex: 'activities',
      width: 100,
      render: (_, record: any) => {
        return (
          <div>
            <div>{record?.in_progress} in progress</div>
            <div>{record?.finished} finished</div>
          </div>
        )
      }
    },
    {
      title: 'Segment',
      dataIndex: 'segment',
      width: 180,
      render: (segment) => {
        return <div>{segment}</div>
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 130,
      render: (status) => {
        return <StatusWorkflow status={status} />
      }
    },
    {
      title: 'Site',
      dataIndex: 'sites',
      width: 80,
      render(sites?: OptionTypeSite[]) {
        if (!sites || !sites.length) {
          return null
        }
        return (
          <div className="site-wrapper">
            <Avatar.Group maxCount={3} size={34} className="avatar-group">
              {sites.map((site, index: number) => {
                return (
                  <Avatar
                    key={index.toString()}
                    src={SITE_FLAGS[site?.name]}
                    className="avatar-group-item"
                    size={34}
                    alt={site?.name}
                  />
                )
              })}
            </Avatar.Group>
          </div>
        )
      }
    },
    {
      title: 'Created at',
      dataIndex: WORKFLOW_TABLE_COLUMNS_DATAINDEX.CREATED_AT,
      width: 320,
      render: (created_at, record) => {
        const updatedAt = dayjs(record?.updated_at)
        const createdAt = dayjs(created_at)

        const title = updatedAt.isValid()
          ? `Updated at: ${updatedAt.format(FORMAT_DATE_DAYJS)}`
          : ''
        const subTitle = createdAt.isValid()
          ? `Created at: ${createdAt.format(FORMAT_DATE_DAYJS)}`
          : ''

        return (
          <TableCellTitle tooltip={false} title={title} subTitle={subTitle} />
        )
      }
    },
    {
      title: '',
      dataIndex: 'actions',
      width: 68
    }
  ]
}

export const WORKFLOW_TABLE_DROPDOWN_ITEMS = {
  [WORKFLOW_TABLE_TABS_KEYS.FOLDER]: [
    {
      label: 'Delete',
      key: 'delete',
      icon: <Icon component={TrashBin} />,
      className: 'text-red600 px-4 py-2'
    }
  ],
  [WORKFLOW_TABLE_TABS_KEYS.WORKFLOW]: [
    {
      label: 'Edit',
      key: 'edit',
      icon: <Icon component={Edit} />,
      className: 'px-4 py-2'
      // disabledStatus: [
      //   WorkflowStatus.InProgress,
      //   WorkflowStatus.Finish,
      //   WorkflowStatus.Cancel
      // ]
    },
    {
      label: 'Move to folder',
      key: 'move_to_folder',
      className: 'px-4 py-2',
      icon: <Icon component={Move} />,
      disabled: isProduction
    },
    {
      label: 'Start journey',
      key: 'start_journey',
      className: 'px-4 py-2',
      icon: <Icon component={Start} />,
      disabledStatus: [
        WorkflowStatus.Draft,
        WorkflowStatus.InProgress,
        WorkflowStatus.Finish,
        WorkflowStatus.Cancel
      ]
    },
    {
      label: 'Stop journey',
      key: 'stop_journey',
      className: 'px-4 py-2',
      icon: <Icon component={TurnOff2} />,
      disabledStatus: [
        WorkflowStatus.Draft,
        WorkflowStatus.Ready,
        WorkflowStatus.Finish,
        WorkflowStatus.Cancel
      ]
    },
    // {
    //   label: 'Turn off',
    //   key: 'turn_off',
    //   className: 'px-4 py-2',icon: <Icon component={TurnOff} />,
    //   disabledStatus: [
    //     WorkflowStatus.Finish,
    //     WorkflowStatus.Ready,
    //     WorkflowStatus.Draft
    //   ]
    // },
    {
      label: 'View Insight',
      key: 'insight',
      className: 'px-4 py-2',
      icon: <Icon component={BarChartGray} />,
      disabledStatus: [WorkflowStatus.Draft, WorkflowStatus.Ready]
    },
    {
      label: 'Duplicate',
      key: 'duplicate',
      className: 'px-4 py-2',
      icon: <Icon component={DuplicateFile} />,
      disabled: isProduction
    },
    {
      label: 'Archive',
      key: 'archive',
      className: 'text-red600 px-4 py-2',
      icon: <Icon component={Archive} />,
      disabled: isProduction
    }
  ]
}

export function generateWorkflowTableDropdownItems(
  items: {
    label?: string
    key?: string
    icon?: ReactNode
    disabledStatus?: number[]
    disabled?: boolean
    className?: string
  }[],
  record?: any
) {
  if (record && record?.status) {
    return items.map((item) => {
      const { disabledStatus, disabled, className, label, key, icon } = item
      if (disabledStatus?.includes(record.status)) {
        const newDisabled = typeof disabled === 'boolean' ? disabled : true
        return { icon, label, key, className, disabled: newDisabled }
      }
      return { icon, label, key, className, disabled }
    }) as MenuProps['items']
  }
  return items.map(({ icon, label, key, className, disabled }) => ({
    icon,
    label,
    key,
    className,
    disabled
  })) as MenuProps['items']
}
