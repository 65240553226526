import { FORMAT_DATE_DAYJS_API } from 'constants/common'
import { SEGMENT_CONDITION_OPERATOR } from 'constants/segment'
import dayjs from 'dayjs'
import {
  ConditionDataType,
  ConditionValueOperator,
  operatorLabel,
  operatorValue
} from 'interfaces/condition'

const SEGMENT_CONDITION_OPERATOR_KEY_LABEL: Record<string, string> = {}
Object.keys(SEGMENT_CONDITION_OPERATOR).forEach((key) => {
  SEGMENT_CONDITION_OPERATOR_KEY_LABEL[key] =
    SEGMENT_CONDITION_OPERATOR[key as keyof typeof SEGMENT_CONDITION_OPERATOR]
})

export const convertConditionToQuery = (
  conditions: any[],
  relation: string,
  attributes: ConditionDataType[]
) => {
  let query = ''
  if (!conditions || !conditions.length) {
    return ''
  }
  query += `<span className='where'>where</span> `
  for (let i = 0; i < conditions.length; i++) {
    if (!conditions[i]?.attribute) {
      continue
    }
    let sql = ''
    const resultAttr = attributes.find(
      (attribute) => attribute.id === conditions[i].attribute
    )
    const conditonName = resultAttr?.name
      ? resultAttr.name.split(' ').join('_')
      : ''
    const operatorName =
      operatorLabel[
        operatorValue[conditions[i].operation] as keyof typeof operatorLabel
      ] || ''
    const operationText = operatorName.split(' ').join('_')
    if (Array.isArray(conditions[i].value)) {
      conditions[i].value.forEach((item: any, index: number) => {
        sql += `${conditonName} <span className='operation'>${operationText}</span> ${item}`
        if (index !== conditions[i].value.length - 1) {
          sql += ` <span className='relation'>${relation}</span> `
        }
      })
    } else {
      sql += `${conditonName} <span className='operation'>${operationText}</span> ${
        conditions[i].value ? conditions[i].value : ''
      }`
    }
    if (i !== conditions.length - 1) {
      sql += ` <span className='relation'>${relation}</span> `
    }
    query += sql
  }
  return query
}

export const formatConditonAttribute = (
  conditions: any[],
  attributes: ConditionDataType[]
) => {
  const conditionList = [] as any
  conditions.forEach((field: any) => {
    let newValue = [] as string[]
    const attributeField = attributes.find(
      (item) => item.id === field.attribute
    )
    const attributeConfig = {
      dataType: attributeField?.dataType || '',
      description: attributeField?.description || '',
      id: attributeField?.id || '',
      key: attributeField?.key || '',
      name: attributeField?.name || '',
      status: attributeField?.status || '',
      type: attributeField?.type || ''
    }
    if (field.value) {
      if (Array.isArray(field.value)) {
        if (field.operation === ConditionValueOperator.BETWEEN) {
          const startDate = dayjs(field.value[0]).format(FORMAT_DATE_DAYJS_API)
          const endDate = dayjs(field.value[1]).format(FORMAT_DATE_DAYJS_API)
          newValue = [startDate, endDate]
        } else {
          newValue = field.value.join().split(',')
        }
      } else if (
        typeof field.value === 'string' ||
        typeof field.value === 'number'
      ) {
        newValue = [field.value.toString()]
      } else {
        const date = dayjs(field.value).format(FORMAT_DATE_DAYJS_API)
        newValue = [date]
      }
      conditionList.push({
        ...attributeConfig,
        operator: field.operation,
        selected: newValue
      })
    } else if (
      field.operation === ConditionValueOperator.BETWEEN &&
      field.fieldValueFrom &&
      field.fieldValueTo
    ) {
      const fromNumber = field.fieldValueFrom.toString()
      const toNumber = field.fieldValueTo.toString()
      newValue = [fromNumber, toNumber]

      conditionList.push({
        ...attributeConfig,
        operator: field.operation,
        selected: newValue
      })
    }
  })
  return conditionList
}

export const formatConditionAttributeForm = (data: any) => {
  const { listCondition = [], ...restProps } = data
  const results = listCondition.map((condition: any) => {
    const fieldValueDate = []
    let fieldValueBoolean = false as boolean
    const moreFieldConfig = {} as any
    if (condition.dataType === 'DATE') {
      if (condition.operator === ConditionValueOperator.BETWEEN) {
        const startDate = dayjs(condition.selected[0])
        const endDate = dayjs(condition.selected[1])
        fieldValueDate.push(startDate)
        fieldValueDate.push(endDate)
        moreFieldConfig.value = fieldValueDate
      } else {
        const date = dayjs(condition.selected[0])
        moreFieldConfig.value = date
      }
    }
    if (condition.dataType === 'BOOLEAN') {
      fieldValueBoolean = condition.selected[0] === 'true'
      moreFieldConfig.value = fieldValueBoolean.toString()
    }
    if (
      condition.dataType === 'NUMBER' &&
      condition.operator === ConditionValueOperator.BETWEEN
    ) {
      moreFieldConfig.fieldValueFrom = condition.selected[0] || ''
      moreFieldConfig.fieldValueTo = condition.selected[1] || ''
    }
    if (condition.dataType === 'API') {
      moreFieldConfig.value = condition.selected
    }
    if (!moreFieldConfig.value && !moreFieldConfig.fieldValueFrom) {
      moreFieldConfig.value = condition.selected?.[0]
    }
    return {
      attribute: condition.id,
      operation: condition.operator,
      ...moreFieldConfig
    }
  })
  return {
    ...restProps,
    relation: restProps?.relation || 'OR',
    conditions: results
  }
}
