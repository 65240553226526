import { PlusOutlined } from '@ant-design/icons'
import { notification } from 'antd'
import styled from 'styled-components'

const StyledWrap = styled.div`
  min-height: 130px;
  position: relative;
  height: 100%;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;

  img {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .placeholder {
    text-align: center;
    p {
      margin: 0;
    }
  }

  &:hover {
    border-color: ${(props) => props.theme.colors.primary};
  }
`
export const getBase64 = (img: File, callback: (url: string) => void) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result as string))
  reader.readAsDataURL(img)
}

export const isImageValid = (file: File) => {
  const isJpgOrPng =
    file.type === 'image/jpeg' ||
    file.type === 'image/png' ||
    file.type === 'image/gif'

  if (!isJpgOrPng) {
    notification.error({ message: 'You can only upload JPG/PNG/GIF file!' })
  }
  const maximumMB = window.location.pathname.includes('landing-page') ? 2 : 2
  const isLt1M = file.size / 1024 / 1024 < maximumMB
  if (!isLt1M) {
    notification.error({ message: `Image must smaller than ${maximumMB}MB!` })
  }
  return isJpgOrPng && isLt1M
}

function ImageUploader({ onChange, value, onChangeFile }: any) {
  function handleChangeFile(file?: File) {
    if (!file) {
      return
    }

    const isValid = isImageValid(file)

    if (!isValid) {
      return
    }

    getBase64(file, (base64) => {
      onChange(base64)
      onChangeFile && onChangeFile(file)
    })
  }

  function handleClick() {
    const $input = document.createElement('input')
    $input.type = 'file'
    $input.accept = 'image/*'
    $input.addEventListener('change', () => {
      handleChangeFile($input.files?.[0])
    })

    $input.click()
  }

  return (
    <StyledWrap onClick={handleClick}>
      {!value ? (
        <div className="placeholder">
          <PlusOutlined />
          <p>Upload</p>
        </div>
      ) : (
        <img src={value} alt="" />
      )}
    </StyledWrap>
  )
}

export default ImageUploader
