import { useWorkflowBuilder } from '../index.context'
import { useConfig } from '../index.helpers'
import { WorkflowBuilderDragHandle } from './WorkflowBuilderDragHandle'
import { WFNodeContainer, WFNodeWrapper } from './index.styled'
import { memo, useMemo } from 'react'
import { NodeProps } from 'reactflow'

function WorkflowBuilderNodeWithoutMemo(props: NodeProps) {
  const { type, selected } = props

  const { disabled } = useWorkflowBuilder()

  const config = useConfig(type)
  const Component = useMemo(() => config.node, [config])

  if (type === 'WAIT_UNTIL') {
    return (
      <Component
        {...props}
        disabled={disabled}
        bgColor={config.bgColor}
        color={config.color}
        icon={config.icon}
        title={config.label}
        modalWidth={config.modalWidth}
      />
    )
  }

  return (
    <WFNodeContainer
      selected={selected}
      color={config.color}
      width={config.nodeWidth}>
      <WorkflowBuilderDragHandle color={config.color} selected={selected} />
      <WFNodeWrapper>
        {typeof Component === 'string' ? (
          <i className="not-available">{Component}</i>
        ) : (
          <Component
            {...props}
            disabled={disabled}
            bgColor={config.bgColor}
            color={config.color}
            icon={config.icon}
            title={config.label}
            modalWidth={config.modalWidth}
          />
        )}
      </WFNodeWrapper>
    </WFNodeContainer>
  )
}

export const WorkflowBuilderNode = memo(WorkflowBuilderNodeWithoutMemo)
