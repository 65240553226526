import { useWorkflowBuilder } from '../../index.context'
import { optionsSending } from '../../index.helpers'
import { WorkflowBuilderDrawerTemplate } from '../WorkflowBuilderDrawerTemplate'
import { WorkflowBuilderHandle } from '../WorkflowBuilderHandle'
import { WorkflowBuilderNodeTemplate } from '../WorkflowBuilderNodeTemplate'
import { WFNode, useNode } from '../index.helpers'
import {
  StyledWFButton,
  StyledWFTitle,
  StyledWFTitleContainer,
  WFDrawerButton,
  WFDrawerFooter,
  WFDrawerFooterGroup,
  WFForm,
  WFPreview,
  WFPreviewContainer
} from '../index.styled'
import Icon from '@ant-design/icons'
import { Form, Input, Select, Skeleton, notification } from 'antd'
import cn from 'classnames'
import { ListTemplate } from 'components/atoms/list/listTemplate'
import {
  AppPushPreview,
  PreviewAppPush
} from 'components/molecules/templateBuilder/previewAppPush'
import { CheckedCircle, ChevronLeft, Close, Edit, TrashBin } from 'icons'
import { OptionType } from 'interfaces'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Position } from 'reactflow'
import { TemplateServices } from 'services/template'
import styled from 'styled-components'

const StyledEmailInsight = styled.div`
  color: ${({ theme }) => theme?.colors?.gray400};
`

const configInsightsDelivery = {
  total_email_sends: { label: 'Total sends', unit: '' },
  email_delivery_rate: { label: 'Delivery rate', unit: '%' }
}
const configInsightsEngagement = {
  email_open_rate: { label: 'Open rate', unit: '%' },
  email_click_rate: { label: 'Click rate', unit: '%' }
}

const WFAppPush: WFNode = (props) => {
  const {
    id,
    data,
    title: titleProps,
    bgColor,
    color,
    icon,
    modalWidth
  } = props
  const [form] = Form.useForm()
  const [formSending] = Form.useForm()
  const { site, disabled, nodes, setNodes, onNodesDelete, onNodesChangeData } =
    useWorkflowBuilder()
  const { open, onOpen, onClose, onCancel, onUpdate, onDelete } = useNode({
    id,
    form,
    nodes,
    setNodes,
    onNodesDelete,
    onNodesChangeData
  })
  const [isPreview, setIsPreview] = useState(Boolean(data?.template_id))
  const [previewInfo, setPreviewInfo] = useState<AppPushPreview>({
    title: '',
    push_message: '',
    image: ''
  })
  const [previewId, setPreviewId] = useState<string | number | undefined>()
  const [previewLoading, setPreviewLoading] = useState(false)
  const onPreview = useCallback(async () => {
    try {
      const values = await form.validateFields()
      if (!values?.template_id) {
        notification.error({
          message: 'Please choose template before review!'
        })
        return
      }
      setIsPreview(true)
      setPreviewId(values.template_id)
    } catch (error) {}
  }, [form])

  const [editTitle, setEditTitle] = useState(false)
  const [title, setTitle] = useState(data?.label || titleProps)

  const onBack = useCallback(() => {
    setIsPreview(false)
  }, [])

  const onSave = useCallback(async () => {
    const values = await formSending.validateFields()
    const nodeChanged = nodes.find((node) => node.id === id)
    if (!nodeChanged || !previewId || !values?.sending) {
      return
    }
    nodeChanged.data = {
      label: title,
      template_id: previewId,
      sending: values.sending
    }
    onUpdate(nodeChanged)
    onClose({ isReset: false })
  }, [id, nodes, onClose, onUpdate, previewId, formSending, title])

  const titleComp = useMemo(() => {
    if (editTitle) {
      return (
        <StyledWFTitleContainer>
          <Input
            size="small"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            maxLength={30}
            status={title?.length > 30 ? 'error' : undefined}
          />
          <StyledWFButton
            danger
            icon={<Icon component={Close} />}
            onClick={() => {
              setTitle(data?.label || titleProps)
              setEditTitle(false)
            }}
          />
          <StyledWFButton
            className="checked"
            icon={<Icon component={CheckedCircle} />}
            onClick={() => {
              const nodeChanged = nodes.find((node) => node.id === id)
              if (!nodeChanged) {
                return
              }

              nodeChanged.data = { ...nodeChanged.data, label: title }
              onUpdate(nodeChanged)
              onClose({ isReset: false })
              setEditTitle(false)
            }}
          />
        </StyledWFTitleContainer>
      )
    }
    return (
      <StyledWFTitleContainer>
        <StyledWFTitle>{title}</StyledWFTitle>
        <StyledWFButton
          icon={<Icon component={Edit} />}
          onClick={() => setEditTitle(true)}
        />
      </StyledWFTitleContainer>
    )
  }, [editTitle, title, onClose, onUpdate])

  const descriptionComp = useMemo(() => {
    if (!data?.template_id) {
      return ''
    }
    return `Template ID : ${data.template_id}`
  }, [data?.template_id])

  const footerComp = useMemo(() => {
    if (isPreview) {
      return (
        <WFDrawerFooter>
          <WFDrawerButton
            icon={<Icon component={ChevronLeft} />}
            size="small"
            onClick={onBack}
            className="back">
            Back
          </WFDrawerButton>
          <WFDrawerFooterGroup>
            <WFDrawerButton
              onClick={onDelete(id)}
              icon={<Icon component={TrashBin} />}
              size="small"
            />
            <WFDrawerButton size="small" onClick={onCancel}>
              Discard
            </WFDrawerButton>
            <WFDrawerButton
              disabled={disabled}
              type="primary"
              size="small"
              onClick={onSave}>
              Select Template
            </WFDrawerButton>
          </WFDrawerFooterGroup>
        </WFDrawerFooter>
      )
    }
    return (
      <WFDrawerFooter>
        <WFDrawerFooterGroup>
          <WFDrawerButton
            onClick={onDelete(id)}
            icon={<Icon component={TrashBin} />}
            size="small"
          />
          <WFDrawerButton onClick={onPreview} type="primary" size="small">
            Preview Template
          </WFDrawerButton>
        </WFDrawerFooterGroup>
      </WFDrawerFooter>
    )
  }, [id, isPreview, onPreview, onBack, onCancel, onSave, onDelete])

  useEffect(() => {
    if (!previewId || typeof previewId === 'boolean') {
      return
    }
    ;(async () => {
      setPreviewLoading(true)
      try {
        const previewRes = (await TemplateServices.getById(previewId)) as any
        const { data: previewData } = previewRes
        if (!previewData?.data) {
          throw new Error(previewRes?.data?.error)
        }
        const reviewData = {
          title: previewData.data.title,
          push_message: previewData.data.push_message,
          image: previewData.data.image
        }
        setPreviewInfo(reviewData)
      } catch (error: any) {
        console.log('** Error preview ', previewId, ' : ', error)
      }
      setPreviewLoading(false)
    })()
  }, [previewId])

  useEffect(() => {
    if (open) {
      setPreviewId(data?.template_id)
      formSending && formSending.setFieldsValue({ sending: data.sending })
    }
  }, [data?.template_id, data.sending, open, formSending])

  const appInsights = useMemo(() => {
    const insights: OptionType[] = []
    if (data?.campaignInsightDelivery) {
      Object.keys(configInsightsDelivery).forEach((k) => {
        const key = k as keyof typeof configInsightsDelivery
        const config = configInsightsDelivery[key]
        if (typeof data.campaignInsightDelivery?.[key] !== 'undefined') {
          insights.push({
            value: data.campaignInsightDelivery[key] + config.unit,
            label: config.label
          })
        }
      })
    }
    if (data?.campaignInsightEngagement) {
      Object.keys(configInsightsEngagement).forEach((k) => {
        const key = k as keyof typeof configInsightsEngagement
        const config = configInsightsEngagement[key]
        insights.push({
          value: data.campaignInsightEngagement[key] + config.unit,
          label: config.label
        })
      })
    }
    return insights
  }, [data?.campaignInsightDelivery, data?.campaignInsightEngagement])

  return (
    <>
      <WorkflowBuilderNodeTemplate
        bgColor={bgColor}
        color={color}
        icon={icon}
        title={title}
        description={descriptionComp}
        onClick={onOpen}
        sources={
          <WorkflowBuilderHandle
            type="source"
            id={`${id}-source`}
            position={Position.Bottom}
          />
        }
        targets={
          <WorkflowBuilderHandle
            type="target"
            id={`${id}-target`}
            position={Position.Top}
          />
        }>
        {Boolean(appInsights.length) &&
          appInsights.map((insight, index) => {
            return (
              <StyledEmailInsight key={index.toString()}>
                {insight.label}: {insight.value || '--'}
              </StyledEmailInsight>
            )
          })}
      </WorkflowBuilderNodeTemplate>
      <WorkflowBuilderDrawerTemplate
        open={open}
        width={modalWidth}
        title={titleComp}
        onClose={onClose}
        onUpdate={onPreview}
        footer={footerComp}
        className="disableBodyScroll"
        destroyOnClose>
        <WFForm form={form} initialValues={data}>
          <Form.Item name="template_id">
            <ListTemplate site={site} type="app_push" />
          </Form.Item>
        </WFForm>
        <WFPreview className={cn({ isPreview })}>
          <WFPreviewContainer>
            {previewLoading ? (
              <Skeleton active title paragraph={{ rows: 5 }} />
            ) : (
              <div>
                <Form form={formSending} layout="vertical">
                  <Form.Item
                    name="sending"
                    label="Sending to unsubscribe"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required!'
                      }
                    ]}
                    wrapperCol={{ span: 12 }}>
                    <Select options={optionsSending} />
                  </Form.Item>
                </Form>
                <br />
                <PreviewAppPush preview={previewInfo} />
              </div>
            )}
          </WFPreviewContainer>
        </WFPreview>
      </WorkflowBuilderDrawerTemplate>
    </>
  )
}

WFAppPush.validate = (node) => {
  if (!node.data || !node.data.template_id) {
    return false
  }
  return true
}
export { WFAppPush }
