import { TIME_WINDOW_TIME_OPTIONS } from './FormWorkflowTimeWindow'
import Icon from '@ant-design/icons'
import { Button, ButtonProps, Select } from 'antd'
import cn from 'classnames'
import { SelectProps } from 'components/atoms/select'
import { Minus } from 'icons'
import { Ref, forwardRef, useEffect, useState } from 'react'

export type FormWorkflowTimePickerState = number | null
export type FormWorkflowTimePickerValue = string | null
export type FormWorkflowTimePickerRef = HTMLDivElement
export type FormWorkflowTimePickerProps = Pick<
  SelectProps,
  'size' | 'options' | 'placeholder' | 'className' | 'style'
> & {
  defaultValue?: FormWorkflowTimePickerValue
  value?: FormWorkflowTimePickerValue
  onChange?: (value: FormWorkflowTimePickerValue) => void
  onRemove?: ButtonProps['onClick']
}

const FormWorkflowTimePickerWithoutRef = (
  props: FormWorkflowTimePickerProps,
  ref?: Ref<FormWorkflowTimePickerRef>
) => {
  const {
    size,
    options = TIME_WINDOW_TIME_OPTIONS,
    placeholder,
    className,
    style,
    defaultValue,
    value: valueProps,
    onChange,
    onRemove
  } = props
  const [value, setValue] = useState<FormWorkflowTimePickerState>(
    formatFormWorkflowTime(defaultValue || valueProps || null)
  )

  const handleChange = (val: number) => {
    setValue(val)
    const valChanged = val < 10 ? `0${val}:00` : `${val}:00`
    onChange?.(valChanged)
  }

  useEffect(() => {
    setValue(formatFormWorkflowTime(valueProps || null))
  }, [valueProps])

  return (
    <div ref={ref} className="flex items-center gap-2 w-full">
      <Select
        size={size}
        placeholder={placeholder}
        options={options}
        value={value}
        onChange={handleChange}
        className={cn(className, {
          'min-w-[160px]': !!onRemove,
          'flex-1 min-w-0': !onRemove
        })}
        style={style}
      />
      {!!onRemove && (
        <Button
          icon={<Icon component={Minus} />}
          size="small"
          shape="circle"
          onClick={onRemove}
        />
      )}
    </div>
  )
}
export const FormWorkflowTimePicker = forwardRef(
  FormWorkflowTimePickerWithoutRef
)

function formatFormWorkflowTime(
  value: FormWorkflowTimePickerValue
): FormWorkflowTimePickerState {
  if (!value) {
    return null
  }
  return parseInt(value.split(':')[0])
}
