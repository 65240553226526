import { Button, Divider, Form, Space } from 'antd'
import styled, { css } from 'styled-components'
import { cssLimitLines } from 'styles'

export const StyledWFButton = styled(Button)`
  padding: 0;
  margin-left: 8px;
  width: 16px !important;
  height: 16px !important;
  border: 0;
  line-height: 1;

  &.checked {
    color: ${({ theme }) => theme?.colors?.green500};
  }
`

export const StyledWFTitle = styled.div`
  flex: auto;
  min-width: 0;
  ${cssLimitLines(1)}
`

export const StyledWFTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
`

export const WFNodeTitleOutside = styled.div`
  position: absolute;
  bottom: 100%;
  left: -4px;
  width: calc(100% - 42px);
  margin-bottom: 4px;
  color: ${({ theme }) => theme?.colors?.gray400};
  font-size: 11px;
  ${cssLimitLines(1)}
`

export const WFNodeIcon = styled.div<{ bgColor?: string; color?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 2px;
  color: ${({ color }) => color};
  font-size: 20px;
  line-height: 1;
`

export const WFNodeTitle = styled.h3`
  margin: 0;
  color: ${({ theme }) => theme?.colors?.gray700};
  font-size: 14px;
  ${cssLimitLines(1)}
`

export const WFNodeDescription = styled.div`
  margin: 0;
  margin-top: 4px;
  color: ${({ theme }) => theme?.colors?.gray400};
  font-size: 12px;
`

export const WFNodeHeaderInner = styled.div`
  flex: 1;
  min-width: 0;
`

export const WFNodeInfo = styled(Button)`
  padding: 0;
  width: 14px;
  height: 14px;
  color: ${({ theme }) => theme?.colors?.neutral400};
  font-size: 14px;
  line-height: 1;
  margin-top: 2px;
  > * {
    font-size: inherit !important;
  }
`

export const WFNodeHeader = styled.div`
  display: flex;
  gap: 8px;
`

export const WFNodeDivider = styled(Divider)`
  border-color: ${({ theme }) => theme?.colors?.neural100};
  margin-top: 8px;
  margin-bottom: 8px;
`

export const WFNodeWrapper = styled.div`
  padding: 12px;
  .not-available {
    color: ${({ theme }) => theme?.colors?.gray300};
  }
`

export const WFNodeHandleIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  border: 1px solid ${({ theme }) => theme?.colors?.neutral100};
  background-color: #fff;
  border-radius: 50%;
`

const cssNodeHandleContainer = css`
  position: absolute;
  right: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: space-around;
  pointer-events: none;
  > * {
    pointer-events: auto;
  }
`

export const WFNodeTargetContainer = styled.div`
  ${cssNodeHandleContainer}
  top: 0;
  transform: translateY(-50%);
`

export const WFNodeSourceContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: -12px;
  line-height: 1;
  transform: translateY(8px);
`

export const WFNodeContainer = styled.div<{
  width?: number
  color?: string
  selected?: boolean
}>`
  position: relative;
  min-width: ${({ width }) => (width ? `${width}px` : `220px`)};
  max-width: ${({ width }) => (width ? `${width}px` : `220px`)};
  border: 1px solid
    ${({ color, selected }) => (selected ? color : 'transparent')};
  background-color: #fff;
  box-shadow: 0px 0px 1px 0px #0000000a, 0px 0px 2px 0px #0000000f,
    0px 4px 8px 0px #0000000a;
  font-size: 12px;
  &:before {
    position: absolute;
    top: -1px;
    left: -4px;
    display: block;
    height: calc(100% + 2px);
    width: 4px;
    border-left: 4px solid ${({ color }) => color};
    border-radius: 4px 0 0 4px;
    content: '';
  }
`

// For Email, Web Push and App Push
export const WFForm = styled(Form)`
  height: 100%;
  .ant-form-item {
    height: 100%;
    .ant-form-item-row {
      height: 100%;
      .ant-form-item-control {
        height: 100%;
        .ant-form-item-control-input {
          height: 100%;
          .ant-form-item-control-input-content {
            height: 100%;
          }
        }
      }
    }
  }
`

export const WFDrawerButton = styled(Button)`
  padding-left: 12px;
  padding-right: 12px;
  &.back {
    display: flex;
    align-items: center;
    border-color: ${({ theme }) => theme?.colors?.neutral200};
    > .anticon {
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 1;
      + span {
        margin-left: ;
      }
    }
    &:hover {
      border-color: inherit;
    }
  }
`

export const WFDrawerFooterGroup = styled(Space)`
  flex: 1;
  min-width: 0;
  justify-content: flex-end;
`

export const WFDrawerFooter = styled.div`
  display: flex;
  justify-content: space-between;
`

export const WFPreview = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  transition: 0.25s linear all;
  &.isPreview {
    opacity: 1;
    pointer-events: auto;
    transition: 0.25s linear all;
  }
`

export const WFPreviewContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 16px;
  background-color: ${({ theme }) => theme?.colors?.white};
`

export const WFPreviewMode = styled.div`
  position: absolute;
  z-index: 1;
  right: 40px;
  bottom: 40px;
  padding: 4px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme?.colors?.white};
  border-radius: 4px;
  box-shadow: 0px 0px 1px 0px #0000000a, 0px 0px 2px 0px #0000000f,
    0px 4px 8px 0px #0000000a;
`

export const WFPreviewModeButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 0;
  color: ${({ theme }) => theme?.colors?.gray600};
  border-radius: 2px;
  &.isActive {
    color: ${({ theme }) => theme?.colors?.white};
    background-color: ${({ theme }) => theme?.colors?.blue600};
  }
`

export const WFPreviewWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  &.isDesktop {
    border: 1px solid ${({ theme }) => theme?.colors?.neutral100};
    background-color: ${({ theme }) => theme?.colors?.neutral50};
    border-radius: 4px;
  }
`
