import { Button, Space } from 'antd'
import styled from 'styled-components'

export const StyleButton = styled(Button)``

export const StyleConditionActions = styled(Space)`
  min-width: 85px;
  justify-content: flex-end;
  align-items: flex-start;

  &:before {
    content: '';
    width: 1px;
    height: 32px;
    background: ${({ theme }) => theme.colors.gray200};
  }
`

export const StyleConditionRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;

  .ant-form-item {
    flex: 1;
    max-width: 200px;

    .ant-form-item-control-input {
      min-height: 32px;
    }

    .ant-select-selection-overflow {
      flex-wrap: nowrap;
      overflow: hidden;
    }
  }
`

export const StyleText = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.gray400};
  margin-bottom: 10px;
`

export const StyleWrapperField = styled.div`
  flex-wrap: wrap;
  gap: 10px;
  display: flex;
  width: 100%;
`
