import styled from 'styled-components'

export const NodeHandleNumber = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.gray400};
`

export const StyledDescription = styled.span`
  color: ${({ theme }) => theme?.colors?.gray400};
  strong {
    color: ${({ theme }) => theme?.colors?.gray800};
    font-weight: 400;
  }
`

export const StyleQueryString = styled.div`
  background: ${(props) => props.theme?.colors?.gray800};
  border-radius: 4px;
  padding: 15px;
  background: ${(props) => props.theme?.colors?.gray800};
  color: ${(props) => props.theme?.colors?.white};
  p {
    margin: 0;
  }
  .where {
    font-size: 14px;
    line-height: 22px;
    color: ${(props) => props.theme?.colors?.pink600};
  }
  .operation {
    color: ${(props) => props.theme?.colors?.blue600};
  }
  .relation {
    color: ${(props) => props.theme?.colors?.green600};
  }
`
