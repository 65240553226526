import {
  OPTION_FILTER_APPROACH,
  OPTION_FILTER_COLUMNS,
  OPTION_FILTER_STATUS,
  resetFilterCampaignParams
} from '../CampaignTable/CampaignTable.helpers'
import {
  CampaignHeaderState,
  CampaignHeaderValue,
  campaignHeaderPropsToState,
  campaignHeaderStateToProps,
  menuCreateCampaign,
  tabCampaign
} from './CampaignHeader.helpers'
import {
  StyleLabel,
  StyleWrapperItem,
  StyledCampaignButton,
  StyledCampaignTableSpace,
  StyledContainerHeader
} from './CampaignHeader.styled'
import Icon, { BarChartOutlined } from '@ant-design/icons'
import { Button, Checkbox, Col, Dropdown, Select, Tabs } from 'antd'
import { DatePicker } from 'components/atoms/datePicker'
import { DrawerFilter } from 'components/atoms/drawer'
import { DropdownCheckbox } from 'components/atoms/dropdown'
import { InputSearch } from 'components/atoms/input'
import { SelectSite } from 'components/atoms/select'
import { menuRoutes } from 'components/molecules'
import { PERMISSIONS } from 'constants/permission'
import { TEMPLATE_TYPE } from 'constants/template'
import { useAuth } from 'context/Auth'
import dayjs from 'dayjs'
import { checkAuth } from 'hooks/useCheckAuth'
import { Column, Filter, Plus } from 'icons'
import {
  TransitionEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useNavigate } from 'react-router'

export type CampaignHeaderProps = {
  value?: CampaignHeaderValue
  defaultValue?: CampaignHeaderValue
  onChange?: (value: CampaignHeaderValue) => void
  onTransitionEnd?: TransitionEventHandler<HTMLDivElement>
}
export default function CampaignHeader(props: CampaignHeaderProps) {
  const {
    defaultValue,
    value: valueProps,
    onChange: onChangeProps,
    onTransitionEnd: onTransitionEndProps
  } = props
  const navigate = useNavigate()
  const { userAuth } = useAuth()
  const debouceRef = useRef<NodeJS.Timeout>()
  const [value, setValue] = useState<CampaignHeaderState>(() =>
    campaignHeaderPropsToState(defaultValue || valueProps || {})
  )
  const [openFilter, setOpenFilter] = useState(false)

  const isSearch = checkAuth(
    { permissions: [PERMISSIONS.campaign_search] },
    userAuth?.auth || {}
  )

  const itemsCreateCampaign = useMemo(() => {
    return menuCreateCampaign.map((item) => {
      const disabled = !checkAuth(
        { roles: item.roles, permissions: item.permissions },
        userAuth?.auth || {}
      )
      return {
        key: item.key,
        label: item.label,
        disabled
      }
    })
  }, [userAuth?.auth])

  const onChange = (key: keyof CampaignHeaderValue | string) => {
    return (val: any) => {
      const newValue = {
        ...value,
        [key]: val
      }
      setValue(newValue)

      const isColumnFieldUpdated =
        JSON.stringify(newValue.columns?.sort()) !==
        JSON.stringify(value.columns?.sort())

      if (isColumnFieldUpdated) {
        onChangeProps?.(campaignHeaderStateToProps(newValue))
      }

      const isSearchFieldUpdated = value.search !== newValue.search
      if (debouceRef.current) clearTimeout(debouceRef.current)
      if (isSearchFieldUpdated) {
        debouceRef.current = setTimeout(() => {
          onChangeProps?.(campaignHeaderStateToProps(newValue))
        }, 300)
      }
    }
  }

  const onChangeTab = useCallback(
    (val: string) => {
      const newValue = {
        ...value
      }
      delete newValue.template_types
      if (val === 'automation') {
        newValue.type = val
        delete newValue.template_type
      } else {
        newValue.template_type = val
        delete newValue.type
      }

      if (val === 'sms') {
        delete newValue.type
        newValue.template_type = val
        newValue.template_types = [TEMPLATE_TYPE.VNPT, TEMPLATE_TYPE.TWILIO]
      }
      setValue(newValue)
      onChangeProps?.(campaignHeaderStateToProps(newValue))
    },
    [onChangeProps, value]
  )

  const handleOnClose = () => {
    setValue(defaultValue || valueProps || {})
    setOpenFilter(false)
  }

  const toggleFilter = () => {
    if (openFilter) {
      setValue(defaultValue || valueProps || {})
    }
    setOpenFilter((pre) => !pre)
  }

  const onTransitionEnd: TransitionEventHandler<HTMLDivElement> = (e) => {
    onTransitionEndProps?.(e)
  }

  const onSubmitFilter = useCallback(() => {
    onChangeProps?.(campaignHeaderStateToProps(value))
  }, [onChangeProps, value])

  const onResetFilter = useCallback(() => {
    onChangeProps?.(
      campaignHeaderStateToProps({
        ...value,
        ...resetFilterCampaignParams
      })
    )
  }, [onChangeProps, value])

  useEffect(() => {
    const value = campaignHeaderPropsToState(valueProps || {})
    setValue(value)
  }, [valueProps])

  return (
    <StyledContainerHeader onTransitionEnd={onTransitionEnd}>
      <Col span={10} className="header--left">
        <Tabs
          items={tabCampaign}
          activeKey={value.template_type || value.type}
          onChange={onChangeTab}
        />
      </Col>
      <Col span={14} className="header--right">
        <StyledCampaignTableSpace size={16} className="Table--right">
          <InputSearch
            disabled={!isSearch}
            value={value.search}
            onSearch={onChange('search')}
            allowClear={true}
            placeholder="Search campaign"
          />
          <Button
            disabled={!isSearch}
            type={openFilter ? 'primary' : 'ghost'}
            icon={<Icon component={Filter} />}
            className={openFilter ? 'active-filter' : ''}
            onClick={toggleFilter}>
            Filter
          </Button>
          <DropdownCheckbox
            title="Edit column"
            description="You can display columns on Campaign"
            options={OPTION_FILTER_COLUMNS}
            value={value.columns}
            onOk={onChange('columns')}>
            <Button
              type="ghost"
              icon={<Icon component={Column} />}
              className="btnColumn"
            />
          </DropdownCheckbox>
          <StyledCampaignButton
            icon={<BarChartOutlined />}
            onClick={() => navigate(menuRoutes.campaign_messaging_insights)}
            type="ghost"
            className="btn--insight"
          />
          <Dropdown
            trigger={['click']}
            placement="bottomRight"
            menu={{
              className: 'dropdown_menu',
              items: itemsCreateCampaign,
              onClick(item) {
                navigate(item.key)
              }
            }}>
            <StyledCampaignButton
              icon={<Plus />}
              type="primary"
              className="btn--create">
              New Campaign
            </StyledCampaignButton>
          </Dropdown>
        </StyledCampaignTableSpace>
      </Col>
      <DrawerFilter
        title="Filter"
        open={openFilter}
        onClose={handleOnClose}
        width={360}
        mask={false}
        onReset={onResetFilter}
        onSubmit={onSubmitFilter}>
        <StyleWrapperItem>
          <StyleLabel>Site</StyleLabel>
          <SelectSite
            value={value.site_id}
            onChange={onChange('site_id')}
            placeholder="- Select site -"
            allowClear
          />
        </StyleWrapperItem>

        <StyleWrapperItem>
          <StyleLabel>Status</StyleLabel>
          <Checkbox.Group
            value={value.status}
            onChange={onChange('status')}
            options={OPTION_FILTER_STATUS}
            className="vertical-options"
          />
        </StyleWrapperItem>

        <StyleWrapperItem>
          <StyleLabel>Sent time</StyleLabel>
          <DatePicker.RangePicker
            value={value.send_time || null}
            onChange={onChange('send_time')}
            placeholder={['Sent time from', 'Sent time to']}
            disabledDate={(current) => {
              return current && current > dayjs()
            }}
          />
        </StyleWrapperItem>
        <StyleWrapperItem>
          <StyleLabel>Created Date</StyleLabel>
          <DatePicker.RangePicker
            value={value.created || null}
            onChange={onChange('created')}
            placeholder={['Created from', 'Created to']}
            disabledDate={(current) => {
              return current && current > dayjs()
            }}
          />
        </StyleWrapperItem>
        <StyleWrapperItem>
          <StyleLabel>Approach</StyleLabel>
          <Select
            value={value.approach}
            onChange={onChange('approach')}
            options={OPTION_FILTER_APPROACH}
            className="full-width"
            placeholder="Approach"
            allowClear
          />
        </StyleWrapperItem>
      </DrawerFilter>
    </StyledContainerHeader>
  )
}
