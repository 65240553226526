import { useWorkflowBuilder } from '../../../index.context'
import { WorkflowBuilderDrawerTemplate } from '../../WorkflowBuilderDrawerTemplate'
import { WorkflowBuilderHandle } from '../../WorkflowBuilderHandle'
import { WorkflowBuilderNodeTemplate } from '../../WorkflowBuilderNodeTemplate'
import { WFNode, useNode } from '../../index.helpers'
import {
  formatConditionAttributeForm,
  formatConditonAttribute
} from '../WFUserAttributes/index.helpers'
import { serializeWFTrueFalse } from './index.helpers'
import {
  StyleHandleItem,
  StyledButton,
  StyledDivider,
  StyledItem,
  StyledItemHeader,
  StyledLabel,
  StyledSpace
} from './index.styled'
import Icon from '@ant-design/icons'
import {
  CheckboxOptionType,
  Form,
  FormListFieldData,
  FormListOperation,
  Input,
  Select
} from 'antd'
import { SelectCustomEvent } from 'components/atoms/select/selectCustomEvent'
import { ConditionBase } from 'components/condition/base/'
import { ConditionDataField } from 'components/condition/base/conditionOperator'
import { ConditionRangeDate } from 'components/condition/base/conditionRangeDate'
import { operationDataType } from 'components/condition/base/index.helpers'
import { getFormNamePath } from 'components/condition/conditions/form/index.helpers'
import { FormEvent } from 'components/molecules'
import { useApp } from 'context/App'
import { useAttributeFields } from 'hooks/useSegment'
import { Close, Plus } from 'icons'
import { OptionType } from 'interfaces'
import {
  ConditionDataType,
  ConditionValueOperator,
  OperatorKeys,
  operatorLabel,
  operatorValue,
  optionsConditionBoolean
} from 'interfaces/condition'
import { Fragment, useEffect, useMemo } from 'react'
import { Position } from 'reactflow'

const WFTrueFalse: WFNode = (props) => {
  const { id, data, title, bgColor, color, icon, modalWidth } = props
  const [form] = Form.useForm()
  const { site, nodes, setNodes, onNodesDelete, onNodesChangeData } =
    useWorkflowBuilder()
  const { open, onClose, onOpen, onUpdate } = useNode({
    id,
    form,
    nodes,
    setNodes,
    onNodesDelete,
    onNodesChangeData
  })
  const data_type = Form.useWatch('data_type', form)
  const attributes = useAttributeFields()
  const { sites } = useApp()

  const initialValues = useMemo(() => {
    if (!data?.data_type) {
      if (data?.listCondition?.length) {
        return {
          ...data,
          data_type: 'conditions'
        }
      }
      if (data?.events_conditions?.length) {
        return {
          ...data,
          data_type: 'events_conditions'
        }
      }
      if (data?.email_conditions) {
        return {
          ...data,
          data_type: 'email_conditions'
        }
      }
    }

    return { ...data }
  }, [data])

  const attributeOptions = useMemo<OptionType[]>(() => {
    return attributes?.map((fieldOption: any) => ({
      value: fieldOption.field,
      label: fieldOption.name
    }))
  }, [attributes])

  const selectedSiteName = useMemo(() => {
    if (!site || !sites.length) {
      return ''
    }
    const result = sites.find((item) => item.value === site)
    return result?.domain
  }, [sites, site])

  const handleFinish = async (values: any) => {
    try {
      const formatData = values.conditions
        ? formatConditonAttribute(values.conditions, attributes)
        : undefined
      const nodeChanged = nodes.find((node) => node.id === id)
      if (!nodeChanged) {
        return
      }
      nodeChanged.data = {
        ...values,
        branch_name: values.branch_name,
        listCondition: formatData
      }

      onUpdate(nodeChanged)
      onClose({ isReset: false })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (open && data.branch_name) {
      const initValueForm = formatConditionAttributeForm(data)
      form &&
        form.setFieldsValue({ ...initValueForm, branch_name: data.branch_name })
    }
  }, [open, data, form])

  return (
    <>
      <WorkflowBuilderNodeTemplate
        bgColor={bgColor}
        color={color}
        icon={icon}
        title={data.branch_name || title}
        description={
          data.branch_name ? (
            <>
              <div>True/False Branch on</div>
              <strong>{data?.listCondition?.length} condition</strong>
            </>
          ) : (
            'No condition selected'
          )
        }
        onClick={onOpen}
        isShowDivider
        sources={
          <>
            <StyleHandleItem>
              <div className="handle-title">True</div>
              <WorkflowBuilderHandle
                type="source"
                id={`${id}-true`}
                position={Position.Bottom}
              />
            </StyleHandleItem>
            <StyleHandleItem>
              <div className="handle-title">False</div>
              <WorkflowBuilderHandle
                type="source"
                id={`${id}-false`}
                position={Position.Bottom}
              />
            </StyleHandleItem>
          </>
        }
        targets={
          <WorkflowBuilderHandle
            type="target"
            id={`${id}-target`}
            position={Position.Top}
          />
        }
      />
      <WorkflowBuilderDrawerTemplate
        open={open}
        width={modalWidth}
        title="True/False Branch"
        onClose={onClose}
        onUpdate={form.submit}
        destroyOnClose>
        <Form
          form={form}
          initialValues={initialValues}
          onFinish={handleFinish}
          layout="vertical">
          <Form.Item
            name="branch_name"
            label="Branch Name"
            rules={[
              {
                required: true,
                message: 'This field is required.'
              },
              {
                whitespace: true,
                message: 'Please input branch name with characters'
              },
              { max: 50, message: '50 characters maximum' }
            ]}>
            <Input placeholder="Branch Name" size="small" />
          </Form.Item>
          <Form.Item name="data_type" label="Condition">
            <Select
              options={[
                { label: 'Condition by Attributes', value: 'conditions' },
                { label: 'Condition by Events', value: 'events_conditions' },
                {
                  label: 'Condition by Email Performance',
                  value: 'email_conditions'
                }
              ]}
              placeholder="Condition"
            />
          </Form.Item>
          <StyledDivider />
          <Form.Item
            shouldUpdate={(prev, next) => prev.data_type !== next.data_type}>
            {data_type === 'events_conditions' && (
              <FormEvent name="events_conditions" showDate={false} />
            )}
            {data_type === 'conditions' && (
              <Form.List name="conditions">
                {(conditions, operations) => {
                  return (
                    <>
                      {conditions.map((condition, index) => {
                        return (
                          <Fragment key={condition.key}>
                            <FormAttributeCondition
                              site={selectedSiteName}
                              key={condition.key}
                              attributeOptions={attributeOptions}
                              attributes={attributes}
                              formListFieldData={condition}
                              formListOperation={operations}
                            />
                            {conditions.length - 1 !== index && (
                              <StyledLabel className="and_label">
                                AND
                              </StyledLabel>
                            )}
                          </Fragment>
                        )
                      })}
                      {!conditions.length && (
                        <StyledButton
                          onClick={() => operations.add()}
                          icon={<Icon component={Plus} />}
                          className="condition_btn">
                          Add condition
                        </StyledButton>
                      )}
                    </>
                  )
                }}
              </Form.List>
            )}
            {data_type === 'email_conditions' && (
              <Form.Item
                name="email_conditions"
                rules={[
                  {
                    async validator(_, value) {
                      if (!value || !value?.length) {
                        return Promise.reject('This field is required.')
                      }
                      return true
                    }
                  }
                ]}>
                <SelectCustomEvent
                  options={[
                    {
                      label: 'event_click',
                      value: 'custom-event-click'
                    },
                    {
                      label: 'event_open',
                      value: 'custom-event-open'
                    },
                    {
                      label: 'event_delivery',
                      value: 'custom-event-delivery'
                    },
                    {
                      label: 'event_complaint',
                      value: 'custom-event-complaint'
                    }
                  ]}
                  placeholder="Event conditions"
                  allowClear
                />
              </Form.Item>
            )}
          </Form.Item>
        </Form>
      </WorkflowBuilderDrawerTemplate>
    </>
  )
}

type FormAttributeConditionProps = {
  site: any
  attributes: ConditionDataType[]
  attributeOptions: OptionType[]
  formListFieldData: FormListFieldData
  formListOperation: FormListOperation
}
function FormAttributeCondition({
  site,
  attributes,
  attributeOptions,
  formListFieldData: { name: itemName },
  formListOperation
}: FormAttributeConditionProps) {
  const form = Form.useFormInstance()
  const conditionKey = Form.useWatch(
    getFormNamePath('conditions', [itemName, 'conditions']),
    form
  )
  const operationKey = Form.useWatch(
    getFormNamePath('conditions', [itemName, 'operation']),
    form
  )
  const selectedCondition = useMemo(() => {
    const condition = attributes?.find(({ id }) => id === conditionKey)
    return condition || null
  }, [attributes, conditionKey])

  const operationOptions = useMemo(() => {
    const options = [] as CheckboxOptionType[]
    if (!selectedCondition) {
      return []
    }

    const operationKey = operationDataType[
      selectedCondition.dataType as keyof typeof ConditionDataField
    ] as string[]

    Object.keys(operatorLabel).forEach((k) => {
      const key = k as OperatorKeys
      if (operationKey.includes(key)) {
        options.push({
          value: operatorValue[key],
          label: operatorLabel[key]
        })
      }
    })
    return options
  }, [selectedCondition])

  const RenderConditionFieldComponent = useMemo(() => {
    if (!selectedCondition) {
      // Default value field
      return (
        <Form.Item
          name={[itemName, 'value']}
          rules={[
            {
              required: true,
              message: 'This field is required.'
            }
          ]}>
          <Input placeholder="Value" size="small" />
        </Form.Item>
      )
    }
    const moreProps = {} as any
    if (selectedCondition.dataType === 'BOOLEAN') {
      moreProps.options = optionsConditionBoolean
    }
    if (
      selectedCondition.dataType === 'DATE' &&
      operationKey === ConditionValueOperator.BETWEEN
    ) {
      return (
        <Form.Item
          name={[itemName, 'value']}
          className="item-field"
          rules={[
            {
              required: true,
              message: 'Value is required'
            }
          ]}>
          <ConditionRangeDate size="small" />
        </Form.Item>
      )
    }
    if (
      selectedCondition.dataType === 'NUMBER' &&
      operationKey === ConditionValueOperator.BETWEEN
    ) {
      return (
        <ConditionBase.ConditionNumberRange
          itemName={itemName}
          rootName="attributes"
          className="condition-field"
          size="small"
        />
      )
    }
    const ConditionComponent =
      ConditionDataField[
        selectedCondition.dataType as keyof typeof ConditionDataField
      ]

    return (
      <Form.Item
        name={[itemName, 'value']}
        className="item-field"
        rules={[
          {
            required: true,
            message: 'Value is required'
          }
        ]}>
        <ConditionComponent
          pathUrl={selectedCondition.value[0] || ''}
          site={site}
          placeholder="Value"
          type={selectedCondition.type}
          size="small"
          {...moreProps}
        />
      </Form.Item>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCondition, site, operationKey])

  const onChangeAttributeKey = (val: string) => {
    const resultField = attributes.find((field) => field.id === val)
    if (resultField) {
      let resetFieldValue = null
      if (resultField.dataType === 'API' && resultField.type === 'MANY') {
        resetFieldValue = []
      }
      form.setFieldValue(
        getFormNamePath('conditions', [itemName, 'value']),
        resetFieldValue
      )
      form.setFieldValue(
        getFormNamePath('conditions', [itemName, 'operation']),
        null
      )
      form.setFieldValue(
        getFormNamePath('conditions', [itemName, 'type']),
        resultField.dataType
      )
    }
  }

  return (
    <StyledItem>
      <StyledItemHeader>
        <StyledLabel className="item_label">
          Attribute condition {itemName + 1}
        </StyledLabel>
        <StyledButton
          type="link"
          icon={<Icon component={Close} />}
          className="remove_item_btn"
          onClick={() => formListOperation.remove(itemName)}
        />
      </StyledItemHeader>
      <Form.Item
        name={[itemName, 'conditions']}
        rules={[
          {
            required: true,
            message: 'This field is required.'
          }
        ]}>
        <Select
          options={attributeOptions}
          onChange={onChangeAttributeKey}
          showSearch
          optionFilterProp="label"
          placeholder="Attribute"
          size="small"
        />
      </Form.Item>
      <Form.Item name={[itemName, 'type']} hidden={true}>
        <Input />
      </Form.Item>
      <Form.Item
        name={[itemName, 'operation']}
        wrapperCol={{ span: 12 }}
        rules={[
          {
            required: true,
            message: 'This field is required.'
          }
        ]}>
        <Select
          options={operationOptions}
          placeholder="Operation"
          size="small"
        />
      </Form.Item>
      <StyledSpace className="full_width">
        {RenderConditionFieldComponent}
      </StyledSpace>
    </StyledItem>
  )
}

WFTrueFalse.serialize = serializeWFTrueFalse
WFTrueFalse.validate = (node) => {
  if (node.data?.listCondition && node.data.listCondition?.length) {
    return true
  }

  if (node.data?.events_conditions && node.data.events_conditions?.length) {
    return true
  }

  if (node.data?.email_conditions) {
    return true
  }

  if (!node.data || !node.data?.branch_name) {
    return false
  }

  return false
}
export { WFTrueFalse }
