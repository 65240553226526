import { Select, SelectProps } from '..'
import {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useEffect,
  useState
} from 'react'
import { CustomEventServices } from 'services/customEvent'
import { filterOption as utilFilterOptions } from 'utils/'

export type SelectEventCustomProps = SelectProps
const SelectCustomEventWithoutRef: ForwardRefRenderFunction<
  HTMLSelectElement,
  SelectEventCustomProps
> = (props, ref) => {
  const {
    options: optionsProps,
    mode,
    showSearch = true,
    filterOption = utilFilterOptions,
    ...restProps
  } = props
  const [options, setOptions] = useState(optionsProps)

  const fetchEventCustom = useCallback(async () => {
    try {
      const res: any = await CustomEventServices.getList()
      if (!res?.data) {
        throw new Error(res?._message)
      }
      setOptions(res.data.map((key: string) => ({ label: key, value: key })))
    } catch (error) {
      console.log('** Error SelectCustomEvent.tsx : ', error)
    }
  }, [])

  useEffect(() => {
    setOptions(optionsProps)
  }, [optionsProps])

  useEffect(() => {
    if (!optionsProps || !optionsProps.length) {
      fetchEventCustom()
    }
  }, [fetchEventCustom])

  return (
    <Select
      {...restProps}
      ref={ref}
      options={options}
      mode={mode}
      showSearch={showSearch}
      filterOption={filterOption}
    />
  )
}

export const SelectCustomEvent = forwardRef(SelectCustomEventWithoutRef)
