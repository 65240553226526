import { ConditionBaseProps } from '..'
import {
  StyledCondition,
  StyledConditionCheckbox,
  StyledConditionCheckboxGroup,
  StyledConditionContent,
  StyledConditionLabel
} from '../index.styled'
import { OptionType } from 'interfaces'
import { useEffect, useState } from 'react'

export type ConditionCheckboxComponentProps = ConditionBaseProps<
  (string | number | boolean)[]
> & {
  options?: OptionType[]
}

export const ConditionCheckboxComponent = (
  props: ConditionCheckboxComponentProps
) => {
  const {
    className,
    options,
    defaultValue,
    value: valueProps,
    onChange: onChangeProps
  } = props
  const [value, setValue] = useState<(string | number | boolean)[]>(
    defaultValue || valueProps || []
  )

  const onChange = (valueChanged: (string | number | boolean)[]) => {
    setValue(valueChanged)
    onChangeProps && onChangeProps(valueChanged)
  }

  useEffect(() => {
    setValue(valueProps || [])
  }, [valueProps])

  return (
    <StyledConditionCheckboxGroup
      value={value}
      onChange={onChange}
      className={className}>
      {options?.map((option, index) => {
        return (
          <StyledConditionCheckbox
            key={`${index}--${option.value}`}
            value={option.value}
            disabled={option?.disabled}>
            {option.label}
          </StyledConditionCheckbox>
        )
      })}
    </StyledConditionCheckboxGroup>
  )
}

export type ConditionCheckboxProps = ConditionCheckboxComponentProps & {
  label?: string
}
export const ConditionCheckbox = (props: ConditionCheckboxProps) => {
  const { label, className, ...restProps } = props

  return (
    <StyledCondition className={className}>
      {label && <StyledConditionLabel>{label}</StyledConditionLabel>}
      <StyledConditionContent>
        <ConditionCheckboxComponent {...restProps} />
      </StyledConditionContent>
    </StyledCondition>
  )
}
