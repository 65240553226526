import Icon, { ExclamationCircleFilled } from '@ant-design/icons'
import cn from 'classnames'
import { Close, Warning } from 'icons'
import { CSSProperties, ReactNode, useState } from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
  &[data-alert-type='error'] {
    // border-color: #ff5331;
    background-color: #fae9e8;
  }

  &[data-alert-type='success'] {
    // border-color: #00b16a;
    background-color: #e0f8ee;
  }

  &[data-alert-type='warning'] {
    // border-color: #00b16a;
    background-color: ${({ theme }) => theme?.colors?.yellow50};
    .alert--icon {
      color: ${({ theme }) => theme?.colors?.yellow600};
    }
  }
`

export type AlertComponentProps = {
  type: 'error' | 'success' | 'warning'
  showIcon?: boolean
  showClose?: boolean
  title?: ReactNode
  description?: ReactNode
  className?: string
  style?: CSSProperties
}
export const ALERT_ICON_COMPONENTS: Record<
  AlertComponentProps['type'],
  AntIconComponent
> = {
  error: Warning,
  success: Warning,
  warning: ExclamationCircleFilled
}
export const AlertComponent = (props: AlertComponentProps) => {
  const {
    type,
    showIcon = true,
    showClose,
    title,
    description,
    className,
    style
  } = props
  const [close, setClose] = useState(false)

  if (close) {
    return null
  }

  return (
    <StyledContainer
      data-alert-type={type}
      className={cn('alert', 'relative py-3 px-4', className)}
      style={style}>
      {!!showClose && (
        <Icon
          component={Close}
          onClick={() => setClose(true)}
          className="absolute top-2 right-2 text-[16px]"
        />
      )}
      <div className={cn('alert--header', 'flex mb-1')}>
        {!!showIcon && (
          <Icon
            component={ALERT_ICON_COMPONENTS[type]}
            className="alert--icon"
          />
        )}
        <div
          className={cn(
            'alert--title',
            'flex-1 min-w-0 ml-2 text-sm font-semibold text-gray800'
          )}>
          {title}
        </div>
      </div>
      <div className={cn('alert--description', 'text-sm text-gray800')}>
        {description}
      </div>
    </StyledContainer>
  )
}
