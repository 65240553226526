import {
  FORM_WORKFLOW_PROPS,
  FORM_WORKFLOW_RULES,
  FormWorkflowProps,
  FormWorkflowRef
} from '..'
import { StyledFormWorkflowContainer } from '../FormWorkflow.styled'
import { FormWorkflowDatePicker } from './FormWorkflowRangePicker'
import { FormWorkflowTimePicker } from './FormWorkflowTimePicker'
import { FormWorkflowTimeRangePicker } from './FormWorkflowTimeRangePicker'
import { Button, Form, Select } from 'antd'
import { CheckboxButton } from 'components/atoms/checkbox'
import { SelectTimezone } from 'components/atoms/select'
import { OptionType } from 'interfaces'
import { Ref, forwardRef, useImperativeHandle, useMemo } from 'react'

export type FormWorkflowTimeWindowValue = {
  type?: string
  day?: string[]
  time?: Array<{ from: string; to: string }>
  specific_time?: string[]
  date_time?: Array<{ from: string; to: string }>
  timezone?: string

  type_time?: string
  type_day?: string
}
export type FormWorkflowTimeWindowRef =
  FormWorkflowRef<FormWorkflowTimeWindowValue>
export type FormWorkflowTimeWindowProps =
  FormWorkflowProps<FormWorkflowTimeWindowValue>

const TIME_WINDOW_TYPE_VALUE = {
  any_day: 'any_day',
  specific_days: 'specific_days'
}
const TIME_WINDOW_TYPE_OPTIONS: OptionType[] = [
  { label: 'any day', value: TIME_WINDOW_TYPE_VALUE.any_day },
  { label: 'specific days', value: TIME_WINDOW_TYPE_VALUE.specific_days }
]
const TIME_WINDOW_TYPE_TIME_VALUE = {
  any_time: 'any_time',
  specific_time: 'specific_time'
}
const TIME_WINDOW_TIME_TYPE_OPTIONS: OptionType[] = [
  { label: 'any time', value: TIME_WINDOW_TYPE_TIME_VALUE.any_time },
  { label: 'specific time', value: TIME_WINDOW_TYPE_TIME_VALUE.specific_time }
]
const TIME_WINDOW_DAY_TYPE_VALUE = {
  single_day: 'single_day',
  multiple_day: 'multiple_days'
}
const TIME_WINDOW_DAY_TYPE_OPTIONS: OptionType[] = [
  { label: 'single day', value: TIME_WINDOW_DAY_TYPE_VALUE.single_day },
  { label: 'multiple day', value: TIME_WINDOW_DAY_TYPE_VALUE.multiple_day }
]
const TIME_WINDOW_DAY_OPTIONS: OptionType[] = [
  { label: 'Mon', value: 'mon' },
  { label: 'Tue', value: 'tue' },
  { label: 'Wed', value: 'wed' },
  { label: 'Thu', value: 'thu' },
  { label: 'Fri', value: 'fri' },
  { label: 'Sat', value: 'sat' },
  { label: 'Sun', value: 'sun' }
]
const TIME_WINDOW_TIME_OPTIONS: OptionType[] = []

for (let i = 0; i <= 23; i++) {
  let label = ''
  if (i === 0 || i < 12) {
    label = i === 0 ? `12:00 AM` : `${i < 10 ? `0${i}` : i}:00 AM`
  } else {
    const hour = i - 12
    label = hour === 0 ? `12:00 PM` : `${hour < 10 ? `0${hour}` : hour}:00 PM`
  }

  TIME_WINDOW_TIME_OPTIONS.push({ value: i, label })
}

export { TIME_WINDOW_TIME_OPTIONS }

const FormWorkflowTimeWindowWithoutRef = (
  props: FormWorkflowTimeWindowProps,
  ref?: Ref<FormWorkflowTimeWindowRef>
) => {
  const { initialValues, onValuesChange, ...restProps } = props
  const [form] = Form.useForm()
  const typeValue = Form.useWatch('type', form)
  const typeTimeValue = Form.useWatch('type_time', form)
  const typeDayValue = Form.useWatch('type_day', form)

  const isMultipleDay = useMemo(
    () => typeDayValue === TIME_WINDOW_DAY_TYPE_VALUE.multiple_day,
    [typeDayValue]
  )

  const handleValuesChange = (valueChanged: any, valuesChanged: any) => {
    const newValuesChanged = { ...valuesChanged }
    if (valueChanged?.type === TIME_WINDOW_TYPE_VALUE.any_day) {
      delete newValuesChanged?.date_time
      newValuesChanged.day = []
      newValuesChanged.type_time = TIME_WINDOW_TYPE_TIME_VALUE.any_time
      newValuesChanged.time = [{ from: null, to: null }]
    }
    if (valueChanged?.type === TIME_WINDOW_TYPE_VALUE.specific_days) {
      delete newValuesChanged?.day
      delete newValuesChanged?.time
      delete newValuesChanged?.specific_time
      newValuesChanged.type_day = TIME_WINDOW_DAY_TYPE_VALUE.single_day
      newValuesChanged.date_time = [{ from: null, to: null }]
    }
    if (valueChanged.type_time === TIME_WINDOW_TYPE_TIME_VALUE.any_time) {
      delete newValuesChanged?.specific_time
      newValuesChanged.time = [{ from: null, to: null }]
    }
    if (valueChanged.type_time === TIME_WINDOW_TYPE_TIME_VALUE.specific_time) {
      delete newValuesChanged?.time
      newValuesChanged.specific_time = [null]
    }
    if (
      [
        TIME_WINDOW_DAY_TYPE_VALUE.single_day,
        TIME_WINDOW_DAY_TYPE_VALUE.multiple_day
      ].includes(valueChanged.type_day)
    ) {
      newValuesChanged.date_time = [{ from: null, to: null }]
    }

    form.setFieldsValue(newValuesChanged)
    onValuesChange?.(valueChanged, newValuesChanged)
  }

  useImperativeHandle(ref, () => form, [form])

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onValuesChange={handleValuesChange}
      {...FORM_WORKFLOW_PROPS}
      {...restProps}>
      <StyledFormWorkflowContainer>
        <Form.Item
          name="type"
          label="Wait until"
          className="max-w-[160px]"
          rules={[FORM_WORKFLOW_RULES.required_select]}>
          <Select
            size="small"
            options={TIME_WINDOW_TYPE_OPTIONS}
            placeholder="Type day"
          />
        </Form.Item>
        <Form.Item
          shouldUpdate={(prev, next) => prev.type !== next.type}
          noStyle>
          {typeValue === TIME_WINDOW_TYPE_VALUE.any_day && (
            <>
              <Form.Item
                name="day"
                rules={[FORM_WORKFLOW_RULES.required_select]}>
                <CheckboxButton
                  options={TIME_WINDOW_DAY_OPTIONS}
                  className="timeWindowCheckboxDay"
                />
              </Form.Item>
              <Form.Item
                name="type_time"
                label="at"
                className="max-w-[167px] mb-2"
                rules={[FORM_WORKFLOW_RULES.required_select]}>
                <Select
                  size="small"
                  options={TIME_WINDOW_TIME_TYPE_OPTIONS}
                  placeholder="Type time"
                />
              </Form.Item>
              <Form.Item
                shouldUpdate={(prev, next) => prev.type_time !== next.type_time}
                noStyle>
                {typeTimeValue === TIME_WINDOW_TYPE_TIME_VALUE.any_time && (
                  <Form.List name="time">
                    {(listTime) => {
                      return listTime.map(({ name, ...itemTime }) => {
                        return (
                          <div key={name}>
                            <Form.Item
                              name={name}
                              isListField
                              {...itemTime}
                              rules={[
                                {
                                  validator(rule, value, callback) {
                                    if (!value?.from || !value?.to) {
                                      return Promise.reject(
                                        'This field is required'
                                      )
                                    }
                                    return Promise.resolve()
                                  }
                                }
                              ]}>
                              <FormWorkflowTimeRangePicker size="small" />
                            </Form.Item>
                          </div>
                        )
                      })
                    }}
                  </Form.List>
                )}
                {typeTimeValue ===
                  TIME_WINDOW_TYPE_TIME_VALUE.specific_time && (
                  <Form.List name="specific_time">
                    {(listTime) => {
                      return listTime.map(({ name, ...itemTime }) => {
                        return (
                          <div key={name}>
                            <Form.Item
                              name={name}
                              isListField
                              {...itemTime}
                              className="max-w-[167px] mb-2"
                              rules={[FORM_WORKFLOW_RULES.required_select]}>
                              <FormWorkflowTimePicker
                                size="small"
                                options={TIME_WINDOW_TIME_OPTIONS}
                                placeholder="Specific time"
                              />
                            </Form.Item>
                          </div>
                        )
                      })
                    }}
                  </Form.List>
                )}
              </Form.Item>
            </>
          )}
          {typeValue === TIME_WINDOW_TYPE_VALUE.specific_days && (
            <>
              <Form.Item
                name="type_day"
                label="at"
                className="max-w-[167px] mb-2"
                rules={[FORM_WORKFLOW_RULES.required_select]}>
                <Select
                  size="small"
                  options={TIME_WINDOW_DAY_TYPE_OPTIONS}
                  placeholder="Specific time"
                />
              </Form.Item>
              <Form.Item
                shouldUpdate={(prev, next) => prev.type_day !== next.type_day}
                noStyle>
                <Form.List name="date_time">
                  {(listDateTime, { add, remove }) => {
                    const onRemove = (index: number) => {
                      return () => remove(index)
                    }
                    return (
                      <>
                        {listDateTime.map((itemDateTime) => {
                          return (
                            <div key={itemDateTime.name}>
                              <Form.Item
                                name={itemDateTime.name}
                                className="mb-2"
                                rules={[
                                  {
                                    validator(rule, value, callback) {
                                      if (!value?.from || !value?.to) {
                                        return Promise.reject(
                                          'This field is required'
                                        )
                                      }
                                      return Promise.resolve()
                                    }
                                  }
                                ]}>
                                <FormWorkflowDatePicker
                                  type={typeDayValue}
                                  onRemove={
                                    isMultipleDay
                                      ? onRemove(itemDateTime.name)
                                      : undefined
                                  }
                                />
                              </Form.Item>
                            </div>
                          )
                        })}
                        {isMultipleDay && (
                          <Button
                            type="link"
                            className="p-0 h-auto text-[12px] font-normal"
                            onClick={() => add(null)}>
                            Add date time
                          </Button>
                        )}
                      </>
                    )
                  }}
                </Form.List>
              </Form.Item>
            </>
          )}
        </Form.Item>
        <Form.Item name="timezone" label="in">
          <SelectTimezone
            disabled
            title="All time are in"
            placeholder="Indochina Time (GMT/UTC +7)"
          />
        </Form.Item>
      </StyledFormWorkflowContainer>
    </Form>
  )
}
export const FormWorkflowTimeWindow = forwardRef(
  FormWorkflowTimeWindowWithoutRef
)

export function formatFormWorkflowTimeWindow(
  data: any
): FormWorkflowTimeWindowValue {
  const initValues: FormWorkflowTimeWindowValue = { ...data }

  try {
  } catch (error) {}

  return initValues
}
