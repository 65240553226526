import { CachedSystem } from './cachedSystem'
import { FIELD_PATHS } from 'constants/apiPaths'
import { ResponseType } from 'interfaces'
import {
  FieldItem,
  FieldItemCreate,
  FieldItemUpdate,
  FieldListQuery
} from 'interfaces/field'
import { convertQueryParams } from 'utils'
import { sidisBaseAxios } from 'utils/api'

const PREFIX = 'FieldServices/'

export const FieldServices = {
  getFieldList: async (params: FieldListQuery) => {
    const queryString = convertQueryParams(params)
    const url = `${FIELD_PATHS.field}?${queryString}`
    return CachedSystem.withCached(PREFIX + url, () => sidisBaseAxios.get(url))
  },
  getFieldById: (id: string | number) => {
    return sidisBaseAxios.get(`${FIELD_PATHS.field}/${id}`)
  },
  createField: (data: FieldItemCreate) => {
    CachedSystem.clearCached(PREFIX + FIELD_PATHS.field)
    return sidisBaseAxios.post<FieldItemCreate, ResponseType<FieldItem>>(
      FIELD_PATHS.field,
      data
    )
  },
  updateField: (id: number, data: FieldItemUpdate) => {
    CachedSystem.clearCached(PREFIX + FIELD_PATHS.field)
    return sidisBaseAxios.put<FieldItemUpdate, ResponseType<FieldItem>>(
      `${FIELD_PATHS.field}/${id}`,
      data
    )
  },
  deleteField: (id: number) => {
    CachedSystem.clearCached(PREFIX + FIELD_PATHS.field)
    return sidisBaseAxios.delete<null, ResponseType<null>>(
      `${FIELD_PATHS.field}/${id}`
    )
  },

  getFieldConditionList: () => {
    const url = FIELD_PATHS.field_condition
    return CachedSystem.withCached(PREFIX + url, () => sidisBaseAxios.get(url))
  },
  getFieldOperatorList: () => {
    const url = FIELD_PATHS.field_operator
    return CachedSystem.withCached(PREFIX + url, () => sidisBaseAxios.get(url))
  },
  getFieldDataTypeList: () => {
    const url = FIELD_PATHS.field_data_type
    return CachedSystem.withCached(PREFIX + url, () => sidisBaseAxios.get(url))
  },

  countByCondition: (data: any) => {
    return sidisBaseAxios.post(`${FIELD_PATHS.count_user}`, data)
  }
}
