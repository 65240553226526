import { DragIndicator } from 'icons'
import styled from 'styled-components'

const WFNodeDragHandle = styled.div<{ color?: string; selected?: boolean }>`
  position: absolute;
  right: 12px;
  bottom: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 3px;
  background-color: #fff;
  border: 1px solid
    ${({ color, selected }) => (selected ? color : 'transparent')};
  border-bottom: 1px solid transparent;
  border-radius: 3px 3px 0 0;
  box-shadow: 0px 0px 1px 0px #0000000a, 0px 0px 2px 0px #0000000f;
  cursor: grab;
  font-size: 12px;
  line-height: 1;
  svg {
    color: ${({ theme }) => theme?.colors?.neutral300};
  }
  :before {
    position: absolute;
    top: calc(100% - 1px);
    left: 0;
    display: block;
    width: 100%;
    height: 4px;
    background-color: #fff;
    content: '';
  }
`

export type WorkflowBuilderDragHandleProps = {
  color?: string
  selected?: boolean
}
export function WorkflowBuilderDragHandle(
  props: WorkflowBuilderDragHandleProps
) {
  const { color, selected } = props
  return (
    <WFNodeDragHandle className="drag-handle" color={color} selected={selected}>
      <DragIndicator />
    </WFNodeDragHandle>
  )
}
