import { CampaignHeaderValue } from '../CampaignHeader/CampaignHeader.helpers'
import {
  CampaignPerformance,
  getCampaignPerformanceProps
} from '../CampaignPerformance'
import {
  StyleSegmentName,
  StyleStatusText,
  StyleWrapperIcon,
  StyledCampaignTableCellName,
  StyledCampaignTableMoreInfo,
  StyledWrapperStatus
} from './CampaignTable.styled'
import { MenuProps, Row, TablePaginationConfig, Tooltip } from 'antd'
import { Avatar } from 'components/atoms/avatar'
import { TableCellTitle } from 'components/atoms/table'
import { CAMPAIGN_STATUS } from 'constants/campaign'
import { FORMAT_DATE_DAYJS, LIST_COLOR_AVATAR } from 'constants/common'
import { PERMISSIONS } from 'constants/permission'
import { TEMPLATE_TYPE } from 'constants/template'
import dayjs from 'dayjs'
import { checkAuth } from 'hooks/useCheckAuth'
import {
  Approve,
  BarChartGray,
  CalendarBlue,
  Check,
  CheckedCircleGreen,
  CloseOrange,
  DuplicateFile,
  Edit,
  Preliminary,
  Runing,
  SendEmail,
  Start,
  TrashBin,
  UpdateStatus,
  Visible
} from 'icons'
import { ItemMenu } from 'interfaces'
import { CampaignQueryParams } from 'interfaces/campaign'
import Cookies from 'js-cookie'
import { mapTypeToMedium } from 'pages/templateBuilderList/helpers'
import { Link } from 'react-router-dom'
import { convertQueryParams } from 'utils'
import { getCampaignRoute } from 'utils/campaign'

export const CAMPAIGN_TABLE_COOKIE = 'camp_tb_ck'

export enum campaign_key {
  campaign_name = 'name',
  performance = 'performance',
  delivery_rate = 'email_delivery_rate',
  open_rate = 'email_open_rate',
  click_rate = 'email_click_rate',
  conversion_rate = 'email_conversion_rate',
  client = 'client',
  sites = 'sites',
  segment = 'segment',
  status = 'status',
  created_at = 'created_at',
  created_by = 'created_by',
  type = 'type',
  actions = 'actions',
  sent_time = 'start_datetime',
  estimated_cost = 'estimated_cost'
}

interface campaignStatusType {
  [name: string]: any
}

export const campaign_status_key: campaignStatusType = {
  ready: Check,
  done: CheckedCircleGreen,
  running: Runing,
  schedule: CalendarBlue,
  draft: CloseOrange,
  cancel: CloseOrange,
  failed: CloseOrange,
  waiting_approve: Preliminary,
  approve: Approve
}

export const campaign_sort_key = [
  campaign_key.campaign_name,
  campaign_key.sent_time
]

export const campaign_columns = [
  {
    key: campaign_key.campaign_name,
    title: 'Campaign name',
    dataIndex: campaign_key.campaign_name,
    width: 300,
    render: (_v: any, _record: any) => {
      const { template_type = '', id } = _record
      const pathUrl = getCampaignRoute(id, template_type)
      return (
        <StyledCampaignTableCellName>
          <StyledCampaignTableMoreInfo className="campaignTable--name">
            <Tooltip title={_record.name}>
              <Link to={pathUrl}>
                <div className="name">{_v}</div>
              </Link>
            </Tooltip>
            <div className="description">ID: {_record.id}</div>
          </StyledCampaignTableMoreInfo>
        </StyledCampaignTableCellName>
      )
    }
  },
  {
    key: campaign_key.estimated_cost,
    title: 'Estimated Cost ($)',
    dataIndex: campaign_key.estimated_cost,
    width: 136,
    render(_: any, record: any) {
      console.log('record', record)
      return (
        <p className="m-0 text-[#262626] font-semibold">
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
            minimumFractionDigits: 0
          })
            .format(record.estimated_cost || 0)
            .replace('$', '')}
        </p>
      )
    }
  },
  {
    key: campaign_key.performance,
    title: 'Total sent',
    dataIndex: campaign_key.delivery_rate,
    width: 400,
    render(_: any, record: any) {
      if (record.type === 'automation') {
        return <p>N/A</p>
      }
      return <CampaignPerformance {...getCampaignPerformanceProps(record)} />
    }
  },
  {
    key: campaign_key.client,
    title: 'Clients',
    dataIndex: campaign_key.client,
    width: 200
  },
  {
    key: campaign_key.sites,
    title: 'Sites',
    dataIndex: campaign_key.sites,
    width: 100
  },
  {
    key: campaign_key.segment,
    title: 'Segment',
    dataIndex: campaign_key.segment,
    width: 250,
    render: (_v: any, _record: any) => {
      const { segments } = _record
      if (!segments) {
        return
      }
      const segmentName = segments[0].name
      return <StyleSegmentName>{segmentName}</StyleSegmentName>
    }
  },
  {
    key: campaign_key.status,
    title: 'Status',
    dataIndex: campaign_key.status,
    width: 130,
    render: (_v: any, _record: any) => {
      const lowerCaseStatus = _record.status.toLowerCase()
      const status = lowerCaseStatus.split(' ').join('_')
      const IconSvg = campaign_status_key[status]

      return (
        <StyledWrapperStatus>
          <StyleWrapperIcon>{IconSvg && <IconSvg />}</StyleWrapperIcon>
          <StyleStatusText className="whitespace-nowrap">
            {_record.status}
          </StyleStatusText>
        </StyledWrapperStatus>
      )
    }
  },
  {
    key: campaign_key.sent_time,
    title: 'Sent Time',
    dataIndex: campaign_key.sent_time,
    width: 200,
    render: (_v: any, _record: any) => {
      const sentAtDayObj = dayjs(_record.start_datetime)
      const sentAt = sentAtDayObj.format(FORMAT_DATE_DAYJS)

      return (
        <TableCellTitle
          tooltip={false}
          title={sentAtDayObj.isValid() ? sentAt : 'N/A'}
          subTitle=""
        />
      )
    }
  },
  {
    key: campaign_key.created_at,
    title: 'Created at',
    dataIndex: campaign_key.created_at,
    width: 300,
    render: (_v: any, _record: any) => {
      const createdAt = dayjs(_record.created_at * 1000).format(
        FORMAT_DATE_DAYJS
      )
      const updatedAt = dayjs(_record.updated_at * 1000).format(
        FORMAT_DATE_DAYJS
      )
      return (
        <TableCellTitle
          tooltip={false}
          title={`Updated at: ${updatedAt}`}
          subTitle={`Created at: ${createdAt}`}
        />
      )
    }
  },
  {
    key: campaign_key.created_by,
    title: 'Created by',
    dataIndex: campaign_key.created_by,
    width: 300,
    render: (_v: any, _record: any, index: number) => {
      const { created_by } = _record
      const indexColor = index % LIST_COLOR_AVATAR.length
      const name = `${created_by.firstname} ${created_by.lastname}`
      return (
        <Row align="middle" wrap={false}>
          <Avatar
            name={name}
            size={32}
            email={created_by.email}
            background={LIST_COLOR_AVATAR[indexColor]}
          />
        </Row>
      )
    }
  },
  {
    key: campaign_key.type,
    title: 'Type',
    dataIndex: campaign_key.type,
    width: 160,
    render: (_v: any, record: any) => {
      if (record.type === 'automation') {
        return <>Automation</>
      } else {
        return (
          <Row align="middle">
            {mapTypeToMedium[record.template_type]?.icon}
            &nbsp;&nbsp;
            <span>{mapTypeToMedium[record.template_type]?.label}</span>
          </Row>
        )
      }
    }
  },
  {
    key: campaign_key.actions,
    title: '',
    dataIndex: campaign_key.actions,
    width: 70
  }
]

type CampaignAction = ItemMenu & {
  disabledStatus?: string[]
  disabledTemplateType?: string[]
}
export const CAMPAIGN_ACTIONS: Array<CampaignAction> = [
  {
    key: 'edit',
    label: 'Edit',
    permissions: [
      PERMISSIONS.campaign_edit,
      PERMISSIONS.campaign_app_push_edit,
      PERMISSIONS.campaign_web_push_edit,
      PERMISSIONS.campaign_landing_page_edit,
      PERMISSIONS.campaign_whatsapp_edit
    ],
    icon: <Edit />
  },
  {
    key: 'update_status',
    label: 'Update status',
    permissions: [
      PERMISSIONS.campaign_restore_archived,
      PERMISSIONS.campaign_start
    ],
    disabledStatus: [
      CAMPAIGN_STATUS.cancel,
      CAMPAIGN_STATUS.done,
      CAMPAIGN_STATUS.fail,
      CAMPAIGN_STATUS.running,
      CAMPAIGN_STATUS.schedule,
      CAMPAIGN_STATUS.waiting_approve
    ],
    icon: <UpdateStatus />
    // disabled: !isDisableUpdateStatus(_record),
  },
  {
    key: 'insight',
    label: 'View Insight',
    permissions: [PERMISSIONS.campaign_view_insights],
    disabledStatus: [
      CAMPAIGN_STATUS.cancel,
      CAMPAIGN_STATUS.draft,
      CAMPAIGN_STATUS.fail,
      CAMPAIGN_STATUS.ready
    ],
    disabledTemplateType: [TEMPLATE_TYPE.APP_PUSH, TEMPLATE_TYPE.WHATSAPP],
    icon: <BarChartGray />
  },
  {
    key: 'start_campaign',
    label: 'Start Campaign',
    permissions: [PERMISSIONS.campaign_start],
    disabledStatus: [
      CAMPAIGN_STATUS.cancel,
      CAMPAIGN_STATUS.done,
      CAMPAIGN_STATUS.draft,
      CAMPAIGN_STATUS.fail,
      CAMPAIGN_STATUS.running,
      CAMPAIGN_STATUS.schedule,
      CAMPAIGN_STATUS.waiting_approve
    ],
    icon: <Start />
  },
  {
    key: 'cancel',
    label: 'Cancel',
    permissions: [PERMISSIONS.campaign_cancel],
    disabledStatus: [
      CAMPAIGN_STATUS.cancel,
      CAMPAIGN_STATUS.done,
      CAMPAIGN_STATUS.draft,
      CAMPAIGN_STATUS.fail,
      CAMPAIGN_STATUS.ready,
      CAMPAIGN_STATUS.waiting_approve,
      CAMPAIGN_STATUS.approve
    ],
    icon: <TrashBin />
  },
  {
    key: 'send-test',
    label: 'Send Test Email',
    permissions: [PERMISSIONS.campaign_send_test_email],
    disabledTemplateType: [
      TEMPLATE_TYPE.APP_PUSH,
      TEMPLATE_TYPE.WEB_PUSH,
      TEMPLATE_TYPE.LP_PAGE,
      TEMPLATE_TYPE.WHATSAPP
    ],
    icon: <SendEmail />
  },
  {
    key: 'preview',
    label: 'Preview',
    icon: <Visible />
  },
  {
    key: 'duplicate',
    label: 'Duplicate',
    permissions: [PERMISSIONS.campaign_create_trigger],
    disabledStatus: [
      CAMPAIGN_STATUS.ready,
      CAMPAIGN_STATUS.cancel,
      CAMPAIGN_STATUS.draft,
      CAMPAIGN_STATUS.fail,
      CAMPAIGN_STATUS.running,
      CAMPAIGN_STATUS.schedule
    ],
    disabledTemplateType: [
      TEMPLATE_TYPE.EMAIL,
      TEMPLATE_TYPE.APP_PUSH,
      TEMPLATE_TYPE.LP_PAGE,
      TEMPLATE_TYPE.WHATSAPP
    ],
    icon: <DuplicateFile />
  }
]

export const checkAuthCampaignActions = (
  actions: Array<CampaignAction>,
  auth: Partial<UserAuth['auth']>,
  record?: any
): MenuProps['items'] => {
  const disabledUpdateStatus =
    (record?.push_type === 'broadcast' && !record?.template_id) ||
    (!record?.push_type &&
      (!record?.segments || !record?.sites || !record?.template_id)) ||
    record?.template_type === 'automation'
  const disabledSendTest = !record?.template_id
  return actions.map((action) => {
    const isAuth = !checkAuth(
      { roles: action.roles, permissions: action.permissions },
      auth
    )

    if (isAuth) {
      return {
        type: undefined,
        key: action.key,
        icon: action.icon,
        label: action.label,
        disabled: isAuth
      }
    }

    const item = {
      type: undefined,
      key: action.key,
      icon: action.icon,
      label: action.label,
      disabled: isAuth
    }

    const disabledByStatus = action.disabledStatus?.some(
      (status) => status === record?.status
    )
    if (action.key === 'update_status') {
      return {
        ...item,
        disabled: disabledUpdateStatus || disabledByStatus
      }
    }
    const disabledByTemplateType = action.disabledTemplateType?.some(
      (template_type) => template_type === record?.template_type
    )
    if (action.key === 'send-test') {
      return { ...item, disabled: disabledByTemplateType || disabledSendTest }
    }

    return {
      ...item,
      disabled: disabledByTemplateType || disabledByStatus
    }
  })
}

export const OPTION_FILTER_STATUS = [
  {
    label: 'Ready',
    value: 'Ready'
  },
  {
    label: 'Done',
    value: 'Done'
  },
  {
    label: 'Running',
    value: 'Running'
  },
  {
    label: 'Schedule',
    value: 'Schedule'
  },
  {
    label: 'Draft',
    value: 'Draft'
  },
  {
    label: 'Cancel',
    value: 'Cancel'
  },
  {
    label: 'Failed',
    value: 'Failed'
  },
  {
    label: 'Waiting Approve',
    value: 'Waiting Approve'
  },
  {
    label: 'Approved',
    value: 'Approve'
  }
]

export const OPTION_FILTER_APPROACH = [
  {
    label: 'Blast',
    value: '1'
  },
  {
    label: 'Trigger',
    value: '2'
  }
]

export const OPTION_FILTER_COLUMNS = [
  {
    label: 'Total Sent',
    value: campaign_key.performance
  },
  {
    label: 'Clients',
    value: campaign_key.client
  },
  {
    label: 'Sites',
    value: campaign_key.sites
  },
  {
    label: 'Segment',
    value: campaign_key.segment
  },
  {
    label: 'Status',
    value: campaign_key.status
  },
  {
    label: 'Created at',
    value: campaign_key.created_at
  },
  {
    label: 'Created by',
    value: campaign_key.created_by
  },
  {
    label: 'Type',
    value: campaign_key.type
  },
  {
    label: 'Sent time',
    value: campaign_key.sent_time
  }
]

export const REQUIRE_COLUMNS = [
  campaign_key.campaign_name,
  campaign_key.estimated_cost,
  campaign_key.actions
]

export const DEFAULT_FILTER_COLUMNS = [
  campaign_key.performance,
  campaign_key.created_at,
  campaign_key.type,
  campaign_key.sites,
  campaign_key.status
]

export type CampaignTableState = {
  pagination: TablePaginationConfig
  params: CampaignHeaderValue & {
    sort_by?: string
    sort_type?: 'DESC' | 'ASC'
  }
}

export const initCampaignState: CampaignTableState = {
  pagination: {
    position: [],
    total: 0,
    current: 1,
    pageSize: 20
  },
  params: {
    template_type: 'all',
    columns: DEFAULT_FILTER_COLUMNS
  }
}

export const resetFilterCampaignParams = {
  site_id: null,
  created: null,
  start_datetime: null,
  status: [],
  approach: null
}

export const initialStateFunc = (params?: URLSearchParams) => {
  const initState: CampaignTableState = initCampaignState
  try {
    const initStateCookie = JSON.parse(
      Cookies.get(CAMPAIGN_TABLE_COOKIE) as string
    )
    if (initStateCookie?.pagination) {
      initState.pagination = {
        ...initState.pagination,
        ...initStateCookie.pagination
      }
    }
    if (initStateCookie?.params) {
      initState.params = {
        ...initState.params,
        ...initStateCookie.params
      }
    }
  } catch (err) {
    console.log('** Error : ', err)
  }
  // page=1&per_page=20&search=&approach=&sort_by=start_datetime&sort_type=DESC&status=&site_id=&created_from=&created_to=&type=&template_type=app_push
  if (params?.get('page')) {
    initState.pagination.current =
      parseInt(params?.get('page') || '0') || initState.pagination.current
  }
  if (params?.get('per_page')) {
    initState.pagination.pageSize =
      parseInt(params.get('per_page') || '0') || initState.pagination.pageSize
  }
  if (params?.get('search')) {
    initState.params.search = params.get('search') || initState.params?.search
  }
  if (params?.get('approach')) {
    initState.params.approach =
      params.get('approach') || initState.params?.approach
  }
  if (params?.get('sort_by')) {
    initState.params.sort_by =
      params.get('sort_by') || initState.params?.sort_by
  }
  if (params?.get('sort_type')) {
    initState.params.sort_type = (params.get('sort_type') ||
      initState.params?.sort_type) as 'DESC' | 'ASC'
  }
  if (params?.get('status')) {
    initState.params.status =
      params.get('status')?.split(',') || initState.params.status
  }
  if (params?.get('site_id')) {
    initState.params.site_id = params.get('site_id') || initState.params.site_id
  }
  if (params?.get('created_from')) {
    initState.params.created_from =
      params.get('created_from') || initState.params.created_from
  }
  if (params?.get('created_to')) {
    initState.params.created_to =
      params.get('created_to') || initState.params.created_to
  }
  if (params?.get('send_time_from')) {
    initState.params.send_time_from =
      params.get('send_time_from') || initState.params.send_time_from
  }
  if (params?.get('send_time_to')) {
    initState.params.send_time_to =
      params.get('send_time_to') || initState.params.send_time_to
  }
  if (params?.getAll('template_types')?.length) {
    initState.params.template_types =
      params.getAll('template_types') || initState.params.template_types
    initState.params.template_type = 'sms'
  }
  if (params?.get('template_type')) {
    initState.params.template_type =
      params.get('template_type') || initState.params.template_type
    if (initState.params.template_type !== 'sms') {
      delete initState.params.template_types
    }
  }

  return initState
}

export const getCampaignQueryFromParams = (
  state: CampaignTableState
): CampaignQueryParams => {
  const params: CampaignQueryParams = {
    page: state.pagination.current || initCampaignState.pagination.current || 1,
    per_page:
      state.pagination.pageSize || initCampaignState.pagination.pageSize || 20,
    search: state.params.search || '',
    approach: state.params.approach || '',
    sort_by: state.params.sort_by || '',
    sort_type: state.params.sort_type || '',
    status: state.params.status || [],
    site_id: state.params.site_id || '',
    created_from: state.params.created_from || '',
    created_to: state.params.created_to || '',
    send_time_from: state.params.send_time_from || '',
    send_time_to: state.params.send_time_to || '',
    type: state.params.type || '',
    template_type:
      state.params.template_type === 'all' ? '' : state.params.template_type,
    template_types: state.params.template_types
  }
  if (params.template_type === 'sms') {
    delete params.template_type
  }
  Object.keys(params).forEach((k) => {
    const key = k as keyof typeof params
    const value = params[key]
    if (!value || (Array.isArray(value) && !value.length)) {
      delete params[key]
    }
  })
  return params
}

export const saveCampaignStateToParams = (state: CampaignTableState) => {
  const paramsSaved = {
    page: state.pagination?.current || 1,
    per_page: state.pagination?.pageSize || 10,
    template_type: state.params.template_type,
    template_types: state.params.template_types,
    sort_by: state.params.sort_by,
    sort_type: state.params.sort_type,
    site_id: state.params.site_id,
    approach: state.params.approach,
    created_from: state.params.created_from,
    created_to: state.params.created_to,
    sent_time_from: state.params.send_time_from,
    sent_time_to: state.params.send_time_to,
    type: state.params.type,
    status: state.params.status,
    search: state.params.search
  }
  if (paramsSaved.template_type !== 'sms') {
    delete paramsSaved.template_types
  }
  Object.keys(paramsSaved).forEach((k) => {
    const key = k as keyof typeof paramsSaved
    const value = paramsSaved[key]
    if (!value || (Array.isArray(value) && !value.length)) {
      delete paramsSaved[key]
    }
  })
  Cookies.set(
    CAMPAIGN_TABLE_COOKIE,
    JSON.stringify({ pagination: state.pagination, params: paramsSaved })
  )
  return convertQueryParams(paramsSaved)
}
