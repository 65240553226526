import { Button, Tabs } from 'antd'
import styled from 'styled-components'
import { cssScrollBar } from 'styles'

export const WFMenuItemIcon = styled.div<{ bgColor?: string; color?: string }>`
  width: 16px;
  height: 16px;
  margin-right: 10px;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  font-size: 16px;
  line-height: 1;
`

export const WFMenuItemLabel = styled.div`
  overflow: hidden;
  flex: 1;
  white-space: nowrap;
`

export const WFMenuItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme?.colors?.neutral100};
  border-radius: 4px;
`

export const WFMenuItemContainer = styled.div`
  background-color: #fff;
  border-radius: 4px;
  &[draggable='false'] {
    background-color: ${({ theme }) => theme?.colors?.neutral50};
    cursor: not-allowed;
  }
`

export const WFMenuItem = styled.li`
  cursor: grab;
  &.hasChild {
    > ${WFMenuItemContainer} {
      ${WFMenuItemWrapper} {
        padding: 12px 0 4px;
        border: 0;
        background-color: #fff;
        color: ${({ theme }) => theme?.colors?.gray400};
        cursor: auto;
        font-weight: 700;
        line-height: 1;
      }
    }
  }
  &.action {
    > ${WFMenuItemContainer} {
      &[draggable='false'] {
        ${WFMenuItemIcon} {
          background-color: ${({ theme }) => theme?.colors?.neutral50};
        }
      }
      ${WFMenuItemWrapper} {
        padding: 0;
        // border: 0;
        background-color: transparent;
        ${WFMenuItemIcon} {
          display: flex;
          width: 32px;
          height: 32px;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
        }
      }
    }
  }
  &:not(.action) {
    > ${WFMenuItemContainer} {
      ${WFMenuItemWrapper} {
        ${WFMenuItemIcon} {
          background-color: transparent;
        }
      }
    }
  }
`

export const WFMenuList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme?.colors?.gray800};
  font-size: 12px;
  list-style: none;
  ${WFMenuItem} {
    margin-top: 8px;
    &.hasChild {
      &:first-child {
        > ${WFMenuItemContainer} {
          ${WFMenuItemWrapper} {
            padding-top: 0;
          }
        }
      }
      + ${WFMenuItem} {
        margin-top: 12px;
      }
    }
  }
`

export const WFSidebarTabsPaneContent = styled.div`
  padding: 20px 16px;
`

export const WFSidebarTabsExtra = styled(Button)`
  &.ant-btn-sm {
    width: auto;
    height: auto;
    padding: 0;
    color: ${({ theme }) => theme?.colors?.gray400};
    font-size: 16px;
    line-height: 1;
  }
`

export const WFSidebarTabs = styled(Tabs)`
  height: 100%;
  .ant-tabs-nav {
    height: 50px;
    padding-right: 16px;
    padding-left: 16px;
    margin: 0;
    .ant-tabs-nav-operations {
      display: none;
    }
    .ant-tabs-nav-wrap {
      flex: 1;
      transition: 0.3s linear all;
      :before,
      :after {
        display: none;
      }
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          padding: 0;
          color: ${({ theme }) => theme?.colors?.gray400};
          font-size: 12px;
          + .ant-tabs-tab {
            margin-left: 16px;
          }
          &.ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              color: ${({ theme }) => theme?.colors?.gray800};
            }
          }
        }
        .ant-tabs-ink-bar {
          height: 1px;
          background-color: ${({ theme }) => theme?.colors?.gray800};
        }
      }
    }
  }
  .ant-tabs-content {
    height: 100%;
    .ant-tabs-tabpane {
      max-width: 100%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: overlay;
      ${cssScrollBar}
    }
  }
`

export const WFSidebarContainer = styled.div`
  overflow: hidden;
  width: 100%;
  max-width: 200px;
  height: 100%;
  border: 1px solid ${({ theme }) => theme?.colors?.neutral100};
  transition: 0.3s linear all;

  ${WFMenuList} {
    ${WFMenuItem} {
      &.hasChild {
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
  &.collapsed {
    max-width: 56px;
    transition: 0.3s linear all;
    ${WFSidebarTabsPaneContent} {
      padding: 16px 8px;
    }
    ${WFSidebarTabs} {
      .ant-tabs-nav {
        justify-content: center;
        .ant-tabs-nav-wrap {
          flex: 0;
          transition: 0.3s linear all;
        }
      }
    }
    ${WFMenuList} {
      ${WFMenuItem} {
        > ${WFMenuItemContainer} {
          ${WFMenuItemWrapper} {
            justify-content: center;
            ${WFMenuItemIcon} {
              margin-right: 0;
            }
            ${WFMenuItemLabel} {
              display: none;
            }
          }
        }
        &.hasChild {
          :first-child {
            ${WFMenuList} {
              ${WFMenuItem} {
                &:first-child {
                  margin-top: 0;
                }
              }
            }
          }
          > ${WFMenuItemContainer} {
            display: none;
          }
          + ${WFMenuItem} {
            margin-top: 0;
          }
        }
      }
    }
  }
`
