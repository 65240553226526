import { MaxPushMessage, MaxPushTitle, mockDynamicLink, rules } from './helpers'
import {
  StyleDivider,
  StyleTitle,
  StyleWrapperFormItem,
  StyledWrap
} from './index.styled'
import {
  Button,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Select,
  Tooltip,
  notification
} from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import { SelectSite } from 'components/atoms/select'
import useImageUpload from 'hooks/useImageUpload'
import { Infor } from 'icons'
import { MESSAGE_TYPE } from 'interfaces/template'
import { WebPushSettingsForm } from 'pages/templateBuilderCreate/webPush/helpers'
import {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle
} from 'react'
import { TemplateServices } from 'services/template'

type AppPushSettingForm = {
  name: string
}

type Props = {
  initialValues?: AppPushSettingForm
  onChange?: (evt: any) => void
  setSettingValues?: (values: any) => void
  fixedSiteId: string | number | undefined
}
export type HandleRef =
  | {
      form: FormInstance<any>
    }
  | undefined

type TypeComponent = ForwardRefRenderFunction<HandleRef, Props>

const FormSettingWebPush: TypeComponent = (
  { initialValues, onChange, setSettingValues, fixedSiteId },
  ref
) => {
  const [form] = Form.useForm()
  const { file, uploadFile } = useImageUpload()
  const typeMessageLink = Form.useWatch('message_link', form)
  const typeMessageIcon = Form.useWatch('message_icon', form)
  const siteField = Form.useWatch('site', form)

  useImperativeHandle(ref, () => {
    return {
      form
    }
  })

  const handleChangeImageUrl = (url: string) => {
    setSettingValues &&
      setSettingValues((pre: WebPushSettingsForm) => {
        return {
          ...pre,
          image: url
        }
      })
  }

  const handleChangeLinkUrl = (url: string) => {
    setSettingValues &&
      setSettingValues((pre: WebPushSettingsForm) => {
        return {
          ...pre,
          custom_link_url: url
        }
      })
  }

  const handleChangeMessageLink = (value: RadioChangeEvent) => {
    form.setFieldValue('custom_link_url', null)
    setSettingValues &&
      setSettingValues((pre: WebPushSettingsForm) => {
        return {
          ...pre,
          custom_link_url: null
        }
      })
  }

  const handleChangeMessageIcon = (value: RadioChangeEvent) => {
    form.setFieldValue('image', null)
    setSettingValues &&
      setSettingValues((pre: WebPushSettingsForm) => {
        return {
          ...pre,
          image: null
        }
      })
  }

  useEffect(() => {
    setSettingValues &&
      setSettingValues((pre: WebPushSettingsForm) => {
        return {
          ...pre,
          site: siteField
        }
      })
  }, [siteField, setSettingValues])

  useEffect(() => {
    if (!file) {
      return
    }
    ;(async function () {
      try {
        const res = await TemplateServices.uploadMedia(file)
        if (!res.data) {
          throw new Error('')
        }
        form.setFieldValue('image', res.data.URL)
        setSettingValues &&
          setSettingValues((pre: WebPushSettingsForm) => {
            return {
              ...pre,
              image: res.data.URL
            }
          })
      } catch (error) {
        notification.error({
          message: 'Something went wrong. Please try again!'
        })
      }
    })()
  }, [file, form, setSettingValues])

  return (
    <StyledWrap>
      <Form
        layout="vertical"
        form={form}
        initialValues={initialValues}
        onChange={onChange}>
        {/* Name */}
        <Form.Item name="name" label="Template name" rules={rules.name}>
          <Input placeholder="Template name" size="large" />
        </Form.Item>

        {/* Message Type */}
        <Form.Item
          name="message_type"
          label="Message type"
          rules={rules.message_type}>
          <Select placeholder="- Select -" allowClear size="large">
            <Select.Option value={MESSAGE_TYPE.MARKETING}>
              Marketing Message
            </Select.Option>
            <Select.Option value={MESSAGE_TYPE.TRANSACTIONAL}>
              Transactional Message
            </Select.Option>
            <Select.Option value={MESSAGE_TYPE.INTERNAL}>
              Internal Message
            </Select.Option>
            <Select.Option value={MESSAGE_TYPE.PUSH_MARKETING}>
              Push marketing message
            </Select.Option>
          </Select>
        </Form.Item>

        {/* Site */}
        <Form.Item name="site" label="Site" required rules={rules.site}>
          <SelectSite placeholder="Title" disabled={Boolean(fixedSiteId)} />
        </Form.Item>

        {/* Title */}
        <Form.Item name="title" label="Title" rules={rules.title}>
          <Input placeholder="Type your title here" maxLength={MaxPushTitle} />
        </Form.Item>

        {/* Push message */}
        <Form.Item
          name="description"
          label="Push Message"
          rules={rules.push_message}>
          <Input.TextArea
            placeholder="Type your push message here"
            size="large"
            rows={4}
            maxLength={MaxPushMessage}
            showCount
          />
        </Form.Item>
        <StyleDivider />
        <StyleTitle>
          UI demo &nbsp;
          <Tooltip title="You can select a layout without an extensive image besides an icon for your message design ">
            <Infor />
          </Tooltip>
        </StyleTitle>
        <Form.Item
          name="message_icon"
          label="Message Icon"
          rules={rules.message_icon}>
          <Radio.Group onChange={handleChangeMessageIcon}>
            <Radio value="default">Default Icon</Radio>
            <Radio value="custom">Custom Icon</Radio>
            <Radio value="dynamic">Dynamic Image</Radio>
          </Radio.Group>
        </Form.Item>
        {typeMessageIcon === 'custom' && (
          <StyleWrapperFormItem>
            {/* Image upload */}
            <Form.Item name="image" label="Image">
              <Input placeholder="Upload or input URL" />
            </Form.Item>
            <Button ghost type="primary" onClick={uploadFile}>
              Upload
            </Button>
          </StyleWrapperFormItem>
        )}

        {typeMessageIcon === 'dynamic' && (
          <Form.Item name="image" label="Image">
            <Select
              placeholder="Select URL"
              options={mockDynamicLink}
              onChange={handleChangeImageUrl}
            />
          </Form.Item>
        )}

        <StyleDivider />
        <StyleTitle>
          Message Link &nbsp;
          <Tooltip title="You can direct your users to a Custom or Dynamic Link for conversion.">
            <Infor />
          </Tooltip>
        </StyleTitle>
        <Form.Item name="message_link" label="" rules={rules.message_link}>
          <Radio.Group onChange={handleChangeMessageLink}>
            <Radio value="custom">Custom Link</Radio>
            <Radio value="dynamic">Dynamic link</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="custom_link_url"
          label={
            typeMessageLink === 'dynamic' ? 'Dynamic link' : 'Custom Link URL'
          }
          rules={rules.message_link}>
          {typeMessageLink === 'dynamic' ? (
            <Select
              placeholder="Select URL"
              options={mockDynamicLink}
              onChange={handleChangeLinkUrl}
            />
          ) : (
            <Input placeholder="Insert URL" />
          )}
        </Form.Item>
        <StyleDivider />
        <StyleTitle>
          UTM Paramenters &nbsp;
          <Tooltip title="Assign unique tags to Message Link for tracking performance of a campaign on Google Analytics">
            <Infor />
          </Tooltip>
        </StyleTitle>
        <Form.Item name="utm_source" label="UTM Source">
          <Input />
        </Form.Item>
        <Form.Item name="utm_medium" label="UTM Medium">
          <Input />
        </Form.Item>
        <Form.Item name="utm_campaign" label="UTM Campaign">
          <Input />
        </Form.Item>
        <Form.Item name="utm_content" label="UTM Content">
          <Input />
        </Form.Item>
        <Form.Item name="utm_term" label="UTM Term">
          <Input />
        </Form.Item>
      </Form>
    </StyledWrap>
  )
}

export default forwardRef(FormSettingWebPush)
