import { inputTimeToNumber, numberToInputTime } from './index.helpers'
import { InputNumber, Space } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
  .ant-input-number-input {
    text-align: right;
  }
  &:hover,
  &.ant-input-number-focused {
    .ant-input-number-input {
      padding-right: 30px;
      transition-duration: 0.1s;
    }
  }
`

const StyledLabel = styled.div`
  color: ${({ theme }) => theme?.colors?.gray400};
`

const StyledSpace = styled(Space)`
  width: 100%;
  &:not(.item) > .ant-space-item {
    flex: 1;
    min-width: 0;
  }
  &.item {
    flex: 1;
    min-width: 0;
    .ant-space-item:has(.input) {
      flex: 1;
      min-width: 0;
    }
    .ant-space-item:not(:has(.input)) {
      flex: unset;
    }
  }
`

export type InputTimeProps = {
  value?: number | string
  defaultValue?: number | string
  onChange?: (value: number | string | null) => void
}
export type InputTimeState = {
  day?: number | string
  hour?: number | string
  minute?: number | string
}
export function InputTime(props: InputTimeProps) {
  const { value, defaultValue, onChange: onChangeProps } = props
  const [state, setState] = useState<InputTimeState>(() => {
    return numberToInputTime(defaultValue || value || 0)
  })

  const onChange = useCallback(
    (key: string) => {
      return (value: any) => {
        const newState = { ...state, [key]: value }
        setState(newState)
        onChangeProps?.(inputTimeToNumber(newState))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChangeProps, state.day, state.hour, state.minute]
  )

  useEffect(() => {
    setState(() => numberToInputTime(value || 0))
  }, [value])

  return (
    <StyledSpace size={16}>
      <StyledSpace size={8} align="center" className="item">
        <StyledInputNumber
          value={state.day}
          min={0}
          max={364}
          onChange={onChange('day')}
          className="input"
          type="number"
        />
        <StyledLabel>days</StyledLabel>
      </StyledSpace>
      <StyledSpace size={8} align="center" className="item">
        <StyledInputNumber
          value={state.hour}
          min={0}
          max={24}
          onChange={onChange('hour')}
          className="input"
          type="number"
        />
        <StyledLabel>hours</StyledLabel>
      </StyledSpace>
      <StyledSpace size={8} align="center" className="item">
        <StyledInputNumber
          value={state.minute}
          min={0}
          max={60}
          onChange={onChange('minute')}
          className="input"
          type="number"
        />
        <StyledLabel>minutes</StyledLabel>
      </StyledSpace>
    </StyledSpace>
  )
}
