import { WorkflowBuilderHandle } from '../WorkflowBuilderHandle'
import { WorkflowBuilderNodeTemplate } from '../WorkflowBuilderNodeTemplate'
import { WFNode } from '../index.helpers'
import { Position } from 'reactflow'

const WFABSplit: WFNode = (props) => {
  const { id, title, bgColor, color, icon } = props
  // const [form] = Form.useForm()
  // const { nodes, setNodes, onNodesDelete, onNodesChangeData } =
  //   useWorkflowBuilder()
  // const { open, onOpen, onClose } = useNode({
  //   id,
  //   form,
  //   nodes,
  //   setNodes,
  //   onNodesDelete,
  //   onNodesChangeData
  // })
  // const onPreview = useCallback(async () => {
  //   //
  // }, [])

  return (
    <>
      <WorkflowBuilderNodeTemplate
        bgColor={bgColor}
        color={color}
        icon={icon}
        title={title}
        // description={descriptionComp}
        // onClick={onOpen}
        sources={
          <>
            <WorkflowBuilderHandle
              type="source"
              id={`${id}-source-1`}
              position={Position.Bottom}
            />
            <WorkflowBuilderHandle
              type="source"
              id={`${id}-source-2`}
              position={Position.Bottom}
            />
          </>
        }
        targets={
          <WorkflowBuilderHandle
            type="target"
            id={`${id}-target`}
            position={Position.Top}
          />
        }
      />
      {/* <WorkflowBuilderDrawerTemplate
        open={open}
        width={modalWidth}
        title={title}
        onClose={onClose}
        onUpdate={onPreview}
        destroyOnClose>
        <NotificationUnderContruction
          icon={WorkFlow}
          iconColor="#2D87F3" // Primary
          title="About workflow"
          description="Workflows are automated messaging sequences where you can create seamless, personalized customer experiences. Workflows let you orchestrate entire brand interactions, send the right message to the right user at every touchpoint."
        />
      </WorkflowBuilderDrawerTemplate> */}
    </>
  )
}

WFABSplit.validate = () => {
  return false
}
export { WFABSplit }
