import { FormWorkflowProps, FormWorkflowRef } from '..'
import { FormEvent, FormEventOptions } from '../../formEvent'
import { StyledFormWorkflowContainer } from '../FormWorkflow.styled'
import { Form } from 'antd'
import { SegmentCondition } from 'interfaces/segment'
import { Ref, forwardRef, useEffect, useImperativeHandle, useMemo } from 'react'

export type FormWorkflowTriggerValue = {
  events_conditions: SegmentCondition[]
  segment_event_id?: string | number
  segment_events_conditions?: SegmentCondition[]
}
export type FormWorkflowTriggerRef = FormWorkflowRef<FormWorkflowTriggerValue>
export type FormWorkflowTriggerProps =
  FormWorkflowProps<FormWorkflowTriggerValue> & {
    callbackFieldOptions?: (values: FormEventOptions) => void
  }

const FormWorkflowTriggerRenderFunc = (
  props: FormWorkflowTriggerProps,
  ref?: Ref<FormWorkflowTriggerRef>
) => {
  const { callbackFieldOptions, initialValues, ...restProps } = props
  const [form] = Form.useForm()
  const disabledEventFields = useMemo(
    () =>
      initialValues?.segment_events_conditions?.flatMap((cond: any) => [
        ...cond?.conditions.map((c: any) => c.field)
      ]),
    [initialValues?.segment_events_conditions]
  )
  useEffect(() => {
    if (
      initialValues?.segment_events_conditions &&
      initialValues?.segment_events_conditions?.length
    ) {
      form.setFieldValue(
        'segment_events_conditions',
        initialValues?.segment_events_conditions.map((eventCond: any) => ({
          ...eventCond,
          event_id: eventCond.event?.id
        }))
      )
    }
  }, [form, initialValues])
  useImperativeHandle(ref, () => form)
  return (
    <Form form={form} initialValues={initialValues} {...restProps}>
      <StyledFormWorkflowContainer className="trigger--container">
        <FormEvent
          name="segment_events_conditions"
          showDate={false}
          disabledEvent={true}
          disabledValidateEvent
          disabledConditions
          disabledValidateConditions
        />
        <FormEvent
          name="events_conditions"
          showDate={false}
          disabledEvent={true}
          callbackFieldOptions={callbackFieldOptions}
          disabledValidateConditions
          disabledEventFields={disabledEventFields}
          className="trigger--events_conditions"
        />
      </StyledFormWorkflowContainer>
    </Form>
  )
}
export const FormWorkflowTrigger = forwardRef(FormWorkflowTriggerRenderFunc)
