import { useCallback, useEffect, useMemo, useState } from 'react'
import { TemplateServices } from 'services/template'

export function formatTemplatePreview() {
  return {}
}

export function useTemplatePreview(values: any) {
  const [preview, setPreview] = useState(false)

  const [loadingPreview, setLoadingPreview] = useState(false)
  const [previewInfo, setPreviewInfo] = useState<any>()

  const fetchPreviewInfo = useCallback((id?: number | string) => {
    if (!id) {
      return
    }
    setLoadingPreview(true)
    TemplateServices.getById(id)
      .then((res) => {
        if (res?.data.data) {
          setPreviewInfo(res.data?.data)
        }
      })
      .finally(() => setLoadingPreview(false))
  }, [])

  useEffect(() => {
    if (preview && previewInfo?.id !== values?.template_id) {
      fetchPreviewInfo(values?.template_id)
    }
  }, [fetchPreviewInfo, values?.template_id, previewInfo?.id, preview])

  return useMemo(
    () => ({
      loading: loadingPreview,
      preview,
      setPreview,
      previewInfo
    }),
    [loadingPreview, preview, previewInfo]
  )
}
