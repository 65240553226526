import { ConditionBaseProps } from '..'
import {
  StyledCondition,
  StyledConditionContent,
  StyledConditionLabel,
  StyledConditionSliderRange
} from '../index.styled'
import { SliderRangeInputProps } from 'components/atoms/slider/sliderRangeInput'
import { useEffect, useState } from 'react'

export type ConditionRangeProps = Pick<
  SliderRangeInputProps,
  'max' | 'min' | 'formatString'
> &
  ConditionBaseProps<[number, number]> & {
    label?: string
  }
export const ConditionRange = (props: ConditionRangeProps) => {
  const {
    label,
    className,
    defaultValue,
    value: valueProps,
    onChange: onChangeProps,
    ...restProps
  } = props
  const [value, setValue] = useState(defaultValue || valueProps)

  const onChange = (val?: [number, number]) => {
    const newValue = val as [number, number]
    setValue(newValue)
    onChangeProps && onChangeProps(newValue as [number, number])
  }

  useEffect(() => {
    setValue(valueProps)
  }, [valueProps])

  return (
    <StyledCondition className={className}>
      {label && <StyledConditionLabel>{label}</StyledConditionLabel>}
      <StyledConditionContent>
        <StyledConditionSliderRange
          className="full--width"
          value={value}
          onChange={onChange}
          {...restProps}
        />
      </StyledConditionContent>
    </StyledCondition>
  )
}
