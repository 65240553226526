import { Switch } from 'antd'
import styled from 'styled-components'

export const StyledFormWorkflowSwitch = styled(Switch)`
  height: 18px;
  .ant-switch-handle {
    top: 3px;
    left: 3px;
  }
  &.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 12px - 3px);
  }
`

export const StyledFormWorkflowTemplatePreview = styled.div`
  position: absolute;
  inset: 0;
  opacity: 0;
  pointer-events: none;
  background-color: white;
  transition: 0.3s linear all;
  &.preview {
    opacity: 1;
    pointer-events: auto;
    transition: 0.3s linear all;
  }
`

export const StyledFormWorkflowContainer = styled.div`
  position: relative;
  color: ${({ theme }) => theme?.colors?.gray600};

  --input-height: 36px;
  --input-radius: 8px;

  .ant-form-item {
    .ant-form-item-label {
      > label {
        color: ${({ theme }) => theme?.colors?.gray800};
        font-size: 12px;
        font-weight: 600;
        line-height: 1.5;
      }
    }
    .ant-form-item-control-input {
      min-height: auto;
    }
  }

  .ant-select-single {
    .ant-select-selector {
      .ant-select-selection-item {
        line-height: var(--input-height);
      }
    }
  }

  .ant-picker {
    padding-left: 16px;
  }

  .ant-input-number {
    border-radius: var(--input-radius);
    .ant-input-number-input {
      padding-left: 16px;
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: var(--input-radius);
    height: var(--input-height);
    .ant-select-selection-placeholder {
      line-height: var(--input-height);
    }
    .ant-select-selection-search-input {
      height: var(--input-height);
    }
    &:after {
      line-height: var(--input-height);
    }
  }

  .ant-picker {
    border-radius: var(--input-radius);
    height: var(--input-height);
  }

  .timeWindowCheckboxDay {
    &.ant-checkbox-group {
      display: flex;
      width: 100%;
      .ant-checkbox-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px 8px;
        flex: 1;
        min-width: 0;
        text-align: center;

        &:first-child {
          border-radius: 8px 0 0 8px;
        }
        &:last-child {
          border-radius: 0 8px 8px 0;
        }
        &.ant-checkbox-wrapper-checked {
          border-color: ${({ theme }) => theme?.colors?.blue600};
        }
      }
    }
  }

  &.waitUntil--container {
    .waitUntil--condition-item {
      .segmentConditionValueNUMBER-container {
        width: 100%;
        > .ant-input-number {
          flex: 1;
          min-width: 0;
        }
      }
    }
  }

  &.trigger--container {
    .trigger--events_conditions {
      .ant-form-item {
        &:first-child {
          display: none;
        }
      }
    }
  }

  &.form__template {
    height: 100%;
    .form__template_id {
      margin-bottom: 0;
      height: 100%;
      .ant-form-item-row {
        flex-direction: column;
        height: 100%;
        .ant-form-item-control {
          min-height: 0;
          .ant-form-item-control-input {
            height: 100%;
            .ant-form-item-control-input-content {
              height: 100%;
            }
          }
        }
      }
    }
  }
`
