import { SegmentPredictionValue } from '../SegmentPrediction'
import { defaultValueSegmentPrediction } from '../SegmentPredictionDrawer'
import { SegmentFormOverviewValue } from './SegmentFormOverview'
import { formatSegmentFormOverview } from './SegmentFormOverview/SegmentFormOverview.helpers'
import { SegmentFormQueryValue } from './SegmentFormQuery'
import { formatSegmentFormQuery } from './SegmentFormQuery/SegmentFormQuery.helpers'
import { ButtonProps, FormInstance, FormProps, ModalProps } from 'antd'
import { FORMAT_DATE_DAYJS_API } from 'constants/common'
import { SEGMENT_TYPE } from 'constants/segment'
import { SITE_KEYS_DB } from 'constants/site'
import { OptionTypeSite } from 'interfaces'
import { SegmentCountByConditionParams } from 'interfaces/segment'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { segmentServices } from 'services/segment'
import { segmentConditonBuilderServices } from 'services/segmentBuilder'
import { FormNamePath } from 'utils/form'

export type SegmentCountData = {
  totalCount: number
  email: number
  phoneCount: number
  appPushCount: number
  webPushCount: number
  whatsappCount: number
  isCountSuccess: boolean
  status: string[] | string
  error: string[] | string
}
export type SegmentFormValue = SegmentFormOverviewValue &
  SegmentFormQueryValue & {
    count_data?: SegmentCountData
  }
export type SegmentFormContainerRef<T = SegmentFormValue> = {
  overviewForm: FormInstance<SegmentFormOverviewValue | T> | null
  queryForm: FormInstance<SegmentFormQueryValue | T> | null
  openConfirm: boolean
  setOpenConfirm: Dispatch<SetStateAction<boolean>>
}
export type SegmentFormContainerProps<T = SegmentFormValue> = {
  title?: string
  loading?: boolean
  disabled?: boolean
  disabledKeys?: string[]
  notFound?: boolean
  segment_type?: string
  defaultValue?: T
  value?: T
  onChange?: (value: T) => void
  saveButtonProps?: Pick<ButtonProps, 'disabled'>
  onSave?: (value: T) => void

  confirmModalProps?: Pick<ModalProps, 'onOk' | 'onCancel'>

  roles?: string[]
  permissions?: string[]

  siteId?: string | number
  onCreateSegment?: (data: any) => void
}
export type SegmentFormProps<T = any> = Pick<
  FormProps<T>,
  | 'name'
  | 'disabled'
  | 'initialValues'
  | 'onValuesChange'
  | 'onFinish'
  | 'onFinishFailed'
> &
  Pick<SegmentFormContainerProps, 'loading' | 'segment_type'> & {
    disabledKeys?: string[]
  }

export const defaultSegmentFormValue: SegmentFormValue = {
  type_time_range: 'custom_range'
}

export const formatSegmentForm = (
  initValue: any,
  defaultValue = defaultSegmentFormValue
): SegmentFormValue => {
  let newValue: SegmentFormValue = formatSegmentFormOverview({
    ...defaultValue,
    ...initValue
  })
  newValue = formatSegmentFormQuery({ ...defaultValue, ...newValue })

  return newValue
}

export const useCountAllSite = (sites: OptionTypeSite[]) => {
  const isMount = useRef(false)
  const [countAllSites, setCountAllSites] = useState<Record<string, number>>({})
  useEffect(() => {
    if (!isMount.current && sites?.length) {
      ;(async () => {
        try {
          const response: any =
            await segmentConditonBuilderServices.getCountUserAllSite()

          if (response?._message && !response?.data) {
            throw new Error(JSON.stringify(response))
          }

          const { data } = response
          if (Array.isArray(data.data)) {
            const countAllSites: Record<string, number> = {}
            data.data.forEach(({ site: siteOnDB, count }: any) => {
              if (siteOnDB in SITE_KEYS_DB) {
                const name = SITE_KEYS_DB[siteOnDB as keyof typeof SITE_KEYS_DB]
                const sitesFilter = sites.filter((site) =>
                  name.includes(site.name)
                )
                if (sitesFilter.length) {
                  sitesFilter.forEach((site) => {
                    countAllSites[site.name] = parseInt(count || '0')
                  })
                }
              }
            })
            setCountAllSites(countAllSites)
            isMount.current = true
          }
        } catch (error) {
          isMount.current = false
        }
      })()
    }
  }, [sites])
  return useMemo(() => {
    return { countAllSites }
  }, [countAllSites])
}

export type FetchQueryAnalyticsInput = {
  start_date: string
  end_date: string
  type?: string
  operator?: string
  site_code?: string[]
  list: any[]
}
export type FetchQueryAnalyticsOutput = {
  data: SegmentCountData | null
  errors: { name?: FormNamePath; errors: string[] }[]
}

export const defaultQueryAnalytics: SegmentCountData = {
  appPushCount: 0,
  email: 0,
  phoneCount: 0,
  totalCount: 0,
  webPushCount: 0,
  whatsappCount: 0,
  isCountSuccess: false,
  status: '',
  error: ''
}
export const formatCountDataToAnalytics = (
  countAllData: any[]
): FetchQueryAnalyticsOutput['data'] => {
  const data: FetchQueryAnalyticsOutput['data'] = {
    appPushCount: 0,
    email: 0,
    phoneCount: 0,
    totalCount: 0,
    webPushCount: 0,
    whatsappCount: 0,
    isCountSuccess: true,
    error: [],
    status: []
  }
  if (!countAllData?.length) {
    return data
  }
  try {
    ;(countAllData || []).forEach((countData: any) => {
      data.appPushCount += parseInt(countData?.appPushCount || '0')
      data.email += parseInt(countData?.email || '0')
      data.phoneCount += parseInt(countData?.phoneCount || '0')
      data.totalCount += parseInt(countData?.totalCount || '0')
      data.webPushCount += parseInt(countData?.webPushCount || '0')
      data.whatsappCount += parseInt(countData?.whatsappCount || '0')
      data.isCountSuccess = data.isCountSuccess && countData?.isCountSuccess
      if (countData?.error) {
        if (Array.isArray(data.error)) {
          data.error.push(countData.error as string)
        }
      }
      if (countData?.status) {
        if (Array.isArray(data.status)) {
          data.status.push(countData.status)
        }
      }
    })
  } catch (error) {}
  return data
}
export const formatDataToCountByConditionParams = (
  input: Partial<FetchQueryAnalyticsInput>
): SegmentCountByConditionParams => {
  const params: SegmentCountByConditionParams = {
    type: input?.type,
    site_code: input?.site_code
  }
  switch (input.type) {
    case SEGMENT_TYPE.ATTRIBUTE: {
      params.attribute_conditions = input?.list || []
      break
    }
    case SEGMENT_TYPE.EVENT: {
      params.events_conditions = input?.list || []
      if (input.list?.[0]?.submitted?.[0] && input.list?.[0]?.submitted?.[1]) {
        const startDate = input.list[0].submitted[0]
        const endDate = input.list[0].submitted[1]
        if (startDate.isValid() && endDate.isValid()) {
          params.events_conditions[0].submitted_from = startDate.format(
            FORMAT_DATE_DAYJS_API
          )
          params.events_conditions[0].submitted_to = endDate.format(
            FORMAT_DATE_DAYJS_API
          )
        }
      }
      if (!input?.list?.[0]?.event_id) {
        throw new Error('There is no event seleted')
      }
      break
    }
    case SEGMENT_TYPE.EVENT_CUSTOM: {
      params.custom_conditions = input?.list || []
      break
    }
    case SEGMENT_TYPE.MOBILE: {
      params.mobile_conditions = input?.list || []
      break
    }
  }
  return params
}
export const fetchQueryAnalytics = async (
  input: FetchQueryAnalyticsInput
): Promise<FetchQueryAnalyticsOutput> => {
  try {
    const params: any = formatDataToCountByConditionParams(input)
    const response: any = await segmentServices.segmentCountByCondition(params)
    if (response?._message || !response?.data) {
      throw new Error(JSON.stringify(response))
    }
    return {
      data: formatCountDataToAnalytics(response.data.count_data),
      errors: []
    }
  } catch (error) {
    console.log('** SegmentForm.helpers.ts 205 Error : ', error)
    return { data: null, errors: [{ errors: ['Something went wrong'] }] }
  }
}

export const useSegmentPredictionDrawer = ({
  value
}: {
  value?: SegmentFormValue
}) => {
  const dataCache = useRef<Record<string, any>>({})
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>()
  const [open, setOpen] = useState(false)
  const debounce = useRef<NodeJS.Timeout>()

  const segmentPredictionValue = useMemo<
    SegmentPredictionValue | undefined
  >(() => {
    let segPredictionVal = undefined
    value?.list?.forEach((item) => {
      const predictionItem = item.conditions?.find(
        (cond) => cond.field === defaultValueSegmentPrediction.field
      )
      if (predictionItem) {
        segPredictionVal = { ...predictionItem }
      }
    })

    return segPredictionVal
  }, [value?.list])

  const onChange = useCallback((value: Partial<FetchQueryAnalyticsInput>) => {
    setLoading(true)
    if (debounce.current) {
      clearTimeout(debounce.current)
    }
    debounce.current = setTimeout(() => {
      const newInput = { ...value, list: value?.list || [] }
      const dataKey = JSON.stringify(newInput)

      if (dataCache.current?.[dataKey]) {
        setData(dataCache.current?.[dataKey])
        setLoading(false)
        return
      }
      segmentServices
        .segmentCountByCondition(formatDataToCountByConditionParams(newInput))
        .then((res: any) => {
          if (!res.data || res._message) {
            throw new Error(JSON.stringify(res))
          }
          setData(res.data.performance_data)
          dataCache.current[dataKey] = res.data.performance_data
        })
        .catch(() => {
          delete dataCache.current[dataKey]
        })
        .finally(() => setLoading(false))
    }, 250)
  }, [])

  return useMemo(
    () => ({
      loading,
      data,
      defaultValue: segmentPredictionValue,
      onChange,
      open,
      setOpen
    }),
    [data, loading, onChange, open, segmentPredictionValue]
  )
}
