import { FormInstance, FormProps } from 'antd'

export type FormWorkflowRef<T = any> = FormInstance<T>
export type FormWorkflowProps<T = any> = Pick<
  FormProps<T>,
  | 'disabled'
  | 'initialValues'
  | 'onValuesChange'
  | 'onFinish'
  | 'onFinishFailed'
>

export const FORM_WORKFLOW_PROPS: FormProps = {
  layout: 'vertical',
  labelCol: { className: '!pb-2' }
}

export const FORM_WORKFLOW_RULES = {
  required_input: { required: true, message: 'Please input this field' },
  required_select: { required: true, message: 'Please select this field' },
  required_list: { required: true, message: 'Please input this list' }
}

export * from './FormWorkflowStartingPoint'
export * from './FormWorkflowTimeWindow'
export * from './FormWorkflowTrigger'
export * from './FormWorkflowWaitUntil'
