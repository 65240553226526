import { FORMAT_DATE_DAYJS_API } from 'constants/common'
import dayjs from 'dayjs'

export function wfDelayValueToForm(input: any): any {
  if (input?.events_conditions && input.events_conditions?.length) {
    input.events_conditions = input.events_conditions.map((event: any) => {
      if (event?.submitted_from && event?.submitted_to) {
        const submittedFrom = dayjs(event.submitted_from, FORMAT_DATE_DAYJS_API)
        const submittedTo = dayjs(event.submitted_to, FORMAT_DATE_DAYJS_API)
        return {
          event_id: event?.event_id,
          conditions: event?.conditions,
          submitted: [submittedFrom, submittedTo]
        }
      }
      return event
    })
  }
  return input
}

export function wfDelayFormToValue(input: any): any {
  if (input?.events_conditions && input.events_conditions?.length) {
    input.events_conditions = input.events_conditions.map((event: any) => {
      if (event?.submitted && event?.submitted.length === 2) {
        const submitted_from = event.submitted[0].isValid()
          ? event.submitted[0].format(FORMAT_DATE_DAYJS_API)
          : ''
        const submitted_to = event.submitted[1].isValid()
          ? event.submitted[1].format(FORMAT_DATE_DAYJS_API)
          : ''
        return {
          event_id: event?.event_id,
          conditions: event?.conditions,
          submitted_from,
          submitted_to
        }
      }
      return event
    })
  }
  return input
}
