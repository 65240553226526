import CopilotDrawerPreview, { replaceAll } from './CopilotDrawerPreview'
import CopilotTabsTemplate from './CopilotTabsTemplate'
import EditorContent from './EditorContent'
import {
  StyledActions,
  StyledActionsBottom,
  StyledCopilotTitle,
  StyledDrawer,
  StyledDrawerMain,
  StyledSelectedContent,
  StyledSelectedType,
  StyledSettings,
  StyledTabs
} from './index.styled'
import { useCopilotSettings } from './useCopilotSettings'
import { templateBuilderStore } from '@luctc/template-builder'
import { Content as JSONContent } from '@tiptap/core'
import { Editor } from '@tiptap/react'
import { Button, Spin, notification } from 'antd'
import axios from 'axios'
import { isProduction } from 'constants/env'
import { useLatest } from 'hooks/useLatest'
import { Close, Copilot, History, Regenerate, SettingOutlined } from 'icons'
import { useEffect, useRef, useState } from 'react'
import { CopilotServices } from 'services/copilot'
import { v4 as uuidv4 } from 'uuid'

export const headerStyle = {
  fontSize: '14px',
  padding: '16px'
}

type ParamsCopilot = {
  style: Record<string, string>
  moduleBlockStyle: Record<string, string>
  computedStyle: Record<string, string>
}

export const MAP_KEY_TO_TEXT: Record<string, string> = {
  improve: 'Improve my current text',
  generate_from_input: 'Generate email from input summary',
  generate_from_bullets: 'Generate email from bullets',
  generate_from_thread: 'Generate follow-up based on thread'
}
export const MAP_KEY_TO_TASK_NAME: Record<string, string> = {
  improve: 'content_improver',
  generate_from_input: 'summarize_text',
  generate_from_bullets: 'persuasive_bullet_points',
  generate_from_thread: 'personalized_cold_emails'
}

export function capitalizeStr(str: string): string {
  if (str.length === 0) {
    return str // Return an empty string if input is empty
  }

  const firstChar = str.charAt(0).toUpperCase()
  const restOfString = str.slice(1).toLowerCase()

  return firstChar + restOfString
}

type Props = {
  countryCode: string
}

const session_uid = uuidv4()

function TemplateCopilotDrawer(props: Props) {
  const timeoutId = useRef<NodeJS.Timeout | undefined>()
  const editor = useRef<Editor | null>(null)
  const cancelRequestRef = useRef(() => {
    //
  })
  const [openCopilot, setOpenCopilot] = useState(false)
  const [editorType, setEditorType] = useState('')
  const [dataStyle, setDataStyle] = useState<ParamsCopilot | null>(null)

  const {
    openSettings,

    settingTone,
    settingLength,
    settingLanguage,

    setOpenSettings,

    renderSettingTone,
    renderSettingLength,
    renderSettingLanguage
  } = useCopilotSettings()

  const [selectedKey, setSelectedKey] = useState('')
  const [loading, setLoading] = useState(true)
  const [currentPreviewIdx, setCurrentPreviewIdx] = useState(0)
  const [recommendsContent, setRecommendsContent] = useState<string[]>([])
  const latestGenerateText = useLatest(generateText)

  useEffect(() => {
    templateBuilderStore.onCopilot(
      ({ key, currentEditor, dataStyle, type }) => {
        editor.current = currentEditor
        setOpenCopilot(true)
        setDataStyle(dataStyle)
        setEditorType(type === 'button' ? 'paragraph' : type)
        setSelectedKey(key)

        /** Fake fetch recommended content from Open AI */
        latestGenerateText.current({
          input: currentEditor.getText(),
          selectedKey: key
        })
        // setTimeout(() => {
        //   setLoading(false)
        //   setRecommendContent(replaceAll(contentDemo.content, '\\n', '<br />'))

        //   setTimeout(() => {
        //     const el = document.querySelector('.recommended-content')

        //     if (el) {
        //       el.scrollIntoView({ behavior: 'smooth', block: 'end' })
        //     }
        //   }, 300)
        // }, 1000)
      }
    )
  }, [latestGenerateText])

  useEffect(() => {
    if (!openCopilot) {
      setTimeout(() => {
        setOpenSettings(false)
        setLoading(true)
        setRecommendsContent([])
        cancelRequestRef.current()
      }, 300)
    }
  }, [openCopilot, setOpenSettings])

  function generateText({
    input,
    selectedKey
  }: {
    input: string
    selectedKey: string
  }) {
    setLoading(true)
    const { promise, cancelRequest } = CopilotServices.generate({
      feature: 'content',
      task: {
        name: MAP_KEY_TO_TASK_NAME[selectedKey],
        input,
        tone: settingTone,
        length: settingLength,
        // format: 'email',
        // industry: 'healthcare_general',
        language:
          settingLanguage === 'auto'
            ? settingLanguage
            : capitalizeStr(settingLanguage)
      },
      session_uid: session_uid
    })
    cancelRequestRef.current = cancelRequest
    promise
      .then((res) => {
        if (res.data?.data?.content?.length) {
          setLoading(false)
          setRecommendsContent((prev) => {
            const newRecommends = [
              ...prev,
              replaceAll(res.data.data.content[0], '\\n', '<br />')
            ]
            setCurrentPreviewIdx(newRecommends.length - 1)
            return newRecommends
          })
        }
      })
      .catch((err) => {
        setLoading(false)
        if (axios.isCancel(err)) {
          return
        }
        notification.error({
          message:
            err.response?.data?.error ||
            'Something went wrong, please try again later.'
        })
      })
  }

  function onChangeEditor(_: string, __: string, rawJson: JSONContent) {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }

    timeoutId.current = setTimeout(() => {
      editor.current?.commands.setContent(rawJson, true)
    }, 300)
  }

  const htmlContent = editor.current?.getHTML()

  return (
    <StyledDrawer
      width={404}
      title={
        <StyledCopilotTitle>
          <Copilot /> AI Copilot Assistant
        </StyledCopilotTitle>
      }
      closable={false}
      headerStyle={headerStyle}
      extra={
        <StyledActions>
          <SettingOutlined onClick={() => setOpenSettings(!openSettings)} />
          <History />
          <Close onClick={() => setOpenCopilot(false)} />
        </StyledActions>
      }
      placement="right"
      onClose={() => setOpenCopilot(false)}
      open={openCopilot}
      closeIcon={null}>
      <StyledDrawerMain>
        <StyledTabs defaultActiveKey="Prompt">
          <StyledTabs.TabPane tab="Prompt" key="Prompt">
            <EditorContent
              label="Text from your selection"
              dataStyle={dataStyle}
              htmlContent={htmlContent}
              editorType={editorType}
              onChange={onChangeEditor}
            />
            {selectedKey && dataStyle && (
              <StyledSelectedType>
                <Button type="primary" size="small">
                  {MAP_KEY_TO_TEXT[selectedKey]}
                </Button>
              </StyledSelectedType>
            )}
            {loading && (
              <StyledSelectedContent>
                <Spin />
              </StyledSelectedContent>
            )}
            {!loading && recommendsContent.length > 0 && (
              <StyledActionsBottom>
                <Button
                  size="small"
                  type="default"
                  block
                  icon={<Regenerate />}
                  onClick={() => {
                    latestGenerateText.current({
                      input: editor.current?.getText() || '',
                      selectedKey
                    })
                  }}>
                  Regenerate again
                </Button>
              </StyledActionsBottom>
            )}
          </StyledTabs.TabPane>
          {!isProduction && (
            <StyledTabs.TabPane tab="Template" key="Template">
              <CopilotTabsTemplate />
            </StyledTabs.TabPane>
          )}
        </StyledTabs>
      </StyledDrawerMain>
      {openSettings && (
        <StyledSettings>
          {/* Tone */}
          {renderSettingTone()}
          {/* Length */}
          {renderSettingLength()}
          {/* Language */}
          {renderSettingLanguage()}
          <div className="group-btn-footer">
            <Button size="small" onClick={() => setOpenSettings(false)}>
              Close
            </Button>
            <Button
              size="small"
              type="primary"
              onClick={() => {
                latestGenerateText.current({
                  input: editor.current?.getText() || '',
                  selectedKey
                })
                setOpenSettings(false)
              }}>
              Apply
            </Button>
          </div>
        </StyledSettings>
      )}
      <CopilotDrawerPreview
        recommendsContent={recommendsContent}
        currentPreviewIdx={currentPreviewIdx}
        setCurrentPreviewIdx={setCurrentPreviewIdx}
        dataStyle={dataStyle}
        editorType={editorType}
        editor={editor}
        onChangeEditor={onChangeEditor}
        onClickSubmit={() => {
          editor.current?.commands.setContent(
            recommendsContent[currentPreviewIdx],
            true
          )
          setOpenCopilot(false)
        }}
      />
    </StyledDrawer>
  )
}

export default TemplateCopilotDrawer
