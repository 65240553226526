export const MaxPushTitle = 60
export const MaxPushMessage = 220

export const rules = {
  name: [
    { required: true, message: 'Please enter template name' },
    {
      whitespace: true,
      message: 'Please input template name with characters'
    },
    {
      max: 120,
      message: 'Maximum 120 characters for name web push'
    }
  ],
  message_type: [{ required: true, message: 'Please select message type' }],
  site: [{ required: true, message: 'Please choose site' }],
  title: [
    { required: true, message: 'Please input title' },
    {
      max: MaxPushTitle,
      message: 'Title is too long'
    },
    {
      whitespace: true,
      message: 'Please input title with characters'
    }
  ],
  push_message: [
    { required: true, message: 'Please input push message' },
    {
      max: MaxPushMessage,
      message: `The maximum length of push messages is ${MaxPushMessage} characters`
    },
    {
      whitespace: true,
      message: 'Please input messages with characters'
    }
  ],
  message_link: [{ required: true, message: 'Please input message link' }],
  message_icon: [{ required: true, message: 'Please upload message icon' }],
  image_upload: [{ required: true, message: 'Please upload image' }]
}

export const mockDynamicLink = [
  {
    label: 'https://cdn-hermes.hellohealthgroup.com/content1.png',
    value: 'https://cdn-hermes.hellohealthgroup.com/content1.png'
  },
  {
    label: 'https://cdn-hermes.hellohealthgroup.com/content2.png',
    value: 'https://cdn-hermes.hellohealthgroup.com/content2.png'
  },
  {
    label: 'https://cdn-hermes.hellohealthgroup.com/content3.png',
    value: 'https://cdn-hermes.hellohealthgroup.com/content3.png'
  }
]
