import { menuRoutes } from 'components/molecules/layout'
import { FORMAT_DATE_DAYJS_API } from 'constants/common'
import { DISABLED_SMS, isProduction } from 'constants/env'
import { PERMISSIONS } from 'constants/permission'
import { TEMPLATE_TYPE } from 'constants/template'
import dayjs, { Dayjs } from 'dayjs'
import { ItemMenu } from 'interfaces'
import { ReactNode } from 'react'

export type CampaignHeaderValue = {
  approach?: string | null
  search?: string
  columns?: string[]
  site_id?: string | null
  type?: string
  created_from?: string
  created_to?: string
  status?: string[]
  template_type?: string
  template_types?: string[]
  send_time_from?: string
  send_time_to?: string
}

export type CampaignHeaderState = Omit<
  CampaignHeaderValue,
  'created_from' | 'created_to' | 'send_time_from' | 'send_time_to'
> & {
  created?: [Dayjs, Dayjs] | null
  send_time?: [Dayjs, Dayjs] | null
}

export const tabCampaign = [
  { label: 'All', key: 'all', children: null },
  // { label: 'Automation', key: 'automation', children: null }, HHS-1675
  { label: 'Email', key: 'email', children: null },
  { label: 'App Push', key: 'app_push', children: null },
  { label: 'Web Push', key: 'web_push', children: null },
  isProduction
    ? null
    : { label: 'Landing Page', key: 'ld_page', children: null },
  { label: 'WhatsApp', key: 'whatsapp', children: null },
  DISABLED_SMS ? null : { label: 'SMS', key: 'sms', children: null }
].filter(Boolean) as { label: string; key: string; children: ReactNode }[]

export const menuCreateCampaign = [
  {
    key: `${menuRoutes.campaign}/${TEMPLATE_TYPE.EMAIL}/create`,
    label: 'Email Campaign',
    permissions: [PERMISSIONS.campaign_email_create]
  },
  // HHS-1675
  // {
  //   key: `${menuRoutes.campaign}/automation/create`,
  //   label: 'Automation Campaign',
  //   disabled: false
  // },
  {
    key: `${menuRoutes.campaign}/${TEMPLATE_TYPE.APP_PUSH}/create`,
    label: 'App Push Campaign',
    permissions: [PERMISSIONS.campaign_app_push_create],
    disabled: false
  },
  {
    key: `${menuRoutes.campaign}/${TEMPLATE_TYPE.WEB_PUSH}/create`,
    label: 'Web Push Campaign',
    permissions: [PERMISSIONS.campaign_web_push_create],
    disabled: false
  },
  {
    key: `${menuRoutes.campaign}/${TEMPLATE_TYPE.WHATSAPP}/create`,
    label: 'WhatsApp Campaign',
    permissions: [PERMISSIONS.campaign_whatsapp_create],
    disabled: false
  },
  // HHS-2256
  DISABLED_SMS
    ? null
    : {
        key: `${menuRoutes.campaign}/${TEMPLATE_TYPE.VNPT}/create`,
        label: 'SMS Campaign',
        // permissions: [PERMISSIONS.campaign_whatsapp_create],
        disabled: false
      },
  isProduction
    ? null
    : {
        key: `${menuRoutes.campaign}/${TEMPLATE_TYPE.LP_PAGE}/create`,
        label: 'Landing Page Campaign',
        permissions: [PERMISSIONS.campaign_landing_page_create],
        disabled: false
      }
].filter(Boolean) as ItemMenu[]

export const campaignHeaderPropsToState = (
  input: CampaignHeaderValue
): CampaignHeaderState => {
  const newState: CampaignHeaderState = {
    approach: input?.approach,
    search: input?.search,
    columns: input?.columns,
    site_id: input?.site_id,
    type: input?.type,
    status: input?.status,
    template_type: input?.template_type,
    template_types: input?.template_types
  }

  if (input?.created_from && input?.created_to) {
    const createdFrom = dayjs(input.created_from, FORMAT_DATE_DAYJS_API)
    const createdTo = dayjs(input.created_to, FORMAT_DATE_DAYJS_API)
    if (createdFrom.isValid() && createdTo.isValid()) {
      newState.created = [createdFrom, createdTo]
    }
  }
  if (input?.send_time_from && input?.send_time_to) {
    const startDatetimeFrom = dayjs(input.send_time_from, FORMAT_DATE_DAYJS_API)
    const startDatetimeTo = dayjs(input.send_time_to, FORMAT_DATE_DAYJS_API)
    if (startDatetimeFrom.isValid() && startDatetimeTo.isValid()) {
      newState.send_time = [startDatetimeFrom, startDatetimeTo]
    }
  }
  return newState
}

export const campaignHeaderStateToProps = (
  input: CampaignHeaderState
): CampaignHeaderValue => {
  const newState: CampaignHeaderValue = {
    approach: input?.approach,
    search: input?.search,
    columns: input?.columns,
    site_id: input?.site_id,
    type: input?.type,
    status: input?.status,
    template_type: input?.template_type,
    template_types: input?.template_types
  }

  if (input?.created && input.created?.length === 2) {
    const createdFrom = input.created?.[0].isValid()
      ? input.created?.[0].format(FORMAT_DATE_DAYJS_API)
      : ''
    const createdTo = input.created?.[1]?.isValid()
      ? input.created?.[1].format(FORMAT_DATE_DAYJS_API)
      : ''

    newState.created_from = createdFrom
    newState.created_to = createdTo
  } else {
    newState.created_from = undefined
    newState.created_to = undefined
  }

  if (input?.send_time && input.send_time?.length === 2) {
    const startDatetimeFrom = input.send_time?.[0].isValid()
      ? input.send_time?.[0].format(FORMAT_DATE_DAYJS_API)
      : ''
    const startDatetimeTo = input.send_time?.[1].isValid()
      ? input.send_time?.[1].format(FORMAT_DATE_DAYJS_API)
      : ''
    newState.send_time_from = startDatetimeFrom
    newState.send_time_to = startDatetimeTo
  } else {
    newState.send_time_from = undefined
    newState.send_time_to = undefined
  }
  return newState
}
