import { TEMPLATE_TYPE } from 'constants/template'

export const getCampaignRoute = (id?: string | number, type = 'automation') => {
  if (typeof id === 'undefined' || !id || id === 0) {
    return ''
  }
  switch (type) {
    case TEMPLATE_TYPE.WEB_PUSH:
    case TEMPLATE_TYPE.APP_PUSH:
    case TEMPLATE_TYPE.WHATSAPP:
    case TEMPLATE_TYPE.LP_PAGE:
    case TEMPLATE_TYPE.VNPT:
    case TEMPLATE_TYPE.TWILIO:
    case TEMPLATE_TYPE.EMAIL: {
      return `/campaign/${type}/${id}`
    }
    default: {
      return `/campaign/automation/${id}`
    }
  }
}
