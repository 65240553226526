import styled from 'styled-components'

export const DnDWrapper = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  border-top: 1px solid ${({ theme }) => theme?.colors?.neutral100};
  border-right: 1px solid ${({ theme }) => theme?.colors?.neutral100};
  border-bottom: 1px solid ${({ theme }) => theme?.colors?.neutral100};

  .react-flow__renderer {
    z-index: 1;
  }

  .react-flow__node {
    padding: 0;
    cursor: auto;
  }

  .react-flow__background {
    pointer-events: none;
  }

  .react-flow__panel {
    box-shadow: none;
    position: absolute;
    z-index: 1;
  }

  .react-flow__attribution {
    display: none;
  }

  /* Reset style default */
  .react-flow__node:hover,
  .react-flow__node-default,
  .react-flow__node-input,
  .react-flow__node-output,
  .react-flow__node-group,
  .react-flow__node-default.selectable.selected,
  .react-flow__node-default.selectable:focus,
  .react-flow__node-default.selectable:focus-visible,
  .react-flow__node-input.selectable.selected,
  .react-flow__node-input.selectable:focus,
  .react-flow__node-input.selectable:focus-visible,
  .react-flow__node-output.selectable.selected,
  .react-flow__node-output.selectable:focus,
  .react-flow__node-output.selectable:focus-visible,
  .react-flow__node-group.selectable.selected,
  .react-flow__node-group.selectable:focus,
  .react-flow__node-group.selectable:focus-visible {
    width: auto;
    border: 0;
    box-shadow: none;
  }

  .react-flow__edge-path {
    stroke: ${({ theme }) => theme.colors.teal400};
  }

  .react-flow__edge.selected {
    .react-flow__edge-path {
      stroke: ${({ theme }) => theme.colors.teal400};
      stroke-width: 1.5;
    }
  }
`

export const DnDContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  .sidebar {
    background-color: #fff;
  }
`
