import { CachedSystem } from './cachedSystem'
import { OLYMPUS_PATHS } from 'constants/apiPaths'
import { COOKIE_AUTH_TOKEN } from 'constants/common'
import { ENV } from 'constants/env'
import Cookies from 'js-cookie'
import { axiosConfigOlympus, callOlympusApi } from 'utils/api'

export function getLoginLink() {
  return `${ENV.baseOlympusApi}oauth/login?client_id=${ENV.baseOlympusClientId}&redirect_uri=${ENV.baseOlympusApi}oauth/callback `
}

export function getLogoutLink() {
  return `${ENV.baseOlympusApi}oauth/logout?client_id=${ENV.baseOlympusClientId}&redirect_uri=${ENV.baseOlympusApi}oauth/callback `
}

export function getProfile() {
  const authToken = Cookies.get(COOKIE_AUTH_TOKEN)
  if (!authToken) {
    return Promise.reject('No auth token cookie')
  }
  return callOlympusApi(OLYMPUS_PATHS.profile, {
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  })
}

export function login({
  email,
  password
}: {
  email: string
  password: string
}) {
  return callOlympusApi(OLYMPUS_PATHS.login, {
    method: 'POST',
    data: { email, password }
  })
}

export function logout() {
  return callOlympusApi(
    OLYMPUS_PATHS.logout,
    axiosConfigOlympus({
      method: 'POST'
    })
  )
}

export function sendResetPassword({ email }: { email: string }) {
  return callOlympusApi(OLYMPUS_PATHS.sendResetPasswordLink, {
    method: 'POST',
    data: { email, base_url: window.location.origin }
  })
}

export function changePassword(args: {
  email: string
  token: string
  password: string
  password_confirmation: string
}) {
  return callOlympusApi(OLYMPUS_PATHS.resetPassword, {
    method: 'POST',
    data: args
  })
}

export function getUsers() {
  return CachedSystem.withCached('OlympusUsers', () => {
    return callOlympusApi(OLYMPUS_PATHS.users, axiosConfigOlympus({}))
  })
}
