import {
  StyledTemplateId,
  StyledTemplateImage,
  StyledTemplateName
} from './index.styled'
import Icon from '@ant-design/icons'
import { Badge, Button, Col, Dropdown, Row, Tooltip, notification } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { Avatar } from 'components/atoms/avatar'
import { TableCellTitle } from 'components/atoms/table'
import { menuRoutes } from 'components/molecules'
import { MenuDropdown } from 'components/molecules/campaign/CampaignTable/CampaignTable.styled'
import { StyledBadgeText } from 'components/molecules/segment'
import { FORMAT_DATE_DAYJS, LIST_COLOR_AVATAR } from 'constants/common'
import { PERMISSIONS } from 'constants/permission'
import { ROLES } from 'constants/role'
import { TEMPLATE_TYPE } from 'constants/template'
import dayjs from 'dayjs'
import { useCheckAuth } from 'hooks/useCheckAuth'
import {
  AppPush,
  CheckedCircleGreen,
  Draft,
  DuplicateFile,
  Edit,
  Email,
  Kebab,
  PendingCircle,
  RejectedIcon,
  Screen,
  SendEmail,
  TemplateImage,
  TrashBin,
  TwilioSMS,
  WhatsApp
} from 'icons'
import { IconPhoneSMS } from 'icons/V2'
import { PREMIUM_NEWSLETTER_IDS } from 'pages/templateBuilderCreate/emailTemplate/helpers'
import { capitalizeStr } from 'pages/templateBuilderCreate/templateCopilotDrawer'
import { ReactNode, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { TemplateServices } from 'services/template'

export interface DataType {
  key: React.Key
  name: string
  type: string
  subject: string
  status: string
  category: string
  createdAt: string
  createdByName: string
  lastModifiedBy: string
  lastModified: string
  thumbnail: string
  createdByEmail: string
  emailFrom?: string
  countryCode: string
  rawHtml: string
}

export type TemplatePaging = {
  page: number
  per_page: number
  total: number
  list: DataType[]
}

export const mapTypeToMedium: Record<
  string,
  { label: string; icon: ReactNode }
> = {
  ld_page: {
    label: 'Landing Page',
    icon: <Screen />
  },
  email: {
    label: 'Email',
    icon: <Email />
  },
  app_push: {
    label: 'App Push',
    icon: <AppPush />
  },
  web_push: {
    label: 'Web Push',
    icon: <AppPush />
  },
  whatsapp: {
    label: 'WhatsApp',
    icon: <WhatsApp />
  },
  vnpt: {
    label: 'VNPT',
    icon: <IconPhoneSMS />
  },
  twilio: {
    label: 'Twilio',
    icon: <TwilioSMS />
  }
}

const mapStatus: Record<string, string> = {
  draft: 'Draft',
  release: 'Release',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  PENDING: 'Pending'
}

const mapStatusIcon: Record<string, React.FunctionComponent> = {
  draft: Draft,
  release: CheckedCircleGreen,
  APPROVED: CheckedCircleGreen,
  REJECTED: RejectedIcon,
  PENDING: PendingCircle
}

export const transformTemplateAPIToColumn = (respData: any) => {
  const data: DataType = {
    key: respData.id,
    name: respData.name,
    type: respData.type,
    subject: '',
    status: respData.status,
    createdAt: `Created at: ${dayjs(respData.created_at).format(
      FORMAT_DATE_DAYJS
    )}`,
    createdByName: respData.created_by,
    lastModified: `Updated at: ${dayjs(respData.updated_at).format(
      FORMAT_DATE_DAYJS
    )}`,
    lastModifiedBy: respData.updated_by,
    thumbnail: respData.thumbnail
      ? respData.thumbnail +
        `?v=${dayjs(respData.updated_at).toDate().getTime()}`
      : '',
    createdByEmail: respData.created_by_email,
    countryCode: respData.country_code,
    emailFrom: respData.emailFrom || '',
    rawHtml: respData.body_html || '',
    category: respData.whats_app_builder?.category || ''
  }

  return data
}

export const renderTemplateStatus = (record: DataType) => {
  const text = mapStatus[record.status] || record.status
  const Component = mapStatusIcon[record.status] || Draft
  const iconColor = record.status === 'release' ? '#00C076' : '#737373'
  return (
    <div>
      <Badge>
        <Icon component={Component} style={{ color: iconColor }} />
        <StyledBadgeText>{text}</StyledBadgeText>
      </Badge>
    </div>
  )
}

const defaultCallbackDelete = (evt: any) => {
  // To be overrided
}
const columnsActionSingleTon = {
  callbackDelete: defaultCallbackDelete,
  callbackSendWhatsapp: defaultCallbackDelete,
  on: (name: 'delete' | 'send_whatsapp', cb: (evt: any) => void) => {
    if (name === 'delete') {
      columnsActionSingleTon.callbackDelete = cb
    }
    if (name === 'send_whatsapp') {
      columnsActionSingleTon.callbackSendWhatsapp = cb
    }
  },
  off: (name: 'delete' | 'send_whatsapp') => {
    if (name === 'delete') {
      columnsActionSingleTon.callbackDelete = defaultCallbackDelete
    }
    if (name === 'send_whatsapp') {
      columnsActionSingleTon.callbackDelete = defaultCallbackDelete
    }
  }
}

function RenderColumnAction({ record }: { record: DataType }) {
  const navigate = useNavigate()
  const isDuplicate = !useCheckAuth({
    roles: [ROLES.admin, ROLES.campaign_manager],
    permissions: [PERMISSIONS.template_duplicate]
  })
  const isEdit = !useCheckAuth({
    roles: [ROLES.admin, ROLES.campaign_manager],
    permissions: [PERMISSIONS.template_email_edit]
  })
  const isDelete = !useCheckAuth({
    roles: [ROLES.admin, ROLES.campaign_manager],
    permissions: [PERMISSIONS.template_delete]
  })

  const disabledEditByTemplateType = [TEMPLATE_TYPE.VNPT].includes(
    record.type as string
  )
  const disabledDuplicateByTemplateType = [
    TEMPLATE_TYPE.WHATSAPP,
    TEMPLATE_TYPE.TWILIO,
    TEMPLATE_TYPE.VNPT
  ].includes(record.type as string)

  const items = useMemo(() => {
    const _items = [
      {
        key: 'duplicate',
        label: 'Duplicate',
        icon: <DuplicateFile />,
        disabled:
          isDuplicate ||
          record.key === PREMIUM_NEWSLETTER_IDS ||
          disabledDuplicateByTemplateType
      },
      {
        key: 'edit',
        label: 'Edit',
        icon: <Edit />,
        disabled: isEdit || disabledEditByTemplateType
      },
      {
        key: 'delete',
        label: 'Delete',
        icon: <TrashBin />,
        disabled:
          isDelete ||
          record.key === PREMIUM_NEWSLETTER_IDS ||
          [TEMPLATE_TYPE.TWILIO, TEMPLATE_TYPE.VNPT].includes(record.type)
      }
    ]

    if (record.type === 'ld_page') {
      _items.push({
        key: 'insight',
        label: 'Insight',
        icon: <Screen />,
        disabled: false
      })
    }

    if (record.type === 'whatsapp') {
      _items.push({
        key: 'send_whatsapp_template',
        label: 'Send Test Whatsapp',
        icon: <SendEmail width="20" height="20" />,
        disabled: false
      })
    }

    return _items
  }, [
    isDuplicate,
    record.key,
    record.type,
    disabledDuplicateByTemplateType,
    isEdit,
    disabledEditByTemplateType,
    isDelete
  ])

  return (
    <Dropdown
      trigger={['click']}
      overlay={
        <MenuDropdown
          items={items}
          onClick={(evt) => {
            if (evt.key === 'edit' && record.type === 'ld_page') {
              navigate(`/template-builder/landing-page/${record.key}`)
            }
            if (evt.key === 'edit' && record.type === 'email') {
              navigate(`/template-builder/email/${record.key}`)
            }
            if (evt.key === 'edit' && record.type === 'app_push') {
              navigate(`/template-builder/app-push/${record.key}`)
            }
            if (evt.key === 'edit' && record.type === 'web_push') {
              navigate(`/template-builder/web-push/${record.key}`)
            }
            if (evt.key === 'edit' && record.type === 'whatsapp') {
              navigate(`/template-builder/whats-app/${record.key}`)
            }
            if (
              evt.key === 'edit' &&
              [TEMPLATE_TYPE.TWILIO].includes(record.type)
            ) {
              navigate(`/template-builder/${record?.type}/${record.key}`)
            }
            if (evt.key === 'delete') {
              columnsActionSingleTon.callbackDelete(record)
            }
            if (evt.key === 'duplicate') {
              if (disabledDuplicateByTemplateType) {
                return
              }
              TemplateServices.duplicateTemplate(record.key)
                .then((res) => {
                  if (!res.data.data) {
                    throw new Error('')
                  }
                  const { template } = res.data.data
                  let url = `/template-builder/landing-page/${template.id}`
                  if (record.type === 'email') {
                    url = `/template-builder/email/${template.id}`
                  }
                  if (record.type === 'app_push') {
                    url = `/template-builder/app-push/${template.id}`
                  }
                  if (record.type === 'web_push') {
                    url = `/template-builder/web-push/${template.id}`
                  }
                  if (record.type === 'whatsapp') {
                    url = `/template-builder/whats-app/${template.id}`
                  }
                  notification.success({
                    message: 'Template is duplicated successfully.'
                  })
                  navigate(url)
                })
                .catch((error) => {
                  notification.error({
                    message: 'Something went wrong. Please try again!'
                  })
                })
            }
            if (evt.key === 'insight') {
              navigate(
                `${menuRoutes.insight}/${TEMPLATE_TYPE.LP_PAGE}/${record.key}`
              )
            }
            if (evt.key === 'send_whatsapp_template') {
              columnsActionSingleTon.callbackSendWhatsapp(record)
            }
          }}
        />
      }>
      <Button type="link" icon={<Kebab />} />
    </Dropdown>
  )
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (name: string, record) => {
      let url = `/template-builder/${record?.type}/${record.key}`

      if (record.type === 'email') {
        url = `/template-builder/email/${record.key}`
      }
      if (record.type === 'app_push') {
        url = `/template-builder/app-push/${record.key}`
      }
      if (record.type === 'web_push') {
        url = `/template-builder/web-push/${record.key}`
      }
      if (record.type === 'whatsapp') {
        url = `/template-builder/whats-app/${record.key}`
      }
      if (record.type === TEMPLATE_TYPE.LP_PAGE) {
        url = `/template-builder/landing-page/${record.key}`
      }

      // TODO make UI when API available
      if ([TEMPLATE_TYPE.VNPT].includes(record.type)) {
        url = `/template-builder`
      }

      return (
        <Row align="middle" wrap={false}>
          <StyledTemplateImage>
            {record.thumbnail ? (
              <img src={record.thumbnail} alt="" />
            ) : (
              <TemplateImage />
            )}
          </StyledTemplateImage>
          <Tooltip title={name}>
            <div className="pl-[12px]" style={{ minWidth: 0 }}>
              <StyledTemplateName to={url} title={name}>
                {name}
              </StyledTemplateName>
              <StyledTemplateId>ID: {record.key}</StyledTemplateId>
            </div>
          </Tooltip>
        </Row>
      )
    }
  },
  {
    title: 'Medium',
    dataIndex: 'type',
    key: 'type',
    width: 150,
    render(value, record, index) {
      return (
        <Row align="middle">
          {mapTypeToMedium[record.type]?.icon}
          &nbsp;&nbsp;<span>{mapTypeToMedium[record.type]?.label}</span>
        </Row>
      )
    }
  },
  // {
  //   title: 'Subject',
  //   dataIndex: 'subject'
  // },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 150,
    render(value, record, index) {
      return renderTemplateStatus(record)
    }
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'whats_app_category',
    render(_, record, index) {
      const mapColor: Record<string, string> = {
        MARKETING: '#E3F2FF',
        AUTHENTICATION: '#FAE9E8',
        UTILITY: '#FFF8E2'
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const color = mapColor[record.category] || '#E3F2FF'
      return (
        <span
          className={`inline-block bg-[${color}] text-black py-[1px] px-[4px] rounded-[2px]`}>
          {capitalizeStr(record.category)}
        </span>
      )
    }
  },
  {
    title: 'Created by',
    dataIndex: 'createdBy',
    key: 'createdBy',
    render(value, record, index) {
      const idxColor = Number(record.key) % LIST_COLOR_AVATAR.length
      return (
        <Row align="middle" wrap={false} title={record.createdByName}>
          <Col>
            <Avatar
              name={record.createdByName}
              size={32}
              email={record.createdByEmail}
              background={LIST_COLOR_AVATAR[idxColor]}
            />
          </Col>
        </Row>
      )
    }
  },
  {
    title: 'Last Modified',
    dataIndex: 'lastModified',
    key: 'lastModified',
    render(value, record) {
      return (
        <Row align="middle" wrap={false}>
          <TableCellTitle
            tooltip={false}
            title={record.lastModified}
            subTitle={record.createdAt}
          />
        </Row>
      )
    }
  },
  {
    title: 'Email From',
    dataIndex: 'emailFrom',
    key: 'emailFrom',
    width: 230
  },
  {
    title: 'Country code',
    dataIndex: 'countryCode',
    key: 'countryCode',
    width: 150
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
    width: 70,
    render(value, record, index) {
      return <RenderColumnAction record={record} />
    }
  }
]

export { columns, columnsActionSingleTon }
