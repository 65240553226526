import { SegmentPageCreate } from 'components/pages'
import { ROLES } from 'constants/role'
import { SEGMENT_TYPE } from 'constants/segment'

export const SegmentCreateEvent = () => {
  return (
    <SegmentPageCreate
      segment_type={SEGMENT_TYPE.EVENT}
      roles={[ROLES.admin, ROLES.campaign_manager]}
    />
  )
}
