import cn from 'classnames'
import { HTMLAttributes, useMemo } from 'react'
import { Handle, HandleProps, useEdges } from 'reactflow'
import styled, { css } from 'styled-components'

const cssHandleInside = css`
  display: block;
  width: 6px;
  height: 6px;
  border: 0;
  background-color: ${({ theme }) => theme?.colors?.teal400};
  border-radius: 50%;
`

const cssHandleOutside = css`
  display: block;
  width: 14px;
  height: 14px;
  border: 1px solid ${({ theme }) => theme?.colors?.neutral100};
  background-color: #fff;
  border-radius: 50%;
`

export const WFNodeHandle = styled(Handle)`
  &.react-flow__handle {
    ${cssHandleOutside}
    position: relative;
    inset: unset;
    display: inline-block;
    line-height: 1;
    transform: unset;
    transition: 0.3s linear all;
    :before {
      ${cssHandleInside}
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: 0.3s linear all;
    }
    :after {
      content: '';
      position: absolute;
      top: 50%;
      left: -1px;
      display: block;
      width: 7px;
      height: 1px;
      background-color: ${({ theme }) => theme?.colors?.teal400};
      opacity: 0;
      transform: translateY(-50%);
      transform-origin: center right;
    }

    &-top {
      transform: rotate(90deg);
    }
    &-right {
      transform: rotate(180deg);
    }
    &-bottom {
      transform: rotate(-90deg);
    }
    &-left {
    }

    &.target {
      :after {
        content: none;
      }
    }

    &.isConnected {
      :before,
      :after {
        opacity: 1;
        transition: 0.3s linear all;
      }
    }
  }
`

export type WorkflowBuilderHandleProps = HandleProps &
  Omit<HTMLAttributes<HTMLDivElement>, 'id'>
export function WorkflowBuilderHandle(props: WorkflowBuilderHandleProps) {
  const { ...restProps } = props
  const edges = useEdges()
  const isConnected = useMemo(() => {
    if (props.type === 'target') {
      return edges.some(({ targetHandle }) => targetHandle === props.id)
    }

    if (props.type === 'source') {
      return edges.some(({ sourceHandle }) => sourceHandle === props.id)
    }
    return false
  }, [edges, props.id, props.type])

  return (
    <WFNodeHandle
      {...restProps}
      className={cn(props.className, { isConnected })}
    />
  )
}
