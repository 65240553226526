import { SegmentTableHeaderValue } from './SegmentTableHeader'
import { StyledTableCell } from './index.styled'
import Icon from '@ant-design/icons'
import { Button, TableColumnProps, TablePaginationConfig, Tooltip } from 'antd'
import { Avatar, AvatarGroupSource } from 'components/atoms/avatar'
import { TableCellTitle, TableDetailDropdown } from 'components/atoms/table'
import { TagGroup } from 'components/atoms/tag'
import { menuRoutes } from 'components/molecules'
import {
  SEGMENT_STATUS_KEYS,
  SegmentStatus
} from 'components/molecules/segment'
import {
  FORMAT_DATE_DAYJS,
  FORMAT_DATE_DAYJS_API,
  LIST_COLOR_AVATAR
} from 'constants/common'
import { PERMISSIONS } from 'constants/permission'
import { ROLES } from 'constants/role'
import { theme } from 'constants/theme'
import dayjs from 'dayjs'
import { FilterList, Folder, TrashBin } from 'icons'
import { ItemMenu } from 'interfaces'
import { Segment } from 'interfaces/segment'
import Cookies from 'js-cookie'
import { NavigateFunction } from 'react-router'
import { Link } from 'react-router-dom'
import { formatNumberToCurrency } from 'utils'

export const SEGMENT_TABLE_COOKIE = 'sgt_tb_ck'
export type SegmentTableValue = SegmentTableHeaderValue & {
  sort_by?: string
  sort_type?: 'DESC' | 'ASC'
  pagination?: TablePaginationConfig
}
export type SegmentKeys = keyof Segment | string
export type SegmentColumnType = TableColumnProps<Segment & any> & {
  key: SegmentKeys & string
  dataIndex?: SegmentKeys & string
}
export type SegmentColumnsProps = {
  menuItems?: ItemMenu[]
  onMenuItemClick?: (id: string | number, type?: string) => void
}
export const segmentColumns = (
  props?: SegmentColumnsProps
): SegmentColumnType[] => {
  const { menuItems, onMenuItemClick } = props || {}
  return [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Segment',
      width: 150,
      render(name, segment) {
        const linkTo = `${menuRoutes.segment}/${segment.id}`
        return (
          <StyledTableCell className="cell--name">
            <div className="wrapper-segment-name">
              <Link to={linkTo} className="block">
                <div className="name">
                  <Tooltip title={name}>{name}</Tooltip>
                </div>
              </Link>
              <div className="description">ID: {segment.id}</div>
            </div>
          </StyledTableCell>
        )
      }
    },
    {
      key: 'created_by',
      dataIndex: 'created_by',
      title: 'Created by',
      width: 100,
      render(created_by, _, index) {
        const isNotUser =
          !created_by ||
          (!created_by?.email &&
            !created_by?.first_name &&
            !created_by?.last_name)
        if (isNotUser) {
          return null
        }
        const name = created_by.first_name + ' ' + created_by.last_name
        const indexColor = index % LIST_COLOR_AVATAR.length

        return (
          <StyledTableCell>
            <Avatar
              name={name}
              size={32}
              email={created_by.email}
              background={LIST_COLOR_AVATAR[indexColor]}
            />
          </StyledTableCell>
        )
      }
    },
    {
      key: 'segment_type',
      dataIndex: 'segment_type',
      title: 'Type',
      width: 70
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: 'Status',
      width: 70,
      render: (status) => {
        return <SegmentStatus status={status} />
      }
    },
    {
      key: 'created_at',
      dataIndex: 'created_at',
      title: 'Created at',
      width: 120,
      render: (_date, segment) => {
        const createdAt = dayjs(_date).isValid()
          ? dayjs(_date).format(FORMAT_DATE_DAYJS)
          : ''
        const updatedAt = dayjs(segment.updated_at).isValid()
          ? dayjs(segment.updated_at).format(FORMAT_DATE_DAYJS)
          : ''
        return (
          <StyledTableCell className="cell--createAt">
            <TableCellTitle
              tooltip={false}
              title={`Updated at: ${updatedAt}`}
              subTitle={`Created at: ${createdAt}`}
              className="min-w-0 flex-1"
            />
          </StyledTableCell>
        )
      }
    },
    {
      key: 'source',
      dataIndex: 'source',
      title: 'Sources',
      width: 120,
      render(sources) {
        return <AvatarGroupSource sources={sources} />
      }
    },
    {
      key: 'num_of_users',
      dataIndex: 'num_of_users',
      title: 'Users',
      width: 70,
      render(_, record) {
        if (record?.count_data && Array.isArray(record?.count_data)) {
          const totalCount =
            record.count_data.reduce((total: number, item: any) => {
              return total + (item?.totalCount || 0)
            }, 0) || 0
          return formatNumberToCurrency(totalCount)
        }
        return 0
      }
    },
    {
      key: 'tags',
      dataIndex: 'tags',
      title: 'Label',
      width: 80,
      render(tags) {
        if (!Array.isArray(tags)) {
          return null
        }
        return (
          <StyledTableCell className="cell-tags">
            <TagGroup
              tags={tags.map(({ name }) => ({
                label: name,
                color: theme.colors.blue50,
                className: 'text-blue900 bg-blue50'
              }))}
              maxTagsCount="responsive"
            />
          </StyledTableCell>
        )
      }
    },
    {
      key: 'publish',
      dataIndex: 'id',
      title: '',
      width: 80,
      render(_, record) {
        if (record?.status !== SEGMENT_STATUS_KEYS.DRAFT) {
          return null
        }
        return (
          <div className="flex justify-end">
            <Button
              ghost
              size="small"
              type="primary"
              onClick={() => onMenuItemClick?.(record?.id, 'publish')}
              className="text-[12px] font-normal py-1 px-2 h-auto flex items-center">
              Publish segment
            </Button>
          </div>
        )
      }
    },
    {
      key: 'actions',
      dataIndex: 'tags',
      title: '',
      width: 40,
      render(_, segment: any) {
        const linkTo = `${menuRoutes.segment}/${segment.id}`
        const onClick = (type: string) => {
          if (type === 'edit') {
            onMenuItemClick?.(linkTo, type)
            return
          }
          onMenuItemClick?.(segment.id, type)
        }

        return (
          <div className="flex items-center justify-start">
            <TableDetailDropdown
              items={(menuItems || []).map((item) => {
                let disabled = item.disabled
                if (item.key === 'delete' && !item.disabled) {
                  disabled = [SEGMENT_STATUS_KEYS.PUBLIC].some(
                    (sKey) => sKey?.toString() === segment.status?.toString()
                  )
                }
                return { ...item, disabled }
              })}
              onClick={onClick}
            />
          </div>
        )
      }
    }
  ]
}
export const segmentColumnsDisabled: SegmentKeys[] = [
  // 'segment_type',
  // 'status',
  'source'
]
export const segmentColumnsTableAlwaysShow = ['publish', 'actions']
export const segmentColumnsTableDefault = segmentColumns()
  .map(({ key }) => key)
  .filter((key) => !segmentColumnsDisabled.includes(key))
export const segmentTableValueDefault: SegmentTableValue = {
  search: undefined,
  types: 'condition',
  columns: segmentColumnsTableDefault,
  sort_by: '',
  sort_type: 'DESC',
  pagination: {
    position: [],
    current: 1,
    pageSize: 20,
    total: 20
  }
}
export const segmentMenuItemsDetail: ItemMenu[] = [
  {
    type: undefined,
    key: 'detail',
    label: 'Detail',
    icon: <Icon component={Folder} />,
    roles: [
      ROLES.admin,
      ROLES.campaign_manager,
      ROLES.campaign_view,
      ROLES.visitor,
      ROLES.read_only
    ],
    permissions: [PERMISSIONS.segment_view_segment_details]
  },
  {
    type: undefined,
    key: 'edit',
    label: 'Edit',
    icon: <Icon component={FilterList} />,
    roles: [
      ROLES.admin,
      ROLES.campaign_manager,
      ROLES.campaign_view,
      ROLES.visitor,
      ROLES.read_only
    ],
    permissions: [PERMISSIONS.segment_edit]
  },
  // TODO: Update API Later
  // {
  //   type: undefined,
  //   key: 'export',
  //   label: 'Export',
  //   icon: <Icon component={Download} />,
  //   roles: [
  //     'admin',
  //     'campaign_manager',
  //     'campaign_view',
  //     'visitor',
  //     'read_only'
  //   ],
  //   permissions: ['segment_export']
  // },
  {
    type: undefined,
    key: 'delete',
    label: 'Delete',
    icon: <Icon component={TrashBin} />,
    className: 'delete',
    roles: [
      ROLES.admin,
      ROLES.campaign_manager,
      ROLES.campaign_view,
      ROLES.visitor,
      ROLES.read_only
    ],
    permissions: [PERMISSIONS.segment_delete]
  }
]

export const saveSegmentTableValueToParams = (
  value: SegmentTableValue,
  navigate: NavigateFunction
) => {
  Cookies.set(SEGMENT_TABLE_COOKIE, JSON.stringify(value))
  const query = {
    search: value.search,
    page: value.pagination?.current,
    per_page: value.pagination?.pageSize,
    created_from: value.created
      ? dayjs(value.created[0]).format(FORMAT_DATE_DAYJS_API)
      : '',
    created_to: value.created
      ? dayjs(value.created[1]).format(FORMAT_DATE_DAYJS_API)
      : '',
    sites: value.sites,
    site_id: value.site_id,
    types: value.types
  }
  let queryString = ''
  let sep = ''

  Object.keys(query).forEach((k) => {
    const key = k as keyof typeof query
    if (!query[key]) {
      delete query[key]
    } else {
      queryString += `${sep}${key}=${query[key]}`
      sep = '&'
    }
  })

  navigate(`?${queryString}`, { replace: true })
}

export const getSegmentTableValueFromParams = (
  params?: URLSearchParams
): SegmentTableValue => {
  const value = segmentTableValueDefault

  try {
    const sgtCookies = JSON.parse(Cookies.get(SEGMENT_TABLE_COOKIE) || '{}')
    if (sgtCookies.columns && Array.isArray(sgtCookies.columns)) {
      value.columns = sgtCookies.columns
    }
    if (sgtCookies.pagination) {
      value.pagination = {
        ...value.pagination,
        ...sgtCookies.pagination
      }
    }
  } catch (error) {
    console.log('** Error getSegmentTableValueFromParams : ', error)
  }
  const page = params?.get('page')
  const per_page = params?.get('per_page')
  const search = params?.get('search')
  const created_from = params?.get('created_from')
  const created_to = params?.get('created_to')
  const site_id = params?.get('site_id')
  const types = params?.get('types')

  if (search) {
    value.search = search
  }

  if (types) {
    value.types = types
  }

  if (site_id) {
    value.site_id = site_id
  }

  const current = page ? parseInt(page) : value.pagination?.current
  const pageSize = per_page ? parseInt(per_page) : value.pagination?.pageSize

  value.pagination = {
    ...value.pagination,
    current,
    pageSize
  }

  if (created_from && created_to) {
    const createdFrom = dayjs(created_from, FORMAT_DATE_DAYJS_API)
    const createdTo = dayjs(created_to, FORMAT_DATE_DAYJS_API)
    if (createdFrom.isValid() && createdTo.isValid()) {
      value.created = [createdFrom, createdTo]
    }
  }

  return value
}
