import Icon from '@ant-design/icons'
import { Menu, MenuProps } from 'antd'
import styled from 'styled-components'
import { cssDropdownMenuTable, cssDropdownOverlay } from 'styles'

export const StyledSortDropdownOverlay = styled.div`
  ${cssDropdownOverlay}
`

export const StyledSortDropdownMenu = styled(Menu)<MenuProps>`
  ${cssDropdownMenuTable}
  &.menuDropdownTable {
    .ant-dropdown-menu-item {
      &:first-child {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
      }
    }
  }
`

export const StyledSortDropdownTitle = styled.h4`
  padding: 8px 16px;
  margin: 0;
  color: ${({ theme }) => theme?.colors?.gray500 || '#737373'};
  font-size: 14px;
  font-weight: 700;
`

export const StyledSortIcon = styled(Icon)``

export const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-left: 8px;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.25s linear all;
  &:hover {
    background-color: ${({ theme }) => theme?.colors?.gray100};
  }

  &.active {
    background-color: ${({ theme }) => theme?.colors?.gray100};
  }
`
