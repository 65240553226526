import {
  WorkflowBuilderSidebarItemProps,
  WorkflowBuilderSidebarOption
} from '.'
import { WFNodeTypesKeys, wfConfigNodeTypes } from '../index.helpers'

export type WFSidebarOptions = WorkflowBuilderSidebarOption<
  Partial<WFNodeTypesKeys>
>[]

export function getListWorkflowSidebar(
  types: WFNodeTypesKeys[],
  optionDefault?: Partial<
    WorkflowBuilderSidebarOption<Partial<WFNodeTypesKeys>>
  >
): WFSidebarOptions {
  return types.map((type: WFNodeTypesKeys) => {
    const config = wfConfigNodeTypes[type]
    const option = {
      ...optionDefault,
      type,
      label: config.label,
      icon: config.icon
    }

    return option
  }) as WFSidebarOptions
}

export const marketingOptions = getListWorkflowSidebar(
  [
    'APP_PUSH',
    'WEB_PUSH',
    'EMAIL',
    'TWILIO_SMS',
    'LANDING_PAGE',
    'LEAD_POPUP',
    'WHATSAPP',
    'LINE_CHAT'
  ],
  { className: 'medium' }
)

export const advertisingOptions = getListWorkflowSidebar(
  ['FACEBOOK_ADS', 'GAM_BANNER', 'GOOGLE_ADS'],
  { className: 'medium' }
)

export const defaultActionOptions = getListWorkflowSidebar(
  ['STARTING_POINT', 'EXIT'],
  { className: 'action' }
)

export const flowControlOptions = getListWorkflowSidebar(
  ['PERFORM_AN_EVENT', 'USER_ATTRIBUTES', 'TRIGGER', 'TRUE_FALSE', 'AB_SPLIT'],
  { className: 'action' }
)

export const delayOptions = getListWorkflowSidebar(
  ['DELAY', 'WAIT_UNTIL', 'TIME_WINDOW'],
  { className: 'action' }
)

export const wfSidebarOptions: WorkflowBuilderSidebarItemProps[] = [
  {
    type: 'medium',
    label: 'Medium',
    children: [
      {
        type: 'marketing',
        label: 'MARKETING',
        children: marketingOptions
      },
      {
        type: 'advertising',
        label: 'ADVERTISING',
        children: advertisingOptions
      }
    ]
  },
  {
    type: 'action',
    label: 'Action',
    children: [
      {
        type: 'default',
        label: 'DEFAULT',
        children: defaultActionOptions
      },
      {
        type: 'flow_control',
        label: 'FLOW CONTROL',
        children: flowControlOptions
      },
      {
        type: 'delay',
        label: 'DELAY',
        children: delayOptions
      }
    ]
  }
]
