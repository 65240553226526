import { WorkflowState } from '.'
import { SEGMENT_STATUS_KEYS } from 'components/molecules/segment'
import { SEGMENT_TYPE } from 'constants/segment'
import { OptionType } from 'interfaces'
import { EventItem } from 'interfaces/event'
import { FieldItem } from 'interfaces/field'
import { Segment, SegmentQueryParams } from 'interfaces/segment'
import { Dispatch, SetStateAction, createContext, useContext } from 'react'
import { Edge, Node, ReactFlowInstance } from 'reactflow'
import { EventServices } from 'services/event'
import { segmentServices } from 'services/segment'

export type WorkflowBuilderContextProps = {
  instance?: ReactFlowInstance
  nodesData?: Record<string, any>
  setNodesData?: Dispatch<SetStateAction<Record<string, any>>>
  site?: string | number
  timezone?: string
  disabled?: boolean
  events?: OptionType[]
  nodes: Node[]
  edges: Edge[]
  onChange?: (value: WorkflowState) => void
  setEdges?: Dispatch<SetStateAction<Edge<any>[]>>
  setNodes?: Dispatch<SetStateAction<Node<any, string | undefined>[]>>
  onNodesDelete?: (nodesDelete: Node[]) => void
  onNodesChangeData?: (nodesChange: Node[]) => void
}

export const initWorkflowBuilderContext = { nodes: [], edges: [] }

export const WorkflowBuilderContext =
  createContext<WorkflowBuilderContextProps>(initWorkflowBuilderContext)

export const useWorkflowBuilder = () => {
  return useContext(WorkflowBuilderContext)
}

export async function getWorkflowBuilderQueriesSegments(
  query?: Partial<SegmentQueryParams>
) {
  const newQuery: SegmentQueryParams = {
    ...query,
    page: query?.page || 1,
    per_page: query?.per_page || 100000,
    multi_site: query?.multi_site || false,
    analytics: query?.analytics || false,
    not_count_user: true,
    type: query?.type,
    status: [SEGMENT_STATUS_KEYS.PUBLIC]
  }
  if (
    [SEGMENT_TYPE.CSV, SEGMENT_TYPE.DYNAMIC_LIST].some(
      (key) => key === newQuery.type
    )
  ) {
    delete newQuery.site_id
  }
  try {
    let res
    if (query?.type !== SEGMENT_TYPE.DYNAMIC_LIST) {
      res = await segmentServices.getSegmentList(newQuery)
    } else {
      res = await EventServices.getList({
        page: newQuery.page,
        per_page: newQuery.per_page,
        has_list_name: true
      })
    }
    if (res._message || res._statusRes !== 200) {
      throw new Error(JSON.stringify(res))
    }
    return { data: res.data?.data || [], errors: [] }
  } catch (error) {
    return { data: [], errors: [] }
  }
}

export type WorkflowBuilderQueriesContextProps = {
  /** segments:Record<typeof segment, list segment> */
  segments: Record<string, Array<any>>
  setSegments?: Dispatch<SetStateAction<Record<string, Array<any>>>>
  /** fields: list of field from segment selected */
  fields: Record<string | number, FieldItem[]>
  setFields?: Dispatch<SetStateAction<Record<string | number, FieldItem[]>>>

  segmentSelected: Partial<Segment & EventItem>
  setSegmentSelected?: Dispatch<SetStateAction<Partial<Segment & EventItem>>>
}
export const initWorkflowBuilderQueriesContext: WorkflowBuilderQueriesContextProps =
  {
    segments: {},
    fields: {},
    segmentSelected: {}
  }
export const WorkflowBuilderQueriesContext =
  createContext<WorkflowBuilderQueriesContextProps>(
    initWorkflowBuilderQueriesContext
  )
export const useWorkflowBuilderQueries = () => {
  return useContext(WorkflowBuilderQueriesContext)
}
