import { WorkflowState } from 'components/molecules/workflow/WorkflowBuilder'
import { Node } from 'reactflow'

export function serializeWFTrueFalse(
  node: Node,
  { edges, nodes }: WorkflowState
): Node {
  const newNode = { ...node }
  const sourceEdges = edges.filter(({ source }) => source === node.id)
  if (sourceEdges.length) {
    newNode.data = {
      ...newNode.data,
      branch_name: node.data?.branch_name || '',
      fields: sourceEdges.map((edge) => {
        if (edge.sourceHandle?.includes('true')) {
          return {
            source_handle: edge.sourceHandle,
            target: edge.target,
            isTrue: true,
            data: {
              branch_name: node?.data?.branch_name || '',
              listCondition: node?.data?.listCondition || undefined,
              events_conditions: node?.data?.events_conditions || undefined,
              email_conditions: node?.data?.email_conditions || undefined
            }
          }
        }

        return {
          source_handle: edge.sourceHandle,
          target: edge.target,
          isTrue: false
        }
      })
    }
  }

  return newNode
}
