/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  NotificationContext,
  NotificationItem,
  calculateFromTo
} from './helpers'
import { BellOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Button, Select, Spin, notification } from 'antd'
import cls from 'classnames'
import { Box } from 'components/atoms/box'
import { StyledDrawer } from 'components/atoms/drawer/DrawerPreviewTemplate/DrawerPreviewTemplate.styled'
import { PanelTabs } from 'components/molecules/templateBuilder'
import { ENV } from 'constants/env'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import useNotifySoundEffect from 'hooks/useNotifySoundEffect'
import { Close, NotificationColor, SettingOutlined } from 'icons'
import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useNavigate } from 'react-router-dom'
import { NotificationServices } from 'services/notification'
import { axiosConfigOlympus } from 'utils/api'

dayjs.extend(relativeTime)

const defaultValue: NotificationContext = {
  notifications: [],
  isShow: false,
  countNewNoti: 0,
  setIsShow: () => null
}

export const NotificationsContext =
  createContext<NotificationContext>(defaultValue)

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate()
  const [isShow, setIsShow] = useState(false)
  const [listNoti, setListNoti] = useState<NotificationItem[]>([])
  const [nextPage, setNextPage] = useState('')
  const [loading, setLoading] = useState(false)
  const [filterDate, setFilterDate] = useState('this-month')
  const [hashLoading, setHashLoading] = useState<Record<string, boolean>>({})

  const { ting } = useNotifySoundEffect()

  useEffect(() => {
    const source = new window.EventSourcePolyfill(
      `${ENV.baseCopilotApi}api/notification/sse`,
      axiosConfigOlympus({})
    )
    // console.log('source', source)
    source.onmessage = (event: any) => {
      try {
        console.log('OnMessage Called:', event)
        const data = JSON.parse(event.data)

        if (data.topic === 'approve-flow' && data.data.notification_id) {
          ting()
          const newNoti: NotificationItem = {
            id: data.data.notification_id,
            title: data.title,
            body: data.body,
            status: 'new',
            time: parseInt(String(new Date().getTime() / 1000)),
            data: data.data,
            link: data.data.link || ''
          }
          setListNoti((prev) => [newNoti, ...prev])
        }
      } catch (err) {
        //
      }
    }

    return () => {
      source.close()
    }
  }, [ting])

  useEffect(() => {
    setLoading(true)
    NotificationServices.messages({
      ...calculateFromTo(filterDate)
    })
      .then((res) => {
        setListNoti(res.data.data || [])
        setNextPage(res.data.next || '')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [filterDate])

  const markAsReadItem = useCallback((notiItem: NotificationItem) => {
    if (notiItem.status === 'read') return

    NotificationServices.markAsRead([notiItem.id])
    setListNoti((prev) =>
      prev.map((currentItem) => {
        if (currentItem.id === notiItem.id) {
          return {
            ...notiItem,
            status: 'read'
          }
        }
        return currentItem
      })
    )
  }, [])

  const markAllAsRead = useCallback(() => {
    const newIds = listNoti.filter((i) => i.status === 'new').map((i) => i.id)
    if (!newIds.length) return
    NotificationServices.markAsRead(newIds)
    setListNoti((prev) =>
      prev.map((currentItem) => ({
        ...currentItem,
        status: 'read'
      }))
    )
  }, [listNoti])

  const navigateToSetting = useCallback(() => {
    setIsShow(false)
    navigate('/setting/notifications')
  }, [navigate])

  const navigateToNotiLink = useCallback(
    (notiItem: NotificationItem) => {
      if (notiItem.link) {
        if (notiItem.link.startsWith(window.location.origin)) {
          navigate(notiItem.link.replace(window.location.origin, ''))
        } else {
          window.location.href = notiItem.link
        }
        setIsShow(false)
      }
    },
    [navigate]
  )

  const handleClickNotiItem = useCallback(
    (notiItem: NotificationItem) => {
      markAsReadItem(notiItem)
      navigateToNotiLink(notiItem)
    },
    [markAsReadItem, navigateToNotiLink]
  )

  const handleApproveCampaign = useCallback(
    (action: string, notiItem: NotificationItem) => {
      if (notiItem.data?.flow_type === 'approve_campaign') {
        setHashLoading((prev) => ({ ...prev, [notiItem.id]: true }))
        NotificationServices.approveFlow(action, notiItem)
          .then((res) => {
            if (res.data.status === 'failed') {
              throw new Error()
            }
            setListNoti((prev) =>
              prev.map((item) => {
                if (item.id === notiItem.id) {
                  return {
                    ...item,
                    status: 'read',
                    data: undefined
                  }
                }
                return item
              })
            )
          })
          .catch(() => {
            notification.error({
              message: 'Something went wrong. Please try again!'
            })
          })
          .finally(() => {
            setHashLoading((prev) => ({ ...prev, [notiItem.id]: false }))
          })
      }
    },
    []
  )

  const value = useMemo(() => {
    return {
      notifications: listNoti,
      isShow,
      setIsShow,
      countNewNoti: listNoti.filter((noti) => noti.status === 'new').length
    }
  }, [isShow, setIsShow, listNoti])

  return (
    <NotificationsContext.Provider value={value}>
      {children}
      <StyledDrawer
        width={520}
        closable={false}
        placement="right"
        onClose={() => setIsShow(false)}
        open={isShow}
        closeIcon={null}
        maskClosable={false}
        headerStyle={{ borderBottom: '0', padding: '16px 24px 8px 24px' }}
        title={
          <Box className="flex justify-between items-center">
            <p className="m-0 text-[#262626] font-bold text-[18px] leading-[28px]">
              Notification
            </p>
            <Close
              onClick={() => setIsShow(false)}
              className="text-[#595959] w-[18px] h-[18px] cursor-pointer"
            />
          </Box>
        }>
        <Box className="px-2">
          <PanelTabs
            listTabs={[
              // {
              //   key: 'product-updates',
              //   label: 'Product Updates'
              // },
              {
                key: 'activities',
                label: 'Activities'
                // disabled: true
              }
            ]}
            activeTab="activities"
            onChangeTab={console.log}
            className="w-full"
          />
          <Box className="py-[16px] flex justify-between items-center">
            <Select
              options={[
                { value: 'today', label: 'Today' },
                { value: 'yesterday', label: 'Yesterday' },
                { value: 'this-week', label: 'This Week' },
                { value: 'this-month', label: 'This Month' }
              ]}
              value={filterDate}
              onChange={setFilterDate}
              size="small"
              dropdownStyle={{ minWidth: '120px' }}
            />
            <Button size="small" onClick={markAllAsRead} className="ml-auto">
              Mark all as read
            </Button>
            <Button
              icon={<SettingOutlined />}
              onClick={navigateToSetting}
              className="w-[36px] h-[36px] ml-2"
            />
          </Box>
          {!loading && listNoti.length === 0 && (
            <Box className="py-[40px] text-center flex flex-col items-center gap-[16px]">
              <NotificationColor />
              <p className="m-0 text-[#8C8C8C] text-[14px] leading-[22px]">
                No notification
              </p>
            </Box>
          )}
          <Box>
            {listNoti.map((notiItem) => {
              return (
                <Box
                  key={notiItem.id}
                  onClick={() => handleClickNotiItem(notiItem)}
                  className="py-3 gap-4 flex items-start border-0 border-solid border-[#E4E8EC] border-b cursor-pointer">
                  <Box
                    className={cls(
                      'w-[48px] h-[48px] min-w-[48px] rounded-full text-center leading-[48px]',
                      {
                        'bg-[#F7F9FC]': notiItem.status !== 'new',
                        'bg-[#E3F2FF]': notiItem.status === 'new'
                      }
                    )}>
                    <BellOutlined
                      className={cls({
                        'text-[#595959]': notiItem.status !== 'new',
                        'text-[#2D87F3]': notiItem.status === 'new'
                      })}
                    />
                  </Box>
                  <Box>
                    <div
                      className="text-[#262626] text-sm m-0"
                      dangerouslySetInnerHTML={{ __html: notiItem.body }}
                    />
                    <p
                      className={cls('m-0 text-xs mt-2', {
                        'text-[#737373]': notiItem.status !== 'new',
                        'text-[#2D87F3]': notiItem.status === 'new'
                      })}>
                      {dayjs(notiItem.time * 1000).fromNow()}
                    </p>

                    {notiItem.data?.flow_type === 'approve_campaign' &&
                      notiItem.data?.status !== 'approve' &&
                      notiItem.data?.status !== 'reject' && (
                        <Box className="flex gap-2 mt-2">
                          <Button
                            size="small"
                            icon={<CloseOutlined className="text-[12px]" />}
                            onClick={(evt) => {
                              evt.stopPropagation()
                              handleApproveCampaign('reject', notiItem)
                            }}
                            loading={hashLoading[notiItem.id]}
                            className="h-[32px] px-3 py-1 border-0 bg-[#FAE9E8] hover:bg-[#FAE9E8] focus:bg-[#FAE9E8] text-[#E64728] hover:text-[#E64728] focus:text-[#E64728]">
                            Reject
                          </Button>
                          <Button
                            size="small"
                            icon={<CheckOutlined className="text-[12px]" />}
                            onClick={(evt) => {
                              evt.stopPropagation()
                              handleApproveCampaign('approve', notiItem)
                            }}
                            loading={hashLoading[notiItem.id]}
                            className="h-[32px] px-3 py-1 border-0 bg-[#E3F2FF] hover:bg-[#E3F2FF] focus:bg-[#E3F2FF] text-[#2D87F3] hover:text-[#2D87F3] focus:text-[#2D87F3]">
                            Approve
                          </Button>
                        </Box>
                      )}
                  </Box>
                </Box>
              )
            })}
          </Box>
          {loading && (
            <Box className="text-center py-3">
              <Spin />
            </Box>
          )}
        </Box>
      </StyledDrawer>
    </NotificationsContext.Provider>
  )
}
