import { ModalEmpty } from 'components/atoms/modal'
import { menuRoutes } from 'components/molecules'
import { SEGMENT_TYPE } from 'constants/segment'
import { Close, SegmentAttribute, SegmentEvent } from 'icons'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

const ModalStyled = styled(ModalEmpty)`
  .ant-modal-body {
    .ant-form-item-label {
      padding-bottom: 6px;
    }
  }

  .ant-modal-footer {
    .ant-btn {
      padding-right: 24px;
      padding-left: 24px;
      border-radius: ${(props) => props.theme?.borderRadiusBase};
    }
  }
`
const StyleSegmentItem = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;
  cursor: pointer;

  &.segment-attribute {
    background: #fff8e2;
  }

  &.segment-event {
    background: #e3f2ff;
  }
`
const StyleSegmentTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
`

const StyleSegmentDes = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: #595959;
  margin: 0;
`

const StyleSegmentIcon = styled.div`
  margin-right: 15px;
`

interface ISegmentTypeModal {
  visible: boolean
  onCancel?: () => void
}
export const SegmentTypeModal: FC<ISegmentTypeModal> = ({
  visible,
  onCancel
}) => {
  const navigate = useNavigate()
  const handleOnCancel = () => {
    onCancel && onCancel()
  }
  const handleCreateSegment = (type: string) => {
    if (type === 'event') {
      navigate(`${menuRoutes.segment}/create/${SEGMENT_TYPE.EVENT}`)
      // navigate(`${menuRoutes.segment}/new-custom-events-segment`)
      return
    }
    navigate(`${menuRoutes.segment}/create`)
  }
  return (
    <ModalStyled
      open={visible}
      closable={true}
      closeIcon={
        <span>
          <Close onClick={handleOnCancel} />
        </span>
      }
      title="Choose segment"
      footer={null}
      width={500}>
      <StyleSegmentItem
        className="segment-attribute"
        onClick={() => handleCreateSegment('attribute')}>
        <StyleSegmentIcon>
          <SegmentAttribute />
        </StyleSegmentIcon>
        <div>
          <StyleSegmentTitle>Segment by User Attributes</StyleSegmentTitle>
          <StyleSegmentDes>
            Collect and group user profile attributes to create segments for
            marketing campaigns and target users based on those attributes.
          </StyleSegmentDes>
        </div>
      </StyleSegmentItem>
      <StyleSegmentItem
        className="segment-event"
        onClick={() => handleCreateSegment('event')}>
        <StyleSegmentIcon>
          <SegmentEvent />
        </StyleSegmentIcon>
        <div>
          <StyleSegmentTitle>Segment by Custom Events</StyleSegmentTitle>
          <StyleSegmentDes>
            Event based segmentation essentially allows you to create
            sophisticated audience segments using a combination of filters based
            on user event performance.
          </StyleSegmentDes>
        </div>
      </StyleSegmentItem>
    </ModalStyled>
  )
}
