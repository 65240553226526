import Icon from '@ant-design/icons'
import { Button, ButtonProps, SelectProps } from 'antd'
import cn from 'classnames'
import { DatePicker } from 'components/atoms/datePicker'
import dayjs, { Dayjs } from 'dayjs'
import { Minus } from 'icons'
import { ReactNode, Ref, forwardRef, useEffect, useState } from 'react'
import styled from 'styled-components'

const TIME_WINDOW_FORM_TIME = 'HH:mm'
const StyledPanelDropdown = styled.div`
  .ant-picker-panel {
    min-width: 167px;
  }
`
export type FormWorkflowTimeRangePickerState = {
  from?: Dayjs | null
  to?: Dayjs | null
} | null
export type FormWorkflowTimeRangePickerValue = {
  from: string
  to: string
} | null
export type FormWorkflowTimeRangePickerRef = HTMLDivElement
export type FormWorkflowTimeRangePickerProps = Pick<SelectProps, 'size'> & {
  defaultValue?: FormWorkflowTimeRangePickerValue
  value?: FormWorkflowTimeRangePickerValue
  onChange?: (value: FormWorkflowTimeRangePickerValue) => void
  onRemove?: ButtonProps['onClick']
}

const FormWorkflowTimeRangePickerWithoutRef = (
  props: FormWorkflowTimeRangePickerProps,
  ref?: Ref<FormWorkflowTimeRangePickerRef>
) => {
  const { size, defaultValue, value: valueProps, onChange, onRemove } = props
  const [value, setValue] = useState<FormWorkflowTimeRangePickerState>(
    formatFormWorkflowTimeRange(defaultValue || valueProps || null)
  )

  const handleChange = (key: 'from' | 'to') => (val: any) => {
    const newValue: FormWorkflowTimeRangePickerState = { ...value, [key]: val }
    if (key === 'from') {
      newValue.to = null
    }
    setValue(newValue)
    if (newValue?.from?.isValid() && newValue?.to?.isValid()) {
      onChange?.({
        from: newValue.from.format(TIME_WINDOW_FORM_TIME),
        to: newValue.to.format(TIME_WINDOW_FORM_TIME)
      })
    }
  }

  const panelRender = (origin: ReactNode) => {
    return <StyledPanelDropdown>{origin}</StyledPanelDropdown>
  }

  useEffect(() => {
    setValue(formatFormWorkflowTimeRange(valueProps || null) || value)
  }, [valueProps])

  return (
    <div ref={ref} className="flex items-center gap-2 w-full">
      <DatePicker.TimePicker
        showSecond={false}
        format={TIME_WINDOW_FORM_TIME}
        size={size}
        placeholder="From"
        value={value?.from}
        onChange={handleChange('from')}
        panelRender={panelRender}
        getPopupContainer={(html) => html}
        className={cn({
          'min-w-[160px]': !!onRemove,
          'flex-1 min-w-0': !onRemove
        })}
      />
      <span>to</span>
      <DatePicker.TimePicker
        showSecond={false}
        format={TIME_WINDOW_FORM_TIME}
        size={size}
        placeholder="to"
        disabledTime={() => {
          if (value?.from?.isValid()) {
            const hourFrom = value.from.get('hour')
            const minuteFrom = value.from.get('minute')

            return {
              disabledHours: () => {
                const hours = []
                for (let i = 0; i < hourFrom; i++) {
                  hours.push(i)
                }
                return hours
              },
              disabledMinutes(hourTo) {
                if (hourFrom === hourTo) {
                  const minutes = []
                  for (let i = 0; i <= minuteFrom; i++) {
                    minutes.push(i)
                  }
                  return minutes
                }
                return []
              }
            }
          }
          return {}
        }}
        value={value?.to}
        onChange={handleChange('to')}
        panelRender={panelRender}
        getPopupContainer={(html) => html}
        className={cn({
          'min-w-[160px]': !!onRemove,
          'flex-1 min-w-0': !onRemove
        })}
      />

      {!!onRemove && (
        <Button
          icon={<Icon component={Minus} />}
          size="small"
          shape="circle"
          onClick={onRemove}
        />
      )}
    </div>
  )
}
export const FormWorkflowTimeRangePicker = forwardRef(
  FormWorkflowTimeRangePickerWithoutRef
)

function formatFormWorkflowTimeRange(
  value: FormWorkflowTimeRangePickerValue
): FormWorkflowTimeRangePickerState {
  if (!value || (!value.from && !value.to)) {
    return null
  }

  const newValue: FormWorkflowTimeRangePickerState = { from: null, to: null }

  const fromDayjs = dayjs(value.from, TIME_WINDOW_FORM_TIME, true)
  const toDayjs = dayjs(value.to, TIME_WINDOW_FORM_TIME, true)
  if (fromDayjs.isValid() && toDayjs.isValid()) {
    return { from: fromDayjs, to: toDayjs }
  }

  return newValue
}
