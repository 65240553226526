import { useWorkflowBuilder } from '../index.context'
import Icon from '@ant-design/icons'
import { Button, ButtonProps, Drawer, DrawerProps, Space } from 'antd'
import { Close, TrashBin } from 'icons'
import { useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'

export const WFDrawerFooter = styled(Space)`
  width: 100%;
  justify-content: flex-end;
`

export const WFDrawer = styled(Drawer)`
  .ant-drawer-header {
    padding: 16px;
    border-bottom: 1px solid ${({ theme }) => theme?.colors?.neutral100};
    .ant-drawer-header-title {
      flex-direction: row-reverse;
      .ant-drawer-title {
        font-size: 16px;
        font-weight: 600;
      }
      .ant-drawer-close {
        padding: 0;
        margin: 0;
        margin-left: 8px;
        width: 24px;
        height: 24px;
        font-size: 24px;
        line-height: 1;
      }
    }
  }
  .ant-drawer-body {
    position: relative;
    padding: 20px 16px;
  }
  .ant-drawer-footer {
    padding: 12px 16px;
  }

  &.disableBodyScroll {
    .ant-drawer-body {
      overflow: hidden;
      flex: 1;
      min-height: 0;
      padding: 0;
    }
  }
`

export type WorkflowBuilderDrawerPropsTemplate = DrawerProps & {
  cancelText?: string
  cancelProps?: ButtonProps
  updateText?: string
  updateProps?: ButtonProps
  onChangeTitle?: (title?: string) => void
  onDelete?: () => void
  onCancel?: () => void
  onUpdate?: () => void
}

export function WorkflowBuilderDrawerTemplateRef(
  props: WorkflowBuilderDrawerPropsTemplate
) {
  const { disabled } = useWorkflowBuilder()
  const {
    children,
    width = 396,
    destroyOnClose,
    closeIcon = <Icon component={Close} />,
    title,
    footer,
    getContainer: getContainerProps,
    cancelText = 'Cancel',
    cancelProps,
    updateText = 'Update',
    updateProps,
    onChangeTitle,
    onDelete,
    onCancel,
    onUpdate,
    ...restProps
  } = props

  const getContainer = useCallback(() => {
    if (typeof getContainerProps === 'boolean') {
      return getContainerProps
    }

    if (typeof getContainerProps === 'function') {
      return getContainerProps?.()
    }

    return (document.getElementById('workflow') as HTMLElement) || false
  }, [getContainerProps])

  const titleComp = useMemo(() => {
    return title || <></>
  }, [title])
  const footerComp = useMemo(() => {
    if (typeof footer === 'undefined') {
      return (
        <WFDrawerFooter size={8}>
          {Boolean(onDelete) && (
            <Button
              onClick={onDelete}
              icon={<Icon component={TrashBin} />}
              size="small"
            />
          )}
          <Button
            onClick={onCancel}
            size="small"
            type="default"
            {...cancelProps}>
            {cancelText}
          </Button>
          <Button
            onClick={onUpdate}
            size="small"
            type="primary"
            disabled={disabled}
            {...updateProps}>
            {updateText}
          </Button>
        </WFDrawerFooter>
      )
    }
    return footer
  }, [cancelText, footer, onCancel, onDelete, onUpdate, updateText])

  useEffect(() => {
    return
  }, [])

  return (
    <WFDrawer
      {...restProps}
      title={titleComp}
      width={width}
      footer={footerComp}
      closeIcon={closeIcon}
      destroyOnClose={destroyOnClose}
      getContainer={getContainer as any}>
      {children}
    </WFDrawer>
  )
}

export const WorkflowBuilderDrawerTemplate = WorkflowBuilderDrawerTemplateRef
