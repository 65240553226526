import {
  CheckboxOptionType,
  Form,
  FormListFieldData,
  FormListOperation,
  Select
} from 'antd'
import { ConditionBase } from 'components/condition/base/'
import { ConditionDataField } from 'components/condition/base/conditionOperator'
import { ConditionRangeDate } from 'components/condition/base/conditionRangeDate'
import { operationDataType } from 'components/condition/base/index.helpers'
import { getFormNamePath } from 'components/condition/conditions/form/index.helpers'
import { OptionType } from 'interfaces'
import {
  ConditionDataType,
  ConditionValueOperator,
  OperatorKeys,
  operatorLabel,
  operatorValue,
  optionsConditionBoolean
} from 'interfaces/condition'
import { FC, Fragment, useMemo } from 'react'

interface AttributeFieldsProps {
  attributeFields: ConditionDataType[]
  formListFieldData: FormListFieldData
  formListOperation: FormListOperation
}

const AttributeFields: FC<AttributeFieldsProps> = ({
  attributeFields,
  formListFieldData: { name }
}) => {
  const form = Form.useFormInstance()
  const fieldAttribute = Form.useWatch(
    getFormNamePath('conditions', [name, 'attribute']),
    form
  )
  const fieldOperator = Form.useWatch(
    getFormNamePath('conditions', [name, 'operation']),
    form
  )
  const selectedCondition = useMemo(() => {
    const condition = attributeFields?.find(({ id }) => id === fieldAttribute)
    return condition || null
  }, [attributeFields, fieldAttribute])

  const optionsOperation = useMemo(() => {
    const options = [] as CheckboxOptionType[]
    if (!selectedCondition) {
      return []
    }

    const operationKey = operationDataType[
      selectedCondition.dataType as keyof typeof ConditionDataField
    ] as string[]

    Object.keys(operatorLabel).forEach((k) => {
      const key = k as OperatorKeys
      if (operationKey.includes(key)) {
        options.push({
          value: operatorValue[key],
          label: operatorLabel[key]
        })
      }
    })
    return options
  }, [selectedCondition])

  const attributeOptions = useMemo<OptionType[]>(
    () =>
      attributeFields?.map((attr: any) => ({
        label: attr?.name,
        value: attr?.id
      })),
    [attributeFields]
  )

  const onChangeAttributeKey = (val: string) => {
    const resultField = attributeFields.find((field) => field.id === val)
    if (resultField) {
      let resetFieldValue = null
      if (resultField.dataType === 'API' && resultField.type === 'MANY') {
        resetFieldValue = []
      }
      form.setFieldValue(
        getFormNamePath('conditions', [name, 'value']),
        resetFieldValue
      )
      form.setFieldValue(
        getFormNamePath('conditions', [name, 'operation']),
        null
      )
    }
  }

  const RenderConditionFieldComponent = useMemo(() => {
    if (!selectedCondition) {
      return Fragment
    }
    const moreProps = {} as any
    if (selectedCondition.dataType === 'BOOLEAN') {
      moreProps.options = optionsConditionBoolean
    }
    if (
      selectedCondition.dataType === 'DATE' &&
      fieldOperator === ConditionValueOperator.BETWEEN
    ) {
      return (
        <Form.Item
          name={[name, 'value']}
          className="item-field"
          rules={[
            {
              required: true,
              message: 'Value is required'
            }
          ]}>
          <ConditionRangeDate size="small" />
        </Form.Item>
      )
    }
    if (
      selectedCondition.dataType === 'NUMBER' &&
      fieldOperator === ConditionValueOperator.BETWEEN
    ) {
      return (
        <ConditionBase.ConditionNumberRange
          itemName={name}
          rootName="attributes"
          className="condition-field"
          size="small"
        />
      )
    }
    const ConditionComponent =
      ConditionDataField[
        selectedCondition.dataType as keyof typeof ConditionDataField
      ]
    return (
      <Form.Item
        name={[name, 'value']}
        className="item-field"
        rules={[
          {
            required: true,
            message: 'Value is required'
          }
        ]}>
        <ConditionComponent
          pathUrl={selectedCondition.value[0] || ''}
          placeholder="Value"
          type={selectedCondition.type}
          size="small"
          site="hellobacsi.com"
          {...moreProps}
        />
      </Form.Item>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCondition, fieldOperator])

  return (
    <>
      <Form.Item name={[name, 'attribute']} required>
        <Select
          options={attributeOptions}
          onChange={onChangeAttributeKey}
          size="small"
          showSearch
          optionFilterProp="label"
          placeholder="Field"
        />
      </Form.Item>
      {fieldAttribute && (
        <>
          <Form.Item
            label=""
            className="item-field"
            name={[name, 'operation']}
            rules={[
              {
                required: true,
                message: 'Please choose operator'
              }
            ]}>
            <Select
              options={optionsOperation}
              placeholder="Operator"
              size="small"
            />
          </Form.Item>
          {RenderConditionFieldComponent}
        </>
      )}
    </>
  )
}

export default AttributeFields
