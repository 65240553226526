import {
  StyledButton,
  StyledDropdown,
  StyledIcon,
  StyledListSearch,
  StyledOverlay,
  StyledTabs,
  StyledTabsLabel
} from './index.styled'
import cn from 'classnames'
import { ListSearchProps } from 'components/atoms/list/listSearch'
import { Plus } from 'icons'
import { OptionType } from 'interfaces'
import { useCallback, useEffect, useMemo, useState } from 'react'

export type ConditionDropdownTabsProps = {
  open?: boolean
  onOpenChange?: (open: boolean) => void
  options?: OptionType[]
  onClick?: ListSearchProps['onClick']
}

export const ConditionDropdownTabs = (props: ConditionDropdownTabsProps) => {
  const {
    options,
    onClick: onClickProps,
    open: openProps,
    onOpenChange: onOpenChangeProps
  } = props
  const [open, setOpen] = useState(openProps)
  const [activeKey, setActiveKey] = useState(
    options?.[0].value.toString() || undefined
  )

  const onOpenChange = useCallback(
    (open: boolean) => {
      setOpen(open)
      onOpenChangeProps && onOpenChangeProps(open)
    },
    [onOpenChangeProps]
  )

  const onClick = useCallback(
    (
      item: OptionType,
      index?: number | undefined,
      event?: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined
    ) => {
      onOpenChange(false)
      onClickProps && onClickProps(item, index, event)
    },
    [onClickProps, onOpenChange]
  )

  const items = useMemo(() => {
    if (!options) {
      return
    }
    return options
      .map((item) => {
        const label = (
          <StyledTabsLabel>
            {item.icon && (
              <StyledIcon component={item.icon} className="tabs--icon" />
            )}
            {item.label}
          </StyledTabsLabel>
        )

        return {
          key: item.value.toString(),
          label,
          children: (
            <StyledListSearch
              placeholder="Search"
              dataSource={item.children}
              onClick={onClick}
              size="default"
            />
          )
        }
      })
      .filter(Boolean)
  }, [options, onClick])

  useEffect(() => {
    setOpen(openProps)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openProps])

  useEffect(() => {
    if (open) {
      setActiveKey(options?.[0].value.toString() || undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <StyledDropdown
      open={open}
      onOpenChange={onOpenChange}
      trigger={['click']}
      destroyPopupOnHide
      overlay={
        <StyledOverlay>
          <StyledTabs
            activeKey={activeKey}
            onChange={setActiveKey}
            items={items}
            destroyInactiveTabPane
          />
        </StyledOverlay>
      }>
      <StyledButton
        icon={<StyledIcon component={Plus} />}
        type="ghost"
        className={cn(open && 'dropdownTab--active')}>
        Add new
      </StyledButton>
    </StyledDropdown>
  )
}
