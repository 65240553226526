import {
  useWorkflowBuilder,
  useWorkflowBuilderQueries
} from '../../index.context'
import { WorkflowBuilderDragHandle } from '../WorkflowBuilderDragHandle'
import { WorkflowBuilderDrawerTemplate } from '../WorkflowBuilderDrawerTemplate'
import { WorkflowBuilderHandle } from '../WorkflowBuilderHandle'
import { WorkflowBuilderNodeTemplate } from '../WorkflowBuilderNodeTemplate'
import { WFNode, useNode } from '../index.helpers'
import { WFNodeContainer, WFNodeWrapper } from '../index.styled'
import Icon from '@ant-design/icons'
import { Empty, FormInstance, Popover } from 'antd'
import {
  FormWorkflowWaitUntil,
  FormWorkflowWaitUntilRef
} from 'components/molecules/form/FormWorkflow'
import { useApp } from 'context/App'
import { Event, EventTime, MaxTime } from 'icons/V2'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Edge, Handle, Node, Position, useUpdateNodeInternals } from 'reactflow'
import { EventServices } from 'services/event'
import styled from 'styled-components'

const WFWaitUntil: WFNode = (props) => {
  const { id, title, bgColor, color, icon, modalWidth, data, selected } = props
  const form = useRef<FormWorkflowWaitUntilRef>(null)
  const { sites } = useApp()
  const UpdateNodeInternals = useUpdateNodeInternals()
  const { site, nodes, setNodes, setEdges, onNodesDelete, onNodesChangeData } =
    useWorkflowBuilder()
  const { open, onOpen, onClose, onCancel, onUpdate } = useNode({
    id,
    form: form.current as FormInstance<any>,
    nodes,
    setNodes,
    onNodesDelete,
    onNodesChangeData
  })
  const { segmentSelected: segment } = useWorkflowBuilderQueries()
  const [event, setEvent] = useState<any>({})

  const initialValues = useMemo(() => {
    return { ...data }
  }, [data])

  const nodeStart = useMemo(() => {
    return nodes.find(({ type }) => type === 'STARTING_POINT')
  }, [nodes])
  const segment_id = useMemo(() => {
    return nodeStart?.data?.id
  }, [nodeStart])
  const segment_type = useMemo(() => {
    return nodeStart?.data?.type
  }, [nodeStart])

  const descriptionComp = useMemo(() => {
    const notHaveData = !(
      'event' in data ||
      'event_time' in data ||
      'max_time' in data
    )
    return (
      <span>
        Wait until{' '}
        <Popover
          title={null}
          placement="top"
          getPopupContainer={(node) => node}
          content={
            <div className="w-[334px] p-3">
              <h4 className="text-gray800 text-sm font-semibold mb-1">
                Wait Conditions
              </h4>
              {notHaveData ? (
                <Empty imageStyle={{ height: 50 }} className="p-4" />
              ) : (
                <p className="mb-1">Wait until people:</p>
              )}
              {'event' in data && (
                <div className="rounded-lg bg-neutral50 p-2 mb-1">
                  <strong>{event?.name}</strong> the event{' '}
                  {data?.event?.conditions?.map((cond: any, index: number) => (
                    <span
                      key={`${index}`}
                      className="inline-block text-red900 py-1 px-2 border border-solid border-neutral100 bg-white rounded-md leading-none">
                      {cond.field}
                    </span>
                  ))}
                </div>
              )}
              {'event_time' in data && (
                <>
                  <p className="text-gray500 mb-1">or</p>
                  <p className="mb-1">
                    the time specified in{' '}
                    <span className="inline-block text-red900 py-1 px-2 border border-solid border-neutral100 bg-white rounded-md leading-none">
                      <Icon component={EventTime} />
                      {data.event_time.field}
                    </span>
                  </p>
                </>
              )}
              {'max_time' in data && (
                <>
                  <p className="text-gray500 mb-1">or</p>
                  <p className="mb-0">
                    wait a maximum of{' '}
                    <strong>
                      {data.max_time.time} {data.max_time.max_time_type}
                    </strong>{' '}
                    then {data.max_time.actions}
                  </p>
                </>
              )}
            </div>
          }>
          <StyledWaitUntilPopover>conditions are met</StyledWaitUntilPopover>
        </Popover>
      </span>
    )
  }, [data, event?.name])

  const siteOption = useMemo(() => {
    return sites.find(({ value }) => value === site)
  }, [site, sites])

  const onUpdateDrawer = async (values: any) => {
    const node = nodes.find((node) => node.id === id)
    if (!node) {
      return
    }
    node.data = values

    onUpdate(node)
    setEdges?.((edges) => {
      const newEdges = edges.filter(({ sourceHandle }) => {
        const isEvent = sourceHandle === `${id}-event` && !('event' in values)
        const isEventTime =
          sourceHandle === `${id}-event_time` && !('event_time' in values)
        const isMaxTime =
          sourceHandle === `${id}-max_time` && !('max_time' in values)
        if (isEvent || isEventTime || isMaxTime) {
          return false
        }

        return true
      })
      return newEdges
    })
    UpdateNodeInternals(id)
    onClose({ isReset: false })
  }

  useEffect(() => {
    if (!data?.event?.event_id || data?.event?.event_id === event?.id) {
      return
    }
    EventServices.getById(data?.event?.event_id).then((res) => {
      if (res?.data) {
        setEvent(res?.data)
      }
    })
  }, [data?.event?.event_id, event?.id])

  return (
    <>
      <div className="relative w-[350px] flex flex-col items-center">
        <WFNodeContainer
          selected={selected}
          color={color}
          className="!max-w-[250px]">
          <WorkflowBuilderDragHandle color={color} selected={selected} />
          <WFNodeWrapper>
            <WorkflowBuilderNodeTemplate
              bgColor={bgColor}
              color={color}
              icon={icon}
              title={title}
              description={descriptionComp}
              onClick={onOpen}
              targets={
                <WorkflowBuilderHandle
                  type="target"
                  id={`${id}-target`}
                  position={Position.Top}
                />
              }
            />
          </WFNodeWrapper>
        </WFNodeContainer>
        <StyledWaitUntilSourceHandles className="flex w-full justify-between text-sm font-semibold mt-2">
          {'event' in data && (
            <div className="flex-1 min-w-0 relative handleItem-container">
              <div className="handleItem-line" />
              <Handle
                type="source"
                id={`${id}-event`}
                position={Position.Bottom}
                className="whitespace-nowrap flex items-center gap-1 bg-neutral50 border border-solid border-neutral100 rounded-xl py-0 px-2">
                <Icon component={Event} className="text-md" />
                Condition met
              </Handle>
            </div>
          )}
          {'event_time' in data && (
            <div className="flex-1 min-w-0 relative handleItem-container">
              <div className="handleItem-line" />
              <Handle
                type="source"
                id={`${id}-event_time`}
                position={Position.Bottom}
                className="whitespace-nowrap flex items-center gap-1 bg-neutral50 border border-solid border-neutral100 rounded-xl py-0 px-2">
                <Icon component={EventTime} className="text-md" />
                Event time
              </Handle>
            </div>
          )}
          {'max_time' in data && (
            <div className="flex-1 min-w-0 relative handleItem-container">
              <div className="handleItem-line" />
              <Handle
                type="source"
                id={`${id}-max_time`}
                position={Position.Bottom}
                className="whitespace-nowrap flex items-center gap-1 bg-neutral50 border border-solid border-neutral100 rounded-xl py-0 px-2">
                <Icon component={MaxTime} className="text-md" />
                Max. time
              </Handle>
            </div>
          )}
        </StyledWaitUntilSourceHandles>
      </div>
      <WorkflowBuilderDrawerTemplate
        open={open}
        width={modalWidth}
        title={title}
        onClose={onClose}
        onCancel={onCancel}
        onUpdate={() => form.current?.submit()}
        destroyOnClose>
        <FormWorkflowWaitUntil
          ref={form}
          initialValues={initialValues}
          onFinish={onUpdateDrawer}
          event_id={
            segment_type === 'event'
              ? segment_id
              : segment?.events_conditions?.[0]?.event?.id
          }
          site_domain={siteOption?.domain}
        />
      </WorkflowBuilderDrawerTemplate>
    </>
  )
}

const StyledWaitUntilPopover = styled.strong`
  display: inline-block;
  position: relative;
  .ant-popover {
    left: 50% !important;
    top: 0 !important;
    transform: translateX(-50%) translateY(-100%) !important;
  }
  .ant-popover-inner-content {
    padding: 0;
  }
`

const StyledWaitUntilSourceHandles = styled.div`
  .handleItem-container {
    display: flex;
    flex-direction: column;
    height: 60px;
    overflow: visible;
    &:before {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      background-color: ${({ theme }) => theme?.colors?.teal600};
      border-radius: 50%;
      position: absolute;
      left: 50%;
      top: -3px;
      transform: translate(-50%);
    }
    .handleItem-line {
      flex: 1;
      min-width: 0;
      &:before {
        content: '';
        display: block;
        width: 0.5px;
        height: 50%;
        background-color: ${({ theme }) => theme?.colors?.teal600};
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
      }
      &:after {
        content: '';
        display: block;
        width: 0.5px;
        height: 50%;
        background-color: ${({ theme }) => theme?.colors?.teal600};
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%);
      }
    }
    .react-flow__handle {
      position: absolute;
      z-index: 1;
      left: 50%;
      transform: translateX(-50%);
      width: unset;
      height: unset;
    }
    &:first-child {
      &:before {
        left: 75%;
      }
      .handleItem-line {
        &:before {
          width: 50%;
          height: 25%;
          left: 25%;
          transform: translateX(0.5px);
          background-color: transparent;
          border-bottom: 0.5px solid;
          border-right: 0.5px solid;
          border-color: ${({ theme }) => theme?.colors?.teal600};
          border-bottom-right-radius: 6px;
        }
        &:after {
          width: 30%;
          height: 75%;
          top: 25%;
          left: 0;
          transform: translateY(-1px);
          background-color: transparent;
          border-top: 0.5px solid;
          border-left: 0.5px solid;
          border-color: ${({ theme }) => theme?.colors?.teal600};
          border-top-left-radius: 6px;
        }
      }
      .react-flow__handle {
        left: 0;
        transform: translateX(-50%);
      }
    }
    &:last-child {
      &:before {
        left: 25%;
      }
      &:first-child {
        &:before {
          left: 50%;
        }
        .handleItem-line {
          flex: 1;
          min-width: 0;
          &:before {
            content: '';
            display: block;
            width: 0.5px;
            height: 50%;
            background-color: ${({ theme }) => theme?.colors?.teal600};
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
          }
          &:after {
            content: '';
            display: block;
            width: 0.5px;
            height: 50%;
            background-color: ${({ theme }) => theme?.colors?.teal600};
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%);
          }
        }
        .react-flow__handle {
          position: absolute;
          z-index: 1;
          left: 50%;
          transform: translateX(-50%);
          width: unset;
          height: unset;
        }
      }
      .handleItem-line {
        &:before {
          width: 50%;
          height: 25%;
          left: 25%;
          transform: translateX(-0.5px);
          background-color: transparent;
          border-bottom: 0.5px solid;
          border-left: 0.5px solid;
          border-color: ${({ theme }) => theme?.colors?.teal600};
          border-bottom-left-radius: 6px;
        }
        &:after {
          width: 50%;
          top: 25%;
          left: 50%;
          transform: translateY(-1px);
          background-color: transparent;
          border-top: 0.5px solid;
          border-right: 0.5px solid;
          border-color: ${({ theme }) => theme?.colors?.teal600};
          border-top-right-radius: 6px;
        }
      }
      .react-flow__handle {
        left: 100%;
        transform: translateX(-50%);
      }
    }
  }
`

export function formatConnectWaitUntilData(
  node: Node,
  nodes: Node[],
  edges: Edge[]
): Node {
  if (!nodes?.length || !edges?.length) {
    return node
  }

  const newNode = { ...node }
  // Reset value target
  delete node.data?.event?.node_id
  delete node.data?.event?.template_id
  delete node.data?.event_time?.node_id
  delete node.data?.event_time?.template_id
  delete node.data?.max_time?.node_id
  delete node.data?.max_time?.template_id

  const edgesSource = edges.filter(({ source }) => source === newNode.id)

  if (!edgesSource.length) {
    if (newNode.data?.max_time) {
      newNode.data.max_time = { ...newNode.data.max_time, actions: undefined }
    }
    return newNode
  }

  edgesSource.forEach((edge) => {
    const targetNode = nodes.find(({ id }) => id === edge.target)
    const waitUntilTarget = {
      node_id: targetNode?.id,
      template_id: targetNode?.data?.template_id
    }
    const edgeType = edge.sourceHandle?.split('-')
      ? edge.sourceHandle?.split('-')[edge.sourceHandle?.split('-').length - 1]
      : ''

    if (edgeType === 'event_time' && newNode.data?.event_time) {
      newNode.data.event_time = {
        ...newNode.data?.event_time,
        ...waitUntilTarget
      }
    }
    if (edgeType === 'event' && newNode.data?.event) {
      newNode.data.event = { ...newNode.data?.event, ...waitUntilTarget }
    }
    if (edgeType === 'max_time' && newNode.data?.max_time) {
      newNode.data.max_time = {
        ...newNode.data?.max_time,
        ...waitUntilTarget,
        actions:
          targetNode?.type !== 'EXIT' ? 'move to the next action' : 'exit'
      }
    }
  })

  return newNode
}

WFWaitUntil.validate = (node) => {
  return true
}
export { WFWaitUntil }
