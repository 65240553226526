import { FormWorkflowProps, FormWorkflowRef } from '..'
import { StyledFormWorkflowContainer } from '../FormWorkflow.styled'
import { Form, Select } from 'antd'
import { OptionProps } from 'antd/lib/select'
import { SEGMENT_TYPE } from 'constants/segment'
import { OptionType } from 'interfaces'
import { Ref, forwardRef, useImperativeHandle } from 'react'
import { filterOption } from 'utils'

export type FormWorkflowStartingPointValue = {
  id?: number | string
  type: string
}
export type FormWorkflowStartingPointRef =
  FormWorkflowRef<FormWorkflowStartingPointValue>
export type FormWorkflowStartingPointProps =
  FormWorkflowProps<FormWorkflowStartingPointValue> & {
    loading?: boolean
    disabled?: boolean
    segmentOptions?: OptionProps['options']
  }

const STARTING_POINT_INITIALVALUES: FormWorkflowStartingPointValue = {
  type: SEGMENT_TYPE.ATTRIBUTE
}

const STARTING_POINT_TYPE_OPTIONS: OptionType[] = [
  { value: SEGMENT_TYPE.ATTRIBUTE, label: 'Segment by Attribute Condition' },
  { value: SEGMENT_TYPE.EVENT, label: 'Segment by Event Condition' },
  { value: SEGMENT_TYPE.CSV, label: 'Static List' },
  { value: SEGMENT_TYPE.DYNAMIC_LIST, label: 'Dynamic List' }
]
const FormWorkflowStartingPointRenderFunc = (
  props: FormWorkflowStartingPointProps,
  ref?: Ref<FormWorkflowStartingPointRef>
) => {
  const {
    initialValues = STARTING_POINT_INITIALVALUES,
    loading,
    segmentOptions,
    ...restProps
  } = props
  const [form] = Form.useForm()
  useImperativeHandle(ref, () => form, [form])
  return (
    <StyledFormWorkflowContainer>
      <Form
        {...restProps}
        form={form}
        initialValues={initialValues}
        layout="vertical">
        <Form.Item
          name="type"
          label="Segmentation Category"
          rules={[{ required: true, message: 'This is a required!' }]}
          required>
          <Select
            options={STARTING_POINT_TYPE_OPTIONS}
            placeholder="Segment category"
          />
        </Form.Item>
        <Form.Item
          name="id"
          label="Segmentation"
          shouldUpdate={(prev, cur) => prev.type !== cur.type}>
          <Select
            loading={loading}
            options={segmentOptions}
            showSearch
            filterOption={filterOption}
            placeholder="Segmentation"
          />
        </Form.Item>
      </Form>
    </StyledFormWorkflowContainer>
  )
}
export const FormWorkflowStartingPoint = forwardRef(
  FormWorkflowStartingPointRenderFunc
)
