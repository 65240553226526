import { ModalConfirmStopWorkflow } from '../../../molecules/workflow/ModalConfirmStopWorkflow'
import { WorkflowFolderCreateModal } from '../../../molecules/workflow/WorkflowFolderCreateModal'
import {
  COOKIES_WORKFLOW_TABLE,
  getWorkflowTable,
  getWorkflowTableFromQueryString,
  saveWorkflowTableToQueryString,
  transferWorkflowTableDataSource
} from './WorkflowTablePage.helpers'
import { notification } from 'antd'
import { menuRoutes } from 'components/molecules'
import {
  WORKFLOW_TABLE_TABS_KEYS,
  WorkflowTable,
  WorkflowTableState,
  initialWorkflowTableState
} from 'components/molecules/workflow'
import { useApp } from 'context/App'
import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { WorkflowServices } from 'services/workflow'

export { COOKIES_WORKFLOW_TABLE }
export type WorkflowTableProps = { type?: string }
export function WorkflowTablePage(props: WorkflowTableProps) {
  const navigate = useNavigate()
  const location = useLocation()
  const { type: typeProps = WORKFLOW_TABLE_TABS_KEYS.WORKFLOW } = props
  const [openNewFolder, setOpenNewFolder] = useState(false)
  const { sites } = useApp()

  const [state, setState] = useState<WorkflowTableState>({
    ...initialWorkflowTableState,
    type: typeProps
  })
  const [loading, setLoading] = useState(false)
  const [dataSource, setDataSource] = useState<any[]>([])
  const [stopWorkflow, setStopWorkflow] = useState<{
    id: string
    name: string
    loading?: boolean
  }>({ id: '', name: '' })

  const fetchList = useCallback(
    async (params?: WorkflowTableState) => {
      const queryParams = {
        type: state.type,
        page: state.page,
        per_page: state.per_page,
        ...params
      }
      setLoading(true)
      const { data, meta, errors } = await getWorkflowTable(queryParams)
      if (errors.length) {
        notification.error({
          message: errors.map(({ errors }) =>
            errors.map((error, index) => {
              return (
                <p key={`${index}`} className="m-0">
                  {error}
                </p>
              )
            })
          )
        })
      }
      const newState = { ...queryParams, ...meta }
      setState(newState)
      setDataSource(data)
      setLoading(false)
      const link =
        queryParams.type === WORKFLOW_TABLE_TABS_KEYS.WORKFLOW
          ? `${menuRoutes.workflow}`
          : `${menuRoutes.workflow}/folder`
      const queryParamsSaved = saveWorkflowTableToQueryString(newState)
      navigate(`${link}?${queryParamsSaved}`, { replace: false, state: true })
    },
    [navigate, state.per_page, state.page, state.type]
  )

  const onOkNewFolder = useCallback(() => {
    setOpenNewFolder(false)
  }, [])

  const onCancelNewFolder = useCallback(() => {
    setOpenNewFolder(false)
  }, [])

  const onClickButton = useCallback(
    (type) => {
      switch (type) {
        case WORKFLOW_TABLE_TABS_KEYS.FOLDER:
          setOpenNewFolder(true)
          break
        case WORKFLOW_TABLE_TABS_KEYS.WORKFLOW:
          navigate('/workflow/new-workflow')
          break
      }
    },
    [navigate]
  )

  const handleChangeState = useCallback(
    async (stateVal) => {
      if (stateVal.type !== state.type) {
        const link =
          stateVal.type === WORKFLOW_TABLE_TABS_KEYS.WORKFLOW
            ? `${menuRoutes.workflow}`
            : `${menuRoutes.workflow}/folder`
        const queryString = saveWorkflowTableToQueryString(stateVal)
        navigate(`${link}?${queryString}`)
        return
      }
      fetchList(stateVal)
    },
    [fetchList, navigate, state.type]
  )

  const onClickDropdownItem = useCallback(
    async (key: string | number, item: any) => {
      switch (key) {
        case 'edit': {
          navigate(`/workflow/${item?.id}`)
          break
        }
        case 'start_journey': {
          try {
            const actionStartRes = await WorkflowServices.action(item?.id)
            if ((actionStartRes as any)?._message) {
              throw new Error(JSON.stringify(actionStartRes))
            }
            notification.success({
              message: 'Status is updated successfully.'
            })
            fetchList(state)
          } catch (error) {
            console.log('** Error : ', error)
            notification.error({
              message: 'Something went wrong. Please try again!'
            })
          }
          break
        }
        case 'stop_journey': {
          setStopWorkflow({ id: item.id || '', name: item.name || '' })
          break
        }
        case 'insight': {
          navigate(`/workflow/${item.id}/insight`)
          break
        }
        case 'delete': {
          console.log('** RYAN WorkflowTablePage.tsx 142 item : ', item)
          break
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchList, navigate, state.page, state.per_page, state.type]
  )

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search)
    const newState = getWorkflowTableFromQueryString(urlSearchParams)
    fetchList({ ...state, type: typeProps, ...newState })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeProps])

  return (
    <>
      <WorkflowTable
        loading={loading}
        dataSource={transferWorkflowTableDataSource({
          data: dataSource,
          sites
        })}
        state={state}
        onChangeState={handleChangeState}
        onClick={onClickButton}
        onClickDropdownItem={onClickDropdownItem}
      />
      <WorkflowFolderCreateModal
        open={openNewFolder}
        onOk={onOkNewFolder}
        onCancel={onCancelNewFolder}
      />

      <ModalConfirmStopWorkflow
        workflowName={stopWorkflow.name}
        loading={!!stopWorkflow.loading}
        onCancel={() => setStopWorkflow({ id: '', name: '' })}
        onOk={async () => {
          if (!stopWorkflow.id) {
            return
          }

          setStopWorkflow({ ...stopWorkflow, loading: true })
          try {
            const actionStartRes = await WorkflowServices.action(
              stopWorkflow.id,
              'stop'
            )
            if ((actionStartRes as any)?._message) {
              throw new Error(JSON.stringify(actionStartRes))
            }
            notification.success({
              message: 'Status is updated successfully.'
            })
            fetchList()
            setStopWorkflow({ id: '', name: '' })
          } catch (error) {
            console.log('** Error : ', error)
            notification.error({
              message: 'Something went wrong. Please try again!'
            })
          }
        }}
      />
    </>
  )
}
