import { SegmentPageEdit } from 'components/pages'
import { PERMISSIONS } from 'constants/permission'
import Page404 from 'pages/404'
import { useParams } from 'react-router'

export type SegmentEditProps = {
  segment_type?: string
}
export const SegmentEdit = (props: SegmentEditProps) => {
  const { segment_type } = props
  const { id } = useParams()
  if (!id) {
    return <Page404 />
  }
  return (
    <SegmentPageEdit
      id={id}
      segment_type={segment_type}
      permissions={[PERMISSIONS.segment_view_segment_details]}
    />
  )
}
