import {
  FORM_WORKFLOW_PROPS,
  FORM_WORKFLOW_RULES,
  FormWorkflowProps,
  FormWorkflowRef
} from '..'
import {
  StyledFormWorkflowContainer,
  StyledFormWorkflowSwitch
} from '../FormWorkflow.styled'
import Icon from '@ant-design/icons'
import { Button, Form, Input, InputNumber, Select } from 'antd'
import { AlertComponent } from 'components/atoms/alert'
import { DatePicker } from 'components/atoms/datePicker'
import {
  OPERATOR_OPTIONS_BY_TYPE,
  SegmentConditionFieldOption,
  fetchFieldOptions,
  getFieldOption
} from 'components/molecules/segment/SegmentForm/SegmentFormQuery/SegmentCondition/SegmentCondition.helpers'
import { SegmentConditionValue } from 'components/molecules/segment/SegmentForm/SegmentFormQuery/SegmentCondition/SegmentConditionValue'
import { FORMAT_DATE_DAYJS_API } from 'constants/common'
import {
  SEGMENT_CONDITION_OPERATOR,
  SEGMENT_CONDITION_OPERATOR_LABEL
} from 'constants/segment'
import dayjs from 'dayjs'
import { Close, EventTime, MaxTime, Plus, WaitUntil } from 'icons/V2'
import { OptionType } from 'interfaces'
import {
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from 'react'
import { EventServices } from 'services/event'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { filterOption, timeToTimestamp, timestampToTime } from 'utils'

export type FormWorkflowWaitUntilValue = {
  string?: string
}
export type FormWorkflowWaitUntilRef =
  FormWorkflowRef<FormWorkflowWaitUntilValue>
export type FormWorkflowWaitUntilProps =
  FormWorkflowProps<FormWorkflowWaitUntilValue> & {
    event_id?: number
    site_domain?: string
  }

const FormWorkflowWaitUntilWithoutRef = (
  props: FormWorkflowWaitUntilProps,
  ref?: Ref<FormWorkflowWaitUntilRef>
) => {
  const {
    event_id,
    site_domain,
    initialValues,
    onValuesChange,
    onFinish,
    ...restProps
  } = props
  const [form] = Form.useForm()
  const is_event = Form.useWatch('is_event', form)
  const is_max_time = Form.useWatch('is_max_time', form)
  const is_event_time = Form.useWatch('is_event_time', form)
  const conditions = Form.useWatch(['event', 'conditions'], form)
  const event_event_id = Form.useWatch(['event', 'event_id'], form)

  const [eventTimeEventOptions, setEventTimeEventOptions] = useState<
    SegmentConditionFieldOption[]
  >([])

  const [eventOptions, setEventOptions] = useState<OptionType[]>([])
  const [fieldOptions, setFieldOptions] = useState<
    SegmentConditionFieldOption[]
  >([])
  const currentEvent = useMemo(() => {
    return eventOptions.find(({ value }) => value === event_id)?.label
  }, [eventOptions, event_id])
  const handleValuesChange = (valChanged: any, valsChanged: any) => {
    const newValue = { ...valsChanged }
    if ('is_event' in valChanged) {
      if (valChanged.is_event) {
        newValue.event = { event_id }
      }
    }
    if ('event' in valChanged) {
      if ('submitted' in valChanged.event) {
        const submitted = valChanged.event.submitted
        if (
          submitted &&
          submitted?.[0]?.isValid() &&
          submitted?.[1]?.isValid()
        ) {
          newValue.event.from = submitted?.[0].format(FORMAT_DATE_DAYJS_API)
          newValue.event.to = submitted?.[1].format(FORMAT_DATE_DAYJS_API)
        } else {
          newValue.event.from = null
          newValue.event.to = null
        }
      }
      if ('conditions' in valChanged.event) {
        valChanged.event.conditions?.forEach((cond: any, index: number) => {
          if (cond?.type) {
            return
          }
          const fieldOption = getFieldOption({
            field: cond.field,
            fieldOptions
          })
          if (fieldOption) {
            newValue.event.conditions[index].operator = null
            newValue.event.conditions[index].value = null
            newValue.event.conditions[index].type = fieldOption.type
          }
        })
      }
      if ('event_id' in valChanged.event) {
        newValue.event.conditions = []
      }
    }

    if ('is_event_time' in valChanged) {
      if (valChanged?.is_event_time) {
        newValue.event_time = { durations_type: 'day' }
      }
    }
    if ('event_time' in valChanged) {
      if ('field' in valChanged.event_time) {
        const fieldOption = getFieldOption({
          field: valChanged.event_time.field,
          fieldOptions
        })
        if (fieldOption) {
          newValue.event_time.type = fieldOption.type
        }
      }
    }
    if ('is_max_time' in valChanged) {
      if (valChanged?.is_max_time) {
        newValue.max_time = { max_time_type: 'day' }
      }
    }

    form.setFieldsValue(newValue)
    onValuesChange?.(valChanged, newValue)
  }

  useDeepCompareEffect(() => {
    form.setFieldsValue(formatFormWorkflowWaitUntil(initialValues))
  }, [initialValues])

  useEffect(() => {
    EventServices.getList({
      page: 1,
      per_page: 10000
    }).then((res: any) => {
      if (res.data?.data) {
        const data = res.data.data
        setEventOptions(
          data.map((item: any) => ({ value: item.id, label: item.name }))
        )
      }
    })
  }, [site_domain])

  useEffect(() => {
    if (!event_event_id || !site_domain) {
      return
    }

    fetchFieldOptions({
      event_id: event_event_id,
      segment_type: '',
      site_domain: site_domain ? [site_domain] : []
    }).then((res) => {
      setFieldOptions(res || [])
    })
  }, [event_event_id, site_domain])

  useEffect(() => {
    if (!event_id || !site_domain) {
      return
    }
    fetchFieldOptions({
      event_id,
      site_domain: [site_domain],
      segment_type: ''
    }).then((res) => {
      setEventTimeEventOptions(res || [])
    })
  }, [event_id, site_domain])

  useImperativeHandle(ref, () => form, [form])
  return (
    <Form
      {...restProps}
      {...FORM_WORKFLOW_PROPS}
      form={form}
      onValuesChange={handleValuesChange}
      onFinish={(values: any) =>
        onFinish?.(formatFormWorkflowWaitUntilFinish(values))
      }>
      <StyledFormWorkflowContainer className="waitUntil--container">
        <p className="mb-2 text-sm tracking-[-0.2px]">Wait until people...</p>
        <div className="waitUntil--box rounded-lg bg-neutral50 py-3 px-2">
          <div className="flex items-center px-2">
            <Icon component={WaitUntil} className="text-xl" />
            <h4 className="m-0 mx-2 flex-1 min-w-0 text-md font-semibold">
              Condition
            </h4>
            <Form.Item name="is_event" valuePropName="checked" noStyle>
              <StyledFormWorkflowSwitch size="small" />
            </Form.Item>
          </div>
          <Form.Item
            shouldUpdate={(prev, next) => prev.is_event !== next.is_event}
            noStyle>
            {!!is_event && (
              <>
                <div className="mt-2 px-2">
                  <Form.Item hidden name={['event', 'from']}>
                    <Input />
                  </Form.Item>
                  <Form.Item hidden name={['event', 'to']}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    rules={[FORM_WORKFLOW_RULES.required_select]}
                    name={['event', 'operator']}
                    className="waitUntil--form-item !mb-2">
                    <Select
                      placeholder="In/Not"
                      options={[
                        {
                          value: SEGMENT_CONDITION_OPERATOR.IN,
                          label: SEGMENT_CONDITION_OPERATOR_LABEL.IN
                        },
                        {
                          value: SEGMENT_CONDITION_OPERATOR.NOT_IN,
                          label: SEGMENT_CONDITION_OPERATOR_LABEL.NOT_IN
                        }
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    rules={[FORM_WORKFLOW_RULES.required_select]}
                    name={['event', 'event_id']}
                    className="waitUntil--form-item !mb-2">
                    <Select
                      placeholder="Event"
                      options={eventOptions}
                      allowClear
                      showSearch
                      filterOption={filterOption as any}
                    />
                  </Form.Item>
                  <Form.Item
                    name={['event', 'submitted']}
                    className="waitUntil--form-item !mb-2"
                    rules={[FORM_WORKFLOW_RULES.required_select]}>
                    <DatePicker.RangePicker
                      placeholder={['Start date', 'End date']}
                    />
                  </Form.Item>
                </div>
                <Form.List name={['event', 'conditions']}>
                  {(
                    conds,
                    { add: addCond, remove: removeCond },
                    { errors }
                  ) => {
                    return (
                      <>
                        {conds.map((cond) => {
                          const conditionValue = conditions?.[cond.name]
                          const fieldOption = getFieldOption({
                            field: conditionValue?.field,
                            fieldOptions
                          })
                          const valueOptions =
                            fieldOption?.values?.map((option) => ({
                              value: option.key,
                              label: option.value
                            })) || []
                          const operatorOptions =
                            OPERATOR_OPTIONS_BY_TYPE?.[
                              fieldOption?.type as keyof typeof OPERATOR_OPTIONS_BY_TYPE
                            ]
                          return (
                            <div
                              key={cond.name}
                              className="waitUntil--condition-item rounded-lg bg-white p-3 pt-[10px] peer peer-[&]:mt-2">
                              <div className="flex items-center">
                                <h5 className="m-0 flex-1 min-w-0 text-gray800 text-xs font-bold">
                                  Field conditions
                                </h5>
                                <Icon
                                  component={Close}
                                  className="text-lg"
                                  onClick={() => removeCond(cond.name)}
                                />
                              </div>
                              <Form.Item name={[cond.name, 'type']} hidden>
                                <Input />
                              </Form.Item>
                              <Form.Item
                                rules={[FORM_WORKFLOW_RULES.required_select]}
                                name={[cond.name, 'field']}
                                className="mt-2 mb-0">
                                <Select
                                  placeholder="Field"
                                  options={fieldOptions.map(
                                    ({ field, name }) => ({
                                      value: field,
                                      label: name
                                    })
                                  )}
                                  allowClear
                                  showSearch
                                  filterOption={filterOption as any}
                                />
                              </Form.Item>
                              <Form.Item
                                rules={[FORM_WORKFLOW_RULES.required_select]}
                                name={[cond.name, 'operator']}
                                className="mt-2 mb-0">
                                <Select
                                  placeholder="Operator"
                                  options={operatorOptions}
                                />
                              </Form.Item>
                              <Form.Item
                                rules={[FORM_WORKFLOW_RULES.required_input]}
                                name={[cond.name, 'value']}
                                className="mt-2 mb-0">
                                <SegmentConditionValue
                                  size="small"
                                  type={conditionValue?.type}
                                  operator={conditionValue?.operator}
                                  options={valueOptions}
                                />
                              </Form.Item>
                            </div>
                          )
                        })}
                        <Form.ErrorList
                          errors={errors}
                          className="text-[12px] px-2"
                        />
                        <div className="mt-3 px-1">
                          <Button
                            block
                            type="dashed"
                            size="small"
                            onClick={() => addCond({})}
                            icon={<Icon component={Plus} className="text-lg" />}
                            className="bg-transparent hover:bg-transparent"
                          />
                        </div>
                      </>
                    )
                  }}
                </Form.List>
              </>
            )}
          </Form.Item>
        </div>
        <div className="waitUntil--box rounded-lg bg-neutral50 py-3 px-2 mt-3">
          <div className="flex items-center px-2">
            <Icon component={EventTime} className="text-xl" />
            <h4 className="m-0 mx-2 flex-1 min-w-0 text-md font-semibold">
              Event time
            </h4>
            <Form.Item name="is_event_time" valuePropName="checked" noStyle>
              <StyledFormWorkflowSwitch size="small" />
            </Form.Item>
          </div>
          <Form.Item
            shouldUpdate={(prev, next) =>
              prev.is_event_time !== next.is_event_time
            }
            noStyle>
            {!!is_event_time && (
              <div className="mt-2 px-2">
                <Form.Item hidden name={['event_time', 'type']}>
                  <Input />
                </Form.Item>
                <div className="flex gap-4">
                  <Form.Item
                    rules={[FORM_WORKFLOW_RULES.required_input]}
                    name={['event_time', 'time']}
                    className="waitUntil--form-item !mb-2 flex-1 min-w-0">
                    <InputNumber
                      placeholder="Day"
                      size="small"
                      className="w-full"
                    />
                  </Form.Item>
                  <Form.Item
                    rules={[FORM_WORKFLOW_RULES.required_select]}
                    name={['event_time', 'durations_type']}
                    className="waitUntil--form-item !mb-2 flex-1 min-w-0">
                    <Select
                      placeholder="Type"
                      options={[
                        { value: 'minute', label: 'Minute' },
                        { value: 'hour', label: 'Hour' },
                        { value: 'day', label: 'Day' },
                        { value: 'week', label: 'Week' }
                      ]}
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  rules={[FORM_WORKFLOW_RULES.required_select]}
                  name={['event_time', 'operator']}
                  className="waitUntil--form-item !mb-2">
                  <Select
                    placeholder="Operator"
                    options={[
                      {
                        value: 'before',
                        label: 'before the time specified in'
                      },
                      { value: 'after', label: 'after the time specified in' }
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  rules={[FORM_WORKFLOW_RULES.required_select]}
                  name={['event_time', 'field']}
                  className="waitUntil--form-item !mb-2">
                  <Select
                    placeholder="Field"
                    options={eventTimeEventOptions
                      .filter(({ type }) => type === 'date')
                      .map(({ field, name }) => ({
                        value: field,
                        label: name
                      }))}
                  />
                </Form.Item>
                <p className="m-0">
                  for the event{' '}
                  <span className="inline-block px-2 text-red900 bg-white border border-solid border-neutral100 rounded-[4px]">
                    {currentEvent}
                  </span>
                </p>
                <AlertComponent
                  showClose
                  type="warning"
                  title="Check your time format!"
                  className="mt-2"
                  description={
                    <span>
                      If the specified time doesn&apos;t exist or isn&apos;t the{' '}
                      <span className="underline">right format</span>, the
                      person will remain in the Wait Until.
                    </span>
                  }
                />
              </div>
            )}
          </Form.Item>
        </div>
        <div className="waitUntil--box rounded-lg bg-neutral50 py-3 px-2 mt-3">
          <div className="flex items-center px-2">
            <Icon component={MaxTime} className="text-xl" />
            <h4 className="m-0 mx-2 flex-1 min-w-0 text-md font-semibold">
              Max. time
            </h4>
            <Form.Item name="is_max_time" valuePropName="checked" noStyle>
              <StyledFormWorkflowSwitch size="small" />
            </Form.Item>
          </div>
          <Form.Item
            shouldUpdate={(prev, next) => prev.is_max_time !== next.is_max_time}
            noStyle>
            {!!is_max_time && (
              <div className="mt-2 px-2">
                <p className="mb-2">Wait a maximum of</p>
                <div className="flex gap-4">
                  <Form.Item
                    rules={[FORM_WORKFLOW_RULES.required_input]}
                    name={['max_time', 'time']}
                    className="waitUntil--form-item !mb-2 flex-1 min-w-0">
                    <InputNumber
                      placeholder="Day"
                      size="small"
                      className="w-full"
                    />
                  </Form.Item>
                  <Form.Item
                    rules={[FORM_WORKFLOW_RULES.required_select]}
                    name={['max_time', 'max_time_type']}
                    className="waitUntil--form-item !mb-2 flex-1 min-w-0">
                    <Select
                      placeholder="Type"
                      options={[
                        { value: 'minute', label: 'Minute' },
                        { value: 'hour', label: 'Hour' },
                        { value: 'day', label: 'Day' },
                        { value: 'week', label: 'Week' }
                      ]}
                    />
                  </Form.Item>
                </div>
                <p className="mb-2">then</p>
                <Form.Item
                  name={['max_time', 'actions']}
                  className="waitUntil--form-item !mb-2">
                  <Select
                    disabled
                    options={[
                      {
                        value: 'move to the next action',
                        label: 'move to the next action'
                      },
                      { value: 'exit', label: 'exit' }
                    ]}
                  />
                </Form.Item>
              </div>
            )}
          </Form.Item>
        </div>
      </StyledFormWorkflowContainer>
    </Form>
  )
}

export function formatFormWorkflowWaitUntilFinish(data: any) {
  const newData = { ...data }
  delete newData?.is_event
  delete newData?.is_max_time
  delete newData?.is_event_time
  if ('event' in data) {
    delete newData.event?.submitted
  }
  if ('event_time' in data) {
    newData.event_time = {
      ...data.event_time,
      durations: timeToTimestamp(
        data.event_time.time,
        data.event_time?.durations_type
      ),
      operator: data.event_time.operator,
      field: data.event_time.field,
      type: data.event_time.type
    }
    delete newData.event_time.time
  }
  if ('max_time' in data) {
    newData.max_time = {
      ...data.max_time,
      max_time: timeToTimestamp(
        data.max_time.time,
        data.max_time?.max_time_type
      )
    }
    delete newData.max_time.time
  }
  return newData
}

export function formatFormWorkflowWaitUntil(data: any) {
  const newValue = { ...data }
  if ('event' in newValue) {
    newValue.is_event = true
    const fromDayjs = dayjs(newValue.event?.from, FORMAT_DATE_DAYJS_API)
    const toDayjs = dayjs(newValue.event?.to, FORMAT_DATE_DAYJS_API)
    if (fromDayjs.isValid() && toDayjs.isValid()) {
      newValue.event.submitted = [fromDayjs, toDayjs]
    } else {
      newValue.event.submitted = null
    }
  }
  if ('event_time' in newValue) {
    newValue.is_event_time = true
    newValue.event_time.time = timestampToTime(
      newValue.event_time.durations,
      newValue.event_time?.durations_type
    )
  }
  if ('max_time' in newValue) {
    newValue.is_max_time = true
    newValue.max_time.time = timestampToTime(
      newValue.max_time.max_time,
      newValue.max_time?.max_time_type
    )
  }

  return newValue
}
export const FormWorkflowWaitUntil = forwardRef(FormWorkflowWaitUntilWithoutRef)
