import { ConditionBaseProps } from '..'
import {
  StyledCondition,
  StyledConditionContent,
  StyledConditionLabel,
  StyledConditionSelectCheckbox
} from '../index.styled'
import { OptionType } from 'interfaces'
import { useEffect, useState } from 'react'

export type ConditionSelectCheckboxProps = ConditionBaseProps<
  (string | number | boolean)[]
> & {
  title?: string
  label?: string
  placeholder?: string
  options?: OptionType[]
}
export const ConditionSelectCheckboxComponent = (
  props: ConditionSelectCheckboxProps
) => {
  const {
    title,
    options,
    placeholder,
    defaultValue,
    value: valueProps,
    onChange: onChangeProps
  } = props
  const [value, setValue] = useState<(string | number | boolean)[]>(
    defaultValue || valueProps || []
  )

  const onChange = (val: unknown) => {
    const newValue = val as (string | number | boolean)[]
    setValue(newValue)
    onChangeProps && onChangeProps(newValue)
  }

  useEffect(() => {
    setValue(valueProps || [])
  }, [valueProps])
  return (
    <StyledConditionSelectCheckbox
      title={title}
      options={options}
      value={value}
      onChange={onChange}
      allowClear
      className="full--width"
      placeholder={placeholder}
      maxTagCount="responsive"
    />
  )
}
export const ConditionSelectCheckbox = (
  props: ConditionSelectCheckboxProps
) => {
  const { label, className } = props
  return (
    <StyledCondition className={className}>
      {label && <StyledConditionLabel>{label}</StyledConditionLabel>}
      <StyledConditionContent>
        <ConditionSelectCheckboxComponent {...props} />
      </StyledConditionContent>
    </StyledCondition>
  )
}
