import { Form, RefSelectProps, Select, SelectProps } from 'antd'
import cn from 'classnames'
import { DEFAULT_GET_ALL_RECORDS } from 'constants/common'
import { OptionType } from 'interfaces'
import { Ref, forwardRef, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router'
import { EventServices } from 'services/event'
import { filterOption } from 'utils'
import { FormNamePath, getFormNamePath } from 'utils/form'

export type SegmentFormQuerySelectEventRef = RefSelectProps
export type SegmentFormQuerySelectEventProps = Pick<
  SelectProps,
  | 'id'
  | 'placeholder'
  | 'defaultValue'
  | 'value'
  | 'onBlur'
  | 'className'
  | 'style'
  | 'size'
  | 'showSearch'
  | 'filterOption'
> & {
  onChange?: (value: number | null) => void
  rootName?: FormNamePath
  name?: FormNamePath
}
const SegmentFormQuerySelectEventWithoutRef = (
  props: SegmentFormQuerySelectEventProps,
  ref?: Ref<SegmentFormQuerySelectEventRef>
) => {
  const location = useLocation()
  /** Display the form to create segment for webpush inside the campaign create flow */
  const isCampaignWebpushPage = location.pathname.startsWith(
    '/campaign/web_push/'
  )
  const {
    placeholder,
    defaultValue,
    value: valueProps,
    onChange,
    className,
    style,
    size,
    id,
    rootName,
    name,
    ...restProps
  } = props
  const [eventList, setEventList] = useState<Record<string, OptionType[]>>({})
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(defaultValue || valueProps)
  const [source, setSource] = useState<string>()
  const form = Form.useFormInstance()
  const eventIdName = getFormNamePath(rootName, name)
  const eventIdErrors = form.getFieldError(eventIdName)

  const sourceOptions = useMemo(() => {
    return Object.keys(eventList).map((k) => ({ value: k, label: k }))
  }, [eventList])
  const eventOptions = useMemo(() => {
    return (eventList?.[source || ''] || []) as any
  }, [eventList, source])

  const handleChangeSource = (val: string) => {
    setSource(val)
    setValue(null)
    onChange?.(null)
  }

  const handleChange = (val: any) => {
    setValue(val)
    onChange?.(val)
  }

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const { data } = await fetchEventOptions()
      const dataEventList: Record<string, OptionType[]> = {}
      data.forEach((item) => {
        const option = { value: item.id, label: item.name }
        item.actions.forEach((action) => {
          if (typeof action.source === 'string') {
            const valueEventList = dataEventList?.[action.source]
            if (!valueEventList) {
              dataEventList[action.source] = [option]
            }
            if (
              valueEventList?.length &&
              valueEventList.every(({ value }) => value !== option.value)
            ) {
              dataEventList[action.source] = [...valueEventList, option]
            }
          }
        })
      })
      setEventList(dataEventList)
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    if (valueProps) {
      setTimeout(() => {
        const eventHasValueProps = Object.keys(eventList).find((key) => {
          if (eventList[key]?.some(({ value }) => value === valueProps)) {
            return true
          }
        })
        setSource(eventHasValueProps || source)
      }, 0)
    }
  }, [eventList, valueProps])

  return (
    <div
      className={cn('inline-flex flex-wrap items-start gap-4', className)}
      style={style}>
      <Select
        showSearch
        filterOption={filterOption as any}
        size={size}
        placeholder="Source"
        loading={loading}
        options={sourceOptions}
        value={source}
        onChange={handleChangeSource}
        className="min-w-[180px]"
        disabled={Boolean(source && isCampaignWebpushPage)}
      />
      <span>
        <Select
          {...restProps}
          id={id}
          size={size}
          loading={loading}
          ref={ref}
          options={eventOptions}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={value}
          onChange={handleChange}
          className="min-w-[180px]"
          status={eventIdErrors.length ? 'error' : ''}
        />
        <Form.ErrorList errors={eventIdErrors} className="text-[0.8em] pt-1" />
      </span>
    </div>
  )
}
export const SegmentFormQuerySelectEvent = forwardRef(
  SegmentFormQuerySelectEventWithoutRef
)

type EventItem = {
  id: number
  name?: string
  actions: [{ source?: string }]
}
const fetchEventOptions = async () => {
  try {
    const { data } = await EventServices.getList(DEFAULT_GET_ALL_RECORDS)
    if (!data.data) {
      throw new Error()
    }
    return { data: (data.data || []) as EventItem[], errors: [] }
  } catch (error) {
    return { data: [], errors: [{ errors: ['Something went wrong'] }] }
  }
}
