import { StyledSegmentPredictionHighchartsWrapper } from './SegmentPrediction.styled'
import { Slider, Spin } from 'antd'
import cn from 'classnames'
import {
  HighchartsComponent,
  HighchartsComponentRef
} from 'components/atoms/Highcharts'
import { theme } from 'constants/theme'
import {
  CSSProperties,
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'

const categories: number[] = []
for (let i = 0; i <= 100; i++) {
  categories.push(i)
}
const tickPositions = categories.filter((i) => Boolean(i % 20 === 0))
export const SEGMENT_PREDICTION_HIGHCHARTS_OPTIONS: Highcharts.Options = {
  chart: { type: 'area' },
  accessibility: { enabled: true },
  credits: { enabled: false },
  title: { text: '' },
  xAxis: {
    id: 'PERCENT_XAXIS',
    title: { text: 'Percentile' },
    tickPositions,
    tickColor: 'transparent'
  },
  yAxis: {
    title: { text: 'Probability', rotation: -90, style: { marginRight: 16 } },
    labels: { enabled: true },
    min: 0,
    max: 1,
    gridLineWidth: 0
  },
  tooltip: {
    headerFormat: '',
    pointFormat: '{point.y}'
  },
  plotOptions: {
    area: {
      pointStart: 0,
      color: theme?.colors?.blue100,
      fillOpacity: 100,
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false
          }
        }
      }
    }
  },
  series: [{ type: 'area', name: '', data: categories.map(() => null) }]
}

export function formatSegmentPredictionHighchartsData(
  data?: Record<string, number>,
  value?: [number, number]
): Highcharts.Options {
  const highchartsOptions: Highcharts.Options =
    SEGMENT_PREDICTION_HIGHCHARTS_OPTIONS
  const highchartsSeriesArea: Highcharts.SeriesAreaOptions = {
    type: 'area',
    name: '',
    data: categories.map(() => null)
  }

  if (!value || !data) {
    return highchartsOptions
  }

  let total = 0
  const dataArray = Object.keys(data)
    .map((k) => {
      total += data[k]
      return [parseFloat(k), data[k]]
    })
    .sort((a, b) => (a[0] > b[0] ? 1 : -1))

  const from = value[0]
  const to = value[1]
  const tick = to - from

  let t = from
  for (let i = 0; i < dataArray.length; i++) {
    const score = dataArray[i][0]
    const numbers = dataArray[i][1]

    const f = t
    t = f + Math.round((numbers / total) * tick + 0.1)

    if (i === dataArray.length - 1) {
      t = to
    }

    for (let j = f; j <= t; j++) {
      if (typeof highchartsSeriesArea.data?.[j] !== 'undefined' && j <= to) {
        if (typeof highchartsSeriesArea.data[j] === 'number') {
          continue
        }
        if (j === 0) {
          highchartsSeriesArea.data[j] = 0
        } else {
          highchartsSeriesArea.data[j] =
            j === to ? dataArray[dataArray.length - 1][0] : score
        }
      }
    }
  }

  if (highchartsSeriesArea.data) {
    highchartsOptions.series = [highchartsSeriesArea]
  }

  return highchartsOptions
}
export type SegmentPredictionHighchartsSliderRef = HighchartsComponentRef
export type SegmentPredictionHighchartsSliderProps = {
  loading?: boolean
  disabled?: boolean
  type?: string
  data?: any
  defaultValue?: [number, number]
  value?: [number, number]
  onChange?: (value: [number, number]) => void
}

const SegmentPredictionHighchartsSliderRenderFunc = (
  props: SegmentPredictionHighchartsSliderProps,
  ref?: Ref<SegmentPredictionHighchartsSliderRef>
) => {
  const {
    loading,
    data,
    disabled,
    type,
    defaultValue,
    value: valueProps,
    onChange: onChangeProps
  } = props
  const debounce = useRef<NodeJS.Timeout | null>(null)
  const $container = useRef<HTMLDivElement | null>(null)
  const highchartsRef = useRef<HighchartsComponentRef | null>(null)
  const [value, setValue] = useState(defaultValue || valueProps)
  const [isResize, setIsResize] = useState(false)
  const [stylesSlider, setStyleSlider] = useState<CSSProperties>({ right: 10 })
  const highchartsOptions = useMemo(() => {
    return formatSegmentPredictionHighchartsData(data, value)
  }, [data, value])

  const onChange = useCallback(
    (value: [number, number]) => {
      if (value[0] === value[1]) {
        return
      }
      setValue(value)
      onChangeProps?.(value)
    },
    [onChangeProps]
  )

  const resizeObserver = useMemo(
    () =>
      new ResizeObserver(() => {
        setIsResize(true)
        if (debounce.current) {
          clearTimeout(debounce.current)
        }
        debounce.current = setTimeout(() => {
          onInitHighchars(highchartsRef?.current)
        }, 10)
      }),
    []
  )

  const onInitHighchars = (chart: Highcharts.Chart | null) => {
    const parentRect = chart?.container.getBoundingClientRect()
    const xAxis = chart?.get('PERCENT_XAXIS') as any
    if (xAxis) {
      const ticks = xAxis.ticks
      const rect0 = ticks?.['0']?.mark?.element.getBoundingClientRect()
      const rect100 = ticks?.['100']?.mark?.element.getBoundingClientRect()
      if (parentRect && rect100 && rect0) {
        setStyleSlider({
          right: parentRect.right - rect100.right,
          bottom: parentRect.bottom - rect100.bottom + 2,
          width: rect100.left - rect0.left
        })
        setIsResize(false)
      }
    }
  }

  useEffect(() => {
    setValue(valueProps)
  }, [valueProps])

  useDeepCompareEffect(() => {
    const highchartsOptions = formatSegmentPredictionHighchartsData(data, value)
    highchartsRef?.current?.update(highchartsOptions, true, true)
    onInitHighchars(highchartsRef.current)
  }, [data, value])

  useEffect(() => {
    if ($container.current) {
      resizeObserver.observe($container.current)
    }
    return () => {
      if ($container.current) {
        resizeObserver.unobserve($container.current)
      }
    }
  }, [])

  useImperativeHandle(
    ref,
    () => highchartsRef.current as HighchartsComponentRef,
    []
  )

  return (
    <Spin spinning={!!loading}>
      <StyledSegmentPredictionHighchartsWrapper
        ref={$container}
        className={cn(type)}>
        <HighchartsComponent
          ref={highchartsRef}
          id="segmentPrediction_highcharts"
          options={highchartsOptions}
          onInit={onInitHighchars}
        />
        <div
          className={cn('highcharts__slider-wrapper', { isResize })}
          style={stylesSlider}>
          <Slider
            disabled={disabled}
            step={10}
            min={0}
            max={100}
            value={value}
            onChange={onChange}
            included={true}
            tooltip={{
              formatter(value) {
                return 'Drag the slider'
              },
              getPopupContainer() {
                return $container.current as HTMLElement
              }
            }}
            range
          />
        </div>
      </StyledSegmentPredictionHighchartsWrapper>
    </Spin>
  )
}
export const SegmentPredictionHighchartsSlider = forwardRef(
  SegmentPredictionHighchartsSliderRenderFunc
)
