import { HighchartsCompProps, HighchartsCompRef } from '.'
import { HighchartsEmpty } from './HighchartsEmpty'
import cn from 'classnames'
import Highcharts from 'highcharts'
import Accessibility from 'highcharts/modules/accessibility'
import SeriesLabel from 'highcharts/modules/series-label'
import Stock from 'highcharts/modules/stock'
import {
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'

export type HighchartsComponentRef = HighchartsCompRef
export type HighchartsComponentProps = HighchartsCompProps

const formatHighchartsOptionsDefault = (
  options?: Highcharts.Options
): Highcharts.Options => {
  const newOptions = {
    ...options,
    title: { text: '', ...options?.title },
    yAxis: {
      title: { text: null },
      ...options?.yAxis
    },
    xAxis: {
      title: { text: null },
      ...options?.xAxis
    },
    legend: { enabled: false, ...options?.legend },
    credits: { enabled: false, ...options?.credits }
  }

  return newOptions
}
const HighchartsComponentRenderFunc = (
  props: HighchartsComponentProps,
  ref?: Ref<HighchartsComponentRef>
) => {
  const { id, options, className, style, emptyProps, onInit, onResize } = props
  const $highchart = useRef<HTMLDivElement>(null)
  const [highcharts, setHighcharts] = useState<HighchartsCompRef | null>(null)

  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((entries) => {
        highcharts?.reflow()
      }),
    [highcharts]
  )

  useEffect(() => {
    if ($highchart.current && !highcharts) {
      Stock(Highcharts)
      SeriesLabel(Highcharts)
      if (options?.accessibility?.enabled) {
        Accessibility(Highcharts)
      }
      Highcharts.chart($highchart.current, options, (chart) => {
        setTimeout(() => {
          onInit?.(chart)
        }, 5)
        setHighcharts(chart)
      })
    }
  }, [highcharts, onInit, onResize, options, resizeObserver])

  useEffect(() => {
    const newOptions = formatHighchartsOptionsDefault(options)
    highcharts?.update(newOptions, true, true)
  }, [highcharts, options])

  useEffect(() => {
    if ($highchart.current) {
      const $parent = $highchart.current?.parentElement
      resizeObserver.observe($parent as Element)
    }
  }, [resizeObserver])

  useImperativeHandle(ref, () => highcharts as Highcharts.Chart, [highcharts])

  return (
    <div className={cn('highchartsComponent', className)} style={style}>
      {!options?.series?.length ? (
        <HighchartsEmpty {...emptyProps} />
      ) : (
        <div
          ref={$highchart}
          id={id}
          className="highchartsComponent--container h-full"
        />
      )}
    </div>
  )
}
export const HighchartsComponent = forwardRef(HighchartsComponentRenderFunc)
