import { toLowerNonAccentCaseSearch } from './search'
import { FILE_UPLOAD_SIZE, FILE_UPLOAD_TYPE } from 'constants/common'
import { ENV } from 'constants/env'
import { SITE_PREFIX_LANDING_PAGE } from 'constants/site'

export function downloadFile(file?: File | Blob, name?: string) {
  if (!file) {
    return
  }
  const url = window.URL.createObjectURL(file)
  const a = document.createElement('a')
  a.href = url
  a.download = name || 'Contacts-' + new Date().getTime()
  a.click()
}

// size by MB
export function validateFileSize(file?: File, size = FILE_UPLOAD_SIZE) {
  if (!file) {
    return false
  }
  return file.size / 1024 / 1024 < size
}

// type: default text/csv
export function validateFileType(file?: File, type = FILE_UPLOAD_TYPE) {
  if (!file) {
    return
  }
  return file.type === type
}

export function validateFile(
  file?: File,
  type = FILE_UPLOAD_TYPE,
  size = FILE_UPLOAD_SIZE
) {
  return validateFileSize(file, size) && validateFileType(file, type)
}

export function formatBytes(bytes: number, n = 2) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Byte'
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  return (
    Math.round((bytes / Math.pow(1024, i)) * Math.pow(10, n)) /
      Math.pow(10, n) +
    sizes[i]
  )
}

export function formatNumberToCurrency(numProps: number, sep = ',') {
  // remove sign if negative
  let sign = 1
  if (numProps < 0) {
    sign = -1
    numProps = -numProps
  }
  // trim the number decimal point if it exists
  const num = numProps.toString().includes('.')
    ? numProps.toString().split('.')[0]
    : numProps.toString()
  const len = num.toString().length
  let result = ''
  let count = 1

  for (let i = len - 1; i >= 0; i--) {
    result = num.toString()[i] + result
    if (count % 3 === 0 && count !== 0 && i !== 0) {
      result = sep + result
    }
    count++
  }

  // add number after decimal point
  if (numProps.toString().includes('.')) {
    const decimalSep = sep !== ',' ? ',' : '.'
    result = result + decimalSep + numProps.toString().split('.')[1]
  }
  // return result with - sign if negative
  return sign < 0 ? '-' + result : result
}

export function mathRound(num: number, decimal = 2) {
  const decimalNumber = 10 ** decimal
  return Math.round(num * decimalNumber) / decimalNumber
}

const ROUND_STRING = {
  K: 1000,
  M: 1000000,
  B: 1000000000,
  T: 1000000000000
}
export function formatNumberString(numProps: number, number = ROUND_STRING.M) {
  if (numProps < number) {
    return formatNumberToCurrency(numProps, '.')
  }

  let string = ''
  Object.keys(ROUND_STRING).forEach((k, idx) => {
    const roundString = k as keyof typeof ROUND_STRING
    if (ROUND_STRING[roundString] <= numProps) {
      string = roundString
    }
  })

  const num =
    Math.floor(
      (numProps / ROUND_STRING[string as keyof typeof ROUND_STRING]) * 100
    ) / 100

  return num + string
}

export function getAsPathApi(
  path: string,
  fields: Record<string, string | number>
) {
  let newPath = path

  Object.keys(fields).forEach((key) => {
    const value = fields[key].toString() || ''
    newPath = newPath.replaceAll(`{${key}}`, value)
  })

  return newPath
}

export const getSiteToDisplay = (domain: string) => {
  let prefix = 'discover.'
  if (domain && domain.includes('hellohealthgroup')) {
    prefix = 'staging.'
  }
  if (ENV.reactEnv === 'staging') {
    return `https://${prefix}${domain}`
  }

  return `https://${domain}`
}

export const getSiteToDisplayWithPrefix = (domain: string) => {
  return getSiteToDisplay(domain) + '/' + SITE_PREFIX_LANDING_PAGE + '/'
}

export const isValidSingleSlug = (str: string) => {
  const regexExp = /^[a-z0-9]+(?:-[a-z0-9]+)*$/g

  return regexExp.test(str)
}

export const clickAndOpenInNewTab = (url: string) => {
  const a = document.createElement('a')
  a.href = url
  a.target = '_blank'
  a.rel = 'noopener noreferrer'
  a.click()
}

export const copyToClipboard = (str: string) => {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText)
    return navigator.clipboard.writeText(str)
  return Promise.reject('The Clipboard API is not available.')
}

export function getElementOuterHeight(element?: Element, outer = true): number {
  if (!element || !window) {
    return 0
  }
  let height = element.clientHeight
  const style = window.getComputedStyle(element)
  if (style?.marginTop && outer) {
    height += parseFloat(style.marginTop)
  }
  if (style?.marginBottom && outer) {
    height += parseFloat(style.marginBottom)
  }
  return height
}

export function getElementOuterWidth(element?: Element, outer = true): number {
  if (!element || !window) {
    return 0
  }
  let width = element.clientWidth
  const style = window.getComputedStyle(element)
  if (style?.marginLeft && outer) {
    width += parseFloat(style.marginLeft)
  }
  if (style?.marginRight && outer) {
    width += parseFloat(style.marginRight)
  }

  return width
}

export const convertQueryParams = (queryData: any) => {
  if (typeof queryData !== 'undefined') {
    const queryString = Object.keys(queryData)
      .map((key) => {
        const queryValue = queryData[key]
        if (Array.isArray(queryValue)) {
          if (!queryValue.length) {
            return key + '='
          }
          const value = queryValue
            .map((item: any) => {
              return key + '=' + item
            })
            .join('&')
          return value
        }
        return key + '=' + queryValue
      })
      .join('&')
    return queryString
  }
  return
}

export const filterOption = (
  input: string,
  option?: { label: string | number; value: string | number | boolean }
) => toLowerNonAccentCaseSearch(option?.label.toString() || '', input)

const SECONDS_IN_MINUTE = 60
const SECONDS_IN_HOUR = 60 * 60
const SECONDS_IN_DAY = 24 * 60 * 60
const SECONDS_IN_WEEK = 24 * 60 * 60 * 7
export function timestampToTime(
  timestamp: number,
  type: 'minute' | 'hour' | 'day' | 'week'
): number | null {
  let time = null

  if (type === 'minute') {
    time = timestamp / SECONDS_IN_MINUTE
  } else if (type === 'hour') {
    time = timestamp / SECONDS_IN_HOUR
  } else if (type === 'day') {
    time = timestamp / SECONDS_IN_DAY
  } else if (type === 'week') {
    time = timestamp / SECONDS_IN_WEEK
  }

  return time
}

export function timeToTimestamp(
  time: number,
  type: 'minute' | 'hour' | 'day' | 'week'
): number | null {
  let timestamp = null

  if (type === 'minute') {
    timestamp = time * SECONDS_IN_MINUTE
  } else if (type === 'hour') {
    timestamp = time * SECONDS_IN_HOUR
  } else if (type === 'day') {
    timestamp = time * SECONDS_IN_DAY
  } else if (type === 'week') {
    timestamp = time * SECONDS_IN_WEEK
  }

  return timestamp
}
