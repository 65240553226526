import { WorkflowBuilderDrawerTemplateRef } from '../../WorkflowBuilderDrawerTemplate'
import { WorkflowBuilderHandle } from '../../WorkflowBuilderHandle'
import { WorkflowBuilderNodeTemplate } from '../../WorkflowBuilderNodeTemplate'
import { WFNode, useNode } from '../../index.helpers'
import {
  convertConditionToQuery,
  formatConditionAttributeForm,
  formatConditonAttribute
} from './index.helpers'
import {
  NodeHandleNumber,
  StyleQueryString,
  StyledDescription
} from './index.styled'
import { Form } from 'antd'
import { FormUserAttribute } from 'components/molecules/form/formUserAttribute'
import { useWorkflowBuilder } from 'components/molecules/workflow/WorkflowBuilder/index.context'
import { FORMAT_DATE_DAYJS_API } from 'constants/common'
import dayjs from 'dayjs'
import { useAttributeFields } from 'hooks/useSegment'
import { useMemo, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Node, Position } from 'reactflow'
import rehypeRaw from 'rehype-raw'

const WFUserAttributes: WFNode = (props) => {
  const { id, data, bgColor, color, icon, title, modalWidth } = props
  const [form] = Form.useForm()
  const attributes = useAttributeFields()
  const { nodes, setNodes, onNodesDelete, onNodesChangeData } =
    useWorkflowBuilder()
  const { open, onClose, onOpen, onUpdate, onCancel } = useNode({
    id,
    form,
    nodes,
    setNodes,
    onNodesDelete,
    onNodesChangeData
  })
  const [queryAttribute, setQueryAttribute] = useState('')

  const number = useMemo(() => {
    return data?.totalUser || 0
  }, [data?.totalUser])

  const numberConditions = useMemo(() => {
    return data?.listCondition?.length || 0
  }, [data?.listCondition])

  const initialValues = useMemo(() => {
    const initValueForm = formatConditionAttributeForm(data)
    const startDate = data?.start_date
      ? dayjs(data.start_date, FORMAT_DATE_DAYJS_API)
      : null
    const endDate = data?.end_date
      ? dayjs(data.end_date, FORMAT_DATE_DAYJS_API)
      : null
    const rangeDate = startDate && endDate ? [startDate, endDate] : []

    setQueryAttribute(
      convertConditionToQuery(
        initValueForm.conditions,
        initValueForm.relation || 'OR',
        attributes
      )
    )

    return {
      ...initValueForm,
      relation: data.operator,
      date_range: rangeDate
    }
  }, [data.conditions, data.relation, attributes])

  const handleValuesChange = (_: any, values: any) => {
    const query = convertConditionToQuery(
      values.conditions,
      values.relation,
      attributes
    )
    setQueryAttribute(query)
  }

  const handleFinish = (values: any) => {
    if (values) {
      const nodeChanged = nodes.find((node) => node.id === id) as Node
      const result = formatConditonAttribute(values.conditions, attributes)
      nodeChanged.data = {
        listCondition: result,
        operator: values.relation || 'OR',
        start_date: values.date_range[0].format(FORMAT_DATE_DAYJS_API),
        end_date: values.date_range[1].format(FORMAT_DATE_DAYJS_API)
      }

      nodeChanged.selected = true
      if (!nodeChanged) {
        return
      }
      onUpdate(nodeChanged)
      onClose({ isReset: false })
    }
  }

  return (
    <>
      <WorkflowBuilderNodeTemplate
        color={color}
        bgColor={bgColor}
        icon={icon}
        title={title}
        description={`${number} users`}
        onClick={onOpen}
        targets={
          <WorkflowBuilderHandle
            id={`${id}-target`}
            type="target"
            position={Position.Top}
          />
        }
        sources={
          <NodeHandleNumber>
            <WorkflowBuilderHandle
              id={`${id}-source`}
              type="source"
              position={Position.Bottom}
            />
          </NodeHandleNumber>
        }
        isShowDivider>
        {numberConditions ? (
          <StyledDescription>
            Only create new segment or updates if user meets&nbsp;
            <strong>
              {numberConditions}
              &nbsp;conditions
            </strong>
          </StyledDescription>
        ) : (
          <StyledDescription>No condition selected</StyledDescription>
        )}
      </WorkflowBuilderNodeTemplate>
      <WorkflowBuilderDrawerTemplateRef
        open={open}
        title={title}
        width={modalWidth}
        onClose={() => {
          onClose({ isReset: true })
        }}
        onCancel={onCancel}
        onUpdate={form.submit}>
        <Form
          form={form}
          initialValues={initialValues}
          onFinish={handleFinish}
          onValuesChange={handleValuesChange}>
          <FormUserAttribute attributes={attributes} />
        </Form>
        <br />
        <StyleQueryString>
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>
            {queryAttribute}
          </ReactMarkdown>
        </StyleQueryString>
      </WorkflowBuilderDrawerTemplateRef>
    </>
  )
}

WFUserAttributes.validate = (node) => {
  if (
    !node.data ||
    !node.data?.end_date ||
    !node.data?.start_date ||
    !node.data?.operator ||
    !node.data?.listCondition ||
    !node.data?.listCondition?.length
  ) {
    return false
  }
  return true
}
export { WFUserAttributes }
