import {
  WFNodeDescription,
  WFNodeDivider,
  WFNodeHeader,
  WFNodeHeaderInner,
  WFNodeIcon,
  WFNodeInfo,
  WFNodeSourceContainer,
  WFNodeTargetContainer,
  WFNodeTitle,
  WFNodeTitleOutside
} from './index.styled'
import { Infor } from 'icons'
import { MouseEventHandler, ReactNode, useMemo } from 'react'

export type WorkflowBuilderNodeTemplateProps = {
  color?: string
  bgColor?: string
  icon?: AntIconComponent | string
  titleOutside?: string
  title?: string
  description?: ReactNode
  onClick?: MouseEventHandler<HTMLElement>
  isShowDivider?: boolean
  targets?: ReactNode
  children?: ReactNode
  sources?: ReactNode
}
export function WorkflowBuilderNodeTemplate(
  props: WorkflowBuilderNodeTemplateProps
) {
  const {
    color,
    bgColor,
    icon: Icon,
    titleOutside,
    title,
    description,
    onClick,
    isShowDivider = false,
    targets,
    children,
    sources
  } = props

  const hasTargets = useMemo(() => Boolean(targets), [targets])
  const hasSources = useMemo(() => Boolean(sources), [sources])

  return (
    <>
      {hasTargets && <WFNodeTargetContainer>{targets}</WFNodeTargetContainer>}
      {Boolean(titleOutside) && (
        <WFNodeTitleOutside>{titleOutside}</WFNodeTitleOutside>
      )}
      <WFNodeHeader>
        {Icon && (
          <WFNodeIcon bgColor={bgColor} color={color}>
            <Icon />
          </WFNodeIcon>
        )}
        <WFNodeHeaderInner>
          <WFNodeTitle title={title}>{title}</WFNodeTitle>
          {Boolean(description) && (
            <WFNodeDescription>{description}</WFNodeDescription>
          )}
        </WFNodeHeaderInner>
        <WFNodeInfo icon={<Infor />} onClick={onClick} type="link" />
      </WFNodeHeader>
      {(isShowDivider || (Boolean(children) && isShowDivider)) && (
        <WFNodeDivider />
      )}
      {children}
      {hasSources && <WFNodeSourceContainer>{sources}</WFNodeSourceContainer>}
    </>
  )
}
