import { CampaignStepSelectTemplatesParams } from './CampaignStepSelectTemplate'
import {
  StyledStepSelectCell,
  StyledStepSelectCellContent,
  StyledStepSelectCellImage
} from './CampaignStepSelectTemplate.styled'
import { Row, TableColumnProps, Tooltip } from 'antd'
import { Avatar } from 'components/atoms/avatar'
import { FORMAT_DAY_MONTH_YEAR_HOUR, LIST_COLOR_AVATAR } from 'constants/common'
import { TEMPLATE_STATUS, TEMPLATE_TYPE } from 'constants/template'
import dayjs from 'dayjs'
import { TemplateImage } from 'icons'
import { mapTypeToMedium } from 'pages/templateBuilderList/helpers'
import { TemplateServices } from 'services/template'

export const templateStatusSelect = {
  [TEMPLATE_TYPE.EMAIL]: TEMPLATE_STATUS.RELEASE,
  [TEMPLATE_TYPE.WEB_PUSH]: TEMPLATE_STATUS.RELEASE,
  [TEMPLATE_TYPE.APP_PUSH]: TEMPLATE_STATUS.RELEASE,
  [TEMPLATE_TYPE.LP_PAGE]: TEMPLATE_STATUS.RELEASE,
  [TEMPLATE_TYPE.WHATSAPP]: TEMPLATE_STATUS.APPROVED,
  [TEMPLATE_TYPE.VNPT]: TEMPLATE_STATUS.RELEASE,
  [TEMPLATE_TYPE.TWILIO]: TEMPLATE_STATUS.RELEASE, // TODO
  sms: TEMPLATE_STATUS.RELEASE
}

type NameComponentProps = {
  id?: string | number
  title?: string
  thumbnail?: string
}
const NameComponent = (props: NameComponentProps) => {
  return (
    <StyledStepSelectCell className="name">
      <StyledStepSelectCellImage>
        {props?.thumbnail ? (
          <img src={props.thumbnail} alt="" />
        ) : (
          <TemplateImage />
        )}
      </StyledStepSelectCellImage>
      <StyledStepSelectCellContent>
        <Tooltip title={props?.title}>
          <h5 className="title">{props?.title}</h5>
        </Tooltip>
        <p className="id">ID: {props.id}</p>
      </StyledStepSelectCellContent>
    </StyledStepSelectCell>
  )
}

export const selectTemplatesColumns: TableColumnProps<any>[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: 60,
    render(_, record) {
      return (
        <NameComponent
          id={record?.id}
          title={record?.name}
          thumbnail={record?.thumbnail}
        />
      )
    }
  },
  {
    title: 'Medium',
    dataIndex: 'type',
    width: 60,
    render(type, record, index) {
      const hasType = Boolean(record.type in mapTypeToMedium)
      const icon = hasType ? mapTypeToMedium[record.type]?.icon : ''
      const label = hasType ? mapTypeToMedium[record.type]?.label : ''
      return (
        <Row align="middle">
          {icon}
          &nbsp;&nbsp;<span>{label}</span>
        </Row>
      )
    }
  },
  {
    title: 'Body',
    dataIndex: 'whats_app_builder',
    width: 80,
    render(whatsapp, record) {
      return (
        <StyledStepSelectCellContent className="whatsapp_body">
          {whatsapp?.body_text || record?.body_html || 'N/A'}
        </StyledStepSelectCellContent>
      )
    }
  },
  {
    title: 'Last Modified',
    dataIndex: '',
    width: 60,
    render(_updated_by, record, index) {
      const updatedAt = dayjs(record?.updated_at)
      const createdAt = dayjs(record?.created_at)
      return (
        <Avatar
          size={32}
          avatar={record?.updated_by_avatar}
          name={
            updatedAt.isValid()
              ? updatedAt.format(FORMAT_DAY_MONTH_YEAR_HOUR)
              : ''
          }
          email={
            createdAt.isValid()
              ? `Created at: ${createdAt.format(FORMAT_DAY_MONTH_YEAR_HOUR)}`
              : ''
          }
          background={LIST_COLOR_AVATAR[index % LIST_COLOR_AVATAR.length]}
        />
      )
    }
  }
]

export const selectTemplatesTabs = [
  { label: 'All', key: 'all', children: null },
  { label: 'Email', key: TEMPLATE_TYPE.EMAIL, children: null },
  { label: 'App Push', key: TEMPLATE_TYPE.APP_PUSH, children: null },
  { label: 'Web Push', key: TEMPLATE_TYPE.WEB_PUSH, children: null },
  { label: 'Landing page', key: TEMPLATE_TYPE.LP_PAGE, children: null },
  { label: 'WhatsApp', key: TEMPLATE_TYPE.WHATSAPP, children: null },
  { label: 'SMS', key: 'sms', children: null }
]

export const fetchStepSelectTemplates = async (
  params: CampaignStepSelectTemplatesParams
) => {
  try {
    const paramsQuery = {
      page: params.pagination.current || 1,
      per_page: params.pagination.pageSize || 20,
      search: params.search,
      site: params.site?.toString(),
      status: params.status,
      type: params.type,
      types: [],
      message_type: params.message_type
    }

    if (params.type === 'sms') {
      paramsQuery.type = 'all'
      paramsQuery.types = [TEMPLATE_TYPE.VNPT, TEMPLATE_TYPE.TWILIO] as never[]
    }
    const response: any = await TemplateServices.getList(paramsQuery)
    if (response._message && !response.data?.data) {
      throw new Error(JSON.stringify(response))
    }
    return {
      data: response.data.data,
      meta: {
        total_items: response.data.total,
        total_page: 0,
        current_page: response.data.current_page,
        per_page: params.pagination.pageSize
      }
    }
  } catch (error) {
    return {
      data: null,
      meta: {
        total_items: 0,
        total_page: 0,
        current_page: params.pagination.current,
        per_page: params.pagination.pageSize
      }
    }
  }
}

export const fetchStepTemplate = async (id: string | number) => {
  try {
    const response: any = await TemplateServices.getById(id)
    if (response._message && !response.data) {
      throw new Error(JSON.stringify(response))
    }

    return response.data.data
  } catch (error) {
    return null
  }
}
