import { WorkflowBuilderControls } from './WorkflowBuilderControls'
import { WorkflowBuilderNode } from './WorkflowBuilderNode'
import { WorkflowBuilderSidebar } from './WorkflowBuilderSidebar'
import { wfSidebarOptions } from './WorkflowBuilderSidebar/index.helpers'
import {
  WorkflowBuilderContext,
  WorkflowBuilderQueriesContext
} from './index.context'
import {
  WFNodeTypesKeys,
  defaultEdgeOptions,
  fitViewOptions,
  initNodes,
  onConnect,
  transferWorkflowStateToWorkflowValue,
  transferWorkflowValueToWorkflowState,
  wfConfigNodeTypes
} from './index.helpers'
import { DnDContainer, DnDWrapper } from './index.styled'
import { message } from 'antd'
import { EventItem } from 'interfaces/event'
import { FieldItem } from 'interfaces/field'
import { Segment } from 'interfaces/segment'
import { Workflow } from 'interfaces/workflow'
import { nanoid } from 'nanoid'
import {
  DragEventHandler,
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'
import ReactFlow, {
  Background,
  BackgroundVariant,
  Connection,
  ConnectionLineType,
  Edge,
  Node,
  NodeTypes,
  ReactFlowInstance,
  ReactFlowProvider,
  applyEdgeChanges,
  applyNodeChanges,
  useEdgesState,
  useNodesState,
  useReactFlow
} from 'reactflow'
import 'reactflow/dist/style.css'

let id = 0
const getId = () => nanoid() + '_' + id++

export type WorkflowValue = Pick<Workflow, 'nodes' | 'edges'>
export type WorkflowState = { nodes: Node[]; edges: Edge[] }
export type WorkflowBuilderProps = {
  value?: WorkflowValue
  defaultValue?: WorkflowValue
  onChange?: (value: WorkflowValue) => void
  site?: string | number
  timezone?: string
  disabled?: boolean
}

export const WorkflowBuilderWithoutRef = (
  props: WorkflowBuilderProps,
  ref?: Ref<ReactFlowInstance>
) => {
  const {
    defaultValue,
    value: valueProps,
    onChange: onChangeProps,
    site,
    timezone,
    disabled
  } = props
  const reactFlowWrapper = useRef<HTMLDivElement>(null)
  const reactFlowInstance = useReactFlow()
  const [nodesData, setNodesData] = useState<Record<string, any>>({})

  const [segments, setSegments] = useState<Record<string, Array<any>>>({})
  const [segmentSelected, setSegmentSelected] = useState<
    Partial<Segment & EventItem>
  >({})
  const [fields, setFields] = useState<Record<string | number, FieldItem[]>>({})

  const { nodes: initNodesProps, edges: initEdgesProps } =
    transferWorkflowValueToWorkflowState({
      nodes: defaultValue?.nodes || valueProps?.nodes || [],
      edges: defaultValue?.edges || valueProps?.edges || []
    })
  const [edges, setEdges, onEdgesChange] = useEdgesState(initEdgesProps || [])
  const [nodes, setNodes, onNodesChange] = useNodesState(initNodesProps || [])

  const onChange = useCallback(
    (values: WorkflowState) => {
      onChangeProps?.(transferWorkflowStateToWorkflowValue(values))
    },
    [onChangeProps]
  )

  const onNodesChangeData = useCallback(
    (nodesChange: Node[]) => {
      const newNodes = nodes.map((node) => {
        const nodeFind = nodesChange.find(({ id }) => id === node.id)
        if (nodeFind) {
          return { ...node, ...nodeFind }
        }

        return node
      })
      setNodes(newNodes)
      onChange({ nodes: newNodes, edges })
    },
    [edges, nodes, onChange, setNodes]
  )

  /** onNodeDragStop */
  const onNodeDragStop = useCallback(
    (nodesChanged: Node[]) => {
      const newNodes = nodes.map((node) => {
        const nodeFind = nodesChanged.find(({ id }) => id === node.id)
        if (!nodeFind) {
          return node
        }
        return { ...node, ...nodeFind }
      })
      setNodes(newNodes)
      onChange({ nodes: newNodes, edges })
    },
    [edges, nodes, onChange, setNodes]
  )

  /** onConnect */
  const handleConnect = useCallback(
    (params: Connection) => {
      const newEdges = onConnect({ nodes, edges })(params)
      if (newEdges) {
        setEdges(newEdges)
        onChange({ nodes, edges: newEdges })
      } else {
        let sourceLabel = ''
        let targetLabel = ''
        nodes.forEach((node) => {
          if (node.id === params.source) {
            sourceLabel = wfConfigNodeTypes[node.type as WFNodeTypesKeys].label
          }
          if (node.id === params.target) {
            targetLabel = wfConfigNodeTypes[node.type as WFNodeTypesKeys].label
          }
        })
        message.warning(`${sourceLabel} cannot connect to ${targetLabel}`)
      }
    },
    [edges, nodes, onChange, setEdges]
  )

  /** onNodesDelete */
  const onNodesDelete = useCallback(
    (nodesChange: Node[]) => {
      if (!nodesChange.length) return

      const newEdges = edges.filter((edge) => {
        const { source, target } = edge
        return !nodesChange.some(({ id }) => id === source || id === target)
      })

      const newNodes = applyNodeChanges(
        nodesChange.map((node) => {
          return { id: node.id, type: 'remove' }
        }),
        nodes
      )

      setNodes(newNodes)
      onChange({ nodes: newNodes, edges: newEdges })
    },
    [edges, nodes, onChange, setNodes]
  )

  /** onEdgesDelete */
  const onEdgesDelete = useCallback(
    (edgesDelete: Edge[]) => {
      const newEdges = applyEdgeChanges(
        edgesDelete.map((edge) => ({ id: edge.id, type: 'remove' })),
        edges
      )
      setEdges(newEdges)
      onChange({ nodes, edges: newEdges })
    },
    [edges, nodes, onChange, setEdges]
  )

  /** Trigger event for drag & drop from sidebar to reactflow */
  const onDragOver: DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }
  /** onDrop for drag & drop from sidebar to reactflow */
  const onDrop: DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault()

    const reactFlowBounds =
      reactFlowWrapper.current?.getBoundingClientRect() as DOMRect
    const type = event.dataTransfer.getData('application/reactflow')

    const validateType =
      typeof type === 'undefined' || !type || !(type in wfConfigNodeTypes)

    // validate Node Starting Point
    const validateStartingPoint =
      nodes.every(
        ({ type }) => (type as WFNodeTypesKeys) !== 'STARTING_POINT'
      ) && type !== 'STARTING_POINT'

    // check if the dropped element is valid
    if (validateType) {
      message.error('This node is not valid!')
      return
    }

    if (validateStartingPoint) {
      message.error('Node STARTING POINT must be added first!')
      return
    }

    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top
    })
    const newNode = {
      id: getId(),
      type,
      position,
      data: { label: wfConfigNodeTypes[type as WFNodeTypesKeys].label }
    }
    const newNodes = nodes.concat(newNode)
    setNodes(newNodes)
    onChange({ nodes: newNodes, edges })
  }

  /** Console log error */
  const onError = (code: string, message: string) => {
    if (typeof window === 'undefined') return

    const isShowLog =
      window.sessionStorage.getItem('WF_DEV') === 'true' ||
      window.sessionStorage.getItem('WF_DEV')
    if (isShowLog) {
      console.log('*** WF LOG : ', code, ' ==> ', message)
    }
  }

  /** Combine all components to WorkflowBuilderNode */
  const nodeTypes = useMemo(() => {
    const nodeTypes: NodeTypes = {
      default: WorkflowBuilderNode,
      input: WorkflowBuilderNode,
      output: WorkflowBuilderNode,
      group: WorkflowBuilderNode
    }
    const keys = Object.keys(wfConfigNodeTypes)
    keys.forEach((key) => {
      nodeTypes[key] = WorkflowBuilderNode
    })
    return nodeTypes
  }, [])

  useEffect(() => {
    const { nodes, edges } = transferWorkflowValueToWorkflowState({
      nodes: valueProps?.nodes || initNodes,
      edges: valueProps?.edges || []
    })
    setEdges(edges)
    setNodes(nodes)
  }, [setEdges, setNodes, valueProps?.nodes, valueProps?.edges])

  useImperativeHandle(ref, () => reactFlowInstance, [reactFlowInstance])

  return (
    <WorkflowBuilderQueriesContext.Provider
      value={{
        segments,
        setSegments,
        fields,
        setFields,
        segmentSelected,
        setSegmentSelected
      }}>
      <WorkflowBuilderContext.Provider
        value={{
          nodesData,
          setNodesData,
          site,
          timezone,
          disabled,
          edges,
          nodes,
          onChange: onChangeProps,
          setEdges,
          setNodes,
          onNodesDelete,
          onNodesChangeData
        }}>
        <DnDContainer id="workflow" className="workflow">
          <WorkflowBuilderSidebar
            options={wfSidebarOptions}
            className="sidebar"
          />
          <DnDWrapper ref={reactFlowWrapper}>
            <ReactFlow
              // nodesDraggable={!disabled}
              nodesConnectable={!disabled}
              nodes={nodes}
              nodeTypes={nodeTypes}
              edges={edges}
              onNodeDragStop={(e, node, nodes) => onNodeDragStop(nodes)}
              onNodesDelete={onNodesDelete}
              onEdgesDelete={onEdgesDelete}
              onConnect={handleConnect}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onDragOver={onDragOver}
              onDrop={onDrop}
              onError={onError as any}
              connectionRadius={30}
              connectionLineType={defaultEdgeOptions.type as ConnectionLineType}
              defaultEdgeOptions={defaultEdgeOptions}
              fitViewOptions={fitViewOptions}
              fitView>
              <Background
                size={1}
                gap={16}
                color="#CFCFCF"
                variant={BackgroundVariant.Dots}
              />
              <WorkflowBuilderControls fitViewOptions={fitViewOptions} />
            </ReactFlow>
          </DnDWrapper>
        </DnDContainer>
      </WorkflowBuilderContext.Provider>
    </WorkflowBuilderQueriesContext.Provider>
  )
}

export const WorkflowBuilderWithRef = forwardRef(WorkflowBuilderWithoutRef)

export const WorkflowBuilderWithoutProvider = (
  props: WorkflowBuilderProps,
  ref?: Ref<ReactFlowInstance>
) => {
  return (
    <ReactFlowProvider>
      <WorkflowBuilderWithRef {...props} ref={ref} />
    </ReactFlowProvider>
  )
}

export const WorkflowBuilder = forwardRef(WorkflowBuilderWithoutProvider)
