import {
  useWorkflowBuilder,
  useWorkflowBuilderQueries
} from '../../index.context'
import { WorkflowBuilderDrawerTemplate } from '../WorkflowBuilderDrawerTemplate'
import { WorkflowBuilderHandle } from '../WorkflowBuilderHandle'
import { WorkflowBuilderNodeTemplate } from '../WorkflowBuilderNodeTemplate'
import { WFNode, useNode } from '../index.helpers'
import { wfDelayFormToValue } from './WFDelay/index.helpers'
import { FormInstance } from 'antd'
import { FormWorkflowRef } from 'components/molecules/form/FormWorkflow'
import { FormWorkflowTrigger } from 'components/molecules/form/FormWorkflow/FormWorkflowTrigger'
import { FormEventOptions } from 'components/molecules/form/formEvent'
import { SEGMENT_TYPE } from 'constants/segment'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Position } from 'reactflow'
import styled from 'styled-components'

const WFStyledNoContent = styled.div`
  color: ${({ theme }) => theme?.colors?.gray400};
`

const WFTrigger: WFNode = (props) => {
  const { id, title, bgColor, color, icon, modalWidth, data } = props
  const form = useRef<FormWorkflowRef>(null)

  const { nodes, setNodes, onNodesDelete, onNodesChangeData } =
    useWorkflowBuilder()
  const { segmentSelected } = useWorkflowBuilderQueries()
  const { open, onOpen, onUpdate, onClose } = useNode({
    id,
    form: form.current as FormInstance,
    nodes,
    setNodes,
    onNodesDelete,
    onNodesChangeData
  })
  const [fieldOptions, setFieldOptions] = useState<FormEventOptions>([])

  const segment_type = useMemo(
    () => segmentSelected?.segment_type || SEGMENT_TYPE.DYNAMIC_LIST,
    [segmentSelected?.segment_type]
  )
  const segment_id = useMemo(() => segmentSelected?.id, [segmentSelected?.id])
  const eventNames = useMemo(() => {
    if (!segmentSelected?.segment_type) {
      return [segmentSelected?.list_name]
    }
    if (segmentSelected?.segment_type === SEGMENT_TYPE.EVENT) {
      return (
        segmentSelected?.events_conditions?.map((cond) => cond?.event?.name) ||
        []
      )
    }

    return []
  }, [segmentSelected])
  const initialValues = useMemo(() => {
    const initValue = { ...data }

    if (segment_type === SEGMENT_TYPE.DYNAMIC_LIST) {
      delete initValue?.segment_event_id
      delete initValue?.segment_events_conditions
      return {
        ...initValue,
        events_conditions: initValue?.events_conditions || [
          { event_id: segment_id, conditions: [] }
        ]
      }
    }

    if (segment_type === SEGMENT_TYPE.EVENT) {
      return {
        ...initValue,
        events_conditions: initValue.events_conditions || [
          {
            event_id: segmentSelected.events_conditions?.[0]?.event?.id,
            conditions: []
          }
        ],
        segment_event_id: initValue?.segment_event_id || segment_id,
        segment_events_conditions:
          (
            initValue?.segment_events_conditions ||
            segmentSelected.events_conditions
          )?.map((cond: any) => {
            return { ...cond, event_id: cond.event.id }
          }) || []
      }
    }

    return initValue
  }, [data, segmentSelected.events_conditions, segment_id, segment_type])

  const onFinish = useCallback(
    async (values: any) => {
      const node = nodes.find((node) => node.id === id)
      if (!node) {
        return
      }
      const newValues = { ...values }
      newValues.events_conditions = newValues?.events_conditions.map(
        (eventCond: any) => ({
          ...eventCond,
          conditions:
            eventCond?.conditions?.map((cond: any) => {
              const fieldOption = fieldOptions.find(
                ({ value }) => value === cond?.field
              )
              if (fieldOption) {
                return { ...cond, type: fieldOption.type }
              }
              return cond
            }) || []
        })
      )
      node.data = wfDelayFormToValue(newValues)
      if (segmentSelected?.segment_type === SEGMENT_TYPE.EVENT) {
        newValues.segment_events_conditions =
          segmentSelected?.events_conditions || []
        newValues.segment_event_id = segmentSelected.id
      }

      onUpdate(node)
      onClose({ isReset: false })
    },
    [
      fieldOptions,
      id,
      nodes,
      onClose,
      onUpdate,
      segmentSelected?.events_conditions,
      segmentSelected.id,
      segmentSelected?.segment_type
    ]
  )

  useEffect(() => {
    form.current?.setFieldsValue(initialValues)
  }, [form, initialValues])

  // useEffect(() => {
  //   const node = nodes.find((node) => node.id === id)
  //   if (!node) {
  //     return
  //   }
  //   onUpdate({ ...node, data: initialValues })
  // }, [])

  return (
    <>
      <WorkflowBuilderNodeTemplate
        bgColor={bgColor}
        color={color}
        icon={icon}
        title={title}
        onClick={onOpen}
        isShowDivider
        sources={
          <WorkflowBuilderHandle
            type="source"
            id={`${id}-source`}
            position={Position.Bottom}
          />
        }
        targets={
          <WorkflowBuilderHandle
            type="target"
            id={`${id}-target`}
            position={Position.Top}
          />
        }>
        {(!data?.events_conditions || !data?.events_conditions.length) &&
          !eventNames?.length && (
            <WFStyledNoContent>No condition selected</WFStyledNoContent>
          )}
        {Boolean(eventNames?.length) && (
          <WFStyledNoContent>
            Only create new segment or updates if user meets&nbsp;
            <strong>{eventNames?.join(',')}</strong>
          </WFStyledNoContent>
        )}
      </WorkflowBuilderNodeTemplate>
      <WorkflowBuilderDrawerTemplate
        open={open}
        width={modalWidth}
        title={title}
        onClose={onClose}
        onCancel={onClose}
        onUpdate={form.current?.submit}>
        <FormWorkflowTrigger
          ref={form}
          initialValues={initialValues}
          onFinish={onFinish}
          callbackFieldOptions={(fieldOptions) => setFieldOptions(fieldOptions)}
        />
      </WorkflowBuilderDrawerTemplate>
    </>
  )
}

WFTrigger.validate = (node) => {
  if (!node.data || !node.data?.events_conditions) {
    return false
  }
  return true
}
export { WFTrigger }
