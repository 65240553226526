import { FormWorkflowProps, FormWorkflowRef } from '..'
import {
  StyledFormWorkflowContainer,
  StyledFormWorkflowTemplatePreview
} from '../FormWorkflow.styled'
import { useTemplatePreview } from './FormWorkflowTemplate.helpers'
import Icon from '@ant-design/icons'
import { Button, Form, Skeleton } from 'antd'
import cn from 'classnames'
import { ListTemplate } from 'components/atoms/list'
import {
  PreviewAppPush,
  PreviewWebPush
} from 'components/molecules/templateBuilder'
import { TEMPLATE_TYPE } from 'constants/template'
import { useApp } from 'context/App'
import { Iphone, ScreenEmpty } from 'icons'
import { TemplateBuilderPreviewIframe } from 'pages/templateBuilderCreate/TemplateBuilderPreview'
import View from 'pages/templateBuilderCreate/whatsApp/Preview/view'
import { Ref, forwardRef, useImperativeHandle, useState } from 'react'

export type FormWorkflowTemplateRef = FormWorkflowRef
export type FormWorkflowTemplateProps = FormWorkflowProps & {
  type?: string
  preview?: boolean
  previewLoading?: boolean
  previewInfo?: any
}

const FormWorkflowTemplateRenderFunc = (
  props: FormWorkflowTemplateProps,
  ref?: Ref<FormWorkflowTemplateRef>
) => {
  const {
    type = TEMPLATE_TYPE.EMAIL,
    preview,
    previewLoading,
    previewInfo,
    initialValues,
    ...restProps
  } = props
  const { sites } = useApp()
  const [form] = Form.useForm()
  const [previewMode, setPreviewMode] = useState('mobile')

  console.log('** RYAN FormWorkflowTemplate.tsx 38 previewInfo : ', previewInfo)

  useImperativeHandle(ref, () => form, [form])
  return (
    <Form
      {...restProps}
      form={form}
      initialValues={initialValues}
      className="h-full">
      <StyledFormWorkflowContainer className="form__template">
        <Form.Item
          name="template_id"
          className="form__template_id"
          rules={[{ required: true }]}>
          <ListTemplate type={type} />
        </Form.Item>
        <StyledFormWorkflowTemplatePreview className={cn({ preview }, 'p-4')}>
          <Skeleton active loading={previewLoading} paragraph={{ rows: 5 }}>
            {type === TEMPLATE_TYPE.EMAIL && (
              <div
                className={cn(
                  'w-full h-full',
                  '[&.desktop]:border [&.desktop]:border-solid [&.desktop]:border-neutral100 [&.desktop]:rounded-lg [&.desktop]:bg-neutral50',
                  previewMode
                )}>
                <TemplateBuilderPreviewIframe
                  isMobile={Boolean(previewMode === 'mobile')}
                  htmlTemplate={previewInfo?.template?.body_html || ''}
                />
                <div
                  className={cn(
                    'absolute z-10 bottom-10 right-10 bg-white rounded-md flex flex-col shadow-sm p-1'
                  )}>
                  <Button
                    type="link"
                    onClick={() => setPreviewMode('desktop')}
                    icon={<Icon component={ScreenEmpty} />}
                    className={cn(
                      { active: previewMode === 'desktop' },
                      '[&.active]:bg-primary [&.active]:text-white',
                      'w-[24px] h-[24px] p-0 cursor-pointer rounded-md text-black'
                    )}
                  />
                  <Button
                    type="link"
                    onClick={() => setPreviewMode('mobile')}
                    icon={<Icon component={Iphone} />}
                    className={cn(
                      { active: previewMode === 'mobile' },
                      '[&.active]:bg-primary [&.active]:text-white',
                      'w-[24px] h-[24px] p-0 cursor-pointer rounded-md text-black'
                    )}
                  />
                </div>
              </div>
            )}
            {type === TEMPLATE_TYPE.WEB_PUSH && (
              <PreviewWebPush
                preview={{
                  name: previewInfo?.template?.name,
                  message_type: previewInfo?.message_type,
                  title: previewInfo?.title,
                  description: previewInfo?.description || '',
                  message_icon: previewInfo?.message_icon,
                  image: previewInfo?.image,
                  message_link: previewInfo?.message_link,
                  custom_link_url: previewInfo?.custom_link_url,
                  utm_source: previewInfo?.utm_source,
                  utm_medium: previewInfo?.utm_medium,
                  utm_campaign: previewInfo?.utm_campaign,
                  utm_content: previewInfo?.utm_content,
                  utm_term: previewInfo?.utm_term,
                  site: previewInfo?.template.site,
                  country_code: previewInfo?.template.country_code,
                  site_name: previewInfo?.template.site_name
                }}
                sites={sites}
              />
            )}
            {type === TEMPLATE_TYPE.APP_PUSH && (
              <PreviewAppPush
                preview={{
                  title: previewInfo?.title,
                  push_message: previewInfo?.push_message,
                  image: previewInfo?.image
                }}
              />
            )}
            {[
              TEMPLATE_TYPE.WHATSAPP,
              TEMPLATE_TYPE.TWILIO,
              TEMPLATE_TYPE.VNPT
            ].includes(type) && (
              <View
                bodyExample={previewInfo?.body_example || ''}
                bodyText={
                  previewInfo?.body_text ||
                  previewInfo?.template?.body_html ||
                  ''
                }
                bodyVariable={previewInfo?.body_variable || []}
                footerText={previewInfo?.footer_text || ''}
                headerExample={previewInfo?.header_example || ''}
                headerFormat={previewInfo?.header_format || ''}
                headerHandle={previewInfo?.header_handle || ''}
                headerText={previewInfo?.header_text || ''}
                headerVariable={previewInfo?.header_variable || []}
                buttons={previewInfo?.buttons || []} // TODO
                isMedia={type === TEMPLATE_TYPE.WHATSAPP}
              />
            )}
          </Skeleton>
        </StyledFormWorkflowTemplatePreview>
      </StyledFormWorkflowContainer>
    </Form>
  )
}

export { useTemplatePreview }
export const FormWorkflowTemplate = forwardRef(FormWorkflowTemplateRenderFunc)
