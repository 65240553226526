import { CampaignDrawer } from '../CampaignDrawer'
import CampaignHeader from '../CampaignHeader/CampaignHeader'
import { CampaignHeaderValue } from '../CampaignHeader/CampaignHeader.helpers'
import {
  CAMPAIGN_ACTIONS,
  CAMPAIGN_TABLE_COOKIE,
  CampaignTableState,
  OPTION_FILTER_STATUS,
  REQUIRE_COLUMNS,
  campaign_columns,
  campaign_key,
  campaign_sort_key,
  checkAuthCampaignActions,
  getCampaignQueryFromParams,
  initCampaignState,
  initialStateFunc,
  saveCampaignStateToParams
} from './CampaignTable.helpers'
import {
  StyleContainerTable,
  StyleWrapperAction,
  StyleWrapperFilter,
  StyledFilterIcon
} from './CampaignTable.styled'
import {
  Avatar,
  Button,
  Dropdown,
  Modal,
  TablePaginationConfig,
  notification
} from 'antd'
import cn from 'classnames'
import { DropdownSort } from 'components/atoms/dropdown'
import {
  DropdownCheckbox,
  DropdownCheckboxProps
} from 'components/atoms/dropdown/DropdownCheckbox'
import { TableScroll } from 'components/atoms/table'
import { menuRoutes } from 'components/molecules/layout'
import { ModalSendEmail } from 'components/molecules/templateBuilder'
import { RES_STATUS, sort } from 'constants/common'
import { SITE_FLAGS } from 'constants/site'
import AppContext, { AppContextProps } from 'context/App'
import { useAuth } from 'context/Auth'
import { useTableScroll } from 'hooks/useTableScroll'
import { CaretDown, Kebab } from 'icons'
import { OptionTypeSite } from 'interfaces'
import { Campaign } from 'interfaces/campaign'
import Cookies from 'js-cookie'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { CampaignServices } from 'services/campaign'
import { NotificationServices } from 'services/notification'
import { TemplateServices } from 'services/template'
import { getCampaignRoute } from 'utils/campaign'

export const CampaignTable = () => {
  const tableWrapRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const location = useLocation()

  const { userAuth } = useAuth()

  const { sites } = useContext<AppContextProps>(AppContext) || {}

  const [state, setState] = useState<CampaignTableState>(initCampaignState)
  const [loading, setLoading] = useState(true)
  const [dataSource, setDataSource] = useState<Campaign[]>([])

  const [openSendEmail, setOpenSendEmail] = useState(false)
  const [preview, setPreview] = useState<any>()

  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(
    null
  )
  const { scrollTableVal, calculate } = useTableScroll(
    tableWrapRef,
    dataSource || []
  )

  // const validationCampaign = (campaign: Campaign) => {
  //   return (
  // (campaign.segments && campaign.sites && campaign.template_id) ||
  // campaign.type === 'automation'
  //   )
  // }
  // const isDisableUpdateStatus = useCallback((campaign: Campaign) => {
  //   return (
  //     campaign.status === CAMPAIGN_STATUS.draft && validationCampaign(campaign)
  //   )
  // }, [])

  const onClickDropdown = useCallback(
    (record: any) => async (item: any) => {
      switch (item.key) {
        case 'edit': {
          navigate(getCampaignRoute(record?.id, record?.template_type))
          break
        }

        case 'update_status': {
          try {
            const response: any = await CampaignServices.actionCampaign(
              record.id,
              {
                action: 'restore'
              }
            )
            if (response.data?.status_code === 403) {
              throw new Error(response.data?.message)
            }
            notification.success({
              message: 'Status is updated successfully.'
            })
            await fetchList(state)
          } catch (err: any) {
            notification.error({
              message: err.message
            })
          }
          break
        }

        case 'insight': {
          navigate(`${menuRoutes.insight}/${record.template_type}/${record.id}`)
          break
        }

        case 'start_campaign': {
          try {
            const response: any = await CampaignServices.actionCampaign(
              record.id,
              {
                action: 'start'
              }
            )
            if (response.data?.status_code === 403) {
              throw new Error(response.data?.message)
            }
            notification.success({
              message: 'Campaign is started successfully.'
            })
            await fetchList(state)
          } catch (err: any) {
            notification.error({
              message: err.message
            })
          }
          break
        }

        case 'cancel': {
          Modal.confirm({
            title: 'Cancel 1 campaign',
            content: (
              <div>
                Are you sure you want to cancel&nbsp;
                <span className="item-delete">{record.name}</span>?
              </div>
            ),
            okText: 'Apply',
            wrapClassName: 'modalWrap--delete',
            icon: null,
            onOk: async () => {
              try {
                const response = await CampaignServices.actionCampaign(
                  record.id,
                  { action: 'cancel' }
                )
                if (response._status !== RES_STATUS.SUCCESS) {
                  throw new Error('')
                }
                notification.success({
                  message: 'The campaign is canceled successfully.'
                })
                await fetchList(state)
              } catch (err) {
                notification.error({
                  message: 'Something went wrong. Please try again!'
                })
              }
            }
          })
          break
        }

        case 'send-test': {
          setOpenSendEmail(true)
          setSelectedCampaign(record)
          break
        }

        case 'preview': {
          setPreview(record)
          break
        }

        case 'duplicate': {
          Modal.confirm({
            title: 'Duplicate the campaign',
            content: (
              <div>
                Are you sure you want to duplicate the campaign&nbsp;
                <span className="item-delete">{record.name}</span>?
              </div>
            ),
            okText: 'Apply',
            wrapClassName: 'modalWrap--delete',
            icon: null,
            onOk: async () => {
              try {
                const response = await CampaignServices.duplicateCampaign(
                  record.id
                )

                if (response?.data?.id) {
                  notification.success({
                    message: 'The campaign is duplicated successfully.'
                  })
                  navigate(
                    getCampaignRoute(
                      response.data.id,
                      response.data.template_type
                    )
                  )
                } else {
                  throw new Error('')
                }
              } catch (err) {
                notification.error({
                  message: 'Something went wrong. Please try again!'
                })
              }
            }
          })
          break
        }

        default:
          break
      }
    },
    [navigate, state]
  )
  const _columns = useMemo(() => {
    const resultFilter = campaign_columns.filter((item) => {
      const _filter =
        REQUIRE_COLUMNS.includes(item.key) ||
        state.params.columns?.includes(item.key)

      // remove column performance when select tab Automation HHS-918
      if (
        state.params.type === 'automation' &&
        item.key === campaign_key.performance
      ) {
        return false
      }

      return _filter
    })
    return resultFilter.map((column) => {
      const col = {
        ...column,
        className: `campaignTableCell--${column.key} ${column.dataIndex}`
      }
      switch (col.key) {
        case campaign_key.actions: {
          return {
            ...col,
            render: (_v: any, _record: any) => {
              const configActions = checkAuthCampaignActions(
                CAMPAIGN_ACTIONS,
                userAuth?.auth || {},
                _record
              )
              return (
                <StyleWrapperAction>
                  <Dropdown
                    trigger={['click']}
                    menu={{
                      items: configActions,
                      onClick: onClickDropdown(_record)
                    }}>
                    <Button type="link" icon={<Kebab />} />
                  </Dropdown>
                </StyleWrapperAction>
              )
            }
          }
        }
        case campaign_key.sites: {
          return {
            ...col,
            render: (_v: any, _record: any) => {
              return (
                <div className="site-wrapper">
                  {_record.sites && (
                    <Avatar.Group
                      maxCount={3}
                      size={34}
                      className="avatar-group">
                      {_record.sites.map((id: number, index: number) => {
                        const site = sites.find(
                          (item: OptionTypeSite) => item.value === id
                        )
                        return (
                          <Avatar
                            key={index.toString()}
                            src={site && SITE_FLAGS?.[site.name]}
                            className="avatar-group-item"
                            size={34}
                            alt={site && site.name}
                          />
                        )
                      })}
                    </Avatar.Group>
                  )}
                </div>
              )
            }
          }
        }
        default: {
          return col
        }
      }
    })
  }, [
    state.params.columns,
    state.params.type,
    onClickDropdown,
    userAuth?.auth,
    sites
    // isDisableUpdateStatus,
  ])

  const handleSendTestAppPush = async (
    templateId: string | number,
    email: string
  ) => {
    const resTemplate = await TemplateServices.getById(templateId)
    if (resTemplate.data.data) {
      try {
        const res = await NotificationServices.appPushTestSendEmail({
          email,
          title: resTemplate.data.data.title,
          body: resTemplate.data.data.push_message
        })
        if (res._statusRes !== 200) {
          throw new Error('')
        }
        setSelectedCampaign(null)
        setOpenSendEmail(false)
        notification.success({
          message: 'Send test email is successfully.'
        })
      } catch (error) {
        notification.error({
          message: 'Something went wrong. Please try again!'
        })
      }
    }
  }

  const handleSendTestEmailTemplate = async (email: string) => {
    try {
      const res = await NotificationServices.templateTestSendEmail({
        email,
        template_id: selectedCampaign?.template_id || ''
      })
      if (res._statusRes !== 200) {
        throw new Error('')
      }
      setSelectedCampaign(null)
      setOpenSendEmail(false)
      notification.success({
        message: 'Send test email is successfully.'
      })
    } catch (error) {
      notification.error({
        message: 'Something went wrong. Please try again!'
      })
    }
  }

  const onSendEmail = async (email: string) => {
    if (!selectedCampaign) {
      return
    }
    if (
      selectedCampaign.template_type === 'app_push' &&
      selectedCampaign.template_id
    ) {
      handleSendTestAppPush(selectedCampaign.template_id, email)
    }
    if (selectedCampaign.template_type === 'email') {
      handleSendTestEmailTemplate(email)
    }
  }

  const fetchList = useCallback(
    async (params: CampaignTableState) => {
      setLoading(true)
      try {
        const paramsQuery = getCampaignQueryFromParams(params)
        const response = await CampaignServices.getList(paramsQuery)
        if (!response?.data) {
          throw new Error(JSON.stringify(response))
        }
        setLoading(false)
        setDataSource(response.data.data || [])
        const newState: CampaignTableState = {
          ...state,
          ...params,
          pagination: {
            ...state.pagination,
            ...params.pagination,
            total: response.data.meta.total_items
          },
          params: {
            ...state.params,
            ...params.params
          }
        }
        setState(newState)
        const queryString = saveCampaignStateToParams(newState)
        navigate(`${menuRoutes.campaign}?${queryString}`, {
          replace: false,
          state: true
        })
      } catch (err) {
        setLoading(false)
        notification.error({
          message: 'Something went wrong. Please try again!'
        })
      }
    },
    [navigate, state]
  )

  const onChangeTable = useCallback(
    (paginationConfig: TablePaginationConfig) => {
      const newState: CampaignTableState = {
        ...state,
        pagination: {
          ...state.pagination,
          ...paginationConfig
        }
      }
      setState(newState)
      fetchList(newState)
    },
    [fetchList, state]
  )

  const onChangeStatus: DropdownCheckboxProps['onOk'] = useCallback(
    (statuses) => {
      const newState: CampaignTableState = {
        pagination: {
          ...state.pagination,
          current: 1
        },
        params: {
          ...state.params,
          status: statuses as string[]
        }
      }
      fetchList(newState)
    },
    [fetchList, state.pagination, state.params]
  )

  const onChangeSort = useCallback(
    (key: string) => (val: sort) => {
      const newState: CampaignTableState = {
        pagination: {
          ...state.pagination
        },
        params: {
          ...state.params,
          sort_by: key,
          sort_type: val as sort
        }
      }

      if (state.params.sort_by === key && state.params.sort_type === val) {
        newState.params.sort_by = undefined
        newState.params.sort_type = undefined
      }
      fetchList(newState)
    },
    [fetchList, state.pagination, state.params]
  )

  const onChangeHeader = useCallback(
    (value: CampaignHeaderValue) => {
      const newState: CampaignTableState = {
        pagination: {
          ...state.pagination,
          current: 1
        },
        params: {
          ...value
        }
      }

      if (
        JSON.stringify(newState.params.columns?.sort()) !==
          JSON.stringify(state.params.columns?.sort()) ||
        JSON.stringify(newState.params) === JSON.stringify(state.params)
      ) {
        Cookies.set(CAMPAIGN_TABLE_COOKIE, JSON.stringify(newState))
        setState(newState)
        return
      }
      fetchList(newState)
    },
    [fetchList, state.pagination, state.params]
  )

  useEffect(() => {
    const newState = initialStateFunc(new URLSearchParams(location.search))
    fetchList(newState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyleContainerTable ref={tableWrapRef}>
      <TableScroll
        columns={_columns}
        dataSource={dataSource}
        pagination={state.pagination}
        loading={loading}
        scroll={{ y: scrollTableVal }}
        title={() => (
          <CampaignHeader
            value={state.params}
            onChange={onChangeHeader}
            onTransitionEnd={calculate}
          />
        )}
        components={{
          header: {
            cell: ({ children, ...restProps }: any) => {
              const key = campaign_sort_key.reduce((curKey, key) => {
                if (restProps.className.includes(key)) {
                  return key
                }
                return curKey
              }, '')
              const isSort = key ? state.params.sort_by === key : false
              const isFilterStatus = restProps.className.includes('status')
              const isSendTime = restProps.className.includes('start_datetime')
              if (isFilterStatus) {
                return (
                  <th {...restProps}>
                    <StyleWrapperFilter>
                      {children}
                      <DropdownCheckbox
                        title="FILTER STATUS"
                        description="Select status to filter"
                        options={OPTION_FILTER_STATUS}
                        value={state.params?.status || []}
                        onOk={onChangeStatus}>
                        <StyledFilterIcon>
                          <CaretDown />
                        </StyledFilterIcon>
                      </DropdownCheckbox>
                    </StyleWrapperFilter>
                  </th>
                )
              }
              if (isSendTime) {
                return (
                  <th {...restProps}>
                    <span className={cn({ 'has-sort': !!key })}>
                      {children}
                      {key && (
                        <DropdownSort
                          value={state.params.sort_type}
                          onChange={onChangeSort(key)}
                          sorted={isSort}
                          items={[
                            {
                              label: 'Oldest',
                              type: undefined,
                              key: sort.ascend
                            },
                            {
                              label: 'Lastest',
                              type: undefined,
                              key: sort.descend
                            }
                          ]}
                        />
                      )}
                    </span>
                  </th>
                )
              }
              return (
                <th {...restProps}>
                  <span className={cn({ 'has-sort': !!key })}>
                    {children}
                    {key && (
                      <DropdownSort
                        value={state.params.sort_type}
                        onChange={onChangeSort(key)}
                        sorted={isSort}
                      />
                    )}
                  </span>
                </th>
              )
            }
          }
        }}
        rowKey="id"
        onChange={onChangeTable}
      />
      <ModalSendEmail
        open={openSendEmail}
        setOpenSendEmail={setOpenSendEmail}
        onSendEmail={onSendEmail}
      />
      <CampaignDrawer data={preview} onClose={setPreview} />
    </StyleContainerTable>
  )
}
