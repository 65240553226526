import { optionsTimezone } from './index.helpers'
import {
  StyledContainer,
  StyledIcon,
  StyledLabel,
  StyledSelect
} from './index.styled'
import Icon from '@ant-design/icons'
import { Select, SelectProps } from 'antd'
import { ChevronDown, Info } from 'icons'
import { useMemo } from 'react'

export { optionsTimezone }
export type SelectTimezoneProps = Partial<
  Omit<SelectProps, 'mode' | 'showSearch' | 'suffixIcon'>
> & {
  title?: string
}
export function SelectTimezone(props: SelectTimezoneProps) {
  const { title } = props
  const options = useMemo(
    () => props.options || optionsTimezone,
    [props.options]
  )
  return (
    <StyledContainer>
      <StyledIcon component={Info} className="icon-prefix" />
      <StyledSelect>
        <StyledLabel>{title}</StyledLabel>
        <Select
          {...props}
          showSearch
          options={options}
          suffixIcon={<Icon component={ChevronDown} />}
        />
      </StyledSelect>
    </StyledContainer>
  )
}
