import { ErrorsSegment, FieldData, getSegment, putUpdateSegment } from '.'
import { notification } from 'antd'
import { NamePath } from 'antd/lib/form/interface'
import { menuRoutes } from 'components/molecules'
import {
  SEGMENT_STATUS_KEYS,
  SegmentForm,
  SegmentFormContainerProps,
  SegmentFormContainerRef,
  SegmentFormValue
} from 'components/molecules/segment'
import { PERMISSIONS } from 'constants/permission'
import { useCheckAuth } from 'hooks/useCheckAuth'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { segmentServices } from 'services/segment'

export const SegmentPageEdit = (
  props: SegmentFormContainerProps & { id?: string }
) => {
  const { id } = props
  const isSave = !useCheckAuth({
    permissions: [PERMISSIONS.segment_edit]
  })

  const navigate = useNavigate()

  const segmentFormRef = useRef<SegmentFormContainerRef>(null)
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState<SegmentFormValue>()
  const notFound = useRef(false)

  const isPublic = useMemo(() => {
    return value?.status === SEGMENT_STATUS_KEYS.PUBLIC
  }, [value?.status])

  const handleError = (errors: ErrorsSegment[]) => {
    const fieldOverview: FieldData[] = []
    if (errors.length) {
      errors.forEach((error) => {
        if (
          typeof error.name === 'string' &&
          ['name'].some((name) => name === error.name)
        ) {
          fieldOverview.push({
            name: [error.name],
            errors: error.errors
          })
        } else {
          fieldOverview.push({
            name: error.name as NamePath,
            errors: error.errors
          })
        }
      })
      if (fieldOverview.length && fieldOverview?.[0]?.name) {
        segmentFormRef.current?.overviewForm?.setFields(fieldOverview)
        segmentFormRef.current?.overviewForm?.scrollToField(
          fieldOverview[0].name,
          { behavior: 'smooth', block: 'end' }
        )
        setLoading(false)
        return
      } else {
        fieldOverview.forEach(({ errors }) => {
          notification.error({ message: errors })
        })
        setLoading(false)
        return
      }
    }
  }

  const handleSave = async (values: SegmentFormValue) => {
    setLoading(true)

    if (isPublic) {
      const newValues = {
        id: values?.id,
        name: values?.name,
        tag_ids: values?.tag_ids,
        description: values?.description
      }
      if (values?.id) {
        segmentServices
          .updateSegment(values.id, newValues)
          .then((res: any) => {
            if (res.data && !res._message) {
              notification.success({
                message: (
                  <span>
                    Status segment <strong>{res.data?.name}</strong> is updated
                    successfully
                  </span>
                )
              })
            }
          })
          .catch((err) => {
            console.log('** RYAN SegmentPageEdit.tsx 100 err : ', err)
            notification.error({ message: 'Segment cannot updated status.' })
          })
          .finally(() => {
            setLoading(false)
          })
      }
      return
    }

    const { data, errors } = await putUpdateSegment(values)
    handleError(errors)

    if (data?.id) {
      notification.success({
        message: 'Your segment is saved successfully'
      })
      setValue(data)
      setLoading(false)
    }
  }

  const handleOkModal = () => {
    segmentFormRef.current?.setOpenConfirm(false)
    navigate(`${menuRoutes.segment}`)
  }

  useEffect(() => {
    if (!id) {
      return
    }
    ;(async () => {
      setLoading(true)
      try {
        const { data, errors }: any = await getSegment(id)
        if (errors.length) {
          throw new Error(JSON.stringify({ data, errors }))
        }

        setValue(data)
      } catch (error) {
        notFound.current = true
      }
      setLoading(false)
    })()
  }, [])

  return (
    <SegmentForm
      ref={segmentFormRef}
      title="Edit Segment"
      loading={loading}
      disabledKeys={['site_ids', 'segment_type']}
      notFound={notFound.current}
      value={value}
      onSave={handleSave}
      saveButtonProps={{ disabled: isSave }}
      confirmModalProps={{
        onOk: handleOkModal
      }}
    />
  )
}
