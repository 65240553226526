import Icon from '@ant-design/icons'
import { Button, ButtonProps, DatePickerProps } from 'antd'
import { DatePicker } from 'components/atoms/datePicker'
import dayjs, { Dayjs } from 'dayjs'
import { Minus } from 'icons'
import { Ref, forwardRef, useEffect, useState } from 'react'

type FormWorkflowDatePickerState = any

export type FormWorkflowDatePickerValue = {
  from: string
  to: string
} | null
export type FormWorkflowDatePickerRef = HTMLDivElement
export type FormWorkflowDatePickerProps = Pick<DatePickerProps, 'size'> & {
  type?: string
  format?: string
  defaultValue?: FormWorkflowDatePickerValue
  value?: FormWorkflowDatePickerValue
  onChange?: (value: FormWorkflowDatePickerValue) => void
  onRemove?: ButtonProps['onClick']
}

const FORMAT_DAYJS_TIME_WINDOW = 'YYYY-MM-DD HH:mm'
const FormWorkflowDatePickerWithoutRef = (
  props: FormWorkflowDatePickerProps,
  ref?: Ref<FormWorkflowDatePickerRef>
) => {
  const {
    type,
    size,
    format = FORMAT_DAYJS_TIME_WINDOW,
    defaultValue,
    value: valueProps,
    onChange,
    onRemove
  } = props
  const [value, setValue] = useState<FormWorkflowDatePickerState>(
    formatFormWorkflowRange(defaultValue || valueProps || null, format, type)
  )

  const handleChangeSingleDay = (
    val: FormWorkflowDatePickerState,
    valString: string | null
  ) => {
    const toDay = val?.isValid() ? (val as Dayjs).add(60, 'minutes') : val
    setValue([val, toDay])
    onChange?.({ from: valString as string, to: toDay?.format(format) })
  }

  const handleChange = (
    val: FormWorkflowDatePickerState,
    valString: [string, string] | null
  ) => {
    setValue(val)
    onChange?.({ from: valString?.[0] as string, to: valString?.[1] as string })
  }

  useEffect(() => {
    setValue(formatFormWorkflowRange(valueProps || null))
  }, [valueProps])

  return (
    <div ref={ref} className="flex gap-2 items-center">
      {type === 'single_day' ? (
        <DatePicker
          size={size}
          format={format}
          value={value?.[0]}
          showTime
          showSecond={false}
          onChange={handleChangeSingleDay}
        />
      ) : (
        <DatePicker.RangePicker
          size={size}
          format={format}
          value={value}
          onChange={handleChange}
          showTime
          className="flex-1 min-w-0"
        />
      )}
      {!!onRemove && (
        <Button
          danger
          icon={<Icon component={Minus} />}
          shape="circle"
          onClick={onRemove}
          className="w-[16px] h-[16px] flex items-center justify-center rounded-full p-0 min-w-0"
        />
      )}
    </div>
  )
}
export const FormWorkflowDatePicker = forwardRef(
  FormWorkflowDatePickerWithoutRef
)

function formatFormWorkflowRange(
  value: FormWorkflowDatePickerValue,
  format?: string,
  type?: string
): FormWorkflowDatePickerState {
  if (value?.from && value?.to) {
    const fromDay = dayjs(value.from, format, true)
    const toDay = dayjs(value.to, format, true)
    if (fromDay.isValid() && toDay.isValid()) {
      return [fromDay, toDay]
    }
  }

  return null
}
