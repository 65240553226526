import { WorkflowState, WorkflowValue } from '.'
import { WFABSplit } from './WorkflowBuilderNode/components/WFABSplit'
import { WFAppPush } from './WorkflowBuilderNode/components/WFAppPush'
import { WFDelay } from './WorkflowBuilderNode/components/WFDelay'
import { WFEmail } from './WorkflowBuilderNode/components/WFEmail'
import { WFNodeExit } from './WorkflowBuilderNode/components/WFNodeExit'
import { WFNodeStartingPoint } from './WorkflowBuilderNode/components/WFNodeStartingPoint'
import { WFPerformAnEvent } from './WorkflowBuilderNode/components/WFPerformAnEvent'
import { WFTimeWindow } from './WorkflowBuilderNode/components/WFTimeWindow'
import { WFTrigger } from './WorkflowBuilderNode/components/WFTrigger'
import { WFTrueFalse } from './WorkflowBuilderNode/components/WFTrueFalse'
import { WFTwilioSMS } from './WorkflowBuilderNode/components/WFTwilioSMS/WFTwilioSMS'
import { WFUserAttributes } from './WorkflowBuilderNode/components/WFUserAttributes'
import {
  WFWaitUntil,
  formatConnectWaitUntilData
} from './WorkflowBuilderNode/components/WFWaitUntil'
import { WFWebPush } from './WorkflowBuilderNode/components/WFWebPush'
import { WFWhatsApp } from './WorkflowBuilderNode/components/WFWhatsApp'
import { TWILIO_WORKFLOW_DISABLED_PRODUCT } from 'constants/env'
import { theme } from 'constants/theme'
import {
  AbSplitBranch,
  Delay,
  Email2,
  Exit,
  FacebookAds,
  GAMBanner,
  GoogleAds,
  InAppMessage,
  LandingPage,
  LeadPopup,
  LineChat,
  PerformAnEvent,
  Push,
  StartingPoint,
  TimeWindow,
  Trigger2,
  TruefalseBranch,
  TwilioSMS,
  UserAttributes,
  Viber2,
  WaitUntil,
  WhatsApp
} from 'icons'
import { WorkflowEdge, WorkflowNode } from 'interfaces/workflow'
import { nanoid } from 'nanoid'
import { useMemo } from 'react'
import {
  Connection,
  ConnectionLineType,
  DefaultEdgeOptions,
  Edge,
  MarkerType,
  Node,
  addEdge
} from 'reactflow'

export const fitViewOptions = { maxZoom: 1 }
export const defaultEdgeOptions: NonNullable<DefaultEdgeOptions> = {
  type: ConnectionLineType.SmoothStep,
  markerEnd: {
    type: MarkerType.ArrowClosed,
    color: theme?.colors?.teal400
  }
}

export const wfConfigNodeTypes = {
  Default: {
    label: '',
    color: theme.colors.gray300,
    bgColor: '',
    icon: 'span',
    node: 'This module is not available'
  },
  // Actions
  STARTING_POINT: {
    label: 'Starting Point',
    color: theme.colors.green600,
    bgColor: theme.colors.green50,
    icon: StartingPoint,
    modalWidth: undefined,
    node: WFNodeStartingPoint,
    nodeWidth: 287,
    draggable: true
  },
  EXIT: {
    label: 'Exit',
    color: theme.colors.red600,
    bgColor: theme.colors.red50,
    icon: Exit,
    node: WFNodeExit
  },
  USER_ATTRIBUTES: {
    label: 'User Attributes',
    color: theme.colors.violet600,
    bgColor: theme.colors.violet50,
    icon: UserAttributes,
    node: WFUserAttributes,
    modalWidth: 590
  },
  AB_SPLIT: {
    label: 'AB Split',
    color: theme.colors.violet600,
    bgColor: theme.colors.violet50,
    icon: AbSplitBranch,
    node: WFABSplit,
    draggable: false
  },
  TRIGGER: {
    label: 'Trigger',
    color: theme.colors.violet600,
    bgColor: theme.colors.violet50,
    icon: Trigger2,
    node: WFTrigger,
    draggable: true
  },
  PERFORM_AN_EVENT: {
    label: 'Perform an Event',
    color: theme.colors.violet600,
    bgColor: theme.colors.violet50,
    icon: PerformAnEvent,
    node: WFPerformAnEvent
  },
  TRUE_FALSE: {
    label: 'True/False Branch',
    color: theme.colors.violet600,
    bgColor: theme.colors.violet50,
    icon: TruefalseBranch,
    node: WFTrueFalse
  },
  DELAY: {
    label: 'Delay',
    color: theme.colors.yellow800,
    bgColor: theme.colors.yellow50,
    icon: Delay,
    node: WFDelay
  },
  WAIT_UNTIL: {
    label: 'Wait Until',
    color: theme.colors.yellow800,
    bgColor: theme.colors.yellow50,
    icon: WaitUntil,
    node: WFWaitUntil
  },
  TIME_WINDOW: {
    label: 'Time Window',
    color: theme.colors.yellow800,
    bgColor: theme.colors.yellow50,
    icon: TimeWindow,
    node: WFTimeWindow,
    draggable: true
  },

  // Medium
  APP_PUSH: {
    label: 'App Push',
    color: theme.colors.green300,
    bgColor: 'transparent',
    icon: InAppMessage,
    modalWidth: 560,
    node: WFAppPush
  },
  WEB_PUSH: {
    label: 'Web Push',
    color: theme.colors.green300,
    bgColor: 'transparent',
    icon: Push,
    modalWidth: 560,
    node: WFWebPush
  },
  EMAIL: {
    label: 'Email',
    color: theme.colors.green600,
    bgColor: theme.colors.green50,
    icon: Email2,
    modalWidth: 560,
    node: WFEmail
  },
  TWILIO_SMS: {
    label: 'Twillo SMS',
    color: theme.colors.red600,
    bgColor: 'transparent',
    icon: TwilioSMS,
    modalWidth: 560,
    node: WFTwilioSMS,
    draggable: !TWILIO_WORKFLOW_DISABLED_PRODUCT
  },
  LANDING_PAGE: {
    label: 'Landing Page',
    color: '',
    bgColor: 'transparent',
    icon: LandingPage,
    node: WFNodeExit,
    draggable: false
  },
  LEAD_POPUP: {
    label: 'Lead Popup',
    color: '',
    bgColor: 'transparent',
    icon: LeadPopup,
    node: WFNodeExit,
    draggable: false
  },
  WHATSAPP: {
    label: 'WhatsApp',
    color: theme.colors.green800,
    bgColor: 'transparent',
    icon: WhatsApp,
    modalWidth: 560,
    node: WFWhatsApp
  },
  VIBER: {
    label: 'Viber',
    color: '',
    bgColor: 'transparent',
    icon: Viber2,
    node: WFNodeExit,
    draggable: false
  },
  LINE_CHAT: {
    label: 'Line chat',
    color: '',
    bgColor: 'transparent',
    icon: LineChat,
    node: WFNodeExit,
    draggable: false
  },
  FACEBOOK_ADS: {
    label: 'Facebook Ads',
    color: '',
    bgColor: 'transparent',
    icon: FacebookAds,
    node: WFNodeExit,
    draggable: false
  },
  GAM_BANNER: {
    label: 'GAM Banner',
    color: '',
    bgColor: 'transparent',
    icon: GAMBanner,
    node: WFNodeExit,
    draggable: false
  },
  GOOGLE_ADS: {
    label: 'Google Ads',
    color: '',
    bgColor: 'transparent',
    icon: GoogleAds,
    node: WFNodeExit,
    draggable: false
  }
}

export type WFNodeTypesKeys = keyof typeof wfConfigNodeTypes

export type WFNodeTypesProps = Record<
  keyof typeof wfConfigNodeTypes.STARTING_POINT & string,
  any
>

export const wfConfigValidate: Partial<
  Record<WFNodeTypesKeys, WFNodeTypesKeys[]>
> = {
  STARTING_POINT: Object.keys(wfConfigNodeTypes) as WFNodeTypesKeys[],
  TRUE_FALSE: [
    'EMAIL',
    'APP_PUSH',
    'WEB_PUSH',
    'WHATSAPP',
    'TWILIO_SMS',
    'DELAY',
    'TIME_WINDOW',
    'EXIT'
  ],
  USER_ATTRIBUTES: Object.keys(wfConfigNodeTypes).filter((k) => {
    const key = k as WFNodeTypesKeys
    const except: WFNodeTypesKeys[] = [
      'WAIT_UNTIL',
      'USER_ATTRIBUTES',
      'STARTING_POINT',
      'EXIT'
    ]
    return !except.includes(key)
  }) as WFNodeTypesKeys[],
  PERFORM_AN_EVENT: Object.keys(wfConfigNodeTypes).filter((k) => {
    const key = k as WFNodeTypesKeys
    const except: WFNodeTypesKeys[] = [
      'WAIT_UNTIL',
      'PERFORM_AN_EVENT',
      'USER_ATTRIBUTES',
      'STARTING_POINT',
      'EXIT'
    ]
    return !except.includes(key)
  }) as WFNodeTypesKeys[],
  WAIT_UNTIL: Object.keys(wfConfigNodeTypes).filter((k) => {
    const key = k as WFNodeTypesKeys
    const except: WFNodeTypesKeys[] = [
      'DELAY',
      'WAIT_UNTIL',
      'USER_ATTRIBUTES',
      'STARTING_POINT',
      'PERFORM_AN_EVENT',
      'TRUE_FALSE',
      'TRIGGER'
    ]
    return !except.includes(key)
  }) as WFNodeTypesKeys[],
  DELAY: [
    'EMAIL',
    'APP_PUSH',
    'WEB_PUSH',
    'WHATSAPP',
    'TWILIO_SMS',
    'TRUE_FALSE',
    'PERFORM_AN_EVENT'
  ],
  EMAIL: Object.keys(wfConfigNodeTypes).filter((k) => {
    const key = k as WFNodeTypesKeys
    const except: WFNodeTypesKeys[] = [
      'EMAIL',
      'APP_PUSH',
      'WEB_PUSH',
      'WHATSAPP',
      'TWILIO_SMS'
    ]
    return !except.includes(key)
  }) as WFNodeTypesKeys[],
  APP_PUSH: Object.keys(wfConfigNodeTypes).filter((k) => {
    const key = k as WFNodeTypesKeys
    const except: WFNodeTypesKeys[] = [
      'EMAIL',
      'APP_PUSH',
      'WEB_PUSH',
      'WHATSAPP',
      'TWILIO_SMS'
    ]
    return !except.includes(key)
  }) as WFNodeTypesKeys[],
  WEB_PUSH: Object.keys(wfConfigNodeTypes).filter((k) => {
    const key = k as WFNodeTypesKeys
    const except: WFNodeTypesKeys[] = [
      'EMAIL',
      'APP_PUSH',
      'WEB_PUSH',
      'WHATSAPP',
      'TWILIO_SMS'
    ]
    return !except.includes(key)
  }) as WFNodeTypesKeys[],
  TWILIO_SMS: Object.keys(wfConfigNodeTypes).filter((k) => {
    const key = k as WFNodeTypesKeys
    const except: WFNodeTypesKeys[] = [
      'EMAIL',
      'APP_PUSH',
      'WEB_PUSH',
      'TWILIO_SMS',
      'WHATSAPP'
    ]
    return !except.includes(key)
  }) as WFNodeTypesKeys[],
  TIME_WINDOW: [
    'EMAIL',
    'APP_PUSH',
    'WEB_PUSH',
    'WHATSAPP',
    'TWILIO_SMS',
    'TRUE_FALSE',
    'PERFORM_AN_EVENT',
    'WHATSAPP'
  ]
}

export const useConfig = (type?: string) => {
  return useMemo<Partial<WFNodeTypesProps>>(() => {
    if (!type || !(type in wfConfigNodeTypes)) {
      return wfConfigNodeTypes.Default
    }

    return wfConfigNodeTypes?.[type as WFNodeTypesKeys]
  }, [type])
}

export const initNodes = [
  {
    id: nanoid(),
    type: 'STARTING_POINT',
    position: { x: 0, y: 0 },
    data: { label: `Starting Point` }
  }
]

export const optionsSending = [
  {
    label: 'Use campaign settings',
    value: 'settings'
  },
  {
    label: 'Send even if unsubscribed',
    value: 'send_unsubscribed'
  },
  {
    label: 'Don’t send to unsubscribed',
    value: 'done_send_unsubscribed'
  }
]

export function generateEdgeId(edge: WorkflowEdge | Edge) {
  return `reactflow_edge-${edge.source + edge.sourceHandle}-${
    edge.target + edge.targetHandle
  }`
}

export function serializeWorkflowValue(values: WorkflowState): WorkflowState {
  const newValues = { ...values }

  newValues.nodes = values.nodes.map((node) => {
    if (node.type && node.type in wfConfigNodeTypes) {
      const configNode = wfConfigNodeTypes?.[node.type as WFNodeTypesKeys]
      if (typeof configNode.node !== 'string') {
        const serialize =
          configNode.node.serialize ||
          ((value: Node, _: WorkflowState) => value)
        const newNode = serialize(node, values)
        return newNode
      }
    }

    return node
  })

  return newValues
}

export function onConnect({ nodes, edges }: { nodes: Node[]; edges: Edge[] }) {
  return (connection: Connection) => {
    const nodeSource = nodes.find(({ id }) => id === connection.source)
    const nodeTarget = nodes.find(({ id }) => id === connection.target)

    if (!nodeSource || !nodeTarget) {
      return false
    }

    // HHS-1081
    if (
      nodeSource.type === 'STARTING_POINT' &&
      ['PERFORM_AN_EVENT', 'TRIGGER'].includes(
        (nodeTarget.type || '') as string
      )
    ) {
      const edgesTarget = edges
        .filter(({ source }) => source === nodeSource.id)
        .flatMap(({ target }) => {
          return [...nodes.filter(({ id }) => id === target)]
        })
      if (
        edgesTarget.every(({ type }) =>
          ['PERFORM_AN_EVENT', 'TRIGGER'].includes((type || '') as string)
        )
      ) {
        return addEdge(connection, edges)
      }
    }

    const nodeValidate = wfConfigValidate?.[nodeSource.type as WFNodeTypesKeys]
    if (
      !nodeValidate ||
      nodeValidate.includes(nodeTarget.type as WFNodeTypesKeys)
    ) {
      return addEdge(
        connection,
        edges.filter(({ sourceHandle, targetHandle }) => {
          return Boolean(
            sourceHandle !== connection.sourceHandle &&
              targetHandle !== connection.targetHandle
          )
        })
      )
    }

    return false
  }
}

export function isValidConnection(conn: Connection, init: { nodes: Node[] }) {
  const { nodes } = init
  const nodeSource = nodes.find(({ id }) => id === conn.source)
  const nodeTarget = nodes.find(({ id }) => id === conn.target)

  if (!nodeSource || !nodeTarget) {
    return false
  }

  if (nodeSource.type === 'STARTING_POINT' && nodeTarget.type === 'TRIGGER') {
    return true
  }

  const nodeValidate = wfConfigValidate?.[nodeSource.type as WFNodeTypesKeys]
  if (!nodeValidate) {
    return true
  }

  return nodeValidate.includes(nodeTarget.type as WFNodeTypesKeys)
}

export function validateNode(flows: Node[][]) {
  let errorValidation = ''
  if (!flows.length) {
    return 'There aren`t any flows to create!'
  }
  const isValid = flows.every((flow: Node[]) => {
    return (
      flow[0]?.type === 'STARTING_POINT' &&
      flow[flow.length - 1]?.type === 'EXIT'
    )
  })
  if (!isValid) {
    errorValidation =
      'A workflow should begin at the Starting Point and end at the Exit node'
  }
  return errorValidation
}

export function validateWorkflowValue(
  workflow: WorkflowValue
): { id: string; label?: string; message: string }[] {
  const errors: { id: string; label?: string; message: string }[] = []
  workflow.nodes.forEach((node) => {
    if (node.type && node.type in wfConfigNodeTypes) {
      const configNode = wfConfigNodeTypes[node.type as WFNodeTypesKeys].node
      if (typeof configNode !== 'string') {
        const validate = configNode.validate || ((node) => false)
        if (!validate(node, workflow)) {
          errors.push({
            id: node.id?.toString() || '',
            label: node?.data?.label || node?.label,
            message: `${node.type} is not valid!`
          })
        }
      }
    }
  })
  return errors
}

export function transferWorkflowValueToWorkflowState(
  workflow: WorkflowValue
): WorkflowState {
  const workflowValue: WorkflowState = {
    nodes: [],
    edges: []
  }

  workflowValue.nodes =
    workflow.nodes.map((workflowNode: WorkflowNode) => {
      const node: Node = {
        ...workflowNode,
        id: workflowNode.id || '',
        type: workflowNode.type,
        data: {
          ...workflowNode.data,
          label: workflowNode.data?.label || workflowNode?.label
        },
        position: workflowNode.position,
        dragHandle: '.drag-handle'
      }

      return node
    }) || []

  workflowValue.edges =
    workflow.edges.map((workflowEdge: WorkflowEdge) => {
      const edge = {
        ...workflowEdge,
        id: workflowEdge?.id || generateEdgeId(workflowEdge),
        source: workflowEdge.source,
        sourceHandle: workflowEdge.sourceHandle,
        target: workflowEdge.target,
        targetHandle: workflowEdge.targetHandle
      } as Edge

      return edge
    }) || []

  if (!workflowValue.nodes.length) {
    workflowValue.nodes = initNodes
  }

  return workflowValue
}

export function transferWorkflowStateToWorkflowValue(
  workflowState: WorkflowState
): WorkflowValue {
  const nodes: Node[] = workflowState.nodes || []
  const edges: Edge[] = workflowState.edges || []
  return serializeWorkflowValue({
    nodes: nodes.map((node) => {
      const newNode = {
        ...node,
        id: node.id,
        type: node.type,
        position: node.position,
        data: node.data,
        label: node.data?.label || node.type || ''
      }
      if (node.type === 'WAIT_UNTIL') {
        return formatConnectWaitUntilData(newNode, nodes, edges)
      }

      return newNode
    }),
    edges: edges.map((edge) => ({
      ...edge,
      id: generateEdgeId(edge),
      source: edge.source,
      target: edge.target,
      sourceHandle: edge.sourceHandle,
      targetHandle: edge.targetHandle
    }))
  })
}
