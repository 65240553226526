import { useWorkflowBuilder } from '../../../index.context'
import {
  WFDrawerFooter,
  WorkflowBuilderDrawerTemplate
} from '../../WorkflowBuilderDrawerTemplate'
import { WorkflowBuilderHandle } from '../../WorkflowBuilderHandle'
import { WorkflowBuilderNodeTemplate } from '../../WorkflowBuilderNodeTemplate'
import { WFNode, useNode } from '../../index.helpers'
import { WFDrawerButton, WFDrawerFooterGroup } from '../../index.styled'
import Icon from '@ant-design/icons'
import { FormInstance } from 'antd'
import {
  FormWorkflowTemplate,
  useTemplatePreview
} from 'components/molecules/form/FormWorkflow/FormWorkflowTemplate'
import { TEMPLATE_TYPE } from 'constants/template'
import { ChevronLeft, TrashBin } from 'icons'
import { WorkflowNode } from 'interfaces/workflow'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Position } from 'reactflow'

const validateTwilioSMSData: WFNode['validate'] = (node: WorkflowNode) => {
  if (!node.data || !node.data?.template_id) {
    return false
  }

  return true
}
const WFTwilioSMS: WFNode = (props) => {
  const { id, data, title, bgColor, color, icon, modalWidth } = props
  const form = useRef<FormInstance>(null)
  const { disabled, nodes, setNodes, onNodesDelete, onNodesChangeData } =
    useWorkflowBuilder()
  const {
    open,
    onOpen,
    onClose: onCloseUseNode,
    onUpdate,
    onDelete
  } = useNode({
    id,
    form: form.current as FormInstance,
    nodes,
    setNodes,
    onNodesDelete,
    onNodesChangeData
  })
  const [values, setValues] = useState<any>(data)

  const {
    loading: previewLoading,
    previewInfo,
    preview,
    setPreview
  } = useTemplatePreview(values)
  const onClose = useCallback(() => {
    setPreview(false)
    onCloseUseNode({ isReset: true })
    setValues(data)
  }, [data, onCloseUseNode, setPreview])

  const onFinish = (vals: any) => {
    const nodeCur = nodes.find((node) => node.id === id)
    if (nodeCur) {
      nodeCur.data = { ...nodeCur.data, ...vals }
      onUpdate(nodeCur)
      onCloseUseNode()
    }
  }

  const footerComp = useMemo(() => {
    if (preview) {
      return (
        <WFDrawerFooter className="!justify-between">
          <WFDrawerButton
            icon={<Icon component={ChevronLeft} />}
            size="small"
            onClick={() => setPreview(false)}
            className="back">
            Back
          </WFDrawerButton>
          <WFDrawerFooterGroup>
            <WFDrawerButton
              onClick={onDelete(id)}
              icon={<Icon component={TrashBin} />}
              size="small"
            />
            <WFDrawerButton size="small" onClick={onClose}>
              Discard
            </WFDrawerButton>
            <WFDrawerButton
              disabled={disabled}
              onClick={() => form.current?.submit()}
              type="primary"
              size="small">
              Select Template
            </WFDrawerButton>
          </WFDrawerFooterGroup>
        </WFDrawerFooter>
      )
    }
    return (
      <WFDrawerFooter>
        <WFDrawerFooterGroup>
          <WFDrawerButton
            onClick={onDelete(id)}
            icon={<Icon component={TrashBin} />}
            size="small"
          />
          <WFDrawerButton
            onClick={() => {
              !!form.current?.getFieldValue('template_id') && setPreview(true)
            }}
            type="primary"
            size="small">
            Preview Template
          </WFDrawerButton>
        </WFDrawerFooterGroup>
      </WFDrawerFooter>
    )
  }, [preview, onDelete, id, onClose, disabled, setPreview])

  useEffect(() => {
    if (typeof data?.template_id === 'number') {
      setPreview(true)
    }
  }, [open, data?.template_id, setPreview])

  useEffect(() => {
    setValues(data)
  }, [data])

  return (
    <>
      <WorkflowBuilderNodeTemplate
        // isShowDivider={Boolean(webInsights.length)}
        bgColor={bgColor}
        color={color}
        icon={icon}
        title={title}
        description={
          typeof data?.template_id !== 'undefined' &&
          `Template ID : ${data.template_id}`
        }
        onClick={onOpen}
        sources={
          <WorkflowBuilderHandle
            type="source"
            id={`${id}-source`}
            position={Position.Bottom}
          />
        }
        targets={
          <WorkflowBuilderHandle
            type="target"
            id={`${id}-target`}
            position={Position.Top}
          />
        }>
        {/* insight */}
      </WorkflowBuilderNodeTemplate>
      <WorkflowBuilderDrawerTemplate
        open={open}
        width={modalWidth}
        title={title}
        onClose={onClose}
        onUpdate={() => form.current?.submit()}
        footer={footerComp}
        className="disableBodyScroll"
        destroyOnClose>
        <FormWorkflowTemplate
          ref={form}
          type={TEMPLATE_TYPE.TWILIO}
          preview={preview}
          previewLoading={previewLoading}
          previewInfo={previewInfo}
          initialValues={data}
          onFinish={onFinish}
          onValuesChange={(_, valsChanged: any) => {
            setValues(valsChanged)
          }}
        />
      </WorkflowBuilderDrawerTemplate>
    </>
  )
}
WFTwilioSMS.validate = validateTwilioSMSData
export { WFTwilioSMS }
