import {
  StyleFinalUrl,
  StyleWrapperSystem,
  StyledActions,
  StyledPreviewBox,
  StyledWrap
} from './index.styled'
import PreviewAndroid from './previewType/android'
import PreviewMacOS from './previewType/mac'
import PreviewWindow from './previewType/window'
import { Select } from 'antd'
import { Android, Apple, Window } from 'icons'
import { OptionTypeSite } from 'interfaces'
import {
  WebPushSettingsForm,
  webPushUTMKeys
} from 'pages/templateBuilderCreate/webPush/helpers'
import { useCallback, useMemo, useState } from 'react'

const ComponentPreview = {
  ios: PreviewMacOS,
  android: PreviewAndroid,
  window: PreviewWindow
}

export type PreviewProps = {
  preview: WebPushSettingsForm
}

interface PreviewWebPushProps extends PreviewProps {
  sites: OptionTypeSite[]
}

type CollapsedType = 'collapsed' | 'expand'

export interface PreviewMessageProps extends PreviewProps {
  collapsed: CollapsedType
  domain: string
}

export function PreviewWebPush({ preview, sites }: PreviewWebPushProps) {
  const [type, setType] = useState<'ios' | 'android' | 'window'>('ios')
  const [collapsed, setCollapsed] = useState<CollapsedType>('collapsed')

  const handleOnChange = (value: CollapsedType) => {
    setCollapsed(value)
  }

  const paramsUtm = useMemo(() => {
    const params = [] as any[]
    Object.keys(preview).forEach((key) => {
      const valueField = preview[key as keyof typeof preview]
      if (
        webPushUTMKeys.includes(key) &&
        valueField &&
        typeof valueField === 'string'
      ) {
        params.push(`${key}=${valueField}`)
      }
    })
    if (params.length) {
      return params.join('&')
    }
    return ''
  }, [preview])

  const finalUrl = useMemo(() => {
    if (preview.custom_link_url && paramsUtm) {
      return `${preview.custom_link_url}?${paramsUtm}`
    }
    return preview.custom_link_url
  }, [preview.custom_link_url, paramsUtm])

  const domainSite = useMemo(() => {
    const result = sites.find((site) => site.value === preview.site)
    if (!result) {
      return ''
    }
    return result.domain
  }, [preview.site, sites])

  const renderComponentPreview = useCallback(() => {
    if (!type) {
      return null
    }
    const ComponentRender = ComponentPreview[type]
    return (
      <ComponentRender
        collapsed={collapsed}
        preview={preview}
        domain={domainSite}
      />
    )
  }, [type, collapsed, domainSite, preview])

  return (
    <StyledWrap>
      <StyleWrapperSystem>
        <StyledActions>
          <li
            onClick={() => setType('ios')}
            className={type === 'ios' ? 'active' : ''}>
            <Apple />
          </li>
          <li
            onClick={() => setType('window')}
            className={type === 'window' ? 'active' : ''}>
            <Window />
          </li>
          <li
            onClick={() => setType('android')}
            className={type === 'android' ? 'active' : ''}>
            <Android />
          </li>
        </StyledActions>
        <Select
          placeholder="Collapsed View"
          onChange={handleOnChange}
          value={collapsed}
          options={[
            {
              label: 'Collapsed Type',
              value: 'collapsed'
            },
            {
              label: 'Expand Type',
              value: 'expand'
            }
          ]}
          className="select-view"
        />
      </StyleWrapperSystem>
      <StyledPreviewBox>{renderComponentPreview()}</StyledPreviewBox>
      <StyleFinalUrl>
        <p className="title">Final URL</p>
        <p className="url">{finalUrl}</p>
      </StyleFinalUrl>
    </StyledWrap>
  )
}
