import {
  StyleContainer,
  StyleDescription,
  StyleDescriptionWrapper,
  StyleIdText,
  StyleName, // StyleTitleFilter,
  StyleType, // StyleWrapperFilter,
  StyleWrapperInfor
} from './index.styled'
import { capitalizeStr } from 'pages/templateBuilderCreate/templateCopilotDrawer'

interface PanelHeaderTopBarProps {
  name?: string
  id?: number | null
  type?: string
  created_at?: string
  status?: string
  // startDate?: string
  // dateFilter?: any
  // setDateFilter?: (value: [Dayjs, Dayjs] | null) => void
}

export const PanelHeaderTopBar = ({
  name,
  id,
  type,
  created_at,
  status
}: PanelHeaderTopBarProps) => {
  return (
    <StyleContainer>
      <StyleWrapperInfor>
        <StyleName>{name}</StyleName>
        <StyleType>{type}</StyleType>
        {status && <StyleType>{capitalizeStr(status)}</StyleType>}
        <StyleDescriptionWrapper>
          <StyleIdText>ID: {id}</StyleIdText>
          <StyleDescription>Created at {created_at}</StyleDescription>
        </StyleDescriptionWrapper>
      </StyleWrapperInfor>
    </StyleContainer>
  )
}
