import { FieldAttributeServices } from 'services/fieldAttribute'

export const fetchAttributeConditions = async ({
  page = 1,
  perPage = 100000,
  search = ''
} = {}) => {
  try {
    const response = await FieldAttributeServices.getListFields({
      page,
      perPage,
      search
    })
    if (!response.data || !response.data?.data || !response.data.data?.length) {
      throw new Error('')
    }
    const { data, ...rest } = response.data
    return { data, meta: { ...rest } }
  } catch (error) {
    console.log('** ERROR ModalDictionary.helpers.ts error : ', error)
    return { data: [], meta: {} }
  }
}
