import { CachedSystem } from './cachedSystem'
import { SEGMENT_PATHS } from 'constants/apiPaths'
import { ResponseType } from 'interfaces'
import {
  Segment,
  SegmentByCompareType,
  SegmentCreateParams,
  SegmentCustomEventsCreateParams,
  SegmentQueryParams,
  SegmentUpdateParams,
  TagCreateParams
} from 'interfaces/segment'
import { convertQueryParams, getAsPathApi } from 'utils'
import { segmentBaseAxios } from 'utils/api'

// GET : get list of tag
const PREFIX_TAG = 'TagServices/'
const getTagsList = (params: any) => {
  const path = `${SEGMENT_PATHS.tag}?${convertQueryParams(params)}`
  const keyCached = PREFIX_TAG + path
  return CachedSystem.withCached(keyCached, () => segmentBaseAxios.get(path))
}

// GET : get tag detail
const getTag = async (id: string | number) => {
  return segmentBaseAxios.get(`${SEGMENT_PATHS.tag}/${id}`)
}

const createTag = async (params: TagCreateParams) => {
  CachedSystem.clearCached(PREFIX_TAG)
  return segmentBaseAxios.post(SEGMENT_PATHS.tag, params)
}

const updateTag = async (id: string, data: any) => {
  CachedSystem.clearCached(PREFIX_TAG)
  return segmentBaseAxios.put(`${SEGMENT_PATHS.tag}/${id}`, data)
}

const deleteTag = async (id: string | number) => {
  CachedSystem.clearCached(PREFIX_TAG)
  return segmentBaseAxios.delete(`${SEGMENT_PATHS.tag}/${id}`)
}

export const tagServices = {
  getTagsList,
  getTag,
  createTag,
  updateTag,
  deleteTag
}

const PREFIX_SEGMENT = 'SegmentServices/'
// GET : get list of segment
const getSegmentList = (params: SegmentQueryParams) => {
  const keyCached =
    PREFIX_SEGMENT + SEGMENT_PATHS.segment_v2 + JSON.stringify(params)
  return CachedSystem.withCached(keyCached, () => {
    return segmentBaseAxios.get(SEGMENT_PATHS.segment_v2, { params })
  })
}

// GET : get segment detail
const getSegment = (id: string | number) => {
  return segmentBaseAxios.get(
    getAsPathApi(SEGMENT_PATHS.segment_v2_detail, { id })
  )
}

const createSegment = (params: SegmentCreateParams) => {
  CachedSystem.clearCached(PREFIX_SEGMENT)
  return segmentBaseAxios.post<SegmentCreateParams, ResponseType<Segment>>(
    SEGMENT_PATHS.segment,
    params
  )
}

const createSegmentCustomEvents = (params: SegmentCustomEventsCreateParams) => {
  CachedSystem.clearCached(PREFIX_SEGMENT)
  return segmentBaseAxios.post<
    SegmentCustomEventsCreateParams,
    ResponseType<Segment>
  >(SEGMENT_PATHS.segment, params)
}

const updateSegment = (id: string | number, params: SegmentUpdateParams) => {
  CachedSystem.clearCached(PREFIX_SEGMENT)
  return segmentBaseAxios.put(
    getAsPathApi(SEGMENT_PATHS.segment_detail, { id }),
    params
  )
}

const deleteSegment = (id: string | number) => {
  CachedSystem.clearCached(PREFIX_SEGMENT)
  return segmentBaseAxios.delete<null, ResponseType<null>>(
    getAsPathApi(SEGMENT_PATHS.segment_detail, { id })
  )
}

const getSegmentDetail = (props: { id: string | number; category: string }) => {
  return segmentBaseAxios.get<null, ResponseType<any>>(
    getAsPathApi(SEGMENT_PATHS.segment_filter, props)
  )
}

const updateSegmentDetail = (props: {
  id: string
  category: string
  data: any
}) => {
  return segmentBaseAxios.put<any, ResponseType<any>>(
    getAsPathApi(SEGMENT_PATHS.segment_filter, props),
    props.data
  )
}

const createSegmentByCompare = (data: SegmentByCompareType) => {
  return segmentBaseAxios.post(SEGMENT_PATHS.segment, data)
}

const segmentCountByCondition = (params: any) => {
  return segmentBaseAxios.post(SEGMENT_PATHS.count_by_condition, params)
}

export const segmentServices = {
  getSegmentList,
  getSegment,

  createSegment,
  createSegmentCustomEvents,
  updateSegment,
  deleteSegment,
  getSegmentDetail,
  updateSegmentDetail,
  createSegmentByCompare,
  segmentCountByCondition
}

const PREFIX_SITE_SERVICES = 'SiteServices/'

export const siteServices = {
  getSiteList: () => {
    const url = SEGMENT_PATHS.site
    return CachedSystem.withCached(PREFIX_SITE_SERVICES + url, () =>
      segmentBaseAxios.get(url)
    )
  }
}
