import { useWorkflowBuilder } from '../../../index.context'
import { WorkflowBuilderDrawerTemplate } from '../../WorkflowBuilderDrawerTemplate'
import { WorkflowBuilderHandle } from '../../WorkflowBuilderHandle'
import { WorkflowBuilderNodeTemplate } from '../../WorkflowBuilderNodeTemplate'
import { WFNode, useNode } from '../../index.helpers'
import { wfDelayFormToValue, wfDelayValueToForm } from './index.helpers'
import { Form } from 'antd'
import { InputTime } from 'components/atoms/input'
import { numberToInputTime } from 'components/atoms/input/InputTime/index.helpers'
import { SelectTimezone } from 'components/atoms/select'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Position } from 'reactflow'
import { EventServices } from 'services/event'

const MAX_DELAY_TIME = 365 * 24 * 60 * 60 * 1000

const WFDelay: WFNode = (props) => {
  const { id, title, bgColor, color, icon, modalWidth, data } = props
  const [form] = Form.useForm()
  const [eventsName, setEventsName] = useState<string[]>([])
  const { timezone, nodes, setNodes, onNodesDelete, onNodesChangeData } =
    useWorkflowBuilder()
  const { open, onOpen, onClose, onCancel, onUpdate } = useNode({
    id,
    form,
    nodes,
    setNodes,
    onNodesDelete,
    onNodesChangeData
  })

  const getEventName = useCallback(async () => {
    if (!data?.events_conditions || !data?.events_conditions?.length) {
      return
    }
    const eventsName = await Promise.all(
      data.events_conditions.map(async (cond: any) => {
        if (!cond?.event_id) {
          return ''
        }
        try {
          const eventRes = await EventServices.getById(cond.event_id)
          if (!eventRes?.data || eventRes.data?.error) {
            throw new Error('Cannot show event')
          }

          return eventRes.data?.name
        } catch (error) {
          console.log(`** Error ${cond.event_id}`)
          return ''
        }
      })
    )
    setEventsName(eventsName)
  }, [data?.events_conditions])

  const descriptionComp = useMemo(() => {
    const timeObject = numberToInputTime(data?.timeunix || 0)
    return `${timeObject?.day || 0} ds ${timeObject?.hour || 0} hrs ${
      timeObject?.minute || 0
    } m`
  }, [data?.timeunix, eventsName])

  const onUpdateDrawer = useCallback(
    async (values: any) => {
      const node = nodes.find((node) => node.id === id)
      if (!node) {
        return
      }
      node.data = wfDelayFormToValue(values)
      onUpdate(node)
      onClose({ isReset: false })
    },
    [id, nodes, onClose, onUpdate]
  )

  useEffect(() => {
    getEventName()
  }, [getEventName])

  return (
    <>
      <WorkflowBuilderNodeTemplate
        bgColor={bgColor}
        color={color}
        icon={icon}
        title={title}
        description={descriptionComp}
        onClick={onOpen}
        sources={
          <WorkflowBuilderHandle
            type="source"
            id={`${id}-source`}
            position={Position.Bottom}
          />
        }
        targets={
          <WorkflowBuilderHandle
            type="target"
            id={`${id}-target`}
            position={Position.Top}
          />
        }
      />
      <WorkflowBuilderDrawerTemplate
        open={open}
        width={modalWidth}
        title={title}
        onClose={onClose}
        onCancel={onCancel}
        onUpdate={form.submit}
        destroyOnClose>
        <Form
          form={form}
          layout="vertical"
          initialValues={wfDelayValueToForm({ ...data, time_zone: timezone })}
          onFinish={onUpdateDrawer}
          onInvalid={console.log}>
          <Form.Item
            name="time_zone"
            rules={[{ message: 'This field is required!', required: true }]}>
            <SelectTimezone
              disabled
              title="All time are in"
              placeholder="Indochina Time (GMT/UTC +7)"
            />
          </Form.Item>
          <Form.Item
            name="timeunix"
            rules={[
              { message: 'This field is required!', required: true },
              {
                validator(_, value) {
                  if (value > MAX_DELAY_TIME) {
                    return Promise.reject('Limited days in 365 days')
                  }

                  return Promise.resolve()
                }
              }
            ]}>
            <InputTime />
          </Form.Item>
        </Form>
      </WorkflowBuilderDrawerTemplate>
    </>
  )
}

WFDelay.validate = (node) => {
  if (!node.data || !node.data?.timeunix || !node.data?.time_zone) {
    return false
  }
  return true
}
export { WFDelay }
