import { WorkflowState, WorkflowValue } from '..'
import { WFNodeTypesKeys, wfConfigNodeTypes } from '../index.helpers'
import { WorkflowBuilderNodeTemplateProps } from './WorkflowBuilderNodeTemplate'
import { FormInstance } from 'antd'
import { ModalConfirm } from 'components/atoms/modal'
import { WorkflowNode } from 'interfaces/workflow'
import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react'
import { Node, NodeProps } from 'reactflow'

export type WFNodeProps = NodeProps &
  Pick<
    WorkflowBuilderNodeTemplateProps,
    'bgColor' | 'color' | 'icon' | 'title'
  > & {
    modalWidth?: number
  }
export type WFNode = FC<WFNodeProps> & {
  serialize?: (node: Node, values: WorkflowState) => Node
  validate?: (node: WorkflowNode, values: WorkflowValue) => boolean
}

export type UseNodeProps = {
  id?: string
  form: FormInstance<any>
  nodes: Node[]
  setNodes?: Dispatch<SetStateAction<Node<any, string | undefined>[]>>
  onNodesDelete?: (nodesDelete: Node[]) => void
  onNodesChangeData?: (nodesChanged: Node<any, string | undefined>[]) => void
}
export function useNode(props: UseNodeProps) {
  const { id, form, nodes, setNodes, onNodesDelete, onNodesChangeData } = props
  const [open, setOpen] = useState(false)

  const onOpen = useCallback(() => {
    setNodes?.((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          return { ...node, selected: true }
        }
        return { ...node, selected: false }
      })
    )
    setOpen(true)
  }, [id, setNodes])

  const onClose = useCallback(
    ({ unSelected = false, isReset = true } = {}) => {
      if (!unSelected) {
        setNodes?.((nodes) =>
          nodes.map((node) => {
            if (node.id === id) {
              return { ...node, selected: false }
            }
            return node
          })
        )
      }
      if (isReset) {
        form?.resetFields()
      }
      setOpen(false)
    },
    [form, id, setNodes]
  )

  const onDelete = useCallback(
    (nodeId: string) => {
      return () => {
        const nodeFind = nodes.find((node) => node.id === nodeId)
        if (nodeFind) {
          ModalConfirm({
            icon: null,
            closable: true,
            title: 'Deleting 1 item from your workflow',
            content: `You're about to permanently delete the ${
              wfConfigNodeTypes[nodeFind.type as WFNodeTypesKeys]?.label
            } Block from your workflow. This cannot be undone. Continue deletion?`,
            okButtonProps: {
              type: 'default',
              ghost: true,
              danger: true
            },
            onOk: () => {
              onNodesDelete?.([nodeFind])
              setOpen(false)
            }
          })
        }
      }
    },
    [nodes, onNodesDelete]
  )

  const onUpdate = useCallback(
    (nodeChanged: Node) => {
      onNodesChangeData?.([nodeChanged])
    },
    [onNodesChangeData]
  )

  const onCancel = useCallback(() => {
    setOpen(false)
    form.resetFields()
  }, [form])

  return {
    open,
    setOpen,
    onOpen,
    onClose,
    onDelete,
    onUpdate,
    onCancel
  }
}
