import { useConfig } from '../index.helpers'
import {
  WFMenuItem,
  WFMenuItemContainer,
  WFMenuItemIcon,
  WFMenuItemLabel,
  WFMenuItemWrapper,
  WFMenuList,
  WFSidebarContainer,
  WFSidebarTabs,
  WFSidebarTabsExtra,
  WFSidebarTabsPaneContent
} from './index.styles'
import Icon from '@ant-design/icons'
import { Tooltip } from 'antd'
import cn from 'classnames'
import { ArrowNext, ArrowPrev } from 'icons'
import { useCallback, useMemo, useState } from 'react'

export type WorkflowBuilderSidebarOption<T extends string> = {
  type: T
  label: string
  icon?: AntIconComponent
  className?: string
  children?: WorkflowBuilderSidebarOption<T>[]
}

export type WorkflowBuilderSidebarItemProps = Omit<
  WorkflowBuilderSidebarOption<string>,
  'children'
> & {
  draggable?: boolean
  collapsed?: boolean
  onClick?: (type: string) => void
  children?: WorkflowBuilderSidebarItemProps[]
}
export function WorkflowBuilderSidebarItem(
  props: WorkflowBuilderSidebarItemProps
) {
  const {
    type,
    icon,
    label,
    children,
    draggable,
    collapsed,
    className,
    onClick: onClickProps
  } = props

  const Container = collapsed ? Tooltip : 'div'
  const containerProps = collapsed
    ? { overlayInnerStyle: { padding: '4px 10px' } }
    : {}
  const configs = useConfig(type)
  const hasChild = useMemo(
    () => Boolean(children && children.length),
    [children]
  )

  const draggableItem = useMemo(
    () =>
      hasChild
        ? Boolean(draggable)
        : typeof configs.draggable === 'undefined'
        ? true
        : configs.draggable,
    [hasChild, configs.draggable, draggable]
  )

  const onDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    event.dataTransfer.setData('application/reactflow', type)
    event.dataTransfer.effectAllowed = 'move'
  }

  const onClick = useCallback(() => {
    if (!hasChild) {
      onClickProps?.(type)
    }
  }, [hasChild, onClickProps, type])

  return (
    <WFMenuItem onClick={onClick} className={cn(className, { hasChild })}>
      {label && (
        <WFMenuItemContainer
          draggable={draggableItem}
          onDragStart={onDragStart}>
          <Container title={label} placement="right" {...containerProps}>
            <WFMenuItemWrapper>
              {icon && (
                <WFMenuItemIcon color={configs.color} bgColor={configs.bgColor}>
                  <Icon component={icon} className="icon" />
                </WFMenuItemIcon>
              )}
              <WFMenuItemLabel>
                <div className="label">{label}</div>
              </WFMenuItemLabel>
            </WFMenuItemWrapper>
          </Container>
        </WFMenuItemContainer>
      )}
      {hasChild && (
        <WorkflowSidebarList
          items={children || []}
          isChildren
          collapsed={collapsed}
          onClick={onClickProps}
        />
      )}
    </WFMenuItem>
  )
}

export type WorkflowBuilderSidebarListProps = {
  items: WorkflowBuilderSidebarItemProps[]
  isChildren?: boolean
  collapsed?: boolean
  className?: string
  onClick?: (type: string) => void
}
export function WorkflowSidebarList(props: WorkflowBuilderSidebarListProps) {
  const { items, isChildren, collapsed, className, onClick } = props
  if (!items.length) {
    return null
  }
  return (
    <WFMenuList className={cn(className, { isChildren })}>
      {items.map((item) => {
        return (
          <WorkflowBuilderSidebarItem
            {...item}
            key={item.type}
            onClick={onClick}
            collapsed={collapsed}
            draggable={Boolean(isChildren)}
          />
        )
      })}
    </WFMenuList>
  )
}

/**
 * In `options` array, FIRST Level are shown for tabs
 * Next Level from FIRST Level of `options` array is selection.
 */
export type WorkflowBuilderSidebarProps = {
  options?: WorkflowBuilderSidebarOption<string>[]
  className?: string
  onClickItem?: (type: string) => void
}
export function WorkflowBuilderSidebar(props: WorkflowBuilderSidebarProps) {
  const { options, className, onClickItem } = props
  const [collapsed, setCollapsed] = useState(false)

  const onCollapsed = useCallback(() => {
    setCollapsed(!collapsed)
  }, [collapsed])

  const tabsItems = useMemo(() => {
    if (!options || !options.length) {
      return []
    }
    return options?.map((option) => {
      return {
        key: option.type,
        label: option.label,
        children: (
          <WFSidebarTabsPaneContent>
            <WorkflowSidebarList
              items={option.children || []}
              collapsed={collapsed}
              onClick={onClickItem}
            />
          </WFSidebarTabsPaneContent>
        )
      }
    })
  }, [collapsed, onClickItem, options])

  if (!tabsItems.length) {
    return null
  }

  return (
    <WFSidebarContainer className={cn({ collapsed }, className)}>
      <WFSidebarTabs
        items={tabsItems}
        defaultActiveKey={options?.[0]?.type || ''}
        tabBarExtraContent={
          <WFSidebarTabsExtra
            type="link"
            size="small"
            icon={collapsed ? <ArrowNext /> : <ArrowPrev />}
            onClick={onCollapsed}
          />
        }
      />
    </WFSidebarContainer>
  )
}
