import { SegmentAnalytic } from '../SegmentAnalytic'
import { getSegmentPredictionSliderValue } from '../SegmentPrediction'
import {
  SegmentPredictionDrawer,
  defaultValueSegmentPrediction
} from '../SegmentPredictionDrawer'
import { SEGMENT_STATUS_KEYS } from '../SegmentStatus'
import { SegmentUserCounter } from '../SegmentUserCounter'
import {
  FetchQueryAnalyticsInput,
  FetchQueryAnalyticsOutput,
  SegmentFormContainerProps,
  SegmentFormContainerRef,
  SegmentFormValue,
  defaultQueryAnalytics,
  defaultSegmentFormValue,
  fetchQueryAnalytics,
  formatCountDataToAnalytics,
  formatSegmentForm,
  useCountAllSite,
  useSegmentPredictionDrawer
} from './SegmentForm.helpers'
import {
  StyledSegmentForm,
  StyledSegmentFormBox,
  StyledSegmentFormCol,
  StyledSegmentFormRow
} from './SegmentForm.styled'
import { SegmentFormComparison } from './SegmentFormComparison'
import { SegmentFormHeader } from './SegmentFormHeader'
import {
  SegmentFormOverview,
  SegmentFormOverviewValue
} from './SegmentFormOverview'
import { SegmentFormQuery, SegmentFormQueryValue } from './SegmentFormQuery'
import { Alert, Col, FormInstance, Row, Spin, notification } from 'antd'
import cn from 'classnames'
import { ModalEmpty } from 'components/atoms/modal'
import { MainHeader, menuRoutes } from 'components/molecules'
import { SEGMENT_TYPE } from 'constants/segment'
import { SITE_KEYS } from 'constants/site'
import { useApp } from 'context/App'
import Page404 from 'pages/404'
import {
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const NUMBER_GET_REACHABLE = 30
const SITE_KEYS_EXCLUDE_TOTAL_PH = [SITE_KEYS.HELLO_DOCTOR, SITE_KEYS.HELLO_FIL]
const SITE_KEYS_EXCLUDE_TOTAL_MB = [SITE_KEYS.MARRY_BABY]

const SegmentFormWithoutRef = (
  props: SegmentFormContainerProps,
  ref?: Ref<SegmentFormContainerRef>
) => {
  const location = useLocation()
  /** Display the form to create segment for webpush inside the campaign create flow */
  const isCampaignWebpushPage = location.pathname.startsWith(
    '/campaign/web_push/'
  )

  const {
    siteId,
    segment_type,
    title,
    disabled,
    disabledKeys,
    loading: loadingProps,
    notFound,
    defaultValue,
    value: valueProps,
    onChange: onChangeProps,
    saveButtonProps,
    onSave,
    confirmModalProps,
    roles,
    permissions
  } = props
  const initValue = useRef<SegmentFormValue | null>(
    defaultValue || { ...defaultSegmentFormValue, segment_type, ...valueProps }
  )
  const countReachable = useRef(0)
  const debouceReachable = useRef<NodeJS.Timeout | null>(null)
  const navigate = useNavigate()
  const { sites: sitesApp } = useApp()
  const { countAllSites } = useCountAllSite(sitesApp)
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(loadingProps)
  const [value, setValue] = useState<SegmentFormValue>(
    defaultValue || { ...defaultSegmentFormValue, ...valueProps }
  )
  const [analytics, setAnalytics] = useState<
    NonNullable<FetchQueryAnalyticsOutput>['data']
  >(defaultQueryAnalytics)
  const overviewForm = useRef<FormInstance<SegmentFormOverviewValue>>(null)
  const queryForm = useRef<FormInstance<SegmentFormQueryValue>>(null)
  const isPublic = useMemo(() => {
    return value?.status === SEGMENT_STATUS_KEYS.PUBLIC
  }, [value?.status])

  const sites = useMemo(() => {
    const site_ids = value?.site_ids ||
      overviewForm.current?.getFieldValue('site_ids') || [siteId]
    const sites = sitesApp?.filter((s) => site_ids?.includes(s.value)) || []

    const sitesFilter: typeof sites = []
    for (const site of sites) {
      if (SITE_KEYS_EXCLUDE_TOTAL_MB.includes(site.name)) {
        if (
          sitesFilter.some((s) => SITE_KEYS_EXCLUDE_TOTAL_MB.includes(s.name))
        ) {
          continue
        }
      }
      if (SITE_KEYS_EXCLUDE_TOTAL_PH.includes(site.name)) {
        if (
          sitesFilter.some((s) => SITE_KEYS_EXCLUDE_TOTAL_PH.includes(s.name))
        ) {
          continue
        }
      }
      if (site.name === SITE_KEYS.MARRY_BABY) {
        site.domain = 'www.marrybaby.vn' // Fuck marrybaby too much domain (www.marrybaby.vn || marrybaby.vn)
      }
      sitesFilter.push(site)
    }

    return sitesFilter
  }, [sitesApp, value?.site_ids, siteId])
  const siteTotal = useMemo(() => {
    if (!sites || !sites?.length) {
      return 0
    }

    return sites.reduce((total, site) => {
      let value = 0
      if (site.name in countAllSites) {
        value = countAllSites[site.name]
      }
      return total + value
    }, 0)
  }, [sites, countAllSites])

  const isDisabledPrediction = useMemo(() => {
    if (
      value.segment_type === SEGMENT_TYPE.ATTRIBUTE &&
      value.status !== SEGMENT_STATUS_KEYS.PUBLIC
    ) {
      return Boolean(!value.list?.length)
    }

    return true
  }, [value.list?.length, value.segment_type, value.status])

  const {
    loading: loadingSegmentPredictionDrawer,
    data,
    onChange: onChangePrediction,
    defaultValue: segmentPredictionValue,
    open: segmentPredictionOpen,
    setOpen: setSegmentPredictionOpen
  } = useSegmentPredictionDrawer({ value })

  const fetchQueryAnalyticsFrequency = useCallback(
    async (params: any) => {
      setLoading(true)
      const { data, errors } = await fetchQueryAnalytics(params)
      if (debouceReachable.current) clearTimeout(debouceReachable.current)
      if (errors?.length) {
        errors?.forEach((error) => {
          if (
            typeof error.name === 'string' &&
            ['type_time_range'].some((name) => name === error.name)
          ) {
            notification.info({ message: error.errors })
            overviewForm.current?.setFields([
              {
                name:
                  value.type_time_range === 'custom_range'
                    ? 'custom_time_range'
                    : 'default_time_range',
                errors: error.errors,
                touched: true
              }
            ])
          } else {
            notification.info({ message: error.errors })
          }
        })
        setLoading(false)
        return
      }
      if (
        (!data || !data?.isCountSuccess) &&
        countReachable.current < NUMBER_GET_REACHABLE
      ) {
        countReachable.current++
        debouceReachable.current = setTimeout(async () => {
          await fetchQueryAnalyticsFrequency(params)
        }, 1000)
        setLoading(false)
        return
      }

      if (!data) {
        notification.error({ message: 'Something went wrong!' })
        setLoading(false)
        return
      }

      notification.success({ message: 'Your query has completed' })
      setLoading(false)
      setAnalytics(data)
    },
    [value.type_time_range]
  )

  const handleBack = () => {
    navigate(menuRoutes.segment)
  }
  const handleOk = useCallback(async () => {
    try {
      const overviewValue = await overviewForm.current?.validateFields()
      const queryValue = await queryForm.current?.validateFields()
      const newValue = {
        ...value,
        ...overviewValue,
        ...queryValue,
        site_domain: sites.map(({ domain }) => domain) || []
      }

      setValue(newValue)
      const data: any = await onSave?.(newValue)
      if (data?.count_data) {
        setAnalytics(data.count_data)
      }
    } catch (error: any) {
      if (Array.isArray(error?.errorFields)) {
        error.errorFields.forEach((error: any) => {
          if (Array.isArray(error?.name) && error?.message?.length) {
            overviewForm.current?.scrollToField(error.name, {
              behavior: 'smooth',
              block: 'center'
            })
          }
        })
      }
    } finally {
      setModal(false)
    }
  }, [onSave, sites, value])

  const handleCancel = () => {
    if (JSON.stringify(value) !== JSON.stringify(initValue.current)) {
      setModal(true)
      return
    }
    handleBack()
  }

  const handleSegmentFormQuery = useCallback(
    async (values: any) => {
      // const operator = values?.list?.[0]?.operator || 'AND'
      const type = values?.list?.[0]?.segment_type || SEGMENT_TYPE.ATTRIBUTE

      const params = {
        start_date: value?.start_date,
        end_date: value?.end_date,
        type,
        // operator,
        site_code: sites.map(({ domain }) => domain),
        list: values?.list || []
      } as FetchQueryAnalyticsInput
      await fetchQueryAnalyticsFrequency(params)
    },
    [value?.start_date, value?.end_date, sites, fetchQueryAnalyticsFrequency]
  )

  const handleValuesChange = useCallback(
    (valChanged: any, valsChanged: any) => {
      const newVals = { ...value, ...valsChanged }
      if (valChanged?.list?.[0]?.segment_type) {
        newVals.segment_type = valChanged?.list?.[0]?.segment_type
      }
      if (valChanged?.site_ids) {
        newVals.list = []
      }
      setAnalytics(defaultQueryAnalytics)
      setValue(newVals)
      onChangeProps?.(newVals)
    },
    [onChangeProps, value]
  )

  const onChangeSegmentPrediction = useCallback(
    async (val) => {
      try {
        const segment_type = overviewForm.current?.getFieldValue('segment_type')
        const formQueryValue = await queryForm.current?.validateFields()
        const params: Partial<FetchQueryAnalyticsInput> = {
          site_code: sites.map(({ domain }) => domain) || [],
          operator: formQueryValue?.operator,
          type: segment_type,
          list: formQueryValue?.list?.slice(0).map((item) => ({
            ...item,
            conditions: item.conditions
              ?.filter(
                (cond) => cond.field !== defaultValueSegmentPrediction.field
              )
              .concat(val)
          }))
        }
        const indexSegmentPrediction = params.list?.[0].conditions?.findIndex(
          (cond: any) => cond.field === val.field
        )
        if (indexSegmentPrediction !== -1) {
          ;(params.list as any[])[0].conditions[indexSegmentPrediction] = {
            ...params.list?.[0].conditions[indexSegmentPrediction],
            ...val
          }
        } else {
          params.list?.[0].conditions.push(val)
        }

        onChangePrediction(params)
      } catch (error) {
        console.log('** ERROR SegmentForm.tsx : ', error)
      }
    },
    [onChangePrediction, sites]
  )

  const onFinishSegmentPrediction = useCallback(
    async (val) => {
      try {
        const formQueryValue = await queryForm.current?.validateFields()
        const newValue: SegmentFormValue = {
          ...value,
          ...formQueryValue,
          list: formQueryValue?.list?.slice(0).map((item) => ({
            ...item,
            conditions: item.conditions
              ?.filter(
                (cond) => cond.field !== defaultValueSegmentPrediction.field
              )
              .concat(val)
          }))
        }

        setValue(newValue)
        queryForm.current?.setFieldsValue(newValue as any)
        setSegmentPredictionOpen(false)
        setTimeout(() => {
          handleOk()
        }, 10)
      } catch (error: any) {
        console.log('** SegmentForm.tsx ERROR : ', error)
      } finally {
        setModal(false)
      }
    },
    [handleOk, setSegmentPredictionOpen, value]
  )

  useEffect(() => {
    const value = { ...defaultSegmentFormValue, segment_type, ...valueProps }
    setValue(value)
    initValue.current = value
    if (value?.count_data) {
      setAnalytics(formatCountDataToAnalytics((value.count_data || []) as any))
    }
  }, [valueProps, segment_type])

  useEffect(() => {
    setLoading(loadingProps)
  }, [loadingProps])

  useEffect(() => {
    return () => {
      countReachable.current = 0
      if (debouceReachable.current) clearTimeout(debouceReachable.current)
      setModal(false)
    }
  }, [])

  useImperativeHandle(
    ref,
    () => ({
      overviewForm: overviewForm.current,
      queryForm: queryForm.current,
      openConfirm: modal,
      setOpenConfirm: setModal
    }),
    [modal]
  )

  if (notFound) {
    return (
      <MainHeader
        headerComponent={
          <SegmentFormHeader title={title} onBack={handleBack} />
        }
        bgColor="#F7F9FC"
        stickyHeader
        roles={roles}
        permissions={permissions}>
        <Page404 />
      </MainHeader>
    )
  }

  return (
    <MainHeader
      headerComponent={
        <SegmentFormHeader
          title={title}
          onBack={handleBack}
          onOk={handleOk}
          okButtonProps={saveButtonProps}
          onCancel={isCampaignWebpushPage ? undefined : handleCancel}
          hideNavigation={isCampaignWebpushPage}
        />
      }
      bgColor="#F7F9FC"
      stickyHeader
      roles={roles}
      permissions={permissions}>
      <StyledSegmentForm>
        <Row gutter={[24, 24]}>
          {/* {isPublic && (
            <Col span={24}>
              <Alert
                type="warning"
                message="You can view the status of each segment, click to publish your draft segment to create its view. Your Public Segments will be available in many products for you to select and use while creating campaigns and workflows."
              />
            </Col>
          )} */}
          <Col span={12}>
            <StyledSegmentFormBox>
              <SegmentFormOverview
                ref={overviewForm}
                segment_type={segment_type}
                disabled={disabled}
                disabledKeys={disabledKeys}
                initialValues={value}
                onValuesChange={handleValuesChange}
              />
            </StyledSegmentFormBox>
          </Col>
          <Col span={12}>
            <StyledSegmentFormBox>
              <SegmentFormComparison
                segmentId={parseInt(`${value?.id}`)}
                disabledComparison
              />
            </StyledSegmentFormBox>
          </Col>
          <Col span={24}>
            <StyledSegmentFormBox>
              <StyledSegmentFormRow>
                <StyledSegmentFormCol className="form_container">
                  <SegmentFormQuery
                    ref={queryForm}
                    disabledKeys={
                      isPublic && disabledKeys
                        ? [...disabledKeys, 'list']
                        : disabledKeys
                    }
                    segment_type={segment_type}
                    segment_status={value?.status}
                    sites={sites}
                    initialValues={value}
                    onValuesChange={handleValuesChange}
                    onFinish={handleSegmentFormQuery}
                  />
                </StyledSegmentFormCol>
                <StyledSegmentFormCol className="chart_container">
                  <SegmentUserCounter
                    numOfUsers={analytics?.totalCount || 0}
                    total={siteTotal || 0}
                  />
                </StyledSegmentFormCol>
              </StyledSegmentFormRow>
            </StyledSegmentFormBox>
          </Col>
          <Col span={24}>
            <StyledSegmentFormBox className="analytics_container">
              {segmentPredictionValue?.value?.length && (
                <Alert
                  showIcon
                  type="warning"
                  className="mb-4"
                  message={
                    <span>
                      Results are based on predictions within the range of&nbsp;
                      <strong>
                        {getSegmentPredictionSliderValue(
                          segmentPredictionValue.value
                        )
                          .map((s) => `${s}%`)
                          .join(' - ')}
                      </strong>
                      .
                    </span>
                  }
                />
              )}
              <SegmentAnalytic
                loading={loading}
                numOfUsers={analytics?.totalCount || 0}
                reachableData={{
                  AppPushCount: `${analytics?.appPushCount}`,
                  EmailCount: `${analytics?.email}`,
                  // ExcludeFacebookCount: `${analytics?.}`,
                  // FacebookCount: `${analytics?.FacebookCount}`,
                  PhoneCount: `${analytics?.phoneCount}`,
                  TotalCount: `${analytics?.totalCount}`,
                  WebPushCount: `${analytics?.webPushCount}`,
                  WhatsAppCount: `${analytics?.whatsappCount}`
                }}
              />
              <div className="mt-4 flex gap-3">
                <SegmentPredictionDrawer
                  loading={loadingSegmentPredictionDrawer}
                  data={data}
                  disabled={isDisabledPrediction}
                  defaultValue={segmentPredictionValue}
                  onChange={onChangeSegmentPrediction}
                  onFinish={onFinishSegmentPrediction}
                  open={segmentPredictionOpen}
                  onClose={setSegmentPredictionOpen}
                />
              </div>
            </StyledSegmentFormBox>
          </Col>
        </Row>
        <div
          className={cn(
            'absolute inset-0 z-0 w-full h-full pointer-events-none opacity-0 flex items-center justify-center',
            {
              'z-10': loading,
              'opacity-1': loading,
              'pointer-events-auto': loading
            }
          )}>
          <div className="w-full h-full bg-neutral100 absolute inset-0 opacity-50" />
          <Spin spinning />
        </div>
      </StyledSegmentForm>
      <ModalEmpty
        open={modal}
        title="Leaving without save?"
        onOk={confirmModalProps?.onOk}
        okButtonProps={{ danger: true }}
        okText="Confirm"
        onCancel={() => setModal(false)}
        cancelButtonProps={{
          onClick: () => setModal(false)
        }}
        cancelText="Exit">
        If you leave, any changes you’ve made will not be saved.
      </ModalEmpty>
    </MainHeader>
  )
}

export { formatSegmentForm }
export type {
  FetchQueryAnalyticsInput,
  SegmentFormContainerProps,
  SegmentFormContainerRef,
  SegmentFormValue
}
export const SegmentForm = forwardRef(SegmentFormWithoutRef)
