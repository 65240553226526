import { useWorkflowBuilder } from '../../index.context'
import { WorkflowBuilderDrawerTemplate } from '../WorkflowBuilderDrawerTemplate'
import { WorkflowBuilderHandle } from '../WorkflowBuilderHandle'
import { WorkflowBuilderNodeTemplate } from '../WorkflowBuilderNodeTemplate'
import { WFNode, useNode } from '../index.helpers'
import { wfDelayFormToValue, wfDelayValueToForm } from './WFDelay/index.helpers'
import { Form } from 'antd'
import { FormEvent } from 'components/molecules/form/formEvent'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Position } from 'reactflow'
import { EventServices } from 'services/event'
import styled from 'styled-components'

const WFStyledNoContent = styled.div`
  color: ${({ theme }) => theme?.colors?.gray400};
`

const WFPerformAnEvent: WFNode = (props) => {
  const { id, title, bgColor, color, icon, modalWidth, data } = props
  const [form] = Form.useForm()
  const [eventsName, setEventsName] = useState<string[]>([])
  const { nodes, setNodes, onNodesDelete, onNodesChangeData } =
    useWorkflowBuilder()
  const { open, onOpen, onUpdate, onClose } = useNode({
    id,
    form,
    nodes,
    setNodes,
    onNodesDelete,
    onNodesChangeData
  })

  const number = useMemo(() => {
    return data?.totalUser || 0
  }, [data?.totalUser])

  const getEventName = useCallback(async () => {
    if (!data?.events_conditions || !data?.events_conditions?.length) {
      return
    }
    const eventsName = await Promise.all(
      data.events_conditions.map(async (cond: any) => {
        if (!cond?.event_id) {
          return ''
        }
        try {
          const eventRes = await EventServices.getById(cond.event_id)
          if (!eventRes?.data || eventRes.data?.error) {
            throw new Error('Cannot show event')
          }

          return eventRes.data?.name
        } catch (error) {
          console.log(`** Error ${cond.event_id}`)
          return ''
        }
      })
    )
    setEventsName(eventsName)
  }, [data?.events_conditions])

  const onFinish = useCallback(
    async (values: any) => {
      const node = nodes.find((node) => node.id === id)
      if (!node) {
        return
      }
      node.data = wfDelayFormToValue(values)
      onUpdate(node)
      onClose({ isReset: false })
    },
    [id, nodes, onClose, onUpdate]
  )

  useEffect(() => {
    getEventName()
  }, [getEventName])

  return (
    <>
      <WorkflowBuilderNodeTemplate
        bgColor={bgColor}
        color={color}
        icon={icon}
        title={title}
        description={`${number} users`}
        onClick={onOpen}
        isShowDivider
        sources={
          <WorkflowBuilderHandle
            type="source"
            id={`${id}-source`}
            position={Position.Bottom}
          />
        }
        targets={
          <WorkflowBuilderHandle
            type="target"
            id={`${id}-target`}
            position={Position.Top}
          />
        }>
        {(!data?.events_conditions || !data?.events_conditions.length) && (
          <WFStyledNoContent>No condition selected</WFStyledNoContent>
        )}
        {Boolean(eventsName?.length) && (
          <WFStyledNoContent>
            Only create new segment or updates if user meets&nbsp;
            <strong>{eventsName.join(',')}</strong>
          </WFStyledNoContent>
        )}
      </WorkflowBuilderNodeTemplate>
      <WorkflowBuilderDrawerTemplate
        open={open}
        width={modalWidth}
        title={title}
        onClose={onClose}
        onUpdate={form.submit}
        destroyOnClose>
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={wfDelayValueToForm({ ...data })}>
          <FormEvent name="events_conditions" />
        </Form>
      </WorkflowBuilderDrawerTemplate>
    </>
  )
}

WFPerformAnEvent.validate = (node) => {
  if (
    !node.data ||
    !node.data?.events_conditions ||
    !node.data?.events_conditions?.length
  ) {
    return false
  }
  return true
}
export { WFPerformAnEvent }
