import AttributeFields from './attributeFields'
import {
  StyleButton,
  StyleConditionActions,
  StyleConditionRow,
  StyleText,
  StyleWrapperField
} from './index.styled'
import Icon from '@ant-design/icons'
import { Button, Form } from 'antd'
import { DatePicker } from 'components/atoms/datePicker'
import { ModalDictionary } from 'components/atoms/modal'
import { ConditionsRelation } from 'components/condition/conditions/relation'
import dayjs from 'dayjs'
import { DataDictionary, Plus, TrashBin } from 'icons'
import { ConditionDataType } from 'interfaces/condition'
import { FC, useState } from 'react'
import { getFormNamePath } from 'utils/form'

interface FormUserAttributeProps {
  attributes: ConditionDataType[]
}

const FormUserAttribute: FC<FormUserAttributeProps> = ({ attributes }) => {
  const form = Form.useFormInstance()
  const fieldRelation = Form.useWatch(getFormNamePath('relation'), form)
  const [openModal, setOpenModal] = useState(false)
  const [nameField, setNameField] = useState<string | number>('')

  const onSelectAttribute = (attribute: any) => {
    let resetFieldValue = null
    if (attribute.data_type === 'API' && attribute.type === 'MANY') {
      resetFieldValue = []
    }
    form.setFields([
      {
        name: getFormNamePath('conditions', [nameField, 'attribute']),
        value: attribute.id
      },
      {
        name: getFormNamePath('conditions', [nameField, 'value']),
        value: resetFieldValue
      },
      {
        name: getFormNamePath('conditions', [nameField, 'operation']),
        value: null
      }
    ])
    setOpenModal(false)
    setNameField('')
  }

  const onChangeRelation = (value: any) => {
    form.setFieldValue('relation', value)
  }

  const onShowDictionary = (val: string | number) => {
    setOpenModal(true)
    setNameField(val)
  }

  return (
    <>
      <Form.Item
        label="Time range"
        name="date_range"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 12 }}
        rules={[
          {
            required: true,
            message: 'Please select time range'
          }
        ]}>
        <DatePicker.RangePicker
          disabledDate={(current) => {
            return current && current >= dayjs().startOf('day')
          }}
          size="small"
        />
      </Form.Item>
      <StyleText>where</StyleText>
      <Form.Item hidden name="relation">
        <ConditionsRelation />
      </Form.Item>
      <Form.List name="conditions">
        {(conditions, operations) => (
          <>
            {conditions.map((condition, index) => {
              return (
                <div key={condition.key}>
                  <StyleConditionRow key={condition.key}>
                    <StyleWrapperField>
                      <AttributeFields
                        attributeFields={attributes}
                        formListFieldData={condition}
                        formListOperation={operations}
                      />
                    </StyleWrapperField>
                    <StyleConditionActions>
                      <StyleButton
                        icon={<Icon component={TrashBin} />}
                        size="small"
                        onClick={() => {
                          operations.remove(condition.name)
                        }}
                      />
                      <StyleButton
                        icon={<Icon component={DataDictionary} />}
                        size="small"
                        onClick={() => onShowDictionary(condition.name)}
                      />
                    </StyleConditionActions>
                  </StyleConditionRow>
                  {index !== conditions.length - 1 && (
                    <ConditionsRelation
                      value={fieldRelation || 'OR'}
                      onChange={onChangeRelation}
                    />
                  )}
                </div>
              )
            })}
            <Button
              onClick={() => operations.add()}
              size="small"
              icon={<Icon component={Plus} />}>
              Add
            </Button>
          </>
        )}
      </Form.List>
      <ModalDictionary
        open={openModal}
        attributeDataSource={attributes}
        disabledTabs={['event']}
        onRowItemClick={onSelectAttribute}
        onClose={() => setOpenModal(false)}
      />
    </>
  )
}

export { FormUserAttribute }
